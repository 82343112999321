<template>
  <div>
    <a-modal
      title="Please fill the form fields"
      :visible="Boolean(modalFormInfo)"
      :confirm-loading="confirmLoading"
      ok-text="Submit"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <FormPage
        :form-data="formData.fields"
        :old-form-values="formData.results"
        :validation-errors="errors"
        @input-change="formChange"
      />
    </a-modal>
  </div>
</template>
<script>
import FormPage from '../FormPage/FormPage';
import socketError from '../../../utils/socketError';

export default {
  components: { FormPage },
  // eslint-disable-next-line vue/require-prop-types
  props: ['modalFormInfo'],
  data() {
    return {
      formValue: {},
      confirmLoading: false,
      formData: {},
      errors: {},
    };
  },
  mounted() {
    this.getFormData();
  },
  methods: {
    formChange(e) {
      this.formValue = {
        ...this.formValue,
        ...Object.values(e)[0],
      };
    },
    async getFormData() {
      this.confirmLoading = true;
      try {
        const res = await axios.get(`${this.modalFormInfo.path}/${this.modalFormInfo.id}/${this.modalFormInfo.action}`);
        if (res && res.data && res.data.fields) {
          this.formData = res.data;
        }
        this.confirmLoading = false;
        return res.data;
      } catch (e) {
        this.confirmLoading = false;
        socketError({
        }, e);
        return false;
      }
    },
    async submitData() {
      try {
        const res = await axios.post(`${this.modalFormInfo.path}/${this.modalFormInfo.id}/${this.modalFormInfo.action}/store`, {
          ...this.formValue,
        });
        return res.data;
      } catch (e) {
        socketError({}, e);
        return false;
      }
    },
    async handleOk() {
      this.confirmLoading = true;
      await this.submitData().then(async (e) => {
        if (!e.errors) {
          this.$emit('update-table-info');
          await this.$store.dispatch('modals/toggleDynamicFormModal');
        } else {
          this.errors = e.errors;
          this.confirmLoading = false;
        }
      }).catch(() => {
        this.confirmLoading = false;
      });
    },
    async handleCancel() {
      await this.$store.dispatch('modals/toggleDynamicFormModal');
    },
  },
};
</script>
