<template>
  <div class="exact-client-page">
    <a-spin :spinning="isLoading">
      <div class="client-page-header">
        <ClientInfoCard :data="exactClientInfo.clientInfo" :hide-edit-btn="false" />
        <ClientDetailsCards :data="exactClientInfo.clientDetails" />
      </div>
      <ClientPageMenu
        :exact-user-menu-section="exactUserMenuSection"
      >
        <div class="page-wrapper">
          <div v-if="$route.params.menuItem==='main'|| $route.params.menuItem===''"
               class="main-page-info"
          >
            <ClientBalanceCard :data="exactClientInfo.clientBalance" />
            <WidgetChartCard
              v-for="(card, index) in exactClientInfo.totalTransactions"
              :key="index"
              :title="card.title"
              :data="card.data"
            />
          </div>
          <div v-else ref="pageInfo" class="page-info">
            <div v-if="$route.params.menuItem==='correction'
                   || $route.params.menuItem==='client-notification'"
                 class="correction-form-page"
            >
              <!--          <FormPage1-->
              <!--            :form-data="exactClientInfo && exactClientInfo.fields"-->
              <!--            :is-form-data-loading="isLoading"-->
              <!--            :submit-path="`${$route.path}/store`"-->
              <!--          />-->
              <FormPage
                :form-data="exactClientPageInfo && exactClientPageInfo.fields"
                :old-form-values="exactClientPageInfo && exactClientPageInfo.result"
                :own-submit="true"
                :is-loading="isFormPageLoading"
                @form-data-submit="formDataSubmit"
              />
            </div>
            <div v-else>
              <div
                v-for="(table, key) in exactClientPageInfo"
                :key="key"
              >
                <div v-if="table.filter" class="filter-wrapper">
                  <FilterForm
                    :filter-list="table.filter"
                    :own-submit="true"
                    :is-loading="isLoading"
                    :old-form-values="exactClientSearchFormData"
                    @filter-form-search="onFilterFormSearch"
                  />
                </div>
                <!--                <div v-if="$route.params.menuItem === 'transaction-game'">-->
                <!--                  <TopWinsTable-->
                <!--                    :id="$route.params.id"-->
                <!--                  />-->
                <!--                </div>-->
                <div>
                  <AntdTable
                    :loading="isLoading"
                    :columns="table.columns"
                    :rows="table.results.data"
                    :order-params="exactClientOrderParams"
                    :total="table.results.total"
                    :page-value="table.results.current_page"
                    :page-count="table.results
                      && Math.ceil(table.results.total / table.results.per_page)"
                    :pagination="Math.ceil(table.results.total / table.results.per_page) > 1"
                    :per-page="table.results.per_page"
                    @on-page-change="tablePageChange"
                    @per-page-select-change="perPageSelectChange"
                    @search-by-order="searchByOrder"
                  >
                    <div>
                      <div v-if="table.actions && table.actions[0]">
                        <span v-for="(action, index) in table.actions" :key="index">
                          <a-button v-if="action.field === 'create'"
                                    type="primary"
                                    @click="toggleCreateFormModal('create')"
                          >
                            {{ action.label }}
                          </a-button>
                        </span>
                      </div>
                      <div>
                        <DownLoadFile v-if="downLoadToken"
                                      :search-params="{
                                        orderBy: orderParams.orderBy || 'ASC',
                                        column: orderParams.column || '',
                                        perPage: 5000,
                                        ...filterFormData,
                                        token: downLoadToken,
                                      }"
                        />
                      </div>
                    </div>
                  </AntdTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ClientPageMenu>
      <EditFormPage
        :visible="isClientEditFormModalOpen"
        type="edit"
        update-dispatch-action="player/updatePlayerModuleById"
        get-edit-form-dispatch-action="player/editPlayerModuleById"
        state-name="player"
        unmount-action="player/deleteEditFormJson"
        request-path="/player/client"
        :id-of-edit-modal="isClientEditFormModalOpen"
        @toggle-modal="toggleClientEditFormModal"
      />
      <EditFormPage
        :visible="isEditFormModalOpen"
        type="edit"
        update-dispatch-action="player/updatePlayerModuleById"
        get-edit-form-dispatch-action="player/editPlayerModuleById"
        state-name="player"
        unmount-action="player/deleteEditFormJson"
        :request-path="!$route.params.menuItem || $route.params.menuItem === 'main'
          ? '/player/client'
          : `/player/${$route.params.module}/${$route.params.id}/${$route.params.menuItem}`"
        :id-of-edit-modal="isEditFormModalOpen"
        @toggle-modal="toggleEditFormModal"
      />
      <EditFormPage
        type="edit"
        :visible="isEditBalanceFormModalOpen"
        update-dispatch-action="player/updatePlayerModuleById"
        get-edit-form-dispatch-action="player/editPlayerModuleById"
        state-name="player"
        unmount-action="player/deleteEditFormJson"
        :request-path="
          `/player/${$route.params.module}/${$route.params.id}/client-balance`"
        :id-of-edit-modal="isEditBalanceFormModalOpen"
        @toggle-modal="toggleBonusBalanceEditFormModal"
      />
      <CreateFormPage
        :visible="isCreateFormModalOpen"
        :id-of-create-modal="isCreateFormModalOpen"
        @toggle-modal="toggleCreateFormModal('after-create')"
      />
      <DeleteModal
        v-if="isDeleteModalOpen"
        :info="isDeleteModalOpen"
        delete-vuex-action="player/deleteById"
        @update-table-info="getPlayerInfo"
      />
      <AntdModal
        :text="`Are You Sure You Want To Verify Client With ID: ${$route.params.id}`"
        ok-text="Verify"
        :visible="isVerifyModalOpen"
        @handle-save="verifyClient"
        @close-modal="() => (isVerifyModalOpen = false)"
      />
      <RiskStatusModal
        :is-loading="isLoading"
        :default-value="clientRiskStatus"
        :client-id="$route.params.id"
        :visible="isRiskStatusModalOpen"
        @handle-accept="changeRiskStatus"
        @close-modal="() => (isRiskStatusModalOpen = false)"
      />
      <AntdModal
        :text="`Are You Sure You Want To Verify
        Client With ID: ${$route.params.id} Without Giving Bonus`"
        ok-text="Verify"
        :visible="isWithoutBonusVerifyModalOpen"
        @handle-save="verifyWithoutBonus"
        @close-modal="() => (isWithoutBonusVerifyModalOpen = false)"
      />
      <AntdModal
        :text="`Are You Sure You Want To Unverify
        Client With ID: ${$route.params.id}`"
        ok-text="Unverify"
        :visible="isUnVerifyModalOpen"
        @handle-save="unVerifyClient"
        @close-modal="() => (isUnVerifyModalOpen = false)"
      />
      <IframeModal
        :visible="Boolean(isIframeModalOpen)"
        :req-url="isIframeModalOpen"
        @toggle-modal="toggleIframeModal"
      />
      <ApproveModal
        v-if="isApproveModalOpen"
        :id="isApproveModalOpen"
      />
      <RejectModal
        v-if="isRejectModalOpen"
        :modal-form-content="isRejectModalOpen"
      />
    </a-spin>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import Table from '../components/Table/Table';
import AntdTable from '../components/Table/AntdTable';
import ClientPageMenu from '../components/partial/ClientPage/ClientPageMenu/ClientPageMenu';
import FormPage from '../components/partial/FormPage/FormPage';
import EditFormPage from './EditFormPage';
import CreateFormPage from './CreateFormPage';
import DeleteModal from '../components/partial/Modals/DeleteModal';
import FilterForm from '../components/partial/CustomFilterList/FilterForm';
import AntdModal from '../components/partial/Modals/AntdModal';
import ApproveModal from '../components/partial/Modals/ApproveModal';
import RejectModal from '../components/partial/Modals/RejectModal';
import IframeModal from '../components/partial/Modals/IframeModal';
import RiskStatusModal from '../components/partial/Modals/RiskStatusModal';
import ClientDetailsCards from '../components/partial/ClientPage/ClientCards/ClientDetailsCards';
import ClientInfoCard from '../components/partial/ClientPage/ClientCards/ClientInfoCard';
import WidgetChartCard from '../components/partial/ClientPage/ClientCards/WidgetChartCard';
import ClientBalanceCard from '../components/partial/ClientPage/ClientCards/ClientBalanceCard';
// import TopWinsTable from '../components/partial/components/TopWinsTable';
import DownLoadFile from '../components/partial/DownLoadFile';
import socketError from '../utils/socketError';

export default {
  components: {
    ClientPageMenu,
    AntdTable,
    FormPage,
    EditFormPage,
    CreateFormPage,
    DeleteModal,
    FilterForm,
    AntdModal,
    ApproveModal,
    RejectModal,
    IframeModal,
    ClientInfoCard,
    RiskStatusModal,
    ClientDetailsCards,
    WidgetChartCard,
    // TopWinsTable,
    ClientBalanceCard,
    DownLoadFile,
  },
  data() {
    return {
      isLoading: false,
      columns: [],
      response: '',
      rows: [],
      filterFormData: {},
      orderParams: {},
      menuOpened: false,
      isVerifyModalOpen: false,
      isWithoutBonusVerifyModalOpen: false,
      isUnVerifyModalOpen: false,
      isRiskStatusModalOpen: false,
      isFormPageLoading: false,
    };
  },
  computed: {
    isMenuToggleable() {
      return window.innerWidth < 800;
    },
    title() {
      return this.$route.params.menuItem || 'Main';
    },
    ...mapState({
      isIframeModalOpen(state) {
        return state.modals.isIframeModalOpen;
      },
      isRejectModalOpen(state) {
        return state.modals.isRejectModalOpen;
      },
      isApproveModalOpen(state) {
        return state.modals.isApproveModalOpen;
      },
      isDeleteModalOpen(state) {
        return state.modals.isDeleteModalOpen[this.$route.params.module];
      },
      isCreateFormModalOpen(state) {
        return state.modals.isCreateFormModalOpen;
      },
      isClientEditFormModalOpen(state) {
        return state.modals.isClientEditFormModalOpen;
      },
      isEditBalanceFormModalOpen(state) {
        return state.modals.isEditBalanceFormModalOpen;
      },
      isEditFormModalOpen(state) {
        return state.modals.isEditFormModalOpen;
      },
      exactClientMenuSection(state) {
        return state.player.exactClientInfo[this.$route.params.id]
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem];
      },
      exactClientSearchFormData(state) {
        return state.player.exactClientInfo[this.$route.params.id]
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem]
          // eslint-disable-next-line max-len
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem].filterFormData;
      },
      exactClientOrderParams(state) {
        return state.player.exactClientInfo[this.$route.params.id]
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem]
          // eslint-disable-next-line max-len
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem].orderParams;
      },
      exactClientInfo(state) {
        return (state.player.exactClientInfo[this.$route.params.id]
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem]
          // eslint-disable-next-line max-len
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem].data) || {};
      },
      exactClientPageInfo(state) {
        return state.player.exactClientInfo[this.$route.params.id]
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem]
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem].data
          // eslint-disable-next-line max-len
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem].data.page_info;
      },
      downLoadToken(state) {
        return state.player.exactClientInfo[this.$route.params.id]
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem]
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem].data
          // eslint-disable-next-line max-len
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem].data.page_info[this.$route.params.menuItem]
          // eslint-disable-next-line max-len
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem].data.page_info[this.$route.params.menuItem].download_token;
      },
      isExactClientVerified(state) {
        return state.player.exactClientInfo[this.$route.params.id]
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem]
          // eslint-disable-next-line max-len
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem].is_verified;
      },
      clientRiskStatus(state) {
        return state.player.exactClientInfo[this.$route.params.id]
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem]
          // eslint-disable-next-line max-len
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem].client_risk_status;
      },
      isNBVerified(state) {
        return state.player.exactClientInfo[this.$route.params.id]
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem]
          // eslint-disable-next-line max-len
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem].is_nb_verified;
      },
      exactUserMenuSection(state) {
        return state.player.exactClientInfo[this.$route.params.id]
          && state.player.exactClientInfo[this.$route.params.id].menu;
      },
      menuStyle(state) {
        return state.player.exactClientInfo[this.$route.params.id]
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem]
          // eslint-disable-next-line max-len
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem].menuStyle;
      },
    }),
  },
  watch: {
    async $route() {
      if (!this.$route.params.menuItem) {
        this.$nextTick(async () => {
          await this.$router.push(`${this.$route.path}/main`);
        });
      }
      if (!this.exactClientMenuSection && this.$route.params.menuItem) {
        this.setDefaultsParams();
        await this.getPlayerInfo();
      }
    },
  },
  async mounted() {
    if (!this.$route.params.menuItem) {
      this.$nextTick(async () => {
        await this.$router.push(`${this.$route.path}/main`);
      });
    } else {
      await this.getPlayerInfo();
    }
    if (this.$route.params.menuItem === 'main') {
      // eslint-disable-next-line max-len
      if (this.exactClientInfo && (this.exactClientInfo.clientInfo || this.exactClientInfo.clientDetails || this.exactClientInfo.clientBalance || this.exactClientInfo.totalTransactions)) {
        this.testData = this.exactClientInfo;
      }
    }
  },
  methods: {
    async toggleIframeModal() {
      await this.$store.dispatch('modals/toggleIframeModal');
    },
    async unVerifyClient() {
      try {
        const res = await axios.post('/player/unverify-client',
          { client_id: this.$route.params.id });
        this.isUnVerifyModalOpen = false;
        await this.getPlayerInfo();
        return res.data;
      } catch (e) {
        this.isUnVerifyModalOpen = false;
        return false;
      }
    },
    async changeRiskStatus(riskStatus) {
      this.isLoading = true;
      try {
        await axios.post('/player/change-risk-status',
          {
            client_id: this.$route.params.id,
            client_risk_status: riskStatus,
          });
        this.isRiskStatusModalOpen = false;
        this.isLoading = false;
        return await this.getPlayerInfo();
      } catch (e) {
        this.isLoading = false;
        this.isRiskStatusModalOpen = false;
        return false;
      }
    },
    async verifyWithoutBonus() {
      try {
        const res = await axios.post('/player/verify-client-without-bonus',
          { client_id: this.$route.params.id });
        this.isWithoutBonusVerifyModalOpen = false;
        await this.getPlayerInfo();
        return res.data;
      } catch (e) {
        this.isWithoutBonusVerifyModalOpen = false;
        return false;
      }
    },
    async verifyClient() {
      try {
        const res = await axios.post(`player/client/${this.$route.params.id}/verify`,
          { client_id: this.$route.params.id });
        this.isVerifyModalOpen = false;
        await this.getPlayerInfo();
        return res.data;
      } catch (e) {
        this.isVerifyModalOpen = false;
        return false;
      }
    },
    toggleMenu() {
      this.menuOpened = !this.menuOpened;
    },
    async perPageSelectChange() {
      await this.getPlayerInfo();
    },
    setDefaultsParams() {
      this.filterFormData = {};
      this.orderParams = {};
    },
    async searchByOrder(data) {
      this.orderParams = data;
      await this.getPlayerInfo();
    },
    async onFilterFormSearch(filterFormData) {
      this.filterFormData = { ...filterFormData };
      await this.getPlayerInfo();
    },
    async toggleCreateFormModal(message) {
      await this.$store.dispatch('modals/toggleCreateFormModal');
      if (message === 'after-create') {
        await this.getPlayerInfo();
      }
    },
    async toggleBonusBalanceEditFormModal() {
      await this.$store.dispatch('modals/toggleEditBalanceFormModal');
      await this.getPlayerInfo();
    },
    async toggleClientEditFormModal() {
      await this.$store.dispatch('modals/toggleClientEditFormModal');
      const query = _.clone(this.$route.query);
      delete query.modalId;
      await this.$router.replace({ query });
      await this.getPlayerInfo();
    },
    async toggleEditFormModal() {
      await this.$store.dispatch('modals/toggleEditFormModal');
      const query = _.clone(this.$route.query);
      delete query.modalId;
      await this.$router.replace({ query });
      await this.getPlayerInfo();
    },
    tablePageChange() {
      this.getPlayerInfo();
    },
    async formDataSubmit(data) {
      this.isFormPageLoading = true;
      try {
        const res = await axios.post(`${this.$route.path}/store`, { data });
        this.response = res.data;
        this.isFormPageLoading = false;
        return res.data;
      } catch (e) {
        this.isFormPageLoading = false;
        socketError({
        }, e);
        return false;
      }
    },
    async getPlayerInfo() {
      this.isLoading = true;
      const params = {
        id: this.$route.params.id,
        path: this.$route.path,
        page: this.$route.query.page,
        perPage: localStorage.getItem('tablePerPage') || this.$route.query.perPage || 10,
        filterFormData: this.filterFormData,
        orderParams: this.orderParams,
        menuItem: this.$route.params.menuItem,
      };
      await this.$store.dispatch('player/getExactClientInfo', params);
      this.isLoading = false;
      // console.log('exactUserMenuSection', this.exactUserMenuSection);
    },
  },
};
</script>

<style scoped lang="scss">
.exact-client-page{
  padding: 20px 24px;
  .client-page-header{
    display: flex;
  }
  .menu-wrapper{
    position: relative;
    padding-top: 20px;
    >i{
      position: absolute;
      font-size: 30px;
      color: #090961b8;
      top: 0;
      left: 20px;
      cursor: pointer;
    }
  }
  .page-wrapper{
    /*margin: auto;*/
    /*margin-top: 5%;*/
    padding-top: 30px;
    .main-page-info{
      display: flex;
      /*justify-content: space-between;*/
      flex-wrap: wrap;
    }
    .header-wrapper{
      display: flex;
      justify-content: flex-end;
      .verify-button-wrapper{
        .risk-status-btn{
          border: 1px solid #1890FF!important;
        }
        button{
          font-weight: 800;
        }
      }
      /*.header{*/
      /*  flex-grow: 1;*/
      /*  margin-bottom: 20px;*/
      /*  p:first-child{*/
      /*    font-size: 40px;*/
      /*    font-weight: 600;*/
      /*    line-height: 1.1;*/
      /*    margin-bottom: 10px;*/
      /*    >span{*/
      /*      display: block;*/
      /*      font-size: 35px;*/
      /*      font-style: italic;*/
      /*      >span{*/
      /*        color: #6d262f;*/
      /*      }*/
      /*    }*/
      /*  }*/
      /*  p:last-child{*/
      /*    font-size: 25px;*/
      /*    font-weight: 600;*/
      /*    color: #464646;*/
      /*  }*/
      /*}*/
    }
    .button-wrapper{
      text-align: left;
      margin-bottom: 15px;
    }
    .main-page{
      .table {
        .data-table{
          margin: 0;
        }
      }
    }
    .correction-form-page{
      border-radius: 10px;
      background: #f5f5f5;
    }
  }
}
</style>
