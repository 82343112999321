<template>
  <div class="player">
    <div class="title">
      <span>
        Client List
      </span>
    </div>
    <ClientList />
  </div>
</template>

<script>
import ClientList from '../components/partial/ClientPage/ClientList';

export default {
  components: { ClientList },
};
</script>

<style scoped lang="scss">
.player{
  .title{
    color: #000000;
    font-size: 14px;
    background: #fff;
    text-align: left;
    padding: 8px 0 8px 25px;
    display: flex;
  }
}
</style>
