<template>
  <div class="collapse-report">
    <div class="filter-wrapper">
      <FilterForm
        :key="Math.random()"
        :filter-list="filter"
        :own-submit="true"
        :is-loading="isLoading"
        :old-form-values="searchParams"
        :has-reset-button="true"
        :is-reset-filter-loading="isResetFilterLoading"
        @reset-search-fields="resetFilters"
        @filter-form-search="onFilterFormSearch"
      />
    </div>
    <div style="margin-bottom: 10px">
      <DownLoadFile v-if="reportTotals && reportTotals.download_token"
                    :search-params="{
                      ...searchParamsForDownloadFile,
                      perPage: 10000000,
                      token: reportTotals && reportTotals.download_token,
                    }"
      />
    </div>
    <a-collapse :class="{loading: isLoading}">
      <a-collapse-panel
        v-for="(collapse, index) in collapseReport"
        :key="index"
        :disabled="!collapse.collapseAble"
      >
        <template slot="header">
          <div
            class="header-wrapper"
          >
            <h3 class="collapse-title">
              {{ collapse.title }}
            </h3>
            <template v-if="collapse.mainRow">
              <a-card
                v-for="(card, ind) in collapse.mainRow"
                :key="ind"
                size="small"
                :head-style="headStyle"
                :hoverable="true"
                :title="card.label"
                style="min-width: 150px; margin-right: 20px"
              >
                {{ card.type === 'number' ? Number(card.value).toLocaleString() : card.value }}
                <span v-if="card.currency">{{ card.currency }}</span>
              </a-card>
            </template>
          </div>
        </template>
        <AntdTable v-if="collapse.table"
                   :columns="collapse.table.columns"
                   :rows="collapse.table.results && collapse.table.results.data"
        />
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AntdTable from '../components/Table/AntdTable';
import FilterForm from '../components/partial/CustomFilterList/FilterForm';
import DownLoadFile from '../components/partial/DownLoadFile';

export default {
  components: { AntdTable, FilterForm, DownLoadFile },
  data() {
    return {
      isLoading: false,
      isResetFilterLoading: false,
      headStyle: { 'font-weight': 'bold' },
      collapseRep: [
        {
          collapseAble: false,
          title: 'Extra Aprroved',
          mainRow: [
            { label: 'Total', value: 5000000000000000 },
            { label: 'Cosdf', value: 200 },
          ],
        },
        {
          collapseAble: true,
          title: 'Withraw Requests',
          mainRow: [
            { label: 'Total', value: 800 },
          ],
          table: {
            rows: [
              {
                key: '1',
                name: 'John Brown',
                age: 32,
                address: 'New York No. 1 Lake Park, New York No. 1 Lake Park',
                tags: ['nice', 'developer'],
              },
              {
                key: '2',
                name: 'Jim Green',
                age: 42,
                address: 'London No. 2 Lake Park, London No. 2 Lake Park',
                tags: ['loser'],
              },
              {
                key: '3',
                name: 'Joe Black',
                age: 32,
                address: 'Sidney No. 1 Lake Park, Sidney No. 1 Lake Park',
                tags: ['cool', 'teacher'],
              },
            ],
            columns: [
              {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                scopedSlots: { customRender: 'name' },
              },
              {
                title: 'Age',
                dataIndex: 'age',
                key: 'age',
                width: 80,
              },
              {
                title: 'Address',
                dataIndex: 'address',
                key: 'address 1',
                ellipsis: true,
              },
              {
                title: 'Long Column Long Column Long Column',
                dataIndex: 'address',
                key: 'address 2',
                ellipsis: true,
              },
              {
                title: 'Long Column Long Column',
                dataIndex: 'address',
                key: 'address 3',
                ellipsis: true,
              },
              {
                title: 'Long Column',
                dataIndex: 'address',
                key: 'address 4',
                ellipsis: true,
              },
            ],
          },
        },
      ],
    };
  },
  computed: {
    ...mapState({
      reportTotals(state) {
        return state.reports.collapseReport;
      },
      collapseReport(state) {
        return state.reports.collapseReport.report;
      },
      searchParamsForDownloadFile(state) {
        return state.reports.collapseReport
          && state.reports.collapseReport.searchParamsForDownloadFile;
      },
      searchParams(state) {
        return state.reports.collapseReport && state.reports.collapseReport.searchParams;
      },
      filter(state) {
        return state.reports.collapseReport && state.reports.collapseReport.filter;
      },
    }),
  },
  async mounted() {
    if (!this.collapseReport) {
      await this.getReport();
    }
  },
  methods: {
    async resetFilters() {
      // it's for reset btn loader
      this.isResetFilterLoading = true;
      await this.getReport({}, true);
      this.isResetFilterLoading = false;
    },
    async onFilterFormSearch(filterFormParams) {
      await this.getReport(filterFormParams);
    },
    async getReport(filterFormParams, isReset) {
      if (!isReset) {
        this.isLoading = true;
      }
      try {
        const params = {
          path: this.$route.path,
          filterFormParams,
        };
        const res = await this.$store.dispatch('reports/getCollapseReport', params);
        this.isLoading = false;
        return res;
      } catch (e) {
        this.isLoading = false;
        return e;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.collapse-report{
  text-align: left;
  padding: 0 24px;
  .filter-wrapper{
  }
  .loading{
    filter: blur(2px);
    -webkit-filter: blur(2px);
  }
  .card-wrapper{
    display: flex;
  }
  .header-wrapper{
    display: flex;
    align-items: center;
    .collapse-title{
      font-weight: bold;
      font-size: 18px;
      min-width: 350px
    }
  }
}
</style>
