<template>
  <div>
    <a-modal
      title="Approve"
      :visible="Boolean(id)"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form-item
        :validate-status="validatingStatus"
        :help="validateHelp"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        label="Expires On"
      >
        <a-date-picker :disabled-date="disabledDate" @change="onChange" />
      </a-form-item>
    </a-modal>
  </div>
</template>
<script>

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['id'],
  data() {
    return {
      confirmLoading: false,
      dateValue: null,
      validatingStatus: 'validating',
      validateHelp: '',
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      },
    };
  },
  methods: {
    onChange(e) {
      this.dateValue = e.unix();
      this.validatingStatus = 'validating';
      this.validateHelp = '';
    },
    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().endOf('day');
    },
    async handleOk() {
      if (this.dateValue) {
        this.confirmLoading = true;
        try {
          await axios.get(`${this.$route.path}/${this.id}/approve`, {
            params: {
              expiresOn: this.dateValue,
            },
          });
          this.confirmLoading = false;
          await this.$store.dispatch('modals/toggleApproveModal');
        } catch (e) {
          this.confirmLoading = false;
        }
      } else {
        this.validatingStatus = 'error';
        this.validateHelp = 'Provide An Expiration Date';
      }
    },
    async handleCancel() {
      await this.$store.dispatch('modals/toggleApproveModal');
    },
  },
};
</script>
