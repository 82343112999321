<template>
  <div class="drag-able-categories" :class="{loading: isLoading}">
    <div class="drag-able-content">
      <SlickList
        v-model="items"
        lock-axis="y"
        @sort-start="onSortStart"
        @sort-end="onSortEnd"
      >
        <SlickItem
          v-for="(item, index) in items"
          :key="item.id"
          :index="index"
          class="dragable-item"
          :class="{ onDrag: activeDragItem === index }"
        >
          {{ item.name }}
        </SlickItem>
      </SlickList>
    </div>
    <div style="background: white; padding-bottom: 20px">
      <a-button
        class="btn"
        type="primary"
        @click="saveChanges"
      >
        Save
      </a-button>
    </div>
  </div>
</template>

<script>
import {
  SlickList, SlickItem,
} from 'vue-slicksort';

export default {
  components: {
    SlickItem,
    SlickList,
  },
  props: {
    moduleName: {
      type: String,
      default: '',
    },
    dragableOrderData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      items: [],
      isDragMoment: false,
      activeDragItem: -1,
      sortedIdes: [],
      isLoading: false,
    };
  },
  watch: {
    dragableOrderData(items) {
      this.items = items;
    },
    items() {
      this.setSortedIdes();
    },
  },
  mounted() {
    this.items = this.dragableOrderData;
  },
  methods: {
    onSortEnd() {
      this.activeDragItem = -1;
    },
    onSortStart(e) {
      this.activeDragItem = e.index;
    },
    setSortedIdes() {
      if (this.sortedIdes.length) {
        this.$store.commit('gameModule/setDragableOrderModule', {
          data: { results: this.items },
          type: this.$route.query.type,
          moduleName: this.moduleName,
        });
      }
      this.sortedIdes = this.items.map((category) => category.id);
    },
    async saveChanges() {
      this.isLoading = true;
      await this.$store.dispatch('gameModule/sendDragableOrderModuleIdes', {
        sortedIdes: this.sortedIdes,
        type: this.$route.query.type,
        moduleName: this.moduleName,
        path: this.$route.path,
      });
      this.isLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.drag-able-categories {
  .drag-able-content {
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #e8e8e8;
  }

  &.loading {
    filter: blur(1px);
    -webkit-filter: blur(1px);
  }

  .dragable-item {
    height: 45px;
    text-align: left;
    color: #000000a6;
    border-bottom: 1px solid #e8e8e8;
    background: #fff;
    cursor: pointer;
    padding-left: 20px;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;

    &:hover {
      background: #e6f7ff;
    }
  }

  .btn {
    margin-top: 30px
  }
}
</style>
