<template>
  <div ref="multiple-select" class="input-multiple-select">
    <a-form-item
      :validate-status="validatingStatus"
      :help="validateHelp"
      class="form-item-holder"
    >
      <template slot="label">
        <span class="form-item-label-text">{{ label }}</span>
      </template>
      <a-select
        v-model="selectedValue"
        mode="multiple"
        placeholder="Please select"
        :disabled="disabled === 'disabled'"
        style="min-width: 200px"
        :validate-status="validatingStatus"
        :help="validateHelp"
        :filter-option="filterOption"
        @change="handleChange"
      >
        <a-select-option v-for="(opt, ind) in options"
                         :key="ind"
                         :disabled="disabled && disabled.includes(opt.code)"
        >
          {{ opt.label }}
        </a-select-option>
      </a-select>
    </a-form-item>
  </div>
</template>
<script>
import socketError from '../../utils/socketError';

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['type', 'label', 'fieldName', 'relation', 'values', 'oldValue', 'isValidationFirstError', 'validationError', 'serverSideDependence', 'disabled', 'relationId', 'requestMethod'],
  data() {
    return {
      options: [],
      selectedValue: [],
      relationValues: [],
      optionLabels: [],
      validatingStatus: 'validating',
      validateHelp: '',
    };
  },
  watch: {
    async serverSideDependence(e, prevValue) {
      if (e && Object.keys(e)[0] && (Object.values(prevValue)[0] !== Object.values(e)[0])) {
        await this.setOptions();
      }
    },
    relationValues() {
      this.setOldValue();
    },
    oldValue() {
      this.setOldValue();
    },
    isValidationFirstError(e) {
      if (e) {
        this.$refs['multiple-select'].scrollIntoView();
      }
    },
    validationError(e) {
      if (e) {
        this.validatingStatus = 'error';
        // eslint-disable-next-line prefer-destructuring
        this.validateHelp = e[0];
      } else {
        this.deleteValidationErrors();
      }
    },
  },
  mounted() {
    this.setOptions();
    this.setOldValue();
    // console.log('ddddddddddddddddd', this.oldValue);
  },
  methods: {
    filterOption(input, option) {
      if (!option.componentOptions.children[0].text && !option.componentOptions.children[0].elm) {
        return true;
      }
      const text = option.componentOptions.children[0].text
        || option.componentOptions.children[0].elm.innerText;
      return (
        text && text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    deleteValidationErrors() {
      this.validatingStatus = 'validating';
      this.validateHelp = '';
    },
    handleChange(selectedIndexes) {
      // eslint-disable-next-line max-len
      const multipleSelectValue = selectedIndexes.map((selectedIndex) => this.options[selectedIndex].code);
      // console.log('hendlechange multipleSelectValue', multipleSelectValue);
      this.$emit('input-change', { [this.fieldName]: multipleSelectValue });
      this.deleteValidationErrors();
    },
    async getValues() {
      try {
        const params = {
          type: this.type,
          ...this.serverSideDependence,
        };
        if (this.relationId) {
          params.relationId = this.relationId;
        }
        if (this.requestMethod === 'post') {
          const res = await axios.post(`${this.$route.path}/relation/${this.relation}`, {
            ...params,
          });
          return res.data;
        }
        const res = await axios.get(`${this.$route.path}/relation/${this.relation}`, {
          params,
        });
        return res.data;
      } catch (e) {
        socketError({}, e);
        return false;
      }
    },
    setOldValue() {
      if (this.oldValue) {
        const arr = [];
        let oldValue;
        if (typeof this.oldValue !== 'object') {
          oldValue = [this.oldValue];
        } else {
          oldValue = [...this.oldValue];
        }
        oldValue.forEach((code) => {
          this.options.forEach((obj, index) => {
            // eslint-disable-next-line eqeqeq
            if (code == obj.code) {
              arr.push(index);
            }
          });
          // console.log(arr, oldValue, this.options);
        });
        this.selectedValue = arr;
        // console.log('mounted multiple select oldValue', this.oldValue);
        this.$emit('input-change', { [this.fieldName]: this.oldValue });
      }
    },

    async setOptions() {
      if (this.relation) {
        this.relationValues = await this.getValues();
        this.options = [...this.relationValues];
        return;
      }
      if (this.values && this.values[0]) {
        this.options = [...this.values];
      }
    },
  },
};
</script>

<style scoped lang="scss">
.input-multiple-select{
  text-align: left;
  .select-label {
    font-size: 14px!important;
    font-weight: 400!important;
    color: rgba(0, 0, 0, 0.85);
  }
}
</style>
