<template>
  <a-collapse>
    <a-collapse-panel
      v-for="(report, index) in sportReports"
      :key="index"
      :show-arrow="showArrow"
    >
      <template slot="header">
        <div class="header-wrapper">
          <span>{{ report[reportNameKey] }}</span>
          <SportOneRowTable
            :table-object="report"
            :columns-info="sportReportColumns"
            :order-state="orderState"
            @handle-order="handleOrder"
          />
        </div>
      </template>
      <CollapseRecursion
        v-if="report"
        :sport-report-columns="sportReportColumns"
        :sport-report-reports="report"
      />
    </a-collapse-panel>
  </a-collapse>
</template>

<script>
import SportOneRowTable from './SportOneRowTable';
import CollapseRecursion from './CollapseRecursion';

export default {
  components: { SportOneRowTable, CollapseRecursion },
  // eslint-disable-next-line vue/require-prop-types
  props: ['sportReportColumns', 'sportReportReports', 'sportReportName'],
  data() {
    return {
      sportReports: [],
      reportNameKey: '',
      showArrow: false,
      orderState: {
        orderBy: 'ASC',
        filed: '',
      },
    };
  },
  watch: {
    sportReportReports(e) {
      if (e) {
        this.setSportReports();
      }
    },
  },
  mounted() {
    if (!_.isEmpty(this.sportReportReports)) {
      this.setSportReports();
    }
  },
  methods: {
    setSportReports() {
      if (this.sportReportName) {
        this.sportReports = [...this.sportReportReports];
        this.reportNameKey = 'sport_name';
        this.showArrow = true;
      } else {
        Object.values(this.sportReportReports).forEach((elem) => {
          if (typeof elem === 'object') {
            this.sportReports = [...elem];
          }
        });
        if (this.sportReports[0]) {
          Object.keys(this.sportReports[0]).forEach((objKey) => {
            if (objKey.slice(-5) === '_name') {
              this.reportNameKey = objKey;
            }
          });
          Object.values(this.sportReports[0]).forEach((objKey) => {
            if (typeof objKey === 'object') {
              this.showArrow = true;
            }
          });
        }
      }
    },
    handleOrder(field) {
      if (this.orderState.orderBy === 'DESC') {
        this.sportReports = this.sportReports.sort((a, b) => a[field] - b[field]);
        this.orderState.orderBy = 'ASC';
        this.orderState.field = field;
      } else {
        this.sportReports = this.sportReports.sort((a, b) => b[field] - a[field]);
        this.orderState.orderBy = 'DESC';
        this.orderState.field = field;
      }

      // event.stopPropagation();
    },
  },
};
</script>

<style scoped lang="scss">
.ant-collapse-item-active{
  .header-wrapper{
    >span{
      font-weight: bold;
    }
  }
}
.header-wrapper{
  display: flex;
  align-items: center;
  >span{
    min-width: 150px;
  }
}
</style>
