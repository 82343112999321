<template>
  <div class="sport-report-container">
    <SportReportPage />
  </div>
</template>

<script>
import SportReportPage from '../components/SportReportParts/SportReportPage';

export default {
  components: { SportReportPage },
};
</script>

<style scoped lang="scss">
.sport-report-container{
}
</style>
