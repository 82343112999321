<template>
  <div v-if="userInfo.need2FAAuth" class="google-auth-wrapper">
    <a-spin :spinning="isPageLoading">
      <div class="google-auth">
        <span style="font-size: 32px;font-weight: bold">
          <a-icon type="safety" />
          Enable Google Authenticator
        </span>
        <span style="width: 320px;display: block;margin: auto">Scan the QR code below with the
          Google authenticator app on your mobile device,after
          that fill in the field below with the code generated in the app.
        </span>
        <div>
          <a-row type="flex" justify="center">
            <a-col :span="12" style="margin: auto">
              <a-form :label-col="{ span: 24, offset: 1 }">
                <a-form-item>
                  <div style="min-height: 320px; min-width: 320px">
                    <img :src="qrImgSrc" width="320px" alt="QR">
                  </div>
                </a-form-item>
                <a-form-item
                  label="Secret Key"
                  :label-col="{ span: 9}"
                  :wrapper-col="{ span: 10 }"
                >
                  <span style="display: block;text-align: left; width: 100%">
                    {{ secretKey }}
                  </span>
                </a-form-item>
                <a-form-item
                  label="Verification Code"
                  :label-col="{ span: 9}"
                  :wrapper-col="{ span: 10 }"
                  :help="validationStatus && 'Invalid Code'"
                  :validate-status="validationStatus"
                >
                  <a-input
                    v-model="authCode"
                    placeholder="Enter Google Authenticator Code"
                  />
                </a-form-item>
                <a-form-item>
                  <a-button
                    :loading="isEnablingLoad"
                    type="primary"
                    :disabled="isAuthEnabled"
                    style="margin-right: 10px"
                    @click="submitAuthCode"
                  >
                    Enable Google Authenticator
                  </a-button>
                  <a-button
                    :disabled="!isAuthEnabled"
                    :loading="isDisablingLoad"
                    type="primary"
                    @click="disableGoogleAuth"
                  >
                    Disable Google Auth
                  </a-button>
                </a-form-item>
              </a-form>
            </a-col>
          </a-row>
        </div>
      </div>
    </a-spin>
  </div>
  <NotFound v-else />
</template>
<script>
import { mapState } from 'vuex';
import NotFound from './NotFound';

export default {
  components: { NotFound },
  data() {
    return {
      qrImgSrc: '',
      secretKey: '',
      isPageLoading: true,
      labelCol: { span: 4, offset: 4 },
      wrapperCol: { span: 9 },
      validationStatus: '',
      authCode: '',
      isEnablingLoad: false,
      isDisablingLoad: false,
      isAuthEnabled: false,
    };
  },
  computed: {
    ...mapState({
      userInfo(state) {
        return state.info.userInfo;
      },
    }),
  },
  watch: {
    authCode() {
      if (this.validationStatus) {
        this.validationStatus = '';
      }
    },
  },
  mounted() {
    if (this.userInfo && this.userInfo.need2FAAuth) {
      this.getQrImage();
    }
  },
  methods: {
    async disableGoogleAuth() {
      this.isDisablingLoad = true;
      try {
        const res = await axios.post('/google-authenticate/disable', {
          authCode: this.authCode,
        });
        if (res.data && res.data.success) {
          this.$message.success(
            'You have successfully Disabled Google authenticator',
            6,
          );
          await this.$store.dispatch('tabsVuex/removeTab', { key: '/google-authentication' });
        } else {
          this.validationStatus = 'error';
        }
        this.isDisablingLoad = false;
        return res.data;
      } catch (e) {
        this.validationStatus = 'error';
        this.isDisablingLoad = false;
        return e;
      }
    },
    async submitAuthCode() {
      this.isEnablingLoad = true;
      try {
        const res = await axios.post('/google-authenticate', {
          authCode: this.authCode,
        });
        if (res.data && res.data.success) {
          this.$message.success(
            'You have successfully enabled Google authenticator',
            6,
          );
          await this.$store.dispatch('tabsVuex/removeTab', { key: '/google-authentication' });
        } else {
          this.validationStatus = 'error';
        }
        this.isEnablingLoad = false;
        return res.data;
      } catch (e) {
        this.validationStatus = 'error';
        this.isEnablingLoad = false;
        return e;
      }
    },
    async getQrImage() {
      this.isPageLoading = true;
      try {
        const res = await axios.get('/get-qr-image');
        if (res.data && res.data.imageSrc) {
          this.qrImgSrc = res.data && res.data.imageSrc;
          this.secretKey = res.data && res.data.secretKey;
          this.isAuthEnabled = res.data && res.data.authEnabled;
        }
        this.isPageLoading = false;
        return res.data && res.data.imageSrc;
      } catch (e) {
        this.isPageLoading = false;
        return e;
      }
    },
  },
};
</script>

<style lang="scss">
.google-auth-wrapper{
  padding: 24px;
  .google-auth{
    padding-top: 12px;
    background: #fff;
    border-radius: 8px;
    min-height: 80vh;
  }
}
</style>
