<template>
  <div>
    <highcharts :options="chartOptions" />
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue';

export default {
  components: {
    highcharts: Chart,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['chartType', 'chartSeries', 'selectedChartByField'],
  data() {
    return {
      chartOptions: {
        chart: {
          type: this.chartType || 'column',
        },
        tooltip: {
          shared: false,
          formatter() {
            return `<b>${this.point.series.name}: ${this.point.y} </b> <br/> ${moment(this.point.x).format('YYYY-MM-DD, HH:mm:ss')}`;
          },
        },
        title: {
          text: '',
        },
        yAxis: {
          title: { text: '' },
        },
        xAxis: {
          type: 'dateformat',
          labels: {
            format: '{value:%Y-%m-%d <br/> %H:%M}',
          },
        },
        series: this.chartSeries || [],
      },
    };
  },
  watch: {
    chartType(type) {
      this.chartOptions.chart.type = type;
    },
    chartSeries() {
      this.chartOptions.series = this.chartSeries
        || [{ data: [], name: this.selectedChartByField + ' load error' }];
    },
  },
  mounted() {
    // console.log('this.chartSeries', this.chartSeries);
    // setTimeout(() => {
    //   this.chartOptions.series = {
    //     data: [
    //       { x: 1356994200500, y: 40 },
    //       { x: 1356995300500, y: 25 },
    //       { x: 1356998400800, y: 65 },
    //       { x: 1356999500800, y: 40 },
    //       { x: 1356999600800, y: 70 },
    //       { x: 1356999700800, y: 90 },
    //       { x: 1356999900800, y: 10 },
    //     ],
    //     name: 'fffffffffff',
    //   };
    // }, 5000);
  },
};
</script>

<style>
.highcharts-credits{
  display: none;
}
</style>
