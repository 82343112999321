<template>
  <div>
    <a-modal
      title="Are you sure change the password?"
      :visible="Boolean(id)"
      :confirm-loading="confirmLoading"
      ok-text="Generate Password"
      cancel-text="Close"
      :ok-button-props="{ props: okButtonProps }"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <span v-if="context">
        New Password is <span style="font-weight: bold">{{ context }}</span>
      </span>
    </a-modal>
  </div>
</template>
<script>

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['id'],
  data() {
    return {
      confirmLoading: false,
      context: '',
      okButtonProps: {},
    };
  },
  methods: {
    async handleOk() {
      this.confirmLoading = true;
      try {
        const res = await axios.post(`${this.$route.path}/${this.id}/generate-password`);
        this.okButtonProps = { disabled: true };
        this.context = res.data.password;
        this.confirmLoading = false;
      } catch (e) {
        this.confirmLoading = false;
      }
    },
    async handleCancel() {
      await this.$store.dispatch('modals/toggleChangePassModal');
    },
  },
};
</script>
