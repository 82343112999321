<template>
  <a-form-item
    ref="date-input"
    :validate-status="validatingStatus"
    :help="validateHelp"
    class="form-item-holder"
  >
    <template slot="label">
      <span class="form-item-label-text">{{ label }}</span>
    </template>
    <a-date-picker v-model="dateValue"
                   style="width: 100%"
                   :allow-clear="!disAllowClear"
                   :show-time="!hideTime"
                   :disabled="disabled"
                   :input-read-only="disAllowClear"
    />
  </a-form-item>
</template>
<script>

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['oldValue', 'field', 'label', 'defaultValue', 'hideTime', 'disabled', 'validationError', 'disAllowClear'],
  data() {
    return {
      dateValue: null,
      validatingStatus: 'validating',
      validateHelp: '',
    };
  },
  watch: {
    isValidationFirstError(e) {
      if (e) {
        this.$refs['date-input'].scrollIntoView();
      }
    },
    validationError(e) {
      if (e) {
        this.validatingStatus = 'error';
        // eslint-disable-next-line prefer-destructuring
        this.validateHelp = e[0];
      } else {
        this.deleteValidationErrors();
      }
    },
    oldValue(e) {
      if (e !== moment(this.dateValue).unix()) {
        if (e) {
          this.dateValue = moment(e * 1000);
        } else {
          this.dateValue = null;
        }
      }
    },
    dateValue(e) {
      let date = e ? moment(e).unix() : '';
      if (date && this.hideTime) {
        if (this.hideTime === 'startOfDay') {
          date = moment(e).startOf('day').unix();
        } else if (this.hideTime === 'endOfDay') {
          date = moment(e).endOf('day').unix();
        }
      }
      const value = {
        [this.field]: date,
      };
      this.$emit('input-change', value);
      this.deleteValidationErrors();
    },
  },
  mounted() {
    this.dateValue = this.oldValue || this.defaultValue
      ? moment((this.oldValue || this.defaultValue) * 1000) : null;
    // if (this.oldValue || this.defaultValue) {
    //   this.dateValue = this.oldValue ? moment(this.oldValue * 1000)
    //     : moment(this.defaultValue * 1000);
    // }
  },
  methods: {
    deleteValidationErrors() {
      this.validatingStatus = 'validating';
      this.validateHelp = '';
    },
  },
};
</script>

<style scoped lang="scss">
</style>
