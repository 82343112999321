<template>
  <a-form-item
    :validate-status="validatingStatus"
    :help="validateHelp"
    class="form-item-holder"
  >
    <template slot="label">
      <span class="form-item-label-text">{{ label }}</span>
    </template>
    <a-card class="array-form-item">
      <div>
        <a-button type="primary" style="margin-bottom: 15px" @click.prevent="addForm">
          Add
        </a-button>
      </div>
      <a-card v-for="(formFields, index) in localForms"
              :key="String(index) + localForms.length"
      >
        <FormPage
          :field-name="String(index)"
          :form-data="formFields"
          :parent-form-value="parentFormValue"
          :old-form-values="formValues[index]"
          :validation-errors="validationError
            && !arrayGroupValidationError && validationError[index]"
          layout-type="vertical"
          :label-col="{}"
          :wrapper-col="{}"
          form-class-name="array-form"
          @delete-array-form-value="deleteArrayFormValue"
          @input-change="inputChange"
        />
        <a-button type="danger" @click.prevent="removeForm(index)">
          Delete
        </a-button>
      </a-card>
    </a-card>
  </a-form-item>
</template>

<script>

export default {
  components: {
    FormPage: () => import('../partial/FormPage/FormPage'),
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['oldFormValues', 'parentFormValue', 'formData', 'label', 'fieldName', 'validationError'],
  // props: {
  //   parentFormValue: {
  //     type: Object,
  //     default() {
  //       return {};
  //     },
  //   },
  //   formData: {
  //     type: Object,
  //     default() {
  //       return {};
  //     },
  //   },
  //   label: {
  //     type: String,
  //     default: null,
  //   },
  //   fieldName: {
  //     type: String,
  //     default: '',
  //   },
  // },
  data() {
    return {
      localForms: [],
      localFormData: {},
      formValues: [],
      validatingStatus: 'validating',
      validateHelp: '',
    };
  },
  computed: {
    arrayGroupValidationError() {
      return this.validationError && this.validationError.groupError;
    },
  },
  watch: {
    arrayGroupValidationError(e) {
      if (e) {
        this.validatingStatus = 'error';
        // eslint-disable-next-line prefer-destructuring
        this.validateHelp = e[0];
      } else {
        this.deleteValidationErrors();
      }
    },
  },
  mounted() {
    this.localFormData = JSON.parse(JSON.stringify(this.formData));
    if (this.oldFormValues) {
      this.formValues = JSON.parse(JSON.stringify(this.oldFormValues));
      this.localForms = this.oldFormValues.map(() => this.formData.fields);
    }
    if (this.oldFormValues) {
      this.$emit('input-change', { [this.fieldName]: this.oldFormValues });
    }
  },
  methods: {
    addForm() {
      this.localForms = [...this.localForms, this.formData.fields];
      this.formValues = [...this.formValues, {}];
      this.$emit('input-change', { [this.fieldName]: this.formValues });
      this.deleteValidationErrors();
    },
    removeForm(index) {
      this.formValues = [
        ...this.formValues.slice(0, index),
        ...this.formValues.slice(index + 1),
      ];
      this.localForms = [
        ...this.localForms.slice(0, index),
        ...this.localForms.slice(index + 1),
      ];
      if (!this.formValues.length) {
        this.$emit('input-change', { [this.fieldName]: {} });
      }
    },
    deleteArrayFormValue(params) {
      _.unset(this.formValues, params.unsetPath);
      this.$emit('input-change', { [this.fieldName]: this.formValues });
    },
    inputChange(e) {
      const index = Number(_.keys(e)[0]);
      this.formValues = [
        ...this.formValues.slice(0, index),
        { ...this.formValues[index], ...e[index] },
        ...this.formValues.slice(index + 1),
      ];
      this.$emit('input-change', { [this.fieldName]: this.formValues });
      this.deleteValidationErrors();
    },
    deleteValidationErrors() {
      this.validatingStatus = 'validating';
      this.validateHelp = '';
    },
  },
};
</script>

<style scoped lang="scss">
.array-form-item{
  text-align: left;
  flex-wrap: wrap;
  .info-box{
    align-items: center;
  }
  .array-fields{
    max-width: 80%;
    margin: auto;
  }
}
</style>
