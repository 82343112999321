<template>
  <div>
    <a-radio-group
      :value="selectedValue"
      button-style="solid"
      @change="(e) => onChange(e, selectedValue)"
    >
      <a-radio-button
        v-for="radio in values"
        :key="radio.code"
        :value="radio.code"
        :disabled="selectedValue === radio.code"
      >
        {{ radio.label }}
      </a-radio-button>
    </a-radio-group>
  </div>
</template>

<script>
import socketError from '../../../utils/socketError';

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['rowData', 'actionDesc'],
  data() {
    return {
      selectedValue: '',
    };
  },
  computed: {
    values() {
      return (this.actionDesc && this.actionDesc.options) || [];
    },
    queryParams() {
      let queryParams = '';
      const queryObj = {};
      if (this.actionDesc && this.actionDesc.queryParams && this.rowData) {
        this.actionDesc.queryParams.forEach((elem) => {
          queryObj[elem] = this.rowData[elem];
        });
      }
      queryParams = new URLSearchParams({
        ...queryObj,
        state: this.selectedValue,
      }).toString();
      return '?' + queryParams;
    },
  },
  mounted() {
    if (this.actionDesc && this.actionDesc.defValueCode) {
      this.selectedValue = this.rowData[this.actionDesc.defValueCode];
    }
  },
  methods: {
    async onChange(event, prevValue) {
      this.selectedValue = event.target.value;
      try {
        const res = await axios.get(`${this.$route.path}/action${this.queryParams}`);
        if (!res || !res.data || (res.data.alert === 'error')) {
          this.selectedValue = prevValue;
        }
        return res.data;
      } catch (e) {
        this.selectedValue = prevValue;
        socketError({
        }, e);
        return false;
      }
    },
  },
};
</script>
