<template>
  <div class="form-wrapper card-body">
    <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item v-if="isFormTranslatable"
                   label="Language"
                   style="border-bottom: 1px solid #E9E9E9"
      >
        <FormLanguageSwitcher @language-change="(lang) => formSelectedLanguage = lang" />
      </a-form-item>
      <div v-for="(val, key) in formData"
           :key="String(key)"
      >
        <div
          v-if="val.type === 'checkbox'
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <Checkbox
            :label="val.label || key"
            :field-name="key"
            :disabled="val.disabled"
            :old-value="oldFormValues[key]"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @input-change="inputChange"
          />
        </div>
        <div
          v-else-if="val.type === 'text'
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <Input
            :label="val.label || key"
            :field-name="key"
            :disabled="val.disabled"
            :shown-in-step="currentStep.includes(key)"
            :show-validation="stepChangeSimulation"
            :required="true"
            :old-value="oldFormValues[key]"
            :translatable="val.translatable"
            :translation="oldFormValues && oldFormValues.translation"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            :form-locale-lang="formSelectedLanguage || localeLang"
            @form-element-validation="formElementValidation"
            @translation-input-change="translationInputChange"
            @input-change="inputChange"
          />
        </div>
        <div
          v-else-if="(val.type === 'ant-date' || val.type === 'datetime')
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <AntDateInput
            :label="val.label || key"
            :field="key"
            :disabled="val.disabled"
            :old-value="oldFormValues[key]"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @input-change="inputChange"
          />
        </div>
        <div
          v-else-if="val.type === 'ant-date-range'
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <AntDateRangePicker
            :label="val.label || key"
            :default-value="val.defaultValue"
            :show-time="val.showTime"
            :dis-allow-clear="val.disAllowClear"
            :field="key"
            :old-value="oldFormValues[key]"
            @input-change="inputChange"
          />
        </div>
        <div
          v-else-if="val.type === 'date-range-picker-with-presets'
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <DateRangePickerWithPresets
            :label="val.label || key"
            :default-value="val.defaultValue"
            :show-time="val.showTime"
            :as-form-element="true"
            :dis-allow-clear="val.disAllowClear"
            :field="key"
            :old-value="oldFormValues[key]"
            @input-change="inputChange"
          />
        </div>
        <div
          v-else-if="(val.type === 'month-picker')
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <MonthPicker
            :label="val.label || key"
            :field="key"
            :disabled="val.disabled"
            :old-value="oldFormValues[key]"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @input-change="inputChange"
          />
        </div>
        <div
          v-else-if="val.type === 'date'
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <Input
            type="date"
            :label="val.label || key"
            :field-name="key"
            :disabled="val.disabled"
            :old-value="oldFormValues[key]"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            :translatable="val.translatable"
            :translation="oldFormValues && oldFormValues.translation"
            :form-locale-lang="formSelectedLanguage || localeLang"
            @translation-input-change="translationInputChange"
            @input-change="inputChange"
          />
        </div>
        <div
          v-else-if="val.type === 'number'
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <Input
            type="number"
            :label="val.label || key"
            :min="$route.params.moduleName === 'cms-pages' && 0"
            :field-name="key"
            :disabled="val.disabled"
            :old-value="oldFormValues[key]"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            :translatable="val.translatable"
            :translation="oldFormValues && oldFormValues.translation"
            :form-locale-lang="formSelectedLanguage || localeLang"
            @translation-input-change="translationInputChange"
            @input-change="inputChange"
          />
        </div>
        <div
          v-else-if="val.type === 'color_picker'
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <Input
            type="color"
            :label="val.label || key"
            :field-name="key"
            :disabled="val.disabled"
            :old-value="oldFormValues[key]"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            :translatable="val.translatable"
            :translation="oldFormValues && oldFormValues.translation"
            :form-locale-lang="formSelectedLanguage || localeLang"
            @translation-input-change="translationInputChange"
            @input-change="inputChange"
          />
        </div>
        <div
          v-else-if="val.type === 'unsigned'
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <Input
            type="number"
            :min="0"
            :label="val.label || key"
            :field-name="key"
            :disabled="val.disabled"
            :old-value="oldFormValues[key]"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            :translatable="val.translatable"
            :translation="oldFormValues && oldFormValues.translation"
            :form-locale-lang="formSelectedLanguage || localeLang"
            @translation-input-change="translationInputChange"
            @input-change="inputChange"
          />
        </div>
        <div
          v-else-if="val.type === 'decimal'
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <Input
            type="number"
            :step="0.01"
            :label="val.label || key"
            :field-name="key"
            :disabled="val.disabled"
            :old-value="oldFormValues[key]"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            :translatable="val.translatable"
            :translation="oldFormValues && oldFormValues.translation"
            :form-locale-lang="formSelectedLanguage || localeLang"
            @translation-input-change="translationInputChange"
            @input-change="inputChange"
          />
        </div>
        <div
          v-else-if="val.type === 'textarea'
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <Input
            type="textarea"
            :label="val.label || key"
            :field-name="key"
            :disabled="val.disabled"
            :old-value="oldFormValues[key]"
            :translatable="val.translatable"
            :translation="oldFormValues && oldFormValues.translation"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            :form-locale-lang="formSelectedLanguage || localeLang"
            @translation-input-change="translationInputChange"
            @input-change="inputChange"
          />
        </div>
        <div
          v-else-if="val.type === 'file'
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <FileInput
            type="file"
            :label="val.label || key"
            :disabled="val.disabled"
            :translatable="val.translatable"
            :field-name="key"
            :old-value="oldFormValues[key]"
            :translation="oldFormValues && oldFormValues.translation"
            :form-locale-lang="formSelectedLanguage || localeLang"
            :validation-error="validationErrors[key] || validationErrors[`old_${key}_en`]
              || validationErrors[`old_${key}_am`] || validationErrors[`old_${key}_ru`]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @translation-input-change="translationInputChange"
            @input-change="inputChange"
          />
        </div>
        <div
          v-else-if="(val.type === 'decimal_unsigned' || val.type === 'unsigned_decimal')
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <Input
            type="number"
            :min="0"
            step="0.01"
            :label="val.label || key"
            :disabled="val.disabled"
            :field-name="key"
            :old-value="oldFormValues[key]"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            :translatable="val.translatable"
            :translation="oldFormValues && oldFormValues.translation"
            :form-locale-lang="formSelectedLanguage || localeLang"
            @translation-input-change="translationInputChange"
            @input-change="inputChange"
          />
        </div>
        <div
          v-else-if="val.type === 'radio'
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <Radio
            :label="val.label || key"
            :field-name="key"
            :disabled="val.disabled"
            :option-disable-server-side="val.disabledOptionDependsOn &&
              disableOptionChecker(val.disabledOptionDependsOn, key)"
            :values="val.values"
            :old-value="oldFormValues[key]"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @input-change="inputChange"
          />
        </div>
        <div
          v-else-if="val.type === 'select'
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <Select
            :type="val.type"
            :field-name="key"
            :label="val.label || key"
            :relation-id="val.relationId"
            :disabled="val.disabled
              || (val.disabledDependsOn && disabledChecker(val.disabledDependsOn))"
            :values="val.values"
            :relation="val.relation"
            :request-method="val.requestMethod"
            :server-side-dependence="(val.serverSideDependence
              && {[val.serverSideDependence]: (formValue[val.serverSideDependence]
                || parentFormValue[val.serverSideDependence])}) || {}"
            :old-value="oldFormValues[key]"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @input-change="inputChange"
          />
        </div>
        <div
          v-else-if="val.type === 'select_multiple'
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <MultipleSelect
            :type="val.type"
            :field-name="key"
            :label="val.label || key"
            :relation-id="val.relationId"
            :request-method="val.requestMethod"
            :disabled="val.disabled
              || (val.disabledDependsOn && disabledChecker(val.disabledDependsOn))"
            :values="val.values"
            :relation="val.relation"
            :server-side-dependence="(val.serverSideDependence
              && {[val.serverSideDependence]: (formValue[val.serverSideDependence]
                || parentFormValue[val.serverSideDependence])}) || {}"
            :old-value="typeof oldFormValues[key] === 'string'
              ? JSON.parse(oldFormValues[key]) : oldFormValues[key]"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @input-change="inputChange"
          />
        </div>
        <div
          v-else-if="val.type === 'select_multiple_paginated'
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <PaginatedMultipleSelect
            :type="val.type"
            :field-name="key"
            :label="val.label || key"
            :disabled="val.disabled
              || (val.disabledDependsOn && disabledChecker(val.disabledDependsOn))"
            :relation="val.relation"
            :request-method="val.requestMethod"
            :server-side-dependence="(val.serverSideDependence
              && {[val.serverSideDependence]: (formValue[val.serverSideDependence]
                || parentFormValue[val.serverSideDependence])}) || {}"
            :values="val.values"
            :old-value="typeof oldFormValues[key] === 'string'
              ? JSON.parse(oldFormValues[key]) : oldFormValues[key]"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @input-change="inputChange"
          />
        </div>
        <div
          v-else-if="val.type === 'form-changer-select'
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <FormChangerSelect
            :type="val.type"
            :field-name="key"
            :label="val.label || key"
            :disabled="val.disabled
              || (val.disabledDependsOn && disabledChecker(val.disabledDependsOn))"
            :relation="val.relation"
            :request-method="val.requestMethod"
            :server-side-dependence="(val.serverSideDependence
              && {[val.serverSideDependence]: (formValue[val.serverSideDependence]
                || parentFormValue[val.serverSideDependence])}) || {}"
            :values="val.values"
            :old-value="typeof oldFormValues[key] === 'string' && isJSON(oldFormValues[key])
              ? JSON.parse(oldFormValues[key]) : oldFormValues[key]"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @input-change="inputChange"
            @change-form="changeForm"
          />
        </div>
        <div
          v-else-if="val.type === 'select_paginated'
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <PaginatedSelect
            :type="val.type"
            :field-name="key"
            :label="val.label || key"
            :disabled="val.disabled
              || (val.disabledDependsOn && disabledChecker(val.disabledDependsOn))"
            :relation="val.relation"
            :request-method="val.requestMethod"
            :server-side-dependence="(val.serverSideDependence
              && {[val.serverSideDependence]: (formValue[val.serverSideDependence]
                || parentFormValue[val.serverSideDependence])}) || {}"
            :values="val.values"
            :old-value="typeof oldFormValues[key] === 'string' && isJSON(oldFormValues[key])
              ? JSON.parse(oldFormValues[key]) : oldFormValues[key]"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @input-change="inputChange"
          />
        </div>
        <!--        <div-->
        <!--          v-else-if="val.type === 'datetime'-->
        <!--            && (!val.depends_on || dependOnChecker(val.depends_on, key))"-->
        <!--          v-show="currentStep.includes(key)"-->
        <!--        >-->
        <!--          <DatePickerInput-->
        <!--            :field-name="key"-->
        <!--            :label="val.label || key"-->
        <!--            :old-value="oldFormValues[key]"-->
        <!--            :disabled="val.disabled"-->
        <!--            :validation-error="validationErrors[key]"-->
        <!--            :is-validation-first-error
        ="isValidationFirstError(validationErrors, key)"-->
        <!--            @input-change="inputChange"-->
        <!--          />-->
        <!--        </div>-->
        <div
          v-else-if="val.type === 'sender'
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <SmsSender
            :field-name="key"
            :label="val.label || key"
            :disabled="val.disabled"
            :path="val.path"
            :button-text="val.buttonText"
            :old-value="oldFormValues[key]"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            :form-value="formValue"
          />
        </div>
        <div v-else-if="val.type === 'array'
               && (!val.depends_on || dependOnChecker(val.depends_on, key))"
             v-show="currentStep.includes(key)"
        >
          <Array
            :form-data="val"
            :parent-form-value="formValue"
            :disabled="val.disabled"
            :field-name="key"
            :label="val.label || key"
            :validation-error="validationErrors[key]"
            :old-form-values="oldFormValues[key]"
            @input-change="inputChange"
          />
        </div>
        <div v-else-if="val.type === 'editor'" v-show="currentStep.includes(key)">
          <TinyEditor
            type="editor"
            :label="val.label || key"
            :field-name="key"
            :disabled="val.disabled"
            :old-value="oldFormValues[key]"
            :translatable="val.translatable"
            :translation="oldFormValues.translation"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            :form-locale-lang="formSelectedLanguage || localeLang"
            @translation-input-change="translationInputChange"
            @input-change="inputChange"
          />
        </div>
        <div v-else-if="val.type === 'tag'" v-show="currentStep.includes(key)">
          <Tag
            type="tag"
            :label="val.label || key"
            :field-name="key"
            :disabled="val.disabled"
            :old-value="oldFormValues[key]"
            :translatable="val.translatable"
            :translation="oldFormValues && oldFormValues.translation"
            :form-locale-lang="formSelectedLanguage || localeLang"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @translation-input-change="translationInputChange"
            @input-change="inputChange"
          />
        </div>
        <div v-else-if="val.type === 'cron_expression'
               && (!val.depends_on || dependOnChecker(val.depends_on, key))"
             v-show="currentStep.includes(key)"
        >
          <CronsTrue
            :label="val.label || key"
            :field-name="key"
            :disabled="val.disabled"
            :old-value="oldFormValues[key]"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            :form-locale-lang="formSelectedLanguage || localeLang"
            @translation-input-change="translationInputChange"
            @input-change="inputChange"
          />
        </div>
        <div v-else-if="val.type === 'hidden'">
          <HiddenInput
            :field-name="key"
            :old-value="oldFormValues[key]"
            @input-change="inputChange"
          />
        </div>
      </div>
      <!--      <div class="steps-action">-->
      <!--        <a-button v-if="isNextStepActive" type="primary" @click="next">-->
      <!--          Next-->
      <!--        </a-button>-->
      <!--        <a-button v-if="isPrevStepActive" style="margin-left: 8px" @click="prev">-->
      <!--          Back-->
      <!--        </a-button>-->
      <!--      </div>-->
      <div class="submit-btn-wrapper">
        <a-button
          v-if="Object.keys(formData)[0]"
          class="btn btn-success"
          type="primary"
          :loading="isLoading || isFormDataLoading"
          @click.prevent="formDataSubmit"
        >
          Submit
        </a-button>
        <a-button :style="{ marginRight: '8px' }" @click="$emit('toggle-modal')">
          Cancel
        </a-button>
      </div>
    </a-form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SmsSender from '../../FormElements/SmsSender';
import Tag from '../../FormElements/Tag';
import HiddenInput from '../../FormElements/HiddenInput';
import Checkbox from '../../FormElements/Checkbox';
import Input from '../../FormElements/Input';
import Radio from '../../FormElements/Radio';
import Select from '../../FormElements/Select';
import Array from '../../FormElements/Array';
// import DatePickerInput from '../../FormElements/DatePickerInput';
import MultipleSelect from '../../FormElements/MultipleSelect';
import TinyEditor from '../../FormElements/TinyEditor';
import FormLanguageSwitcher from '../../FormElements/FormLanguageSwitcher';
import PaginatedMultipleSelect from '../../FormElements/PaginatedMultipleSelect';
import PaginatedSelect from '../../FormElements/PaginatedSelect';
import FileInput from '../../FormElements/FileInput';
import CronsTrue from '../../FormElements/CronsTrue';
import AntDateInput from '../../FormElements/AntDateInput';
import FormChangerSelect from '../../FormElements/FormChangerSelect';
import MonthPicker from '../../FormElements/MonthPicker';
import socketError from '../../../utils/socketError';
import { isJSON } from '../../../utils/helpers';
import AntDateRangePicker from '../../FormElements/AntDateRangePicker';
import DateRangePickerWithPresets from '../../FormElements/DateRangePickerWithPresets';

export default {
  name: 'NewStepForm1',
  components: {
    DateRangePickerWithPresets,
    AntDateRangePicker,
    HiddenInput,
    Tag,
    Checkbox,
    Input,
    Radio,
    Select,
    Array,
    MultipleSelect,
    TinyEditor,
    FormLanguageSwitcher,
    PaginatedMultipleSelect,
    PaginatedSelect,
    FileInput,
    CronsTrue,
    AntDateInput,
    SmsSender,
    FormChangerSelect,
    MonthPicker,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    stepDivision: {
      default: [],
    },
    isNextStepActive: {
      type: Boolean,
      default: true,
    },
    isPrevStepActive: {
      type: Boolean,
      default: true,
    },
    isFrontValidActive: {
      type: Boolean,
      default: false,
    },
    oldFormValues: {
      type: Object,
      default() {
        return {};
      },
    },
    submitPath: {
      type: String,
      default: '',
    },
    parentFormValue: {
      type: Object,
      default() {
        return {};
      },
    },
    validationRules: {
      type: Object,
      default() {
        return {};
      },
    },
    isFormDataLoading: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default() {
        return {};
      },
    },
    // eslint-disable-next-line vue/require-prop-types
    currentStep: {
      default: 'Not specified',
    },
    otherFormItems: {
      type: Object,
      default() {
        return {};
      },
    },
    fieldName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      selectedDateRange: {
        startDate: null,
        endDate: null,
      },
      formSelectedLanguage: '',
      isFormTranslatable: false,
      formValue: {},
      validationErrors: {},
      isLoading: false,
      isFormStepValid: {},
      stepChangeSimulation: false,
      isJSON,
    };
  },
  computed: {
    ...mapState({
      langs(state) {
        return state.languages;
      },
    }),
  },
  watch: {
    formData() {
      this.detectIsFormTranslatable();
      if (this.isFormTranslatable === true) {
        this.setDefaultTranslatableForm();
      }
    },
  },
  mounted() {
    this.detectIsFormTranslatable();
    if (this.isFormTranslatable === true) {
      this.setDefaultTranslatableForm();
    }
  },
  methods: {
    disableOptionChecker(serverDepOn, fieldName) {
      const disabledOptions = [];
      const optionCodeList = [];
      _.forIn(serverDepOn, (data, optionCode) => {
        optionCodeList.push(optionCode);
        _.forIn(data, (value, key) => {
          if (typeof value === 'object') {
            value.forEach((val) => {
              if (this.formValue[key] === val) {
                disabledOptions.push(optionCode);
              }
              if (typeof this.formValue[key] === 'object' && this.formValue[key].includes(val)) {
                disabledOptions.push(optionCode);
              }
              if (val === 'notEmptyValue' && this.formValue[key]) {
                disabledOptions.push(optionCode);
              }
              if (!this.formValue[key] && (val === 'defaultDisabled' || val === 'emptyValue')) {
                disabledOptions.push(optionCode);
              }
            });
          } else if (this.formValue[key] === value || this.parentFormValue[key] === value) {
            disabledOptions.push(optionCode);
          }
        });
      });
      if (disabledOptions.length && optionCodeList.includes(this.formValue[fieldName])) {
        delete this.formValue[fieldName];
      }
      return disabledOptions;
    },
    disabledChecker(data) {
      if (!data) return undefined;
      let presence;
      Object.keys(data).forEach((key) => {
        const value = data[key];
        if (this.formValue[key] === value || this.parentFormValue[key] === value) {
          presence = 'disabled';
        }
      });
      return presence;
    },
    setDefaultTranslatableForm() {
      const translation = this.langs.reduce((acc, lang) => {
        acc[lang] = [];
        return acc;
      }, {});
      this.formValue = {
        ...this.formValue,
        translation,
      };
    },
    asd() {
      this.stepChangeSimulation = !this.stepChangeSimulation;
    },
    prev() {
      this.$emit('prev');
    },
    next() {
      let isValid = true;
      // console.log('this.isFormStepValid', this.isFormStepValid);
      // console.log('this.currentStep', this.currentStep);
      this.currentStep.forEach((fieldName) => {
        if (this.isFormStepValid[fieldName] === 'isInValid' && fieldName !== 'title') {
          isValid = false;
        }
      });
      if (isValid) {
        this.$emit('next');
      } else {
        this.asd();
      }
    },
    isValidationFirstError(errors, key) {
      return Object.keys(errors)[0] === key;
    },
    detectIsFormTranslatable() {
      this.isFormTranslatable = false;
      _.forIn(this.formData, (value) => {
        if (value.translatable) {
          this.isFormTranslatable = true;
        }
      });
    },
    dependsFromMultiSelect(val, key) {
      const depChecker = {
        presence: false,
        areAllTruthy: false,
      };
      const presence = val.every((option) => {
        if (this.formValue[key] && this.formValue[key].indexOf(option) > -1) {
          return true;
        }
        return false;
      });
      if (presence) {
        depChecker.presence = true;
        depChecker.areAllTruthy = true;
      }
      return depChecker;
    },
    dependOnChecker(data, fieldName) {
      let presence = false;
      // eslint-disable-next-line consistent-return
      _.forIn(data, (value, key) => {
        let areAllTruthy = false;
        if (typeof value === 'object') {
          value.forEach((val) => {
            if (typeof val === 'object') {
              if (this.formData[key] && (this.formData[key].type === 'select_multiple'
                || this.formData[key].type === 'select_multiple_paginated')) {
                const checker = this.dependsFromMultiSelect(val, key);
                presence = checker.presence;
                areAllTruthy = checker.areAllTruthy;
              }
            }
            if (this.formValue[key] === val || this.parentFormValue[key] === val) {
              presence = true;
              areAllTruthy = true;
            }
            if (val === 'notEmptyValue' && this.formValue[key]) {
              presence = true;
              areAllTruthy = true;
            }
            if (!this.formValue[key] && !this.parentFormValue[key] && (val === 'defaultShow' || val === 'emptyValue')) {
              presence = true;
              areAllTruthy = true;
            }
          });
          if (data.mustBeSameTimeTruthy && !areAllTruthy) {
            presence = false;
            return false;
          }
        } else if (this.formValue[key] === value || this.parentFormValue[key] === value) {
          presence = true;
        }
      });
      if (!presence) {
        delete this.formValue[fieldName];
      }
      return presence;
    },
    translationInputChange(obj) {
      let existence = false;
      let myArr = [];
      myArr = this.formValue.translation[obj.lang].map((elem) => {
        let elemTranslation;
        if (elem.column_name === obj.info.column_name) {
          elemTranslation = { ...obj.info };
          existence = true;
        }
        return elemTranslation || elem;
      });
      if (!existence) {
        myArr.push(obj.info);
      }
      this.formValue.translation[obj.lang] = myArr;
      // this.$emit('translation-input-change', obj);
    },
    inputChange(e) {
      this.formValue = {
        ...this.formValue,
        ...e,
      };
      this.$emit('input-change', { [this.fieldName]: this.formValue });
    },
    changeForm(data) {
      this.$emit('change-form', data);
    },
    formElementValidation(formElementValidation) {
      this.isFormStepValid = { ...this.isFormStepValid, ...formElementValidation };
    },
    async formDataSubmit() {
      this.isLoading = true;
      if (this.isFrontValidActive) {
        this.validationErrors = this.$formCustomValidate(this.formValue, this.formData);
        if (!_.isEmpty(this.validationErrors)) {
          this.$emit('show-step-validation', this.validationErrors);
          this.isLoading = false;
          return;
        }
      }
      try {
        const formData = new FormData();
        _.forIn(this.formValue, (value, key) => {
          if (key === 'translation' || (typeof value === 'object'
            && this.formData[key] && this.formData[key].type !== 'file')) {
            const val = JSON.stringify(value);
            formData.append(key, val);
          } else {
            formData.append(key, value);
          }
          if (this.$route.params.moduleName === 'limit-game'
            || this.$route.params.moduleName === 'limit-client') {
            formData.append('otherFormItems', JSON.stringify(this.otherFormItems));
          }
        });
        const res = await axios.post(this.submitPath,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        if (res.data.errors) {
          this.validationErrors = res.data.errors;
          this.$emit('show-step-validation', res.data.errors);
        } else {
          this.validationErrors = {};
          this.$emit('toggle-modal', 'updated');
        }
        this.isLoading = false;
        // eslint-disable-next-line consistent-return
        return res.data;
      } catch (e) {
        this.isLoading = false;
        socketError({
        }, e);
        // eslint-disable-next-line consistent-return
        return false;
      }
    },
    // async submitFormData() {
    //   try {
    //     const formData = new FormData();
    //     _.forIn(this.formValue, (value, key) => {
    //       if (key === 'translation' || key === 'seo_image') {
    //         const val = JSON.stringify(value);
    //         formData.append(key, val);
    //       } else {
    //         formData.append(key, value);
    //       }
    //     });
    //     const res = await axios.post(`${this.$route.path}/${this.submitType}`,
    //       formData,
    //       {
    //         headers: {
    //           'Content-Type': 'multipart/form-data',
    //         },
    //       });
    //     if (res.data.errors) {
    //       this.validationErrors = res.data.errors;
    //     } else {
    //       this.validationErrors = {};
    //     }
    //     return res.data;
    //   } catch (e) {
    //     socketError({
    //     }, e);
    //     return false;
    //   }
    // },
  },
};
</script>

<style scoped lang="scss">
.form-wrapper{
  padding: 20px;
  button:last-child{
    height: 30px;
    line-height: 1;
    margin: 0 10px;
    position: relative;
  }
  .submit-btn-wrapper{
    border-top: 1px solid #e9e9e9;
    padding-top: 10px;
    text-align: right;
    .loading-layer{
      position: absolute;
      top: 5px;
      right: 100px;
      color: #fdf70a;
      .spinner-border{
        width: 1rem;
        height: 1rem;
      }
    }
  }
  .components-wrapper{
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
