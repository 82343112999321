<template>
  <div class="form-module-wrapper">
    <FormPage1
      :form-data="formData.fields"
      :old-form-values="formData.result"
      :is-form-data-loading="isLoading"
      :submit-path="`${$route.path}${$route.query['row-id']}/update`"
      :hide-cancel="true"
    />
  </div>
</template>

<script>
import FormPage1 from '../components/partial/FormPage/FormPage1';

export default {
  components: {
    FormPage1,
  },
  data() {
    return {
      isLoading: false,
      formData: {},
    };
  },
  async mounted() {
    await this.getFormData();
  },
  methods: {
    async getFormData() {
      try {
        this.isLoading = true;
        const res = await axios.get(`${this.$route.path}${this.$route.query['row-id']}/edit`);
        this.formData = res.data;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.form-module-wrapper{
  background: #fff;
  margin: 24px 24px 0 24px;
  border-radius: 2px;
}
</style>
