<template>
  <div class="client-sms-collapse">
    <AntdModal
      text="Are You Sure You want to Send Sms"
      ok-text="Send"
      :is-loading="isLoading"
      :visible="isModalOpen"
      @handle-save="formSubmit"
      @close-modal="() => (isModalOpen = false)"
    />
    <a-collapse v-model="activeKey" :bordered="false" :accordion="true">
      <a-collapse-panel key="1" header="This is the client list who will receive sms">
        <a-form>
          <div>
            <h5>Client ID List</h5>
            <a-form-item
              :validate-status="smsTextAreaValidatingStatus"
              :help="smsTextAreaValidatingStatus === 'success' ? '' : textAreaValidationHelp"
            >
              <a-input :value="smsTextArea" type="textarea" @change="smsTextAreaHandler" />
            </a-form-item>
          </div>
          <div>
            <h5>The text that will be sent</h5>
            <a-form-item
              :validate-status="sentTextValidatingStatus"
              :help="sentTextValidatingStatus === 'success' ? '' : sentTextValidateHelp"
            >
              <a-input v-model="sentText" type="textarea" />
            </a-form-item>
          </div>
          <div class="checkbox-comp-wrapper">
            <Checkbox
              label="isPromo"
              field-name="promo"
              :label-col="{
                xs: { span: 24},
                sm: { span: 1 },
              }"
              :wrapper-col="{
                xs: { span: 24},
                sm: { span: 1 },
              }"
              @input-change="checkboxChange"
            />
          </div>
          <div class="btn-wrapper">
            <a-button type="primary" @click.prevent="openModal">
              Send
            </a-button>
          </div>
        </a-form>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import socketError from '../../../utils/socketError';
import Checkbox from '../../FormElements/Checkbox';
import AntdModal from '../Modals/AntdModal';

export default {
  components: { AntdModal, Checkbox },
  data() {
    return {
      activeKey: 0,
      isLoading: false,
      isModalOpen: false,
      sentText: '',
      isPromoChecked: 0,
      smsTextAreaValidatingStatus: 'success',
      sentTextValidatingStatus: 'success',
      sentTextValidateHelp: 'The field can not be empty',
      textAreaValidationHelp: 'The field can not be empty and the count of Ids must be less then 501',
    };
  },
  computed: {
    ...mapState({
      smsTextArea(state) {
        return state.player.smsTextArea;
      },
    }),
  },
  watch: {
    smsTextArea() {
      if (this.activeKey !== 1) {
        this.activeKey = 1;
      }
    },
  },
  methods: {
    checkboxChange(e) {
      this.isPromoChecked = e.promo;
    },
    openModal() {
      if (this.isValid()) {
        this.isModalOpen = true;
      }
    },
    smsTextAreaHandler(event) {
      this.$store.dispatch('player/smsTextAreaHandler', event.target.value);
    },
    isValid() {
      let valid = false;
      // eslint-disable-next-line no-unused-expressions
      this.sentText.length ? this.sentTextValidatingStatus = 'success'
        : this.sentTextValidatingStatus = 'error';
      // eslint-disable-next-line no-unused-expressions
      this.smsTextArea.length && this.smsTextArea.split(',').length < 500
        ? this.smsTextAreaValidatingStatus = 'success'
        : this.smsTextAreaValidatingStatus = 'error';
      // eslint-disable-next-line no-unused-expressions
      this.smsTextAreaValidatingStatus === 'success' && this.sentTextValidatingStatus === 'success'
        ? valid = true : valid = false;
      return valid;
    },
    // eslint-disable-next-line consistent-return
    async formSubmit() {
      if (this.isValid()) {
        this.isLoading = true;
        try {
          const data = {
            clientIdList: this.smsTextArea,
            smsText: this.sentText,
            promo: this.isPromoChecked,
          };
          const res = await axios.post('player/client/send-sms', data);
          this.isModalOpen = false;
          this.isLoading = false;
          await this.$store.dispatch('player/addToSmsTextArea', 'resetSmsTextArea');
          this.sentText = '';
          return res.data;
        } catch (e) {
          this.isModalOpen = false;
          this.isLoading = false;
          socketError({}, e);
          return false;
        }
      } else {
        this.isModalOpen = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.client-sms-collapse{
  text-align: left;
  .ant-collapse-item{
    background: #fff;
  }
  .btn-wrapper{
    text-align: right;
  }
}
</style>
