<!-- eslint-disable -->
<template>
  <div ref="input-component" class="input-component">
    <a-form-item
      v-if="translatable"
      :validate-status="validatingStatus"
      class="form-item-holder"
    >
      <template slot="help">
        <p v-html="validateHelp" />
      </template>
      <template slot="label">
        <a-tag color="blue">
          {{ formLocaleLang }}
        </a-tag>
        <span class="form-item-label-text">{{ label }}</span>
      </template>
      <a-upload
        :multiple="false"
        :default-file-list="translatableValue[formLocaleLang]"
        :file-list="translatableValue[formLocaleLang]"
        list-type="picture"
        :disabled="disabled"
        :before-upload="beforeUpload"
        @change="onTranslatableValueChange"
      >
        <a-button> <a-icon type="upload" /> Upload </a-button>
      </a-upload>
    </a-form-item>
    <a-form-item
      v-else
      :validate-status="validatingStatus"
      class="form-item-holder"
    >
      <template slot="help">
        <p v-html="validateHelp" />
      </template>
      <template slot="label">
        <span class="form-item-label-text">{{ label }}</span>
      </template>
      <a-upload
        ref="input"
        :default-file-list="fileList"
        :file-list="fileList"
        :disabled="disabled"
        :multiple="false"
        list-type="picture"
        :before-upload="beforeUpload"
        @change="handleChange"
      >
        <a-button> <a-icon type="upload" /> Upload </a-button>
      </a-upload>
    </a-form-item>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['oldValue', 'label', 'fieldName', 'translatable', 'translation', 'validationError', 'isValidationFirstError', 'disabled', 'formLocaleLang'],
  data() {
    return {
      value: '',
      fileList: [],
      translatableValue: {},
      validatingStatus: 'validating',
      validateHelp: '',
    };
  },
  computed: {
    ...mapState({
      langs(state) {
        return state.languages;
      },
    }),
  },
  watch: {
    isValidationFirstError(e) {
      if (e) {
        this.$refs['input-component'].scrollIntoView();
      }
    },
    validationError(e) {
      if (e && e[0]) {
        this.validatingStatus = 'error';
        // eslint-disable-next-line prefer-destructuring
        // this.validateHelp = e[0];
        let help = '';
        e.forEach((err) => {
          help += `<p>${err}</p>`;
        });
        this.validateHelp = help;
      } else {
        this.deleteValidationErrors();
      }
    },
  },
  created() {
    if (this.translatable) {
      this.setTranslatableValues();
    } else {
      this.setOldValue();
    }
  },
  mounted() {
    // if (this.translatable) {
    //   this.langs.forEach((lang) => {
    //     this.emitDefaultValues(lang);
    //   });
    // }
  },
  methods: {
    convertBase64(file) {
      if (!file) return '';
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
          resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    },
    async handleChange(e) {
      const fileList = [...e.fileList];
      this.fileList = fileList.slice(-1);
      const file = (this.fileList[0] && this.fileList[0].originFileObj) || '';
      const base64 = await this.convertBase64(file);
      this.$emit('input-change', { [this.fieldName]: base64 });
      this.emitOldValueRemove(this.fieldName);
      this.deleteValidationErrors();
    },
    handleChangeOld(e) {
      const fileList = [...e.fileList];
      this.fileList = fileList.slice(-1);
      this.$emit('input-change', { [this.fieldName]: (this.fileList[0] && this.fileList[0].originFileObj) || '' });
      this.emitOldValueRemove(this.fieldName);
      this.deleteValidationErrors();
    },
    beforeUpload() {
      return false;
    },
    emitOldValueRemove(fieldName) {
      this.$emit('input-change', { [`old_${fieldName}`]: '' });
    },
    deleteValidationErrors() {
      this.validatingStatus = 'validating';
      this.validateHelp = '';
    },
    async onTranslatableValueChange(e) {
      const fileList = [...e.fileList];
      this.translatableValue[this.formLocaleLang] = fileList.slice(-1);
      if (this.formLocaleLang === 'default') {
        this.$emit('input-change', { [this.fieldName]: (this.translatableValue[this.formLocaleLang][0] && this.translatableValue[this.formLocaleLang][0].originFileObj) || '' });
        this.emitOldValueRemove(this.fieldName);
      } else {
        this.$emit('input-change', { [`${this.fieldName}_${this.formLocaleLang}`]: (this.translatableValue[this.formLocaleLang][0] && this.translatableValue[this.formLocaleLang][0].originFileObj) || '' });
        this.emitOldValueRemove(`${this.fieldName}_${this.formLocaleLang}`);
      }
    },
    async emitDefaultValues(lang, name) {
      if (lang === 'default') {
        this.$emit('input-change', { [`old_${this.fieldName}`]: name || '' });
      } else {
        this.$emit('input-change', { [`old_${this.fieldName}_${lang}`]: name || '' });
      }
    },
    setOldValue() {
      if (this.oldValue) {
        this.fileList = [{ url: this.oldValue.url, name: this.oldValue.name, uid: '-1' }];
        this.$emit('input-change', { [`old_${this.fieldName}`]: this.oldValue.name });
      }
    },
    setTranslatableValues() {
      const translatableValue = {};
      this.langs.forEach((lang) => {
        if (lang === 'default') {
          translatableValue[lang] = (this.oldValue
            && [{
              status: 'done', url: this.oldValue.url, name: this.oldValue.name, uid: '-1',
            }]) || [];
          if (this.oldValue) {
            this.emitDefaultValues(lang, this.oldValue.name);
          }
        } else {
          const url = this.translation
            && (this.getTranslation(this.translation, this.fieldName, lang))
            && (this.getTranslation(this.translation, this.fieldName, lang)).url;
          const name = this.translation
            && (this.getTranslation(this.translation, this.fieldName, lang))
            && (this.getTranslation(this.translation, this.fieldName, lang)).name;
          translatableValue[lang] = (url && [{ url, name, uid: '-1' }])
            || [];
          this.emitDefaultValues(lang, name);
          if (url || name) {
            this.emitDefaultValues(lang, name);
          }
        }
      });
      this.translatableValue = translatableValue;
    },
  },
};
</script>

<style scoped lang="scss">
.input-component{
  text-align: left;
  .label-input{
    margin-left: 5px;
    cursor: pointer;
  }
  .label-input:hover{
    color: dodgerblue;
  }
  .img-file{
    img{
      margin-top: 10px;
      max-width: 100px;
      max-height: 100px;
    }
  }
}
</style>
