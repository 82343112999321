<template>
  <div class="top-wins">
    <a-collapse v-model="collapseActive">
      <a-collapse-panel key="1">
        <template slot="header">
          <span>Top 20 Wins</span>
        </template>
        <div>
          <AntdTable
            table-title="Top 20 Wins"
            :columns="topWins && topWins.columns"
            :rows="topWins && topWins.results && topWins.results.data"
            :loading="isLoading"
            @update-table="getTopWins"
          >
            <a-radio-group
              v-model="dateRange"
              button-style="solid"
              @change="onRangeChange"
            >
              <a-radio-button value="48">
                48h
              </a-radio-button>
              <a-radio-button value="all">
                All
              </a-radio-button>
            </a-radio-group>
          </AntdTable>
        </div>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AntdTable from '../../Table/AntdTable';

export default {
  components: { AntdTable },
  props: {},
  data() {
    return {
      dateRange: '48',
      collapseActive: [],
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      topWins(state) {
        return state.player.topWins[this.$route.params.id];
      },
      oldDateRange(state) {
        // eslint-disable-next-line max-len
        return state.player.topWins[this.$route.params.id] && state.player.topWins[this.$route.params.id].dateRange;
      },
    }),
  },
  watch: {
    oldDateRange(e) {
      if (this.dateRange !== e) {
        this.dateRange = e;
      }
    },
  },
  async mounted() {
    if (!this.topWins) {
      await this.getTopWins();
    }
  },
  methods: {
    onRangeChange() {
      this.$nextTick(() => {
        this.getTopWins();
      });
    },
    async getTopWins() {
      this.isLoading = true;
      await this.$store.dispatch('player/getTopWins',
        { dateRange: this.dateRange, path: this.$route.path, id: this.$route.params.id })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.top-wins{
  margin: 20px 0;
  padding: 0 24px;
  text-align: left;
  .ant-collapse-header{
    font-weight: 500;
  }
  .ant-table-body{
    max-height: 500px!important;
  }
}
</style>
