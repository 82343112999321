<template>
  <a-form-item
    ref="date-input"
    :validate-status="validatingStatus"
    :help="validateHelp"
    class="form-item-holder"
  >
    <template slot="label">
      <span class="form-item-label-text">{{ label }}</span>
    </template>
    <a-month-picker v-model="dateValue"
                    style="width: 100%"
                    :allow-clear="!disAllowClear"
                    placeholder="Select month"
                    format="YYYY-MM"
                    :show-time="!hideTime"
                    :disabled="disabled"
                    :input-read-only="disAllowClear"
    />
  </a-form-item>
</template>
<script>

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['oldValue', 'field', 'label', 'defaultValue', 'hideTime', 'disabled', 'validationError', 'disAllowClear'],
  data() {
    return {
      dateValue: null,
      validatingStatus: 'validating',
      validateHelp: '',
    };
  },
  watch: {
    isValidationFirstError(e) {
      if (e) {
        this.$refs['month-input'].scrollIntoView();
      }
    },
    validationError(e) {
      if (e) {
        this.validatingStatus = 'error';
        // eslint-disable-next-line prefer-destructuring
        this.validateHelp = e[0];
      } else {
        this.deleteValidationErrors();
      }
    },
    oldValue(e) {
      if (e !== moment(this.dateValue).format('YYYY-MM')) {
        if (e) {
          this.dateValue = moment(e);
        } else {
          this.dateValue = null;
        }
      }
    },
    dateValue(e) {
      const date = e ? moment(e).format('YYYY-MM') : '';
      const value = {
        [this.field]: date,
      };
      this.$emit('input-change', value);
      this.deleteValidationErrors();
    },
  },
  mounted() {
    this.dateValue = this.oldValue || this.defaultValue
      ? moment(this.oldValue || this.defaultValue) : null;
  },
  methods: {
    deleteValidationErrors() {
      this.validatingStatus = 'validating';
      this.validateHelp = '';
    },
  },
};
</script>

<style scoped lang="scss">
</style>
