<template>
  <div ref="radio-select-unity" class="radio-select-unity">
    <a-form-item
      :validate-status="validatingStatus"
      :help="validateHelp"
      class="form-item-holder"
    >
      <template slot="label" class="radio-label">
        <span class="form-item-label-text">{{ label }}</span>
      </template>
      <div style="display: flex; align-items: center">
        <a-col :xl="6"
               :xxl="6"
               :lg="6"
               :md="6"
               :sm="6"
               :xs="6"
        >
          <a-form-item>
            <a-select
              :value="selectValue"
              mode="multiple"
              @change="handleSelectChange"
            >
              <a-select-option
                v-for="val in selectDescription.values"
                :key="val.code"
              >
                {{ val.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-form-item><span style="margin: 0 4px">-</span></a-form-item>
        <a-form-item>
          <a-radio-group
            :value="picked"
            button-style="solid"
            :disabled="disabled === 'disabled'"
          >
            <a-radio-button
              v-for="(value,index) in radioDescription.values"
              :key="value + String(index)"
              :value="value.code"
              :disabled="(disabled && disabled.includes(value.code))
                || (optionDisableServerSide && optionDisableServerSide.includes(value.code))"
              class="radio-wrapper"
              @click="() => changeHandler(value.code)"
            >
              {{ value.label }}
            </a-radio-button>
          </a-radio-group>
        </a-form-item>
      </div>
    </a-form-item>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['label', 'fieldName', 'radioDescription', 'oldValue', 'validationError', 'isValidationFirstError', 'disabled', 'optionDisableServerSide', 'selectDescription'],
  data() {
    return {
      picked: '',
      validatingStatus: 'validating',
      validateHelp: '',
      selectValue: [],
    };
  },
  watch: {
    optionDisableServerSide(newVal) {
      if (newVal && newVal.length && newVal.includes(this.picked)) {
        this.picked = '';
      }
    },
    isValidationFirstError(e) {
      if (e) {
        this.$refs['radio-select-unity'].scrollIntoView();
      }
    },
    validationError(e) {
      if (e) {
        this.validatingStatus = 'error';
        // eslint-disable-next-line prefer-destructuring
        this.validateHelp = e[0];
      } else {
        this.deleteValidationErrors();
      }
    },
  },
  mounted() {
    if (this.oldValue) {
      this.picked = this.oldValue.picked;
      this.selectValue = this.oldValue.selectValue;
      this.$emit('input-change', { [this.fieldName]: this.oldValue });
    }
    if (this.radioDescription.defaultValue && !this.picked) {
      this.picked = this.radioDescription.defaultValue;
      this.changeFormElementValue();
    }
  },
  methods: {
    deleteValidationErrors() {
      this.validatingStatus = 'validating';
      this.validateHelp = '';
    },
    handleSelectChange(data) {
      this.selectValue = data;
      this.changeFormElementValue();
    },
    changeFormElementValue() {
      const data = { picked: this.picked, selectValue: this.selectValue };
      this.$emit('input-change', { [this.fieldName]: data });
    },
    changeHandler(clickedCode) {
      this.picked = clickedCode;
      this.changeFormElementValue();
    },
  },
};
</script>

<style lang="scss">

.radio-select-unity {
  text-align: left;

  .radio-wrapper {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
  }
}
</style>
