<template>
  <div class="wheel-create-form">
    <WheelForm
      :key="Math.random()"
      :form-data="form && form.fields"
      :old-form-values="form && form.result"
      :is-form-data-loading="isLoading"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import WheelForm from '../partial/FormPage/WheelForm';

export default {
  components: { WheelForm },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      wheelInitialCreateForm(state) {
        // eslint-disable-next-line max-len
        return state.wheel.wheelCreateForm;
      },
      wheelCreatedForm(state) {
        return this.$route.query.wheelId && state.wheel.wheelCreateForm[this.$route.query.wheelId];
      },
    }),
    form() {
      return this.$route.query.wheelId ? this.wheelCreatedForm : this.wheelInitialCreateForm;
    },
  },
  watch: {
    async $route() {
      if (this.$route.query.wheelId && !this.wheelCreatedForm) {
        await this.$store.dispatch('wheel/getWheelById', {
          id: this.$route.query.wheelId,
        });
      }
      if (!this.$route.query.wheelId && !this.wheelInitialCreateForm.fields) {
        await this.getWheelCreateForm();
      }
    },
  },
  async mounted() {
    if (this.$route.query.wheelId) {
      await this.$store.dispatch('wheel/getWheelById', {
        id: this.$route.query.wheelId,
      });
    } else {
      await this.getWheelCreateForm();
    }
  },
  methods: {
    async getWheelCreateForm() {
      await this.$store.dispatch('wheel/getWheelCreateForm');
    },
  },
};
</script>

<style lang="scss" scoped>
.wheel-create-form{
  background: #FFFFFF;
  border-radius: 8px;
  padding: 24px;
}
</style>
