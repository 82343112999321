<template>
  <div class="locale-changer">
    <a-select
      v-model="selectedValue"
      :style="{'width': '120px'}"
    >
      <a-select-option v-for="(option, index) in langs" :key="index" :value="option">
        {{ option }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      selectedValue: '',
    };
  },
  computed: {
    ...mapState({
      langs(state) {
        return state.languages;
      },
    }),
  },
  watch: {
    async selectedValue(e) {
      await this.$store.dispatch('setLanguage', { lang: e });
    },
    localeLang() {
      this.selectedValue = this.localeLang;
    },
  },
  mounted() {
    this.selectedValue = this.localeLang || this.langs[0];
  },
};
</script>

<style lang="scss">
.locale-changer {
  text-align: left;
}
</style>
