<template>
  <div class="cols-and-rows">
    <label v-for="(type, index) in types"
           :key="index"
           :class="{ selected: index === selected }"
           class="stripes-container"
    >
      <span v-for="(row) in [1,2]"
            :key="row"
            style="width: 100%;height:50%;display: flex"
      >
        <span v-for="col in [1,2]"
              :key="col"
              :class="{
                'striped-bg': row <= type.rows && col <= type.cols,
                'solid-bg': row > type.rows || col > type.cols,
              }"
        />
      </span>
      <input v-model="selected"
             type="radio"
             hidden
             :value="index"
             name="colsAndRows"
             @change="onChange(index)"
      >
    </label>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['oldValue'],
  data() {
    return {
      selected: '',
      types: [
        { cols: 1, rows: 1 },
        { cols: 1, rows: 2 },
        { cols: 2, rows: 1 },
        { cols: 2, rows: 2 },
      ],
    };
  },
  mounted() {
    this.setOldValue();
  },
  methods: {
    setOldValue() {
      this.types.forEach((type, index) => {
        if (type.cols === this.oldValue.cols && type.rows === this.oldValue.rows) {
          this.selected = index;
        }
      });
    },
    onChange(index) {
      this.$emit('cols-and-rows-change', this.types[index]);
    },
  },
};
</script>

<style scoped lang="scss">
.cols-and-rows{
  display: flex;
  flex-wrap: wrap;
  width: 64px;
  margin: auto;
  .stripes-container{
    display: flex;
    flex-wrap: wrap;
    width: 30px;
    height: 30px;
    margin: 1px;
    cursor: pointer;
    &.selected{
      border: 1px solid #286090;
    }
    .striped-bg{
      display: block;
      background-image:
        linear-gradient(45deg, #545253 12.50%, #dceefa 12.50%, #dceefa 50%,
          #545253 50%, #545253 62.50%, #dceefa 62.50%, #dceefa 100%);
      background-size: 5.66px 5.66px;
      width: 50%;
      height: 100%;
    }
    .solid-bg{
      width: 50%;
      height: 100%;
      display: block;
      background-color: #DCEEFA;
    }
  }
}
</style>
