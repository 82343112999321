<template>
  <div class="client_verification">
    <CreateFormPage
      :visible="isCreateFormModalOpen"
      :id-of-create-modal="isCreateFormModalOpen"
      @toggle-modal="toggleCreateFormModal('after-create')"
    />
    <div class="container-fluid">
      <div class="row">
        <div class="client_verification_form col-6 border p-4">
          <VerificationForm
            :form-data="fields"
            :old-form-values="formResult"
            :own-submit="true"
            submit-button-text="Save"
            :validation-errors="verificationFormErrors"
            @form-data-submit="updateClientInfo"
          />
        </div>
        <div class="client_document_verification col-6 border p-4">
          <div
            v-for="(oldValue, key) in results"
            :key="String(key)"
            class="row mt-3 mb-3 border"
            :style="{ display: 'flex' }"
          >
            <div class="col-4">
              <img
                v-img
                :src="oldValue.content"
                :alt="oldValue.name"
                class="img-fluid"
              >
            </div>
            <div class="col-xl-8">
              <FormPage
                :form-data="document_fields"
                :old-form-values="oldValue"
                :field-name="typeof oldValue.id !== 'string'
                  ? String(oldValue.id) : oldValue.id"
                :own-submit="false"
                :validation-errors="errors"
                @input-change="docFieldsFormChange"
              />
            </div>
          </div>
          <div class="col-12 mt-4">
            <button class="btn btn-warning mr-2" @click="toggleCreateFormModal('create')">
              Request Document
            </button>
            <button class="btn btn-success" @click="documentRequest">
              Save
            </button>
          </div>
        </div>
        <div class="verify_client col-12 border p-4">
          <button class="btn btn-info mt-10 mb-10" @click="verifyClient">
            Verify
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CreateFormPage from './CreateFormPage';
import FormPage from '../components/partial/FormPage/FormPage';
import VerificationForm from '../components/partial/FormPage/VerificationForm';
import socketError from '../utils/socketError';

export default {
  components: {
    CreateFormPage, FormPage, VerificationForm,
  },
  props: {
    validationErrors: {
      type: Object,
      default() {
        return {};
      },
    },
    oldFormValues: {
      type: Object,
      default() {
        return {};
      },
    },
    parentFormValue: {
      type: Object,
      default() {
        return {};
      },
    },
    ownSubmit: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default() {
        return {};
      },
    },
    fieldName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      docFieldsForm: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      },
      selectedDateRange: {
        startDate: null,
        endDate: null,
      },
      isFormTranslatable: false,
      formValue: {},
      errors: {},
      verificationFormErrors: {},
    };
  },
  computed: {
    ...mapState({
      isCreateFormModalOpen(state) {
        return state.modals.isCreateFormModalOpen;
      },
      fields(state) {
        return state.gameModule.clientInfo[this.$route.params.id]
          && state.gameModule.clientInfo[this.$route.params.id][this.$route.query.type].fields;
      },
      document_fields(state) {
        return state.gameModule.clientInfo[this.$route.params.id]
          && state.gameModule.clientInfo[this.$route.params.id][this.$route.query.type].docFields;
      },
      formResult(state) {
        return state.gameModule.clientInfo[this.$route.params.id]
          && state.gameModule.clientInfo[this.$route.params.id][this.$route.query.type].result;
      },
      results(state) {
        return state.gameModule.clientInfo[this.$route.params.id]
          && state.gameModule.clientInfo[this.$route.params.id][this.$route.query.type].results;
      },
    }),
  },
  async mounted() {
    await this.getClientVerificationInfo();
  },
  methods: {
    async documentRequest() {
      const data = Object.values(this.docFieldsForm);
      try {
        const res = await axios.post('/admin/client-verification/update-client-documents', {
          client_id: this.$route.params.id,
          results: data,
        });
        if (res.data.errors) {
          this.verificationFormErrors = res.data.errors;
        }
        return res.data;
      } catch (e) {
        socketError({}, e);
        return false;
      }
    },
    async updateClientInfo(formData) {
      try {
        const res = await axios.post('/admin/client-verification/update-client-info', {
          client_id: this.$route.params.id,
          ...formData,
        });
        if (res.data.errors) {
          this.verificationFormErrors = res.data.errors;
        }
        return res.data;
      } catch (e) {
        socketError({}, e);
        return false;
      }
    },
    async verifyClient() {
      try {
        const res = await axios.post('/admin/client-verification/verify-client', {
          client_id: this.$route.params.id,
        });
        return res.data;
      } catch (e) {
        socketError({}, e);
        return false;
      }
    },
    docFieldsFormChange(formValue) {
      this.docFieldsForm = {
        ...this.docFieldsForm,
        ...formValue,
      };
    },
    async toggleCreateFormModal() {
      await this.$store.dispatch('modals/toggleCreateFormModal');
    },
    isValidationFirstError(errors, key) {
      return Object.keys(errors)[0] === key;
    },
    inputChange(e) {
      this.formValue = {
        ...this.formValue,
        ...e,
      };
      this.$emit('input-change', { [this.fieldName]: this.formValue });
    },
    submitFormData() {},
    async getClientVerificationInfo() {
      const params = {
        path: this.$route.path,
        id: this.$route.params.id,
        type: this.$route.query.type,
      };
      await this.$store.dispatch('gameModule/getClientVerificationInfo', params);
    },
  },
};
</script>
