/* eslint-disable */
<template>
  <div class="date-range-picker-with-presets">
    <a-form-item
      ref="date-range-picker-with-presets"
      class="form-item-holder"
      :style="asFormElement ? {} : { margin: 0 }"
    >
      <template v-if="asFormElement" slot="label">
        <span class="form-item-label-text">{{ label }}</span>
      </template>
      <a-range-picker v-model="dateValue"
                      style="width: 100%"
                      :ranges="pickerRanges"
                      :disabled-date="disabledDate"
                      :default-value="[]"
                      :allow-clear="false"
                      :popup-style="popupStyle"
                      :show-time="showTime"
                      :dropdown-class-name="isPickerPartsWrapped
                        ? 'picker-custom-wrapped-parts' : ''"
                      @change="handleChange"
      >
        <span
          v-if="labelCreate"
          style="border: 1px solid #0000002e;
                 width: 100%;
                 display: inline-block;
                 cursor: pointer;
                 border-radius: 4px;
                 padding: 4px 6px;"
        >
          {{ labelCreate }}
        </span>
      </a-range-picker>
    </a-form-item>
  </div>
</template>
<script>
import moment from 'moment-timezone';

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['oldValue', 'field', 'label', 'showTime', 'defaultValue', 'disAllowClear', 'asFormElement'],
  data() {
    return {
      dateValue: [],
      moment,
      popupStyle: {},
      presetList: [],
    };
  },
  computed: {
    isPickerPartsWrapped() {
      return window.innerWidth < 600;
    },
    pickerRanges() {
      return _.mapValues(this.presetList, (o) => ([
        moment(o[0]),
        moment(o[1]),
      ]));
    },
    labelCreate() {
      if (!this.dateValue.length) return '';
      const label = _.findKey(this.presetList,
        (o) => this.dateValue[0].isSame(o[0]) && this.dateValue[1].isSame(o[1]));
      return label;
    },
  },
  watch: {
    oldValue(e) {
      if (e && e.startDate && e.endDate) {
        const isSame = e.startDate === moment(this.dateValue[0]).unix()
          && e.endDate === moment(this.dateValue[1]).unix();
        if (!isSame) {
          this.dateValue = [moment(e.startDate * 1000), moment(e.endDate * 1000)];
        }
      }
    },
    dateValue(e) {
      if (e && e[0]) {
        let value = null;
        if (this.showTime) {
          value = {
            [this.field]: {
              startDate: moment(e[0]).unix(),
              endDate: moment(e[1]).unix(),
            },
          };
        } else {
          value = {
            [this.field]: {
              startDate: moment(e[0]).startOf('day').unix(),
              endDate: moment(e[1]).endOf('day').unix(),
            },
          };
        }
        this.$emit('input-change', value);
      } else {
        this.$emit('input-change', {
          [this.field]: null,
        });
      }
    },
  },
  created() {
    this.setPresetList();
  },
  mounted() {
    if (this.oldValue && this.oldValue.startDate) {
      const value = { ...this.oldValue };
      this.dateValue = value
        ? [moment(value.startDate * 1000),
          moment(value.endDate * 1000)] : [];
    }
  },
  methods: {
    handleChange(e) {
      const value = {
        startDate: moment(e[0]).startOf('day').unix(),
        endDate: moment(e[1]).endOf('day').unix(),
      };
      this.$emit('handle-date-range-picker-change', value);
    },
    disabledDate(current) {
      // Can not select days before today and today
      return current && current > moment().endOf('day');
    },
    setPresetList() {
      this.presetList = {
        yesterday: [
          moment().subtract(1, 'days').startOf('day').unix() * 1000,
          moment().subtract(1, 'days').endOf('day').unix() * 1000,
        ],
        today: [
          moment().startOf('day').unix() * 1000,
          moment().endOf('day').unix() * 1000,
        ],
        'This Month': [
          moment().startOf('month').unix() * 1000,
          moment().endOf('day').unix() * 1000,
        ],
        'This Week': [
          moment().startOf('isoWeek').unix() * 1000,
          moment().endOf('day').unix() * 1000,
        ],
        'Previous Week': [
          moment().subtract(1, 'week').startOf('isoWeek').unix() * 1000,
          moment().subtract(1, 'week').endOf('isoWeek').unix() * 1000,
        ],
        'Previous Month': [
          moment().subtract(1, 'month').startOf('month').unix() * 1000,
          moment().subtract(1, 'month').endOf('month').unix() * 1000,
        ],
        'Last 30 Days': [
          moment().subtract(29, 'days').startOf('day').unix() * 1000,
          moment().endOf('day').unix() * 1000,
        ],
        'Last 7 Days': [
          moment().subtract(6, 'days').startOf('day').unix() * 1000,
          moment().endOf('day').unix() * 1000,
        ],
      };
    },
  },
};
</script>
<style lang="scss">
.picker-custom-wrapped-parts{
  &.ant-calendar-picker-container{
    .ant-calendar-range{
      width: 250px;
      .ant-calendar-panel{

        .ant-calendar-date-panel{
          display: flex;
          flex-direction: column;
          .ant-calendar-range-part{
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
<style scoped lang="scss">
.date-range-picker-with-presets{
  .custom-date-viewer {
    border: 1px solid #0000002e;
    width: 200px;
    display: inline-block;
    cursor: pointer;
    border-radius: 6px;
    padding: 8px;
  }
}
</style>
