<template>
  <div class="customized-ant">
    <div class="menu-header">
      <span class="icon-wrapper">
        <i v-if="whichMenuIsOpened"
           class="fas fa-angle-left icon-arrow sub-menu-close"
           @click="() => whichMenuIsOpened = false"
        />
      </span>

      <span class="smartbet">Smartbet</span>
    </div>

    <div class="nav-sidebar-wrapper">
      <div v-if="!hideMenuSearchField" class="nav-sidebar-search">
        <SideBarSearch v-show="!menuCollapsed" @search="searchMenu" />

        <label
          v-show="menuCollapsed"
          for="sidebar-search"
          @click="() => collapseMenu()"
        >
          <a-icon type="search" />
        </label>
      </div>

      <ul class="nav nav-pills nav-sidebar nav-child-indent flex-column"
          data-widget="treeview"
          role="menu"
      >
        <SideBarNavLinkGroup
          v-for="(navLinkGroup, index) in filteredGroupLinks"
          :key="index"
          :nav-link-group="navLinkGroup.children"
          :title="navLinkGroup.title"
          :icon="navLinkGroup.icon_class"
          :url="navLinkGroup.url"
          :is-menu-opened="whichMenuIsOpened === navLinkGroup.title"
          @toggle-menu="toggleMenu"
        />
      </ul>
    </div>
  </div>
<!--  <aside-->
<!--    class="main-sidebar sidebar-dark-primary elevation-4"-->
<!--    :class="{ active: isActive }"-->
<!--  >-->
<!--    &lt;!&ndash;    <router-link&ndash;&gt;-->
<!--    &lt;!&ndash;      to="/"&ndash;&gt;-->
<!--    &lt;!&ndash;    >&ndash;&gt;-->
<!--    &lt;!&ndash;      <img&ndash;&gt;-->
<!--    &lt;!&ndash;        alt="Admin Logo"&ndash;&gt;-->
<!--    &lt;!&ndash;        class="brand-image-xl"&ndash;&gt;-->
<!--    &lt;!&ndash;        src="../../assets/img/logo.svg"&ndash;&gt;-->
<!--    &lt;!&ndash;
   style="left: 12px; width: 80px; margin-top: 10px; margin-bottom: 8px"&ndash;&gt;-->
<!--    &lt;!&ndash;      >&ndash;&gt;-->
<!--    &lt;!&ndash;    </router-link>&ndash;&gt;-->
<!--    <div class="menu-header">-->
<!--      <span class="icon-wrapper">-->
<!--        <i v-if="whichMenuIsOpened"-->
<!--           class="fas fa-angle-left icon-arrow sub-menu-close"-->
<!--           @click="() => whichMenuIsOpened = false"-->
<!--        />-->
<!--      </span>-->
<!--      <span class="smartbet">smartbet</span>-->
<!--    </div>-->
<!--    <div class="sidebar">-->
<!--      <nav class="mt-2">-->
<!--        <div class="nav-sidebar-wrapper">-->
<!--          <ul class="nav nav-pills nav-sidebar nav-child-indent flex-column"-->
<!--              data-widget="treeview"-->
<!--              role="menu"-->
<!--          >-->
<!--            <SideBarNavLinkGroup-->
<!--              v-for="(navLinkGroup, index) in groupLinks"-->
<!--              :key="index"-->
<!--              :nav-link-group="navLinkGroup.children"-->
<!--              :title="navLinkGroup.title"-->
<!--              :icon="navLinkGroup.icon_class"-->
<!--              :url="navLinkGroup.url"-->
<!--              :is-menu-opened="whichMenuIsOpened === navLinkGroup.title"-->
<!--              @toggle-menu="toggleMenu"-->
<!--            />-->
<!--          </ul>-->
<!--        </div>-->
<!--      </nav>-->
<!--    </div>-->
<!--  </aside>-->
</template>

<script>
import { mapGetters } from 'vuex';
import SideBarNavLinkGroup from '../sideBarNavLink/SideBarNavLinkGroup';
import SideBarSearch from '../partial/SideBarSearch/SideBarSearch';

export default {
  components: {
    SideBarNavLinkGroup,
    SideBarSearch,
  },

  // eslint-disable-next-line vue/require-prop-types
  props: ['menuCollapsed', 'collapse'],

  data() {
    return {
      whichMenuIsOpened: false,
      filteredGroupLinks: [],
    };
  },

  computed: {
    ...mapGetters({
      userInfo: 'info/userInfo',
      groupLinks: 'sidebar/groupLinks',
      isActive: 'sidebar/isActive',
    }),
    hideMenuSearchField() {
      return process.env.VUE_APP_hideMenuSearchField;
    },
  },

  watch: {
    groupLinks() {
      this.filteredGroupLinks = this.groupLinks;
    },
  },

  async mounted() {
    await this.$store.dispatch('sidebar/getSideBarGroupList');
  },

  methods: {
    toggleMenu(menuName) {
      this.whichMenuIsOpened = menuName;
      // if (menuName) {
      //   this.whichMenuIsOpened = menuName;
      // } else {
      //   this.whichMenuIsOpened = false;
      // }
    },

    filterLinks(groupLinks, searchText) {
      return groupLinks.reduce((result, currentValue) => {
        if (currentValue.children.length) {
          const childData = this.filterLinks(currentValue.children, searchText);
          return result.concat(childData);
        }

        if (currentValue.title.toLowerCase().indexOf(searchText) !== -1) {
          result.push(currentValue);
        }

        return result;
      }, []);
    },

    searchMenu(searchText) {
      if (!searchText.trim().length) {
        this.filteredGroupLinks = this.groupLinks;

        return;
      }

      this.filteredGroupLinks = this.filterLinks(this.groupLinks, searchText.toLowerCase());
    },

    collapseMenu() {
      this.$emit('collapse', true);
    },
  },
};
</script>

<style scoped lang="scss">
.customized-ant {
  overflow: hidden;

  .nav.nav-sidebar {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    position: relative;
    white-space: nowrap;

    .nav-item {
      min-width: fit-content;
    }
  }

  .menu-header {
    display: flex;
    align-items: center;
    background: #000000;
    height: 64px;

    .icon-wrapper {
      display: inline-block;
      width: 66px;
      margin-right: 30px;
      padding-left: 46px;
      margin-top: 6px;

      .sub-menu-close {
        font-size: 20px;
        color: #1890FF;
        cursor: pointer;
      }
    }

    .smartbet {
      font-size: 20px;
      color: white;
      font-weight: bold;
    }
  }

  .nav-sidebar-wrapper {
    .nav-sidebar-search {
      height: 62px;
      display: flex;
      justify-content: center;
      align-content: center;

      label {
        align-self: center;
        margin-bottom: 0;
      }

      .anticon-search {
        background-color: #000929;
        border: 1px solid rgba(108, 117, 125, 0.6392156863);
        border-radius: 4px;
        color: #6c757d;
        width: 32px;
        height: 32px;
        display: flex;
        padding: 8px;
        align-self: center;
        cursor: pointer;

        &:hover {
          color: #1890ff
        }
      }
    }

    .nav-sidebar {
      height: 100vh;
    }
  }
}

.main-sidebar {
  background: #000929!important;
  border: 1px solid #000000;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  width: 50px;
  height: 100vh !important;

  .sidebar {
    padding-top: 10px;

    .nav-sidebar-wrapper {
      position: relative;
    }
  }

  .menu-header {
    margin-top: 10px;
    display: flex;
    align-items: center;

    .icon-wrapper {
      display: inline-block;
      width: 66px;
      margin-right: 30px;

      .sub-menu-close {
        font-size: 20px;
        color: #1890FF;
        cursor: pointer;
      }
    }

    .smartbet {
      font-size: 20px;
      color: white;
      font-weight: bold;
    }
  }

  &.active {
    width: inherit;
  }

  *::-webkit-scrollbar {
    background:#c3d1e2;
    color:red;
    height: 10px;
    border-radius: 10px;
    width: 10px;
  }

  *::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #172f8a4a;
    border-radius: 10px;
  }

  *::-webkit-scrollbar-thumb {
    background: #1322336b;
    border-radius: 10px;
  }
}
</style>
