<template>
  <div
    class="client-page-menu-wrapper"
  >
    <div ref="tabs-wrapper" class="tabs-wrapper">
      <a-tabs
        v-model="activeKeyLocal"
        :animated="true"
        default-active-key="1"
        tab-position="top"
        :style="isMenuToggleable ? `height: 500px`: ''"
        @change="onTabClick"
      >
        <a-tab-pane v-for="(item) in exactUserMenuSection"
                    :key="item.field"
        >
          <template slot="tab">
            <span :style="menuStyle[item.field]">
              {{ item.label }}
              <span v-if="tabNotation[item.field]" class="tab-notation">
                {{ tabNotation[item.field].notation }}
              </span>
            </span>
          </template>
          <slot />
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    isMenuToggleable: {
      type: Boolean,
      default: false,
    },
    exactUserMenuSection: {
      type: Array,
      default() {
        return [];
      },
    },
    menuStyle: {
      type: Object,
      default() {
        return {};
      },
    },
    menuOpened: {
      type: Boolean,
      default: false,
    },
    tabNotation: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      activeKeyLocal: '',
    };
  },
  watch: {
    $route() {
      this.activeKeyLocal = this.$route.params.menuItem;
    },
  },
  mounted() {
    if (this.$route.params.menuItem) {
      this.activeKeyLocal = this.$route.params.menuItem;
    } else {
      this.activeKeyLocal = 'main';
    }
    // if (!this.menuHeight && this.$refs['tabs-wrapper']) {
    //   this.menuHeight = this.$refs['tabs-wrapper'].clientHeight - 100 > window.innerHeight
    //     ? window.innerHeight - 40
    //     : 0;
    // }
  },
  methods: {
    onTabClick(e) {
      if (this.$route.params.menuItem !== e) {
        this.$router.push(e);
      }
    },
    // toggleMenu(e) {
    //   if (e.target.className) {
    //     this.$emit('toggle-menu');
    //   }
    //   if (!e.target.closest('div#client-page-menu')) {
    //     this.$emit('toggle-menu');
    //   }
    // },
  },
};
</script>

<style scoped lang="scss">
.client-page-menu-wrapper{
  margin-top: 24px;
  background: #FFFFFF;
  border-radius: 2px;
  padding-bottom: 20px;
  /*<!--&.isMenuToggleable{-->*/
  /*<!--  position: absolute;-->*/
  /*<!--  top: 0;-->*/
  /*<!--  left: 0;-->*/
  /*<!--  z-index: 44;-->*/
  /*<!--  .tabs-wrapper{-->*/
  /*<!--    background: white;-->*/
  /*<!--  }-->*/
  /*<!--  .layer{-->*/
  /*<!--    position: absolute;-->*/
  /*<!--    background: #80808017;-->*/
  /*<!--    width: 100vw;-->*/
  /*<!--    height: 100vh;-->*/
  /*<!--    top: 0;-->*/
  /*<!--    z-index: -1;-->*/
  /*<!--  }-->*/
  /*<!--}-->*/
  /*<!--.client-page-menu{-->*/
  /*<!--  display: flex;-->*/
  /*<!--  text-align: left;-->*/
  /*<!--  padding-left: 20px;-->*/
  /*<!--  flex-direction: column;-->*/
  /*<!--  background-color: #f3f3f3eb;-->*/
  /*<!--  width: 200px;-->*/
  /*<!--  border-bottom-right-radius: 60px;-->*/
  /*<!--  border-top-right-radius: 60px;-->*/
  /*<!--  !*>i{*!-->*/
  /*<!--  !*  position: absolute;*!-->*/
  /*<!--  !*  top: 0;*!-->*/
  /*<!--  !*  left: 230px;*!-->*/
  /*<!--  !*  font-size: 30px;*!-->*/
  /*<!--  !*}*!-->*/
  /*<!--  a{-->*/
  /*<!--    line-height: 1.6;-->*/
  /*<!--    font-weight: 700;-->*/
  /*<!--    font-size: 16px;-->*/
  /*<!--    font-style: oblique;-->*/
  /*<!--    color: #1961ad;-->*/
  /*<!--  }-->*/
  /*<!--  a:hover{-->*/
  /*<!--    background-color: #8080801c;-->*/
  /*<!--    border-radius: 30%;-->*/
  /*<!--    color: #006cde;-->*/
  /*<!--  }-->*/
  /*<!--}-->*/
  .tab-notation{
    position: absolute;
    right: -5px;
    top: 5px;
    color: rgba(0, 0, 0, 0.82);;
  }
}
@media only screen and (max-width: 1450px) {
  .client-page-menu-wrapper{
    margin-top: 10px;
  }
}
</style>
