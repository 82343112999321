import axios from 'axios';
import socketError from '../utils/socketError';

export default {
  namespaced: true,

  state: {
    isActive: false,
    groupLinks: [
      {
        dashboard: {
          icon: 'nav-icon fas fa-tachometer-alt',
          subGroupLinks: [],
        },
      },
      {
        reports:
          {
            icon: 'nav-icon fas fa-th',
            subGroupLinks: [
              { financial: {} },
              { someField: {} },
            ],
          },
      },
    ],
  },
  mutations: {
    open(state) {
      state.isActive = true;
    },
    close(state) {
      state.isActive = false;
    },
    setGroupLinks(state, groupLinks) {
      state.groupLinks = groupLinks || [];
    },
  },
  actions: {
    open({ commit }) {
      commit('open');
    },
    close({ commit }) {
      commit('close');
    },
    async getSideBarGroupList({ dispatch, commit }) {
      try {
        const params = {};
        const selectedSiteId = localStorage.getItem('selectedPartner');
        if (selectedSiteId) {
          params.selectedSiteId = selectedSiteId;
        }
        const res = await axios.get('/menu-items', { params });
        const resData = res.data;
        commit('setGroupLinks', resData);
        // console.log(resData);
        return resData;
      } catch (e) {
        socketError({ dispatch, commit }, e);
        return false;
      }
    },
  },
  getters: {
    groupLinks: (s) => s.groupLinks,
    isActive: (s) => s.isActive,
  },
};
