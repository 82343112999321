<template>
  <div ref="input-component" class="input-component">
    <a-form-item
      v-if="translatable"
      :validate-status="validatingStatus"
      :help="validateHelp"
      class="form-item-holder"
      :html-for="`${label}/input`"
    >
      <template slot="label">
        <a-tag color="blue">
          {{ formLocaleLang }}
        </a-tag>
        <span class="form-item-label-text">{{ label }}</span>
      </template>
      <a-input
        :id="`${label}/input`"
        v-model="translatableValue[formLocaleLang]"
        :type="type"
        :disabled="disabled"
        :value="translatableValue[formLocaleLang]"
        @change="onTranslatableValueChange"
      />
    </a-form-item>
    <a-form-item
      v-else
      :validate-status="validatingStatus"
      class="form-item-holder"
      :help="validateHelp"
      :html-for="`${label}/input`"
    >
      <template slot="label">
        <span class="form-item-label-text">{{ label }}</span>
      </template>
      <a-input
        :id="`${label}/input`"
        ref="input"
        v-model="value"
        :disabled="disabled"
        :type="type"
        :value="value"
        :min="min"
        class="form-control"
        :step="step"
        @change="handleInputChange"
      />
      <span v-if="type === 'file' && oldValue">
        <span class="img-file">
          <img :src="oldValue" :alt="oldValue">
        </span>
      </span>
    </a-form-item>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['oldValue', 'label', 'type', 'min', 'step', 'fieldName', 'translatable', 'translation', 'validationError', 'isValidationFirstError', 'disabled', 'required', 'showValidation', 'shownInStep', 'formLocaleLang'],
  data() {
    return {
      value: '',
      translatableValue: {},
      validatingStatus: 'validating',
      validateHelp: '',
    };
  },
  computed: {
    ...mapState({
      langs(state) {
        return state.languages;
      },
    }),
  },
  watch: {
    isValidationFirstError(e) {
      if (e) {
        this.$refs['input-component'].scrollIntoView();
      }
    },
    // showValidation() {
    //   if (this.shownInStep) {
    //     const validation = this.validateComponent();
    //     if (validation === 'valid') {
    //       this.validatingStatus = 'validating';
    //       this.validateHelp = '';
    //     } else {
    //       this.validatingStatus = 'error';
    //       this.validateHelp = 'The field is required';
    //     }
    //   }
    // },
    validationError(e) {
      if (e) {
        this.validatingStatus = 'error';
        // eslint-disable-next-line prefer-destructuring
        this.validateHelp = e[0];
      } else {
        this.deleteValidationErrors();
      }
    },
  },
  created() {
    if (this.translatable) {
      this.setTranslatableValues();
    }
  },
  mounted() {
    // this.validateComponent();
    if (this.type === 'color' && !this.oldValue) {
      this.value = '#000000';
      this.$emit('input-change', { [this.fieldName]: '#000000' });
    }
    if (this.type === 'date' && this.oldValue) {
      this.value = moment(this.oldValue).format('YYYY-MM-DD');
      this.$emit('input-change', { [this.fieldName]: this.oldValue });
    } else if ((this.oldValue || this.oldValue === 0) && !this.translatable) {
      this.value = this.oldValue;
      this.$emit('input-change', { [this.fieldName]: this.oldValue });
    }
    if (this.translatable) {
      this.langs.forEach((lang) => {
        this.emitDefaultValue(lang);
      });
    }
  },
  methods: {
    validateComponent() {
      if (this.required && !this.value && this.value !== 0) {
        this.$emit('form-element-validation', { [this.fieldName]: 'isInValid' });
        return 'isInValid';
      }
      this.$emit('form-element-validation', { [this.fieldName]: 'valid' });
      return 'valid';
    },
    handleInputChange() {
      if (this.type === 'date') {
        this.$emit('input-change', { [this.fieldName]: moment(this.value).unix() });
        // this.validateComponent();
        this.deleteValidationErrors();
      } else {
        this.$emit('input-change', { [this.fieldName]: this.value });
        // this.validateComponent();
        this.deleteValidationErrors();
      }
    },
    deleteValidationErrors() {
      this.validatingStatus = 'validating';
      this.validateHelp = '';
    },
    emitDefaultValue(lang) {
      if (lang === 'default') {
        this.$emit('input-change', { [this.fieldName]: this.translatableValue[lang] || '' });
      } else {
        const obj = {
          lang,
          info: {
            column_name: this.fieldName,
            value: this.translatableValue[lang],
          },
        };
        this.$nextTick(() => {
          this.$emit('translation-input-change', obj);
        });
      }
    },
    onTranslatableValueChange() {
      if (this.formLocaleLang === 'default') {
        this.$emit('input-change', { [this.fieldName]: this.translatableValue[this.formLocaleLang] || '' });
        this.deleteValidationErrors();
      } else {
        const obj = {
          lang: this.formLocaleLang,
          info: {
            column_name: this.fieldName,
            value: this.translatableValue[this.formLocaleLang],
          },
        };
        this.$nextTick(() => {
          this.deleteValidationErrors();
          this.$emit('translation-input-change', obj);
        });
      }
    },
    setTranslatableValues() {
      const translatableValue = {};
      // translatableValue[formLocaleLang][fieldName]
      this.langs.forEach((lang) => {
        if (lang === 'default') {
          translatableValue[lang] = this.oldValue || '';
        } else {
          translatableValue[lang] = (this.translation
            && this.getTranslation(this.translation, this.fieldName, lang)) || '';
        }
      });
      this.translatableValue = translatableValue;
    },
  },
};
</script>

<style scoped lang="scss">
.input-component{
  text-align: left;
  display: block!important;
  .label-input{
    /*margin-left: 5px;*/
    cursor: pointer;
    display: inline!important;
    margin: 0!important;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
;
  }
  .label-input:hover{
    color: dodgerblue;
  }
  .img-file{
    img{
      margin-top: 10px;
      max-width: 100px;
      max-height: 100px;
    }
  }
}
</style>
