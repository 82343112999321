<template>
  <div ref="sms-sender-form-element" class="sms-sender-form-element">
    <a-form-item
      :label="label"
      :validate-status="validatingStatus"
      :help="validateHelp"
    >
      <div style="display: flex">
        <a-input v-model="phoneNumber" />
        <a-button style="margin-left: 15px" :loading="isLoading" @click.prevent="sendSms">
          {{ buttonText || 'Send Sms' }}
        </a-button>
      </div>
    </a-form-item>
  </div>
</template>
<script>

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['type', 'label', 'fieldName', 'oldValue', 'isValidationFirstError', 'validationError', 'disabled', 'path', 'formValue', 'buttonText'],
  data() {
    return {
      validatingStatus: 'validating',
      validateHelp: '',
      phoneNumber: null,
      isLoading: false,
    };
  },
  watch: {
    phoneNumber() {
      this.validatingStatus = 'validating';
      this.validateHelp = '';
    },
    isValidationFirstError(e) {
      if (e) {
        this.$refs['sms-sender-form-element'].scrollIntoView();
      }
    },
    validationError(e) {
      if (e) {
        this.validatingStatus = 'error';
        // eslint-disable-next-line prefer-destructuring
        this.validateHelp = e[0];
      } else {
        this.deleteValidationErrors();
      }
    },
  },
  methods: {
    openNotificationWithIcon() {},
    // openNotificationWithIcon(type, message, duration = 10) {
    //   this.$notification[type]({
    //     message: type,
    //     top: '20%',
    //     left: '20%',
    //     placement: 'topLeft',
    //     duration,
    //     description: message,
    //   });
    // },
    async sendSms() {
      if (!this.phoneNumber) {
        this.validatingStatus = 'error';
        this.validateHelp = 'If you want to send sms then this field cant be empty';
      } else {
        this.isLoading = true;
        await axios.post(this.path, {
          [this.fieldName]: this.phoneNumber,
          ...this.formValue,
        }).then((res) => {
          this.isLoading = false;
          if (res.data && res.data.errors) {
            // this.validatingStatus = 'error';
            // eslint-disable-next-line prefer-destructuring
            // this.validateHelp = Object.values(res.data.errors)[0];
            this.openNotificationWithIcon('error', Object.values(res.data.errors)[0]);
          } else if (res.data && res.data.alert) {
            if (res.data.alert !== 'error') {
              this.openNotificationWithIcon('success', res.data.message || res.data.alert);
            } else {
              this.openNotificationWithIcon('error', res.data.message || res.data.alert);
            }
          } else if (!res) {
            this.openNotificationWithIcon('error', 'Request Error');
          }
        });
      }
    },
    deleteValidationErrors() {
      this.validatingStatus = 'validating';
      this.validateHelp = '';
    },
  },
};
</script>

<style scoped lang="scss">
</style>
