<template>
  <div :key="$route.fullPath" class="bet-shops">
    <div v-if="$route.query.type === 'info'">
      <AntdTable
        :columns="columns"
        :rows="results"
        :loading="isLoading"
        :order-params="ordering"
        :table-title="$route.query.type"
        @search-by-order="searchByOrder"
      />
    </div>
    <div v-else>
      <!--      <FormPage-->
      <!--        :form-data="fields"-->
      <!--        :is-loading="isFormLoading"-->
      <!--        :old-form-values="formResult"-->
      <!--        :own-submit="true"-->
      <!--        :validation-errors="errors"-->
      <!--        @form-data-submit="submitFormData"-->
      <!--      />-->
      <FormPage1
        :form-data="fields"
        :is-form-data-loading="isLoading"
        :old-form-values="formResult"
        submit-path="/admin/cashboxes-action-log/store"
        :hide-cancel="true"
        @toggle-modal="redirectToCashBoxes"
      />
      <AntdTable
        :columns="columns"
        :rows="results"
        :loading="isLoading"
        :order-params="ordering"
        :table-title="$route.query.type"
        @search-by-order="searchByOrder"
      />
      <EditFormPage
        :visible="isEditFormModalOpen"
        update-dispatch-action="gameModule/updateModuleById"
        unmount-action="gameModule/deleteEditFormJson"
        get-edit-form-dispatch-action="gameModule/editModuleById"
        state-name="gameModule"
        request-path="/admin/cashboxes-action-log"
        module-name="cashboxes-action-log"
        :id-of-edit-modal="isEditFormModalOpen"
        @toggle-modal="toggleEditFormModal"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment-timezone';
import FormPage1 from '../components/partial/FormPage/FormPage1';
import EditFormPage from './EditFormPage';
import AntdTable from '../components/Table/AntdTable';

export default {
  components: {
    FormPage1, EditFormPage, AntdTable,
  },
  data() {
    return {
      errors: {},
      isFormLoading: false,
      loading: false,
      isLoading: false,
      orderParams: {},
      moment,
    };
  },
  computed: {
    ...mapState({
      isEditFormModalOpen(state) {
        return state.modals.isEditFormModalOpen;
      },
      moduleInfo(state) {
        return state.gameModule.betShops[this.$route.params.id]
          && state.gameModule.betShops[this.$route.params.id][this.$route.query.type];
      },
      columns(state) {
        return state.gameModule.betShops[this.$route.params.id]
          && state.gameModule.betShops[this.$route.params.id][this.$route.query.type]
          && state.gameModule.betShops[this.$route.params.id][this.$route.query.type].columns;
      },
      ordering(state) {
        return state.gameModule.betShops[this.$route.params.id]
          && state.gameModule.betShops[this.$route.params.id][this.$route.query.type]
          && state.gameModule.betShops[this.$route.params.id][this.$route.query.type].ordering;
      },
      title(state) {
        return state.gameModule.betShops[this.$route.params.id]
          && state.gameModule.betShops[this.$route.params.id][this.$route.query.type]
          && state.gameModule.betShops[this.$route.params.id][this.$route.query.type].title;
      },
      actions(state) {
        return state.gameModule.betShops[this.$route.params.id]
          && state.gameModule.betShops[this.$route.params.id][this.$route.query.type]
          && state.gameModule.betShops[this.$route.params.id][this.$route.query.type].actions;
      },
      fields(state) {
        return state.gameModule.betShops[this.$route.params.id]
          && state.gameModule.betShops[this.$route.params.id][this.$route.query.type]
          && state.gameModule.betShops[this.$route.params.id][this.$route.query.type].fields;
      },
      formResult(state) {
        return state.gameModule.betShops[this.$route.params.id]
          && state.gameModule.betShops[this.$route.params.id][this.$route.query.type]
          && state.gameModule.betShops[this.$route.params.id][this.$route.query.type].result;
      },
      results(state) {
        return state.gameModule.betShops[this.$route.params.id]
          && state.gameModule.betShops[this.$route.params.id][this.$route.query.type]
          && state.gameModule.betShops[this.$route.params.id][this.$route.query.type].results;
      },
    }),
  },
  async mounted() {
    await this.getBetShopsInfo();
  },
  methods: {
    async redirectToCashBoxes(updated) {
      if (updated === 'updated') {
        await this.$router.push('/admin/cashboxes-action-log');
      }
    },
    async toggleEditFormModal(message) {
      await this.$store.dispatch('modals/toggleEditFormModal');
      if (message === 'updated') {
        await this.getBetShopsInfo();
      }
    },
    async searchByOrder(data) {
      this.orderParams = data;
      await this.getBetShopsInfo();
    },
    async submitFormData(data) {
      this.isFormLoading = true;
      const formData = new FormData();
      _.forIn(data, (value, key) => {
        if (key === 'translation' || (typeof value === 'object'
          && this.fields[key] && this.fields[key].type !== 'file')) {
          const val = JSON.stringify(value);
          formData.append(key, val);
        } else {
          formData.append(key, value);
        }
      });
      try {
        const res = await axios.post('/admin/cashboxes-action-log/store',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        this.isFormLoading = false;
        if (res.data.errors) {
          this.errors = res.data.errors;
        } else {
          this.errors = {};
        }
        return res.data;
      } catch (r) {
        this.isFormLoading = false;
        return false;
      }
    },
    async getBetShopsInfo() {
      this.isLoading = true;
      const params = {
        path: this.$route.path,
        id: this.$route.params.id,
        type: this.$route.query.type,
        orderParams: this.orderParams,
      };
      await this.$store.dispatch('gameModule/getBetShopsInfo', params);
      this.isLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.bet-shops{
  padding: 24px;
  .form-wrapper{
    background: white;
    margin-bottom: 24px;
    border-radius: 8px;
  }
}
</style>
