import Vue from 'vue';
import router from '@/router';

export default {
  namespaced: true,

  state: {
    panes: [],
    activeKey: '1',
    newTabIndex: 0,
  },
  mutations: {
    activeKeyChange(state, key) {
      state.activeKey = key;
    },
    removeAllTabs(state) {
      Vue.set(state, 'panes', []);
    },
    addTab(state, { title, key }) {
      let storageOpenedTabs = [];
      if (router.app.$route.matched[0].path === '/player/:module/:id'
        || router.app.$route.matched[0].path === '/partner/:id/:menuItem'
        || sessionStorage.getItem('sessionTabs')
      ) {
        storageOpenedTabs = sessionStorage.getItem('sessionTabs') ? JSON.parse(sessionStorage.getItem('sessionTabs')) : [];
      } else {
        storageOpenedTabs = JSON.parse(localStorage.getItem('backOfficeOpenedTabs')) || [];
      }
      let panes = state.panes.length ? state.panes : storageOpenedTabs;
      const tabExistence = panes[0] && panes.filter((tab) => tab.key === key)[0];
      // const activeKey = `newTab${state.newTabIndex++}`;
      if (!tabExistence) {
        panes.push({ title, key });
      }
      if (panes.length === 1) {
        panes[0].closable = false;
      }
      if (panes.length === 2) {
        panes = panes.map((t) => {
          const tab = { ...t };
          tab.closable = true;
          return tab;
        });
      }
      Vue.set(state, 'panes', panes);
      Vue.set(state, 'activeKey', key);
      if (router.app.$route.matched[0].path === '/player/:module/:id'
        || router.app.$route.matched[0].path === '/partner/:id/:menuItem'
        || sessionStorage.getItem('sessionTabs')
      ) {
        sessionStorage.setItem('sessionTabs', JSON.stringify(panes));
        return;
      }
      localStorage.setItem('backOfficeOpenedTabs', JSON.stringify(panes));
    },
    removeTab(state, { key }) {
      const targetKey = key;
      let { activeKey } = state;
      let lastIndex;
      state.panes.forEach((pane, i) => {
        if (pane.key === targetKey) {
          lastIndex = i - 1;
        }
      });
      const panes = state.panes.filter((pane) => pane.key !== targetKey);
      if (panes.length && activeKey === targetKey) {
        if (lastIndex >= 0) {
          activeKey = panes[lastIndex].key;
        } else {
          activeKey = panes[0].key;
        }
      }
      if (panes.length === 1) {
        panes[0].closable = false;
      }
      Vue.set(state, 'panes', panes);
      Vue.set(state, 'activeKey', activeKey);
      localStorage.setItem('backOfficeOpenedTabs', JSON.stringify(panes));
    },
  },
  actions: {
    addTab({ commit }, params) {
      commit('addTab', params);
    },
    removeTab({ commit }, params) {
      commit('removeTab', params);
    },
    activeKeyChange({ commit }, key) {
      commit('activeKeyChange', key);
    },
  },
  getters: {
    panes(state) {
      return state.panes;
    },
    activeKey(state) {
      return state.activeKey;
    },
    newTabIndex(state) {
      return state.newTabIndex;
    },
  },
};
