<template>
  <div :ref="`input-checkbox${fieldName}`" class="input-checkbox">
    <a-form-item
      :validate-status="validatingStatus"
      :help="validateHelp"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      class="form-item-holder"
    >
      <template slot="label">
        <span class="form-item-label-text">{{ label }}</span>
      </template>
      <a-checkbox
        :id="`${fieldName}/${label}`"
        v-model="checked"
        :disabled="disabled"
        type="checkbox"
        @change="$emit('input-change', { [fieldName]: Number(checked) })"
      />
    </a-form-item>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/require-prop-types,vue/prop-name-casing
  props: ['label', 'oldValue', 'fieldName', 'validationError', 'isValidationFirstError', 'labelCol', 'wrapperCol', 'disabled'],
  data() {
    return {
      checked: false,
      validatingStatus: 'validating',
      validateHelp: '',
    };
  },
  watch: {
    isValidationFirstError(e) {
      if (e) {
        this.$refs[`input-checkbox${this.fieldName}`].scrollIntoView();
      }
    },
    validationError(e) {
      if (e) {
        this.validatingStatus = 'error';
        // eslint-disable-next-line prefer-destructuring
        this.validateHelp = e[0];
      } else {
        this.deleteValidationErrors();
      }
    },
  },
  mounted() {
    if (this.oldValue || this.oldValue === 0 || this.oldValue === '0') {
      this.checked = Boolean(this.oldValue);
      this.$emit('input-change', { [this.fieldName]: Number(this.oldValue) });
    }
  },
};
</script>

<style scoped lang="scss">
.input-checkbox{
  text-align: left;
}
</style>
