<template>
  <a-spin :spinning="loading">
    <a-table
      :columns="columns"
      :data-source="rows"
      :pagination="false"
    />
  </a-spin>
</template>
<script>
import socketError from '../../utils/socketError';

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['queryData', 'rowData'],
  data() {
    return {
      rows: [],
      columns: [],
      loading: false,
    };
  },
  async mounted() {
    // console.log(5, this.queryData);
    await this.getTableData();
  },
  methods: {
    async getTableData() {
      const queryObj = {};
      let queryParams = '';
      if (this.queryData && this.rowData) {
        this.queryData.forEach((elem) => {
          queryObj[elem] = this.rowData[elem];
        });
        queryParams = new URLSearchParams({
          ...queryObj,
        }).toString();
        if (queryParams) {
          queryParams = '?' + queryParams;
        }
      }
      this.loading = true;
      try {
        const res = await axios.get(`${this.$route.path}/expandedRow${queryParams}`);
        if (res.data) {
          this.columns = res.data.columns;
          this.rows = res.data.results;
        }
        this.loading = false;
        return res.data;
      } catch (e) {
        this.loading = false;
        socketError({
        }, e);
        return false;
      }
    },
  },
};
</script>
