<template>
  <div>
    <!--    <AntdTable-->
    <!--      :columns="columns"-->
    <!--      :rows="data"-->
    <!--      table-size="small"-->
    <!--      :without-table-header="true"-->
    <!--    />-->
    <table>
      <tr>
        <th v-for="(column) in columns" :key="column.field">
          {{ column.label }}
        </th>
      </tr>
      <tr v-for="(row) in data" :key="row.id">
        <th v-for="(column, ind) in columns" :key="ind">
          <span v-if="column.type === 'hourFormat'">
            {{ moment(row[column.field]).format('YYYY-MM-DD HH:mm:ss') }}
          </span>
          <span v-if="!column.type || column.type === 'text'">{{ row[column.field] }}</span>
        </th>
      </tr>
    </table>
  </div>
  <!--  <a-table v-if="asd"-->
  <!--           :columns="columns"-->
  <!--           :data-source="data"-->
  <!--           :pagination="false"-->
  <!--  >-->
  <!--    <a slot="name" slot-scope="text">{{ text }}</a>-->
  <!--    <span slot="customTitle"><a-icon type="smile-o" /> Name</span>-->
  <!--    <span slot="tags" slot-scope="tags">-->
  <!--      <a-tag-->
  <!--        v-for="tag in tags"-->
  <!--        :key="tag"-->
  <!--        :color="tag === 'loser' ? 'volcano' : tag.length > 5 ? 'geekblue' : 'green'"-->
  <!--      >-->
  <!--        {{ tag.toUpperCase() }}-->
  <!--      </a-tag>-->
  <!--    </span>-->
  <!--    <span slot="action" slot-scope="text, record">-->
  <!--      <a>Invite 一 {{ record.name }}</a>-->
  <!--      <a-divider type="vertical" />-->
  <!--      <a>Delete</a>-->
  <!--      <a-divider type="vertical" />-->
  <!--      <a class="ant-dropdown-link"> More actions <a-icon type="down" /> </a>-->
  <!--    </span>-->
<!--  </a-table>-->
</template>
<script>
import { mapState } from 'vuex';
// import AntdTable from '../components/Table/AntdTable';

export default {
  // components: { AntdTable },
  data() {
    return {
      moment,
    };
  },
  computed: {
    ...mapState({
      columns(state) {
        return state.info.printData.columns || [];
      },
      data(state) {
        return state.info.printData.data || [];
      },
    }),
  },
  mounted() {
  },
};
</script>

<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
</style>
