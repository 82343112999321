<template>
  <div>
    <a-modal
      :visible="true"
      style="top: 20px;"
      width="60vw"
      :ok-text="isAnsweringPhase ? 'Send' : 'Answer'"
      cancel-text="Close"
      :ok-button-props="!modalInfo.canAnswer ? {style: {display: 'none'}} : {}"
      @ok="handleSubmit"
      @cancel="closeModal"
    >
      <template slot="title">
        <div :style="{display: 'flex', fontSize: '14px', color: '#595959'}">
          <div v-for="item in modalInfo.headerInfo"
               :key="item.label"
               :style="{display: 'flex', flexDirection: 'column', marginRight: '20px'}"
          >
            <span style="font-weight: bold">
              {{ item.label }} :
            </span>
            <span v-if="item.type === '24hourFormat'">
              {{ item.value && moment(item.value).format('DD/MM/YY, HH:mm:ss') }}
            </span>
            <span v-else>{{ item.value }}</span>
          </div>
        </div>
      </template>
      <a-spin :spinning="isLoading">
        <div v-if="isAnsweringPhase">
          <a-form-item label="Subject">
            <a-input :disabled="true" :value="modalInfo.subjectText" />
          </a-form-item>
          <TinyEditor
            :key="1"
            type="editor"
            label="Description"
            :validation-error="validationError"
            field-name="description"
            @input-change="editorChange"
          />
        </div>
        <div v-else>
          <TinyEditor
            v-if="modalInfo.playerMessageText"
            :key="2"
            :disabled="true"
            type="editor"
            label="Client Message"
            :old-value="modalInfo.playerMessageText"
          />
        </div>
        <!--        <a-form-item v-else label="Client Message">-->
        <!--          <a-textarea-->
        <!--            v-model="textAreaValue"-->
        <!--            @change="handleTextAreaChange"-->
        <!--          />-->
        <!--        </a-form-item>-->
      </a-spin>
    </a-modal>
  </div>
</template>
<script>
import TinyEditor from '@/components/FormElements/TinyEditor';
import socketError from '@/utils/socketError';

export default {
  components: { TinyEditor },
  // eslint-disable-next-line vue/require-prop-types
  props: ['id'],
  data() {
    return {
      isLoading: true,
      isAnsweringPhase: false,
      validationError: null,
      moment,
      modalInfo: {
        canAnswer: false,
        subjectText: '',
        playerMessageText: '',
        headerInfo: [],
      },
      textAreaValue: '',
    };
  },
  mounted() {
    this.getModalInfo();
  },
  methods: {
    async getModalInfo() {
      this.isLoading = true;
      try {
        const res = await axios.get(this.$route.path + '/mess-control', {
          params: {
            id: this.id,
          },
        });
        if (res.data) {
          this.modalInfo = { ...res.data };
        }
        this.isLoading = false;
        return res.data;
      } catch (e) {
        socketError(e);
        this.isLoading = false;
        return false;
      }
    },
    editorChange(e) {
      this.description = e.description;
      if (this.validationError) {
        this.validationError = null;
      }
    },
    handleTextAreaChange() {
      this.textAreaValue = this.modalInfo.playerMessageText;
    },
    async storeData() {
      this.isLoading = true;
      try {
        const res = await axios.post(this.$route.path + '/answer', {
          id: this.id,
          description: this.description,
        });
        this.isLoading = false;
        return res && res.data;
      } catch (e) {
        socketError(e);
        this.isLoading = false;
        return false;
      }
    },
    async closeModal(event) {
      if (this.modalInfo.canAnswer) {
        this.$emit('update-table', event && event.deleteOutBoxFromIntMess);
      }
      await this.$store.dispatch('modals/toggleIntMessControlModal', false);
    },
    async handleSubmit() {
      if (this.isAnsweringPhase) {
        if (!this.description) {
          this.validationError = ['The description is required'];
          return;
        }
        await this.storeData().then((res) => {
          if (res && res.success) {
            this.closeModal({ deleteOutBoxFromIntMess: true });
          }
        });
      } else {
        this.isAnsweringPhase = true;
      }
    },
  },
};
</script>
