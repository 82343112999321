<template>
  <div class="report-container">
    <ReportPage />
  </div>
</template>

<script>
import ReportPage from '../components/ReportPage';

export default {
  components: { ReportPage },
};
</script>

<style scoped lang="scss">
.report-container{
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 24px;
  .active{
    color: blue;
  }
  .tabs {
    > th {
      cursor: pointer;
    }

    > th:hover {
      color: green;
    }
  }
}
</style>
