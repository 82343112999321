// import axios from 'axios';
import Vue from 'vue';
// import socketError from '../utils/socketError';

export default {
  namespaced: true,

  state: {
    isApproveModalOpen: false,
    wheelCreateForm: {},
    wheelPartialForms: {},
    wheelPartialForms1: {
      success: {
        id: 1,
        createdAt: 35456465,
      },
      currencyOptions: {
        values: [
          {
            code: 1,
            label: 'AMD',
          },
          {
            code: 2,
            label: 'USD',
          },
        ],
      },
      wheelLevel: {
        1: {
          levelTags: [
            1, 2, 3,
          ],
          1: {
            result: {
              name: 'asdsad11111111111',
            },
          },
          2: {
            result: {
              name: 'lkfg2222222222222',
            },
          },
          fields: {
            name: {
              type: 'text',
              label: 'Name',
            },
            name1: {
              type: 'text',
              label: 'Name1',
            },
            name2: {
              type: 'text',
              label: 'Name2',
            },
          },
        },
        2: {
          levelTags: [
            1, 2, 3,
          ],
          1: {
            result: {
              name: 'asd',
            },
          },
          2: {
            result: {
              name: 'lkfg',
            },
          },
          fields: {
            name: {
              type: 'text',
              label: 'Name',
            },
            title: {
              type: 'text',
              label: 'Title',
            },
          },
        },
      },
    },
  },
  mutations: {
    setPartialFormChange(state, {
      property, tagLevelCode, currencyCode, wheelId,
    }) {
      const oldResult = state.wheelPartialForms[wheelId].wheelLevel[currencyCode][tagLevelCode]
        && state.wheelPartialForms[wheelId].wheelLevel[currencyCode][tagLevelCode].result;
      if (oldResult) {
        const result = { ...oldResult, ...property };
        Vue.set(state.wheelPartialForms[wheelId].wheelLevel[currencyCode][tagLevelCode], 'result', result);
      } else {
        const result = {
          result: {
            ...property,
          },
        };
        Vue.set(state.wheelPartialForms[wheelId].wheelLevel[currencyCode], tagLevelCode, result);
      }
    },
    setNewTagData(state, { currencyCode, code, wheelId }) {
      const tags = state.wheelPartialForms[wheelId].wheelLevel[currencyCode].levelTags || [];
      Vue.set(state.wheelPartialForms[wheelId].wheelLevel[currencyCode], 'levelTags', [
        ...tags,
        code,
      ]);
      Vue.set(state.wheelPartialForms[wheelId].wheelLevel[currencyCode], code, {
        result: {},
      });
      // console.log(state.wheelPartialForms.wheelLevel[currencyCode]);
    },
    setWheelCreateForm(state, createForm) {
      let forms = { ...state.wheelCreateForm };
      if (createForm && createForm.result && createForm.result.id) {
        forms[createForm.result.id] = createForm;
      } else {
        forms = { ...forms, ...createForm };
      }
      Vue.set(state, 'wheelCreateForm', forms);
    },
    setWheelPartialForms(state, { id, form }) {
      Vue.set(state.wheelPartialForms, id, form);
    },
    removeTag(state, { removedTag, currencyCode, wheelId }) {
      // eslint-disable-next-line max-len
      const tags = state.wheelPartialForms[wheelId].wheelLevel[currencyCode].levelTags.filter((tag) => tag !== removedTag);
      Vue.set(state.wheelPartialForms[wheelId].wheelLevel[currencyCode], 'levelTags', [...tags]);
      Vue.set(state.wheelPartialForms[wheelId].wheelLevel[currencyCode], removedTag, {
        result: {},
      });
    },
  },
  actions: {
    async removeTag({ commit }, tagData) {
      try {
        const { removedTag, dbLevelFormId } = tagData;
        let res = null;
        if (dbLevelFormId) {
          res = await axios.get(`admin/wheel/deleteLevel/${dbLevelFormId}/${removedTag}`);
        }
        commit('removeTag', tagData);
        return res;
      } catch (e) {
        return e;
      }
    },
    addTag({ commit }, tagData) {
      commit('setNewTagData', tagData);
    },
    // setWheelPartialForms({ commit }, params) {
    //   commit('setWheelPartialForms', params && params.id);
    // },
    partialFormChange({ commit }, data) {
      // console.log(data);
      commit('setPartialFormChange', data);
    },
    async getWheelCreateForm({ commit }) {
      try {
        const res = await axios.get('admin/wheel/createWheel');
        const createForm = res.data;
        commit('setWheelCreateForm', createForm);
        return createForm;
      } catch (e) {
        return e;
      }
    },
    async getWheelById({ commit }, { id }) {
      try {
        const res = await axios.get(`admin/wheel/createWheel/${id}`);
        commit('setWheelCreateForm', {
          fields: res.data.fields.wlWheel,
          result: res.data.wheelResult,
        });
        commit('setWheelPartialForms', { form: res.data, id });
        return res.data;
      } catch (e) {
        return e;
      }
    },
    // async editCreateWheelForm({}, )
    async createWheel({ commit }, formData) {
      try {
        const res = await axios.post('admin/wheel/store/wheel', formData);
        const wheelLevelFormData = res.data;
        if (wheelLevelFormData && wheelLevelFormData.success) {
          const { id } = wheelLevelFormData.success;
          wheelLevelFormData.wheelCreateData = formData;
          // Object.keys(wheelLevelFormData.wheelLevel).forEach((key) => {
          // eslint-disable-next-line max-len
          //   wheelLevelFormData.wheelLevel[key].levelTags = wheelLevelFormData.wheelLevel[key].levels.values.map((el) => el.code);
          // });
          commit('setWheelPartialForms', { form: wheelLevelFormData, id });
        }
        // console.log('wheelLevelFormData', wheelLevelFormData);
        return wheelLevelFormData;
      } catch (e) {
        return e;
      }
    },
  },
  getters: {
    wheelPartialForms(state) {
      return state.wheelPartialForms;
    },
    wheelCreateForm(state) {
      return state.wheelCreateForm;
    },
  },
};
