<template>
  <a-form-item :label="label" :style="{ display: 'flex', 'margin-left': '100px' }">
    <a-form-item
      :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }"
    >
      <a-date-picker v-model="fromDate" style="width: 100%" />
    </a-form-item>
    <span :style="{ display: 'inline-block', width: '24px', textAlign: 'center' }">-</span>
    <a-form-item :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }">
      <a-date-picker v-model="to" style="width: 100%" />
    </a-form-item>
  </a-form-item>
</template>
<script>

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['toVal', 'fromVal', 'field', 'label'],
  data() {
    return {
      fromDate: null,
      to: null,
    };
  },
  watch: {
    toVal(e) {
      if (e !== moment(this.to).unix()) {
        if (e) {
          this.to = moment(e * 1000);
        } else {
          this.to = null;
        }
      }
    },
    fromVal(e) {
      if (e !== moment(this.fromDate).unix()) {
        if (e) {
          this.fromDate = moment(e * 1000);
        } else {
          this.fromDate = null;
        }
      }
    },
    to(e) {
      const value = {
        [this.field + '_to']: moment(e).unix(),
      };
      this.$emit('on-change', value);
    },
    fromDate(e) {
      const value = {
        [this.field + '_from']: moment(e).unix(),
      };
      this.$emit('on-change', value);
    },
  },
  mounted() {
    if (this.toVal) {
      this.to = moment(this.toVal);
    }
    if (this.fromVal) {
      this.fromDate = moment(this.fromVal);
    }
  },
};
</script>

<style scoped lang="scss">
</style>
