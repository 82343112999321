<template>
  <a-form-item
    ref="multi-date-and-time-picker"
    :validate-status="validatingStatus"
    :help="validateHelp"
    class="form-item-holder multi-date-and-time-picker"
  >
    <template slot="label">
      <span class="form-item-label-text">{{ label }}</span>
    </template>
    <a-card size="small" style="text-align: left">
      <a-tag color="blue" style="margin-bottom: 8px">
        Date:
      </a-tag>
      <a-date-picker v-model="dateValue"
                     style="width: 100%; margin-bottom: 12px"
                     :disabled-date="disabledDate"
                     mode="date"
                     :default-picker-value="getDefaultPickerValue()"
                     dropdown-class-name="multi-date-picker-dropdown"
                     :show-today="false"
                     @change="handleChangeDatePicker"
      >
        <template slot="dateRender" slot-scope="current, today">
          <div class="ant-calendar-date" :style="getCurrentStyle(current, today)">
            {{ current.date() }}
          </div>
        </template>
      </a-date-picker>
      <div>
        <span v-if="dayList.length" style="font-weight: normal">
          Selected Dates:
        </span>
        <a-tag v-for="day in dayList"
               :key="day"
               color="green"
               style="margin-bottom: 8px"
               closable
               @close="deleteDayFromList(day)"
        >
          {{ day }}
        </a-tag>
        <div v-if="dayList.length" style="margin-bottom: 10px" />
        <a-tag color="blue" style="margin-bottom: 8px">
          Time:
        </a-tag>
        <div style="display: flex;
                    justify-content: space-between; align-items: center; margin-bottom: 12px"
        >
          <a-select
            v-model="selectedStartTime"
            style="width: 100%; margin-right: 10px"
            placeholder="Start Time"
            @change="handleSelectChange"
          >
            <a-select-option
              v-for="(time, ind) in timePresets"
              :key="time"
              :disabled="selectedEndTime && timePresets.indexOf(selectedEndTime) < ind"
            >
              {{ time }}
            </a-select-option>
          </a-select>
          <span> - </span>
          <a-select
            v-model="selectedEndTime"
            style="width: 100%; margin-left: 10px"
            placeholder="End Time"
            @change="handleSelectChange"
          >
            <a-select-option
              v-for="(time, ind) in [...timePresets, '23:59']"
              :key="time"
              :disabled="timePresets.indexOf(selectedStartTime) > ind"
            >
              {{ time }}
            </a-select-option>
          </a-select>
        </div>
        <span v-if="selectedTimePresets.length" style="font-weight: normal">
          Selected Times:
        </span>
        <a-tag v-for="timeRange in selectedTimePresets"
               :key="JSON.stringify(timeRange)"
               color="green"
               style="margin-bottom: 8px"
               closable
               @close="deleteTimeRangeFromList(timeRange)"
        >
          {{ timeRange.startTime }} - {{ timeRange.endTime }}
        </a-tag>
      </div>
    </a-card>
  </a-form-item>
</template>
<script>
const timePresets = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
];
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['oldValue', 'field', 'label', 'disabled', 'validationError'],
  data() {
    return {
      dateValue: null,
      validatingStatus: 'validating',
      validateHelp: '',
      dayList: [],
      selectedTimePresets: [],
      timePresets,
      selectedStartTime: undefined,
      selectedEndTime: undefined,
    };
  },
  watch: {
    oldValue() {
      this.setOdlValue();
    },
    isValidationFirstError(e) {
      if (e) {
        this.$refs['multi-date-and-time-picker'].scrollIntoView();
      }
    },
    validationError(e) {
      if (e) {
        this.validatingStatus = 'error';
        // eslint-disable-next-line prefer-destructuring
        this.validateHelp = e[0];
      } else {
        this.deleteValidationErrors();
      }
    },
    dateValue() {
      this.deleteValidationErrors();
    },
  },
  mounted() {
    this.setOdlValue();
  },
  methods: {
    handleSelectChange() {
      if (this.selectedStartTime && this.selectedEndTime) {
        const value = {
          startTime: this.selectedStartTime,
          endTime: this.selectedEndTime,
        };
        this.selectedTimePresets.push(value);
        this.selectedStartTime = undefined;
        this.selectedEndTime = undefined;
        this.handleFormElementChange();
      }
    },
    disabledDate(current) {
      const currDate = current && current.format('YYYY-MM-DD');
      return currDate && moment(currDate).unix() >= moment('2023-11-01').unix();
    },
    getDefaultPickerValue() {
      return moment('10/02/2023');
    },
    setOdlValue() {
      if (this.oldValue) {
        this.dayList = this.oldValue.dayList;
        this.selectedTimePresets = this.oldValue.selectedTimePresets;
      }
    },
    handleFormElementChange() {
      const value = {
        [this.field]: {
          dayList: this.dayList,
          selectedTimePresets: this.selectedTimePresets,
        },
      };
      this.$emit('input-change', value);
    },
    getCurrentStyle(current) {
      const style = {};
      const currentDate = current.format('DD');
      if (this.dayList.includes(currentDate)) {
        style.border = '1px solid #52c41a';
        style.backgroundColor = '#52c41a80';
        style.borderRadius = '50%';
      }
      return style;
    },
    handleChangeDatePicker(dateObject) {
      const date = dateObject.format('DD');
      if (this.dayList.includes(date)) {
        this.deleteDayFromList(date);
      } else {
        this.dayList.push(date);
      }
      this.dateValue = null;
      this.handleFormElementChange();
    },
    deleteDayFromList(date) {
      const index = this.dayList.indexOf(date);
      if (index > -1) {
        this.dayList.splice(index, 1);
      }
      this.handleFormElementChange();
    },
    deleteTimeRangeFromList(timeRange) {
      const index = this.selectedTimePresets.indexOf(timeRange);
      if (index > -1) {
        this.selectedTimePresets.splice(index, 1);
      }
      this.handleFormElementChange();
    },
    deleteValidationErrors() {
      this.validatingStatus = 'validating';
      this.validateHelp = '';
    },
  },
};
</script>

<style lang="scss">
.multi-date-picker-dropdown{
  .ant-calendar-header {
    display: none;
  }
  .ant-calendar-body{
    .ant-calendar-tbody{
      .ant-calendar-disabled-cell{
        display: none;
      }
      .ant-calendar-selected-day{
        & .ant-calendar-date {
          background: white;
        }
      }
    }
    thead{
      display: none;
    }
  }
  .ant-calendar-footer{
    display: none;
  }
}
</style>
