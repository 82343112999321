<template>
  <div>
    <a-modal
      :visible="visible"
      style="top: 20px;"
      :ok-text="okText"
      @ok="handleOk"
      @cancel="$emit('close-modal')"
    >
      {{ text }}
    </a-modal>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['text', 'okText', 'visible', 'isLoading'],
  methods: {
    handleOk() {
      if (!this.isLoading) {
        this.$emit('handle-save');
      }
    },
  },
};
</script>
