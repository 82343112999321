export default {
  methods: {
    validateByType(validType, targetValue, currentValue) {
      let isValid;
      let errorText = '';
      switch (validType) {
        case 'max':
          isValid = currentValue <= targetValue;
          errorText = isValid ? '' : `Value must be less then ${targetValue}`;
          break;
        case 'min':
          isValid = currentValue >= targetValue;
          errorText = isValid ? '' : `Value must be greater then ${targetValue}`;
          break;
        default:
          errorText = '';
      }
      return errorText;
    },
    // regExpType(validType) {
    //   let expression;
    //   switch (validType) {
    //     case 'max':
    //       expression = /[A-Z][a-z]+/;
    //       break;
    //     case 'min':
    //       expression = /^$/;
    //       break;
    //     default:
    //       expression = '';
    //   }
    //   return expression;
    // },
    // $formCustomValidate1(formValue, rules) {
    //   // Es tavrutyun@ mer tavar custom formeri hamara,
    //   // normal form validation chi kara ogtagorcvi mer custom translationneri patcharov
    //   const errors = {};
    //   _.forIn(rules, (validRule, formField) => {
    //     if (formField === 'translation') {
    //       _.forIn(validRule, (translationRules, lang) => {
    //         _.forIn(translationRules, (fieldValidation, fieldName) => {
    //           _.forIn(fieldValidation, (validOption, validType) => {
    //             const fieldValue = this.getTranslation(formValue.translation, fieldName, lang);
    //             if (validType === 'required') {
    //               if (!fieldValue) {
    // eslint-disable-next-line max-len
    //                 errors[validOption.errorRef || fieldName] = [validOption.message || `Please Fill The Input In '${lang}' Translation.`];
    //               }
    //             }
    //           });
    //         });
    //       });
    //     } else {
    //       const fieldValue = formValue[formField];
    //       _.forIn(validRule, (validOption, validType) => {
    //         if (validType === 'required') {
    //           if (!fieldValue) {
    // eslint-disable-next-line max-len
    //             // errorRef@ nra hamara vor karoxa uzum es urish tex error@ qci,urish formFieldi vra
    //             // orinak fileri traslation@ gnum img_am bayc qez petqa imgi vra error qces
    // eslint-disable-next-line max-len
    //             errors[validOption.errorRef || formField] = [validOption.message || 'Please Fill The Input'];
    //           }
    //         } else if (!this.validateByType(validType, validOption.targetValue, fieldValue)) {
    // eslint-disable-next-line max-len
    //           errors[validOption.errorRef || formField] = [validOption.message || 'Please Fill Correct Value'];
    //         }
    //       });
    //     }
    //   });
    //   return errors;
    // },
    $formCustomValidate(formValue, formFields) {
      // if (fieldName === 'seo_image') {
      //   console.log('fieldValue', fieldValue);
      // }
      const errors = {};
      _.forIn(formFields, (fieldDesc, fieldName) => {
        if (fieldDesc.validation) {
          if (fieldDesc.translatable) {
            // console.log(444444444, fieldDesc.validation.rules);
            _.forIn(fieldDesc.validation.rules, (validRules, lang) => {
              _.forIn(validRules, (validOption, validType) => {
                let fieldValue = this.getTranslation(formValue.translation, fieldName, lang);
                if (fieldDesc.type === 'file') {
                  fieldValue = formValue[fieldName + '_' + lang] || formValue['old_' + fieldName + '_' + lang];
                }
                if (validType === 'required') {
                  if (!fieldValue) {
                    if (typeof validOption === 'object') {
                      errors[fieldName] = [validOption.message || `Please Fill The Input In '${lang}' Translation.`];
                    } else {
                      errors[fieldName] = [`Please Fill The Input In '${lang}' Translation.`];
                    }
                  }
                } else {
                  const targetValue = typeof validOption === 'object' ? validOption.value : validOption;
                  const err = this.validateByType(validType, targetValue, fieldValue);
                  if (err) {
                    const mes = typeof validOption === 'object' ? validOption.message : err;
                    errors[fieldName] = [mes];
                  }
                }
              });
            });
          } else {
            let fieldValue = formValue[fieldName];
            // console.log('fieldDesc.validation', fieldDesc.validation);
            if (fieldDesc.validation) {
              _.forIn(fieldDesc.validation.rules, (validOption, validType) => {
                if (validOption.type === 'file' && !fieldValue) {
                  fieldValue = formValue['old_' + fieldName];
                }
                if (validType === 'required') {
                  if (!fieldValue) {
                    if (typeof validOption === 'object') {
                      errors[fieldName] = validOption.message || 'Please Fill The Input';
                    } else {
                      errors[fieldName] = ['Please Fill The Input'];
                    }
                  }
                } else {
                  const targetValue = typeof validOption === 'object' ? validOption.value : validOption;
                  const err = this.validateByType(validType, targetValue, fieldValue);
                  if (err) {
                    const mes = typeof validOption === 'object' ? validOption.message : err;
                    errors[fieldName] = [mes];
                  }
                }
              });
            }
          }
        }
      });
      return errors;
    },
  },
};
