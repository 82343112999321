<template>
  <div class="wheel-control-wrapper">
    <div class="title">
      Wheel
    </div>
    <div class="wheel-control">
      <div>
        <WheelCreateForm />
      </div>
      <div>
        <WheelLevelFormPage v-if="wheelPartialForms" :key="Math.random()" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import WheelCreateForm from '../components/Wheel/WheelCreateForm';
import WheelLevelFormPage from '../components/Wheel/WheelLevelFormPage';

export default {
  components: { WheelCreateForm, WheelLevelFormPage },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      wheelPartialForms(state) {
        return state.wheel.wheelPartialForms[this.$route.query.wheelId];
      },
    }),
  },
  async mounted() {
    // if (this.$route.query.wheelId) {
    //   await this.$store.dispatch('wheel/getWheelById', {
    //     id: this.$route.query.wheelId,
    //   });
    // } else {
    //   await this.getWheelCreateForm();
    // }
  },
  methods: {
    async getWheelCreateForm() {
      await this.$store.dispatch('wheel/getWheelCreateForm');
    },
  },
};
</script>

<style lang="scss" scoped>
.wheel-control-wrapper{
  .title{
    background: white;
    padding: 10px 0 10px 25px;
    font-size: 14px;
    text-align: left;
  }
  .wheel-control{
    padding: 24px;
  }
}
</style>
