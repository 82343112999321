<template>
  <div ref="input-paginated-select" class="input-select">
    <a-form-item
      :validate-status="validatingStatus"
      :help="validateHelp"
      class="form-item-holder"
    >
      <template slot="label">
        <span class="form-item-label-text">{{ label }}</span>
      </template>
      <a-select
        v-model="selectedValue"
        :loading="loading"
        :show-search="true"
        placeholder="Please select"
        style="min-width: 200px"
        :disabled="disabled === 'disabled'"
        :validate-status="validatingStatus"
        :help="validateHelp"
        :allow-clear="true"
        :auto-clear-search-value="false"
        :filter-option="false"
        @popupScroll="onScrollValueGetter"
        @search="onInputSearch"
        @dropdownVisibleChange="dropdownVisibleChange"
      >
        <a-select-option v-for="(option, index) in options"
                         :key="index"
                         :value="option.code"
                         :disabled="disabled && disabled.includes(option.code)"
        >
          {{ option.label }}
        </a-select-option>
      </a-select>
    </a-form-item>
  </div>
</template>
<script>
import path from 'path';
import socketError from '../../utils/socketError';

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['type', 'label', 'fieldName', 'relation', 'values', 'oldValue', 'validationError', 'isValidationFirstError', 'serverSideDependence', 'disabled', 'requestMethod'],
  data() {
    return {
      options: [],
      selectedValue: '',
      relationValues: [],
      validatingStatus: 'validating',
      searchInputValue: '',
      validateHelp: '',
      page: 1,
      loading: false,
      path,
    };
  },
  watch: {
    async serverSideDependence(e, prevValue) {
      if (e && Object.keys(e)[0] && (Object.values(prevValue)[0] !== Object.values(e)[0])) {
        await this.setOptions();
      }
    },
    isValidationFirstError(e) {
      if (e) {
        this.$refs['input-paginated-select'].scrollIntoView();
      }
    },
    validationError(e) {
      if (e) {
        this.validatingStatus = 'error';
        // eslint-disable-next-line prefer-destructuring
        this.validateHelp = e[0];
      } else {
        this.deleteValidationErrors();
      }
    },
    selectedValue(selectedValue) {
      this.$emit('change-form', {
        [this.fieldName]: selectedValue,
      });
      this.$emit('input-change', { [this.fieldName]: selectedValue });
    },
  },
  async mounted() {
    await this.setOptions();
    this.setOldValue();
  },
  methods: {
    async sendSelectedValues(selected) {
      try {
        const params = { ...this.serverSideDependence };
        const relationPath = path.join(this.$route.path, 'relation', this.relation);
        if (selected) {
          params.selected = JSON.stringify(selected);
        }
        if (this.requestMethod === 'post') {
          const res = await axios.post(relationPath, {
            ...params,
          });
          return res.data;
        }
        const res = await axios.get(relationPath, {
          params,
        });
        return res.data;
      } catch (e) {
        socketError({}, e);
        return false;
      }
    },
    async getValues(params) {
      try {
        const id = this.$route.params.moduleName === 'menu-items' ? `/${this.$route.query.modalId}` : '';
        const relationPath = path.join(this.$route.path, 'relation', `${this.relation}${id}`);
        if (this.requestMethod === 'post') {
          const res = await axios.post(relationPath, {
            ...params,
          });
          return res.data;
        }
        const res = await axios.get(relationPath, {
          params,
        });
        return res.data;
      } catch (e) {
        socketError({}, e);
        return false;
      }
    },
    async dropdownVisibleChange(e) {
      if (!e && this.relation) {
        this.searchInputValue = '';
        this.page = 1;
        await this.setOptions();
      }
    },
    async onInputSearch(val) {
      this.loading = true;
      this.searchInputValue = val;
      const params = {
        page: 1,
        key: this.fieldName,
        s: this.searchInputValue,
        selected: JSON.stringify(this.selectedValue),
        exclude_selected: 1,
        type: this.type,
        ...this.serverSideDependence,
      };
      await this.getValues(params).then((result) => {
        this.page = 1;
        this.relationValues = result.data;
        this.options = [...this.relationValues];
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    async onScrollValueGetter(event) {
      if (event.target.scrollHeight === event.target.offsetHeight + event.target.scrollTop
        && !this.loading) {
        this.loading = true;
        const params = {
          page: (this.page + 1) || 1,
          key: this.fieldName,
          s: this.searchInputValue,
          exclude_selected: 1,
          selected: JSON.stringify(this.oldValue),
          type: this.type,
          ...this.serverSideDependence,
        };
        await this.getValues(params).then((result) => {
          this.page = result.current_page;
          this.relationValues = result.data;
          this.options = [...this.options, ...this.relationValues];
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        });
      }
    },
    setOldValue() {
      if (this.oldValue) {
        this.$emit('change-form', { [this.fieldName]: this.oldValue });
        this.$emit('input-change', { [this.fieldName]: this.oldValue });
        this.selectedValue = this.oldValue;
      }
    },
    async setOptions() {
      if (this.relation) {
        this.loading = true;
        await this.sendSelectedValues(this.oldValue)
          .then((result) => {
            this.relationValues = result.data;
            this.options = [...this.relationValues];
            this.page = result.current_page;
            this.loading = false;
          });
        if (this.oldValue) {
          this.relationValues = (await this.getValues({
            selected: JSON.stringify(this.oldValue),
            exclude_selected: 1,
            type: this.type,
            ...this.serverSideDependence,
          })).data;
          this.options = [...this.options, ...this.relationValues];
        }
      } else {
        this.options = [...this.values];
      }
    },
  },
};
</script>

<style scoped lang="scss">
label {
  display: block;
  font-size: 14px!important;
  font-weight: 400!important;
  color: rgba(0, 0, 0, 0.85);
}
</style>
