<template>
  <div class="widget-chart-card">
    <a-card style="width: 303px;min-height: 235px">
      <template slot="title">
        <div class="title-wrapper">
          <span>
            {{ title }}
          </span>
          <span>
          <!--            <a-icon-->
          <!--              type="audit"-->
          <!--              :class="{active: activeTab === 'audit'}"-->
          <!--              @click="() => activeTab = 'audit'"-->
          <!--            />-->
          <!--            <a-icon-->
          <!--              type="pie-chart"-->
          <!--              :class="{active: activeTab === 'chart'}"-->
          <!--              @click="() => activeTab = 'chart'"-->
          <!--            />-->
          </span>
        </div>
      </template>
      <div v-if="activeTab === 'audit'" class="audit-body">
        <span v-for="(row, index) in data" :key="index" class="row">
          <span>{{ row.label }}</span>
          <span>{{ row.value }}</span>
        </span>
      </div>
      <div v-else-if="activeTab === 'chart'" class="chart-body">
        <BarChart :data="data" />
      </div>
    </a-card>
  </div>
</template>

<script>
import BarChart from '../../../Charts/BarChart';

export default {
  components: { BarChart },
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      // headStyle: {
      //   display: 'flex',
      //   'justify-content': 'space-between',
      // },
      activeTab: 'audit',
      data1: [
        {
          label: 'Total Amount',
          value: 565,
        },
        {
          label: 'Total Count',
          value: 15421,
        },
        {
          label: 'Total Online Count',
          value: 5561,
        },
        {
          label: 'Total Online Amount',
          value: 1561,
        },
      ],
    };
  },
  methods: {
    onTabChange(key, type) {
      // console.log(key, type);
      this[type] = key;
    },
  },
};
</script>

<style lang="scss">
.widget-chart-card{
  margin: 0 10px 20px 10px;
  .title-wrapper{
    display: flex;
    justify-content: space-between;
    span:last-child{
      i{
        cursor: pointer;
        &.active{
          color: #1890FF;
        }
      }
      i:hover{
        color: #1890FF;
      }
      i:first-child{
        margin-right: 8px;
      }
    }
  }
  .audit-body{
    .row{
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      line-height: 28px;
      span:last-child{
        color: black
      }
    }
  }
  /*.ant-tabs-nav-wrap{*/
  /*  .ant-tabs-nav-scroll{*/
  /*    .ant-tabs-ink-bar{*/
  /*      display: none!important;*/
  /*    }*/
  /*  }*/
  /*}*/
  /*.ant-tabs-tab{*/
  /*  margin: 0!important;*/
  /*  width: 10px;*/
  /*  align-items: center;*/
  /*}*/
}
</style>
