<template>
  <div class="ordering-btn">
    <i v-if="orderBy === 'DESC'"
       class="fa fa-caret-up"
       aria-hidden="true"
       :class="{active: orderBy === 'DESC'}"
    />
    <i v-if="orderBy === 'ASC'"
       class="fa fa-caret-down"
       aria-hidden="true"
       :class="{active: orderBy === 'ASC'}"
    />
  </div>
</template>

<script>

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['orderFieldName', 'orderBy'],
};
</script>

<style scoped lang="scss">
.ordering-btn{
  position: absolute;
  top: 34%;
  right: 2%;
  .active{
    display: block;
    color: green;
  }
}
</style>
