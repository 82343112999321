import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import auth from './auth';
import info from './info';
import dashboard from './dashboard';
import sidebar from './sidebar';
import reports from './reports';
import player from './player';
import gameModule from './gameModule';
import modals from './modals';
import tabsVuex from './tabsVuex';
import wheel from './wheel';
import partner from './partner';
import socketError from '../utils/socketError';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    error: null,
    isMobile: false,
    localeLang: 'en',
    languages: ['en', 'ru', 'am'],
  },
  mutations: {
    setError(state, error) {
      state.error = error;
    },
    clearError(state) {
      state.error = null;
    },
    mobileDetector(state, isMobile) {
      state.isMobile = isMobile;
    },
    setLanguage(state, lang) {
      state.localeLang = lang;
      if (localStorage.lang !== lang) {
        localStorage.setItem('lang', lang);
      }
    },
    setLanguages(state, langs) {
      Vue.set(state, 'languages', langs);
    },
  },
  actions: {
    async getLanguages({ commit, dispatch }) {
      try {
        const params = {};
        const selectedSiteId = localStorage.getItem('selectedPartner');
        if (selectedSiteId) {
          params.selectedSiteId = selectedSiteId;
        }
        const res = await axios.get('/site/languages', { params });
        const resData = res.data && res.data.languages;
        const localLang = localStorage.getItem('lang');
        commit('setLanguages', resData);
        if (localLang && resData.includes(localLang)) {
          commit('setLanguage', localLang);
        } else {
          commit('setLanguage', resData[0]);
        }
        return resData;
      } catch (e) {
        socketError({ dispatch, commit }, e);
        return false;
      }
    },
    setError({ commit }, error) {
      commit('setError', error);
    },
    mobileDetector({ commit }, { isMobile }) {
      commit('mobileDetector', isMobile);
    },
    setLanguage({ commit }, { lang }) {
      commit('setLanguage', lang);
    },
  },
  getters: {
    error: (s) => s.error,
    isMobile: (s) => s.isMobile,
    localeLang: (s) => s.localeLang,
    langs: (s) => s.languages,
  },
  modules: {
    tabsVuex,
    auth,
    info,
    dashboard,
    sidebar,
    reports,
    player,
    gameModule,
    modals,
    wheel,
    partner,
  },
});
