var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.$route.path,staticClass:"report"},[(Boolean(_vm.antFilter))?_c('div',[_c('FilterForm',{attrs:{"filter-list":_vm.antFilter,"own-submit":true,"is-loading":_vm.isLoading,"filter-ordering":['date-range-picker-with-presets',
                         'ant-date-range', 'ant-date-range-with-timezone',
                         'utc-date-range-picker'],"old-form-values":_vm.oldFilterFormData || {}},on:{"filter-form-search":_vm.onFilterFormSearch}})],1):_vm._e(),_c('AntdTable',{attrs:{"loading":_vm.isLoading,"columns":_vm.columns,"order-params":_vm.orderParams,"rows":_vm.rows,"total":_vm.reportTableInfo && _vm.reportTableInfo.total,"page-count":Math.ceil(_vm.reportTableInfo && _vm.reportTableInfo.total / _vm.reportTableInfo.per_page),"table-size":"small","table-footer":_vm.tableFooterArray,"page-value":_vm.reportTableInfo && _vm.reportTableInfo.current_page,"per-page":_vm.reportTableInfo && _vm.reportTableInfo.per_page,"pagination":_vm.reportTableInfo
      && _vm.reportTableInfo.per_page && _vm.rows.length >= _vm.reportTableInfo.per_page
      || (_vm.reportTableInfo && _vm.reportTableInfo.last_page_url)},on:{"search-by-order":_vm.searchByOrder,"per-page-select-change":_vm.getReportInfo,"on-page-change":_vm.pageChange,"update-table":_vm.getReportInfo}},[(_vm.currencyOptions)?_c('div',[_c('a-select',{staticStyle:{"width":"120px","margin-right":"14px"},attrs:{"placeholder":"Currency"},on:{"change":_vm.currencyOptionChange},model:{value:(_vm.selectedCurrency),callback:function ($$v) {_vm.selectedCurrency=$$v},expression:"selectedCurrency"}},_vm._l((_vm.currencyOptions),function(option){return _c('a-select-option',{key:option.code,attrs:{"disabled":option.disabled}},[_vm._v(" "+_vm._s(option.label)+" ")])}),1)],1):_vm._e(),(_vm.reportInfo && _vm.reportInfo.download_token)?_c('DownLoadFile',{attrs:{"total-count":_vm.reportTableInfo && _vm.reportTableInfo.total,"search-params":Object.assign({}, {orderBy: _vm.orderParams.orderBy || 'ASC',
                    column: _vm.orderParams.column || '',
                    page: 1,
                    perPage: 10000000,
                    reportName: _vm.$route.path,
                    token: _vm.reportInfo && _vm.reportInfo.download_token},
                    _vm.filterFormDataForDownLoad,
                    {currency: _vm.selectedCurrency || ''})}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }