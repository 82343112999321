<template>
  <div class="collapse-table-section">
    <a-collapse v-model="collapseActive" @change="handleCollapseChange">
      <a-collapse-panel key="1">
        <template slot="header">
          <span>{{ collapseHeaderName }}</span>
        </template>
        <a-spin :spinning="isLoading">
          <div>
            <div v-if="filter" class="filter-wrapper">
              <FilterForm
                :filter-list="filter"
                :own-submit="true"
                :is-loading="isLoading"
                :filter-ordering="['date-range-picker-with-presets','ant-date-range']"
                :old-form-values="oldFilterFormData"
                @filter-form-search="onFilterFormSearch"
              />
            </div>
            <AntdTable
              :table-title="collapseHeaderName"
              :columns="columns"
              :rows="rows"
              :loading="isLoading"
              @update-table="getData"
            >
              <DownLoadFile v-if="downLoadToken"
                            :down-load-url="downLoadUrl"
                            :search-params="{
                              perPage: 5000,
                              ...filterFormData,
                              token: downLoadToken,
                            }"
              />
            </AntdTable>
          </div>
        </a-spin>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import FilterForm from '@/components/partial/CustomFilterList/FilterForm';
import DownLoadFile from '@/components/partial/DownLoadFile';
import AntdTable from '../../Table/AntdTable';

export default {
  components: { DownLoadFile, FilterForm, AntdTable },
  // eslint-disable-next-line vue/require-prop-types
  props: ['collapseHeaderName', 'moduleName'],
  data() {
    return {
      collapseActive: [],
      isLoading: false,
      filterFormData: {},
    };
  },
  computed: {
    ...mapState({
      // pageData(state) {
      //   const { id, menuItem } = this.$route.params;
      //   const data =
      //   state.player.exactClientInfo[id] && state.player.exactClientInfo[id][menuItem];
      //   const pageInfo = (data && data[this.moduleName]) || {};
      //   return {
      //     filter: pageInfo.filter,
      //     columns: pageInfo.columns,
      //     rows: pageInfo.results && pageInfo.results.data,
      //     downLoadToken: pageInfo.download_token,
      //     oldFilterFormData: pageInfo.filterFormData,
      //   };
      // },
      pageData(state) {
        return state.player.exactClientInfo[this.$route.params.id]
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem]
          // eslint-disable-next-line max-len
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem][this.moduleName];
      },
      filter(state) {
        return state.player.exactClientInfo[this.$route.params.id]
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem]
          // eslint-disable-next-line max-len
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem][this.moduleName]
          // eslint-disable-next-line max-len
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem][this.moduleName].filter;
      },
      columns(state) {
        return state.player.exactClientInfo[this.$route.params.id]
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem]
          // eslint-disable-next-line max-len
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem][this.moduleName]
          // eslint-disable-next-line max-len
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem][this.moduleName].columns;
      },
      rows(state) {
        return state.player.exactClientInfo[this.$route.params.id]
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem]
          // eslint-disable-next-line max-len
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem][this.moduleName]
          // eslint-disable-next-line max-len
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem][this.moduleName].results.data;
      },
      downLoadToken(state) {
        return state.player.exactClientInfo[this.$route.params.id]
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem]
          // eslint-disable-next-line max-len
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem][this.moduleName]
          // eslint-disable-next-line max-len
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem][this.moduleName].download_token;
      },
      oldFilterFormData(state) {
        return state.player.exactClientInfo[this.$route.params.id]
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem]
          // eslint-disable-next-line max-len
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem][this.moduleName]
          // eslint-disable-next-line max-len
          && state.player.exactClientInfo[this.$route.params.id][this.$route.params.menuItem][this.moduleName].filterFormData;
      },
    }),
    // filter() {
    //   return this.pageData && this.pageData.filter;
    // },
    // columns() {
    //   return this.pageData && this.pageData.columns;
    // },
    // rows() {
    //   return this.pageData && this.pageData.rows;
    // },
    // downLoadToken() {
    //   return this.pageData && this.pageData.downLoadToken;
    // },
    // oldFilterFormData() {
    // eslint-disable-next-line max-len
    //   console.log('this.pageData && this.pageData.filterFormData', this.pageData && this.pageData.filterFormData);
    //   return this.pageData && this.pageData.filterFormData;
    // },
    downLoadUrl() {
      return `${process.env.VUE_APP_API_URL}${this.$route.path}-${this.moduleName}-download-link`;
    },
  },
  mounted() {
    this.setDefaultDateRange();
  },
  methods: {
    setDefaultDateRange() {
      if (this.$route.params.menuItem === 'transaction-game') {
        if (!this.oldFilterFormData || !this.oldFilterFormData.date_range) {
          this.filterFormData.date_range = {
            startDate: moment()
              .startOf('day')
              .unix(),
            endDate: moment()
              .endOf('day')
              .unix(),
          };
        }
      }
      if (this.$route.params.menuItem === 'transaction-payment') {
        if (!this.oldFilterFormData || !this.oldFilterFormData.settled_at_date_range) {
          this.filterFormData.settled_at_date_range = {
            startDate: moment().subtract(6, 'month').startOf('month').unix(),
            endDate: moment().endOf('day').unix(),
          };
        }
      }
    },
    async onFilterFormSearch(filterFormData) {
      this.filterFormData = { ...filterFormData };
      await this.getData();
    },
    handleCollapseChange(collapseArray) {
      const isOpen = Boolean(collapseArray.length);
      if (isOpen && !this.columns) {
        this.getData();
      }
    },
    async getData() {
      this.isLoading = true;
      await this.$store.dispatch('player/getCollapseTableSectionInfo',
        {
          path: this.$route.path,
          id: this.$route.params.id,
          menuItem: this.$route.params.menuItem,
          moduleName: this.moduleName,
          filterFormData: this.filterFormData,
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.collapse-table-section{
  margin: 20px 0;
  padding: 0 24px;
  text-align: left;
  .ant-collapse-header{
    font-weight: 500;
  }
  .ant-table-body{
    max-height: 500px!important;
  }
  .filter-form{
    margin-bottom: 0;
  }
  .table-wrapper{
    padding-top: 0;
  }
  .ant-collapse-content-box{
    padding: 0!important;
  }
}
</style>
