<template>
  <div>
    <a-icon v-if="isLoading" type="loading" />
    <a-icon
      v-else
      type="printer"
      style="font-size: 18px; cursor: pointer"
      @click="getPrintData"
    />
    <!--    <div-->
    <!--      v-show="show"-->
    <!--      id="printable-data"-->
    <!--      ref="asd"-->
    <!--    >-->
    <!--      <a-table :columns="columns" :data-source="data" />-->
    <!--    </div>-->
    <iframe
      v-show="false"
      ref="iframeRef"
      :srcdoc="printableContent"
    />
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['searchParams'],
  data() {
    return {
      printableData: [],
      isLoading: false,
      printableContent: '',
    };
  },
  mounted() {
  },
  methods: {
    async getPrintDataPrevVersion() {
      this.isLoading = true;
      try {
        await axios.get(this.$route.path, {
          params: {
            ...this.searchParams,
          },
        }).then(async (response) => {
          if (response && response.data) {
            await this.$store.dispatch('info/setPrintData', { data: response.data.results.data, columns: response.data.columns })
              .then(async () => { await this.openPrintView(); });
          }
        });
      } finally {
        this.isLoading = false;
      }
    },
    async openPrintView() {
      await this.$router.push('/print-view').then(async () => {
        window.print();
        await this.$router.go(-1);
        await this.$store.dispatch('info/setPrintData', { data: [], columns: [] });
      });
    },
    async getPrintData() {
      this.isLoading = true;
      try {
        await axios.get(this.$route.path, {
          params: {
            ...this.searchParams,
          },
        }).then(async (response) => {
          if (response && response.data) {
            this.columns = response.data.columns;
            this.rows = response.data.results.data;
            this.tableBuilder(response.data.columns, response.data.results.data);
            setTimeout(() => {
              this.isLoading = false;
              this.$refs.iframeRef.contentWindow.print();
            }, 2000);
          }
        });
      } catch {
        this.isLoading = false;
      }
    },
    tableBuilder(columns, rows) {
      const rowStyle = 'border: 1px solid #dddddd;padding: 2px;font-weight: normal;';
      const tableColumns = columns.reduce((acc, col) => acc + `<th style="${rowStyle}">` + col.label + '</th>', '');
      // eslint-disable-next-line no-unused-vars
      const tableRows = rows.reduce((rowAcc, row) => rowAcc + '<tr>' + columns.reduce((acc, col) => {
        let text = row[col.field];
        if (col.type === 'hourFormat') {
          text = moment(text).format('YYYY-MM-DD HH:mm:ss');
        }
        return acc + `<th style="${rowStyle}">` + text + '</th>';
      }, '') + '</tr>', '');
      this.printableContent = `<table style="width: 100%;border-collapse: collapse;"><tr>${tableColumns}</tr>${tableRows}</table>`;
    },
  },
};
</script>

<style scoped>
#printable-data{
  position: fixed;
  background: white;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  padding: 30px;
}
</style>
