<!-- eslint-disable -->
<template>
  <div class="search-bar">
    <a-input
      v-model="inputValue"
      style="width: 320px"
      placeholder="Search"
      @keyup.enter="search"
    >
      <a-select
        slot="addonBefore"
        v-model="selectValue"
        style="min-width: 130px"
      >
        <a-select-option v-for="(opt, index) in columns"
                         v-if="opt.label !== 'Actions'"
                         :key="index"
                         :value="opt.field"
        >
          {{ opt.label }}
        </a-select-option>
      </a-select>
      <div slot="addonAfter">
        <a-icon type="search" @click="search" />
        <a-icon v-if="!isLoading && inputValue" type="close-circle" @click="reset" style="margin-left: 4px" />
        <a-icon v-if="isLoading" type="sync" spin style="margin-left: 4px"/>
      </div>
    </a-input>
  </div>
</template>

<script>

export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
    searchBar: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      options: [],
      selectValue: '',
      inputValue: '',
      selectError: 'success',
      selectValidationHelp: '',
    };
  },
  watch: {
    selectValue() {
      this.selectError = 'success';
      this.selectValidationHelp = '';
    },
    columns() {
      this.setSettings();
    },
  },
  mounted() {
    if (this.columns[0]) {
      this.setSettings();
    }
  },
  methods: {
    setSettings() {
      this.selectValue = this.searchBar.key || this.columns[0].field;
      this.inputValue = this.searchBar.searchValue || '';
    },
    reset() {
      this.selectValue = '';
      this.inputValue = '';
      const params = {
        key: this.selectValue,
        s: this.inputValue,
      };
      this.$emit('search-by-filter-value', params);
    },
    search() {
      if (this.inputValue) {
        const params = {
          key: this.selectValue,
          s: this.inputValue,
        };
        this.$emit('search-by-filter-value', params);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.search-bar{
  margin-left: 15px;
  .input-wrapper{
    margin: 0;
  }
}
</style>
