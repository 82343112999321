<template>
  <div class="wheel-form-wrapper">
    <a-form
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      layout="vertical"
      class="wheel-create-form"
    >
      <div class="wheel-form-row-wrapper">
        <a-row v-for="(row) in orderedFormList"
               :key="row.type"
               :class="row.type"
        >
          <a-col v-for="(val, key) in row.formFields"
                 :key="String(key)"
                 :xl="columnCountInRow(val.type) || 8"
                 :xxl="columnCountInRow(val.type) || 8"
                 :lg="columnCountInRow(val.type) || 12"
                 :md="columnCountInRow(val.type) || 12"
                 :sm="columnCountInRow(val.type) || 24"
                 :xs="columnCountInRow(val.type) || 24"
                 class="component-wrapper"
          >
            <div
              v-if="val.type === 'checkbox' && (!val.depends_on || dependOnChecker(val.depends_on))"
            >
              <Checkbox
                :label="val.label || key"
                :field-name="key"
                :disabled="val.disabled"
                :old-value="oldFormValues[key]"
                :validation-error="validationErrors[key]"
                :is-validation-first-error="isValidationFirstError(validationErrors, key)"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'text'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
            >
              <Input
                :label="val.label || key"
                :field-name="key"
                :disabled="val.disabled"
                :old-value="oldFormValues[key]"
                :translatable="val.translatable"
                :translation="oldFormValues && oldFormValues.translation"
                :validation-error="validationErrors[key]"
                :is-validation-first-error="isValidationFirstError(validationErrors, key)"
                @translation-input-change="translationInputChange"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'ant-date-range'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
            >
              <AntDateRangePicker
                :label="val.label || key"
                :field="key"
                :disabled="val.disabled"
                :old-value="oldFormValues[key]"
                :validation-error="validationErrors[key]"
                :is-validation-first-error="isValidationFirstError(validationErrors, key)"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'ant-date'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
            >
              <AntDateInput
                :label="val.label || key"
                :field="key"
                :disabled="val.disabled"
                :hide-time="val.hideTime"
                :old-value="oldFormValues[key]"
                :validation-error="validationErrors[key]"
                :is-validation-first-error="isValidationFirstError(validationErrors, key)"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'date'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
            >
              <Input
                type="date"
                :label="val.label || key"
                :field-name="key"
                :disabled="val.disabled"
                :old-value="oldFormValues[key]"
                :validation-error="validationErrors[key]"
                :is-validation-first-error="isValidationFirstError(validationErrors, key)"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'number'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
            >
              <Input
                type="number"
                :label="val.label || key"
                :min="$route.params.moduleName === 'cms-pages' && 0"
                :field-name="key"
                :disabled="val.disabled"
                :old-value="oldFormValues[key]"
                :validation-error="validationErrors[key]"
                :is-validation-first-error="isValidationFirstError(validationErrors, key)"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'color_picker'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
            >
              <Input
                type="color"
                :label="val.label || key"
                :field-name="key"
                :disabled="val.disabled"
                :old-value="oldFormValues[key]"
                :validation-error="validationErrors[key]"
                :is-validation-first-error="isValidationFirstError(validationErrors, key)"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'unsigned'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
            >
              <Input
                type="number"
                :min="0"
                :label="val.label || key"
                :field-name="key"
                :disabled="val.disabled"
                :old-value="oldFormValues[key]"
                :validation-error="validationErrors[key]"
                :is-validation-first-error="isValidationFirstError(validationErrors, key)"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'decimal'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
            >
              <Input
                type="number"
                :step="0.01"
                :label="val.label || key"
                :field-name="key"
                :disabled="val.disabled"
                :old-value="oldFormValues[key]"
                :validation-error="validationErrors[key]"
                :is-validation-first-error="isValidationFirstError(validationErrors, key)"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'textarea'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
            >
              <Input
                type="textarea"
                :label="val.label || key"
                :field-name="key"
                :disabled="val.disabled"
                :old-value="oldFormValues[key]"
                :translatable="val.translatable"
                :translation="oldFormValues && oldFormValues.translation"
                :validation-error="validationErrors[key]"
                :is-validation-first-error="isValidationFirstError(validationErrors, key)"
                @translation-input-change="translationInputChange"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'file'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
            >
              <FileInput
                type="file"
                :label="val.label || key"
                :disabled="val.disabled"
                :translatable="val.translatable"
                :field-name="key"
                :old-value="oldFormValues[key]"
                :translation="oldFormValues && oldFormValues.translation"
                :validation-error="validationErrors[key] || validationErrors[`old_${key}_en`]
                  || validationErrors[`old_${key}_am`] || validationErrors[`old_${key}_ru`]"
                :is-validation-first-error="isValidationFirstError(validationErrors, key)"
                @translation-input-change="translationInputChange"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="(val.type === 'decimal_unsigned' || val.type === 'unsigned_decimal')
                && (!val.depends_on || dependOnChecker(val.depends_on))"
            >
              <Input
                type="number"
                :min="0"
                step="0.01"
                :label="val.label || key"
                :disabled="val.disabled"
                :field-name="key"
                :old-value="oldFormValues[key]"
                :validation-error="validationErrors[key]"
                :is-validation-first-error="isValidationFirstError(validationErrors, key)"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'radio'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
            >
              <Radio
                :label="val.label || key"
                :field-name="key"
                :disabled="val.disabled"
                :values="val.values"
                :old-value="oldFormValues[key]"
                :validation-error="validationErrors[key]"
                :is-validation-first-error="isValidationFirstError(validationErrors, key)"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'select'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
            >
              <Select
                :type="val.type"
                :field-name="key"
                :label="val.label || key"
                :relation-id="val.relationId"
                :disabled="val.disabled"
                :values="val.values"
                :relation="val.relation"
                :server-side-dependence="(val.serverSideDependence
                  && {[val.serverSideDependence]: (formValue[val.serverSideDependence]
                    || parentFormValue[val.serverSideDependence])}) || {}"
                :old-value="oldFormValues[key]"
                :validation-error="validationErrors[key]"
                :is-validation-first-error="isValidationFirstError(validationErrors, key)"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'select_multiple'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
            >
              <MultipleSelect
                :type="val.type"
                :field-name="key"
                :label="val.label || key"
                :relation-id="val.relationId"
                :disabled="val.disabled"
                :values="val.values"
                :relation="val.relation"
                :server-side-dependence="(val.serverSideDependence
                  && {[val.serverSideDependence]: (formValue[val.serverSideDependence]
                    || parentFormValue[val.serverSideDependence])}) || {}"
                :old-value="typeof oldFormValues[key] === 'string'
                  ? JSON.parse(oldFormValues[key]) : oldFormValues[key]"
                :validation-error="validationErrors[key]"
                :is-validation-first-error="isValidationFirstError(validationErrors, key)"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'select_multiple_paginated'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
            >
              <PaginatedMultipleSelect
                :type="val.type"
                :field-name="key"
                :label="val.label || key"
                :disabled="val.disabled"
                :relation="val.relation"
                :server-side-dependence="(val.serverSideDependence
                  && {[val.serverSideDependence]: (formValue[val.serverSideDependence]
                    || parentFormValue[val.serverSideDependence])}) || {}"
                :values="val.values"
                :old-value="typeof oldFormValues[key] === 'string'
                  ? JSON.parse(oldFormValues[key]) : oldFormValues[key]"
                :validation-error="validationErrors[key]"
                :is-validation-first-error="isValidationFirstError(validationErrors, key)"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'select_paginated'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
            >
              <PaginatedSelect
                :type="val.type"
                :field-name="key"
                :label="val.label || key"
                :disabled="val.disabled"
                :relation="val.relation"
                :server-side-dependence="(val.serverSideDependence
                  && {[val.serverSideDependence]: (formValue[val.serverSideDependence]
                    || parentFormValue[val.serverSideDependence])}) || {}"
                :values="val.values"
                :old-value="typeof oldFormValues[key] === 'string'
                  ? JSON.parse(oldFormValues[key]) : oldFormValues[key]"
                :validation-error="validationErrors[key]"
                :is-validation-first-error="isValidationFirstError(validationErrors, key)"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'selectProbability'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
            >
              <SelectWithPercent
                :field-name="key"
                :label="val.label || key"
                :max="val.max"
                :disabled="val.disabled"
                :with-image="val.withImage"
                :values="val.values"
                :old-value="oldFormValues[key] || {}"
                :validation-error="validationErrors[key]"
                :is-validation-first-error="isValidationFirstError(validationErrors, key)"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'datetime'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
            >
              <DatePickerInput
                :field-name="key"
                :label="val.label || key"
                :old-value="oldFormValues[key]"
                :disabled="val.disabled"
                :validation-error="validationErrors[key]"
                :is-validation-first-error="isValidationFirstError(validationErrors, key)"
                @input-change="inputChange"
              />
            </div>
            <div v-else-if="val.type === 'array'
              && (!val.depends_on || dependOnChecker(val.depends_on))"
            >
              <Array
                :form-data="val"
                :parent-form-value="formValue"
                :disabled="val.disabled"
                :field-name="key"
                :label="val.label || key"
                :validation-error="validationErrors[key]"
                :old-form-values="oldFormValues[key]"
                @input-change="inputChange"
              />
            </div>
            <div v-else-if="val.type === 'editor'">
              <TinyEditor
                type="editor"
                :label="val.label || key"
                :field-name="key"
                :disabled="val.disabled"
                :old-value="oldFormValues[key]"
                :translatable="val.translatable"
                :translation="oldFormValues.translation"
                :validation-error="validationErrors[key]"
                :is-validation-first-error="isValidationFirstError(validationErrors, key)"
                @translation-input-change="translationInputChange"
                @input-change="inputChange"
              />
            </div>
            <div v-else-if="val.type === 'tag'">
              <Tag
                type="tag"
                :label="val.label || key"
                :field-name="key"
                :disabled="val.disabled"
                :old-value="oldFormValues[key]"
                :translatable="val.translatable"
                :translation="oldFormValues && oldFormValues.translation"
                :validation-error="validationErrors[key]"
                :is-validation-first-error="isValidationFirstError(validationErrors, key)"
                @translation-input-change="translationInputChange"
                @input-change="inputChange"
              />
            </div>
            <div v-else-if="val.type === 'cron_expression'">
              <CronsTrue
                :label="val.label || key"
                :field-name="key"
                :disabled="val.disabled"
                :old-value="oldFormValues[key]"
                :validation-error="validationErrors[key]"
                :is-validation-first-error="isValidationFirstError(validationErrors, key)"
                @translation-input-change="translationInputChange"
                @input-change="inputChange"
              />
            </div>
          </a-col>
        </a-row>
      </div>
      <div class="btn-wrapper">
        <a-button
          v-if="Boolean($route.query.wheelId)"
          style="margin-right: 20px"
          type="primary"
          @click="formEdit"
        >
          Save Changes
        </a-button>
        <a-button :disabled="Boolean($route.query.wheelId)" type="primary" @click="formSubmit">
          Create Wheel
        </a-button>
      </div>
    </a-form>
  </div>
</template>

<script>
import Tag from '../../FormElements/Tag';
import Checkbox from '../../FormElements/Checkbox';
import Input from '../../FormElements/Input';
import Radio from '../../FormElements/Radio';
import Select from '../../FormElements/Select';
// import Array from '../../FormElements/Array';
import DatePickerInput from '../../FormElements/DatePickerInput';
import MultipleSelect from '../../FormElements/MultipleSelect';
import TinyEditor from '../../FormElements/TinyEditor';
import PaginatedMultipleSelect from '../../FormElements/PaginatedMultipleSelect';
import PaginatedSelect from '../../FormElements/PaginatedSelect';
import FileInput from '../../FormElements/FileInput';
import CronsTrue from '../../FormElements/CronsTrue';
import AntDateInput from '../../FormElements/AntDateInput';
import AntDateRangePicker from '../../FormElements/AntDateRangePicker';
import SelectWithPercent from '../../FormElements/SelectWithPercent';
// import socketError from '../../../utils/socketError';

export default {
  name: 'FormPage',
  components: {
    Tag,
    Checkbox,
    Input,
    Radio,
    Select,
    // Array,
    DatePickerInput,
    MultipleSelect,
    TinyEditor,
    PaginatedMultipleSelect,
    PaginatedSelect,
    FileInput,
    CronsTrue,
    AntDateInput,
    AntDateRangePicker,
    SelectWithPercent,
  },
  props: {
    formOrdering: {
      type: Array,
      default() {
        return ['text'];
      },
    },
    oldFormValues: {
      type: Object,
      default() {
        return {};
      },
    },
    submitPath: {
      type: String,
      default: '',
    },
    parentFormValue: {
      type: Object,
      default() {
        return {};
      },
    },
    isFormDataLoading: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default() {
        return {};
      },
    },
    otherFormItems: {
      type: Object,
      default() {
        return {};
      },
    },
    fieldName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      labelCol: {
        // xs: { span: 24 },
        // sm: { span: 5 },
      },
      wrapperCol: {
        // xs: { span: 24 },
        // sm: { span: 16 },
        span: 20,
      },
      selectedDateRange: {
        startDate: null,
        endDate: null,
      },
      formValue: {},
      validationErrors: {},
      isLoading: false,
    };
  },
  computed: {
    orderedFormList() {
      const filterList = [];
      _.forIn(this.formData, (value, key) => {
        const { type } = value;
        const group = filterList.filter((item) => item.type === type)[0];
        if (group) {
          group.formFields = { ...group.formFields, [key]: value };
        } else {
          filterList.push(
            {
              type,
              formFields: { [key]: value },
            },
          );
        }
        // filterList[type] = { ...filterList[type], [key]: value };
      });
      return filterList.sort((elem) => {
        const { type } = elem;
        if (this.formOrdering.includes(type)) {
          return -1;
        }
        return 0;
      });
    },
  },
  watch: {},
  methods: {
    columnCountInRow(type) {
      if (type === 'selectProbability') {
        return 24;
      }
      return null;
    },
    async formEdit() {
      await this.$store.dispatch('wheel/createWheel', { ...this.formValue, wheelId: this.$route.query.wheelId });
    },
    async formSubmit() {
      await this.$store.dispatch('wheel/createWheel', this.formValue).then((res) => {
        const { success } = res;
        const { errors } = res;
        if (errors) {
          this.validationErrors = errors;
        } else {
          this.validationErrors = {};
        }
        if (success) {
          this.$router.push({
            name: 'Wheel',
            query: { wheelId: success.id },
          });
        }
      });
    },
    translationInputChange() {},
    isValidationFirstError(errors, key) {
      return Object.keys(errors)[0] === key;
    },
    dependOnChecker(data) {
      let presence = false;
      Object.keys(data).forEach((key) => {
        const value = data[key];
        if (typeof value === 'object') {
          value.forEach((val) => {
            if (this.formValue[key] === val || this.parentFormValue[key] === val) {
              presence = true;
            }
          });
        } else if (this.formValue[key] === value || this.parentFormValue[key] === value) {
          presence = true;
        }
      });
      return presence;
    },
    inputChange(e) {
      this.formValue = {
        ...this.formValue,
        ...e,
      };
      this.$emit('input-change', { [this.fieldName]: this.formValue });
    },
  },
};
</script>

<style scoped lang="scss">
.wheel-form-wrapper{
  /*.ant-form-vertical{*/
  /*  .form-item-holder {*/
  /*    display: flex;*/
  /*  }*/
  /*}*/
  .wheel-form-row-wrapper{
    display: flex;
    flex-direction: column;
    .ant-col{
      display: block;
    }
    .text{
      order: 1
    }
    .date{
      order: 2
    }
    .checkbox{
      order: 4
    }
    .radio{
      order: 3
    }
  }
  .ant-form{
    /*display: flex;*/
    /*flex-wrap: wrap;*/
    .component-wrapper{
      /*margin-right: 20px*/
    }
    .btn-wrapper{
      text-align: right;
    }
  }
}
</style>
