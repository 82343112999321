import socketError from '../utils/socketError';

export default {
  namespaced: true,

  actions: {
    async login({ dispatch, commit }, { email, password, googleAuthCode }) {
      try {
        const res = await axios.post('/login', {
          email,
          password,
          googleAuthCode,
        });
        if (res.headers) {
          localStorage.setItem('token', res.headers.authorization);
          axios.defaults.headers = {
            Authorization: `Bearer ${res.headers.authorization}`,
          };
        }
        return res;
      } catch (e) {
        socketError({ dispatch, commit }, e);
        return false;
      }
    },
    async refreshToken() {
      // try {
      //   if (window.refreshingToken || !localStorage.getItem('token')) return;
      //   window.refreshingToken = true;
      //   const now = moment();
      //   const lastTokenRefresh = moment.unix(Number(localStorage.getItem('lastTokenRefresh')));
      //   if (!lastTokenRefresh.isValid() || now.diff(lastTokenRefresh, 'hours') >= 12) {
      //     const res = await axios.get('/refresh');
      //     localStorage.setItem('token', res.headers.authorization);
      //     axios.defaults.headers = {
      //       Authorization: `Bearer ${res.headers.authorization}`,
      //     };
      //     localStorage.setItem('lastTokenRefresh', String(now.unix()));
      //     window.refreshingToken = false;
      //   }
      // } catch (e) {
      //   socketError({ dispatch, commit }, e);
      // }
      window.refreshingToken = false;
    },
    async logout({ dispatch, commit }, params) {
      try {
        const data = {};
        if (params && params.logOutAllDevices) {
          data.logOutAllDevices = params.logOutAllDevices;
        }
        const res = await axios.post('/logout', data);
        commit('clearError', null, { root: true });
        localStorage.removeItem('token');
        localStorage.removeItem('backOfficeOpenedTabs');
        localStorage.removeItem('lang');
        localStorage.removeItem('selectedPartner');
        commit('tabsVuex/removeAllTabs', null, { root: true });
        return res.data && res.data.success;
      } catch (e) {
        socketError({ dispatch, commit }, e);
        return false;
      }
    },
  },
};
