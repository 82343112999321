<template>
  <div class="partner-page-wrapper">
    <div class="title">
      Partner Control
    </div>
    <div class="partner-page">
      <ClientPageMenu
        :exact-user-menu-section="tabMenuItems"
      >
        <a-spin :spinning="isLoading">
          <div v-if="pageType === 'form'">
            <FormPage
              :form-data="formData && formData.fields"
              :old-form-values="formData && formData.result"
              :own-submit="true"
              :is-loading="isLoading"
              :validation-errors="errors"
              @form-data-submit="formDataSubmit"
            />
          </div>
          <div v-else>
            <AntdTable
              :columns="columns"
              :rows="results.data"
              :total="results.total"
              :page-value="results.current_page"
              :page-count="results.total
                && Math.ceil(results.total / results.per_page)"
              :pagination="results.total && Math.ceil(results.total / results.per_page) > 1"
              :per-page="results.per_page"
              @update-table="getPartnerInfo"
              @on-page-change="getPartnerInfo"
              @per-page-select-change="getPartnerInfo"
            >
              <div v-if="actions && actions[0]" class="button-wrapper">
                <span v-for="(action, index) in actions" :key="index">
                  <a-button v-if="action.action === 'create'"
                            type="primary"
                            @click="toggleCreateFormModal('create')"
                  >
                    + {{ action.label }}
                  </a-button>
                  <a-button v-else
                            @click="tableActionHandler(action.action)"
                  >
                    {{ action.label }}
                  </a-button>
                </span>
              </div>
            </AntdTable>
          </div>
        </a-spin>
      </ClientPageMenu>
    </div>
    <EditFormPage
      v-if="isEditFormModalOpen"
      :visible="isEditFormModalOpen"
      type="edit"
      update-dispatch-action="partner/updatePlayerModuleById"
      get-edit-form-dispatch-action="partner/editPlayerModuleById"
      state-name="partner"
      unmount-action="partner/deleteEditFormJson"
      :request-path="`/partner/${$route.params.id}/${$route.params.menuItem}`"
      :id-of-edit-modal="isEditFormModalOpen"
      @toggle-modal="toggleEditFormModal"
    />
    <DeleteModal
      v-if="isDeleteModalOpen"
      :info="isDeleteModalOpen"
      delete-vuex-action="partner/deleteById"
      @update-table-info="getPartnerInfo"
    />
    <CreateFormPage
      v-if="isCreateFormModalOpen"
      :visible="isCreateFormModalOpen"
      :id-of-create-modal="isCreateFormModalOpen"
      @toggle-modal="toggleCreateFormModal('after-create')"
    />
    <DynamicFormModal
      v-if="dynamicFormModalInfo"
      :modal-form-info="dynamicFormModalInfo"
      @update-table-info="getPartnerInfo"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AntdTable from '../components/Table/AntdTable';
import ClientPageMenu from '../components/partial/ClientPage/ClientPageMenu/ClientPageMenu';
// import FormPage from '../components/partial/FormPage/FormPage';
import EditFormPage from './EditFormPage';
import DeleteModal from '../components/partial/Modals/DeleteModal';
import CreateFormPage from './CreateFormPage';
import FormPage from '../components/partial/FormPage/FormPage';
import DynamicFormModal from '../components/partial/Modals/DynamicFormModal';
import socketError from '../utils/socketError';

export default {
  components: {
    ClientPageMenu,
    AntdTable,
    EditFormPage,
    DeleteModal,
    CreateFormPage,
    FormPage,
    DynamicFormModal,
  },
  data() {
    return {
      isLoading: false,
      errors: {},
      asd: {
        columns: [],
        results: { data: [] },
      },
    };
  },
  computed: {
    midSize() {
      return window.innerWidth < 1450;
    },
    isMenuToggleable() {
      return window.innerWidth < 800;
    },
    ...mapState({
      isEditFormModalOpen(state) {
        return state.modals.isEditFormModalOpen;
      },
      dynamicFormModalInfo(state) {
        return state.modals.dynamicFormModalInfo;
      },
      isCreateFormModalOpen(state) {
        return state.modals.isCreateFormModalOpen;
      },
      isDeleteModalOpen(state) {
        return state.modals.isDeleteModalOpen[this.$route.params.module];
      },
      partnerInfoByMenuSection(state) {
        return (state.partner.exactPartnerInfo[this.$route.params.id]
          && state.partner.exactPartnerInfo[this.$route.params.id][this.$route.params.menuItem])
          || {};
      },
      actions(state) {
        return (state.partner.exactPartnerInfo[this.$route.params.id]
          && state.partner.exactPartnerInfo[this.$route.params.id][this.$route.params.menuItem]
          // eslint-disable-next-line max-len
          && state.partner.exactPartnerInfo[this.$route.params.id][this.$route.params.menuItem].actions)
          || {};
      },
      formData(state) {
        return (state.partner.exactPartnerInfo[this.$route.params.id]
          && state.partner.exactPartnerInfo[this.$route.params.id][this.$route.params.menuItem]
          // eslint-disable-next-line max-len
          && state.partner.exactPartnerInfo[this.$route.params.id][this.$route.params.menuItem].formData)
          || {};
      },
      pageType(state) {
        return (state.partner.exactPartnerInfo[this.$route.params.id]
          && state.partner.exactPartnerInfo[this.$route.params.id][this.$route.params.menuItem]
          // eslint-disable-next-line max-len
          && state.partner.exactPartnerInfo[this.$route.params.id][this.$route.params.menuItem].pageType)
          || {};
      },
      results(state) {
        return (state.partner.exactPartnerInfo[this.$route.params.id]
          && state.partner.exactPartnerInfo[this.$route.params.id][this.$route.params.menuItem]
          // eslint-disable-next-line max-len
          && state.partner.exactPartnerInfo[this.$route.params.id][this.$route.params.menuItem].results)
          || {};
      },
      columns(state) {
        return (state.partner.exactPartnerInfo[this.$route.params.id]
          && state.partner.exactPartnerInfo[this.$route.params.id][this.$route.params.menuItem]
          // eslint-disable-next-line max-len
          && state.partner.exactPartnerInfo[this.$route.params.id][this.$route.params.menuItem].columns)
          || [];
      },
      tabMenuItems(state) {
        return state.partner.exactPartnerInfo[this.$route.params.id]
          && state.partner.exactPartnerInfo[this.$route.params.id].menu;
      },
    }),
  },
  watch: {
    async $route() {
      if (_.isEmpty(this.partnerInfoByMenuSection) && this.$route.params.menuItem) {
        await this.getPartnerInfo();
      }
    },
  },
  async mounted() {
    await this.getPartnerInfo();
  },
  methods: {
    async tableActionHandler(action) {
      this.isLoading = true;
      try {
        const res = await axios.get(`${this.$route.path}/${action}`);
        await this.getPartnerInfo();
        this.isLoading = false;
        return res.data;
      } catch (e) {
        this.isLoading = false;
        socketError({
        }, e);
        return false;
      }
    },
    async formDataSubmit(data) {
      this.isLoading = true;
      try {
        const res = await axios.post(`${this.$route.path}/store`, { data });
        if (res && res.data && res.data.errors) {
          this.errors = res.data.errors;
        } else {
          this.errors = {};
          await this.$store.commit('partner/setUpdatedFormValue', {
            formValue: data,
            key: this.$route.params.id,
            menuItem: this.$route.params.menuItem,
          });
        }
        this.isLoading = false;
        return res.data;
      } catch (e) {
        this.isLoading = false;
        socketError({
        }, e);
        return false;
      }
    },
    async getPartnerInfo() {
      this.isLoading = true;
      const params = {
        id: this.$route.params.id,
        path: this.$route.path,
        page: this.$route.query.page,
        perPage: localStorage.getItem('tablePerPage') || this.$route.query.perPage || 10,
        filterFormData: this.filterFormData,
        menuItem: this.$route.params.menuItem,
      };
      await this.$store.dispatch('partner/getExactPartnerInfo', params);
      this.isLoading = false;
    },
    async toggleEditFormModal() {
      await this.$store.dispatch('modals/toggleEditFormModal');
      const query = _.clone(this.$route.query);
      delete query.modalId;
      await this.$router.replace({ query });
      await this.getPartnerInfo();
    },
    async toggleCreateFormModal(message) {
      await this.$store.dispatch('modals/toggleCreateFormModal');
      if (message === 'after-create') {
        await this.getPartnerInfo();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.partner-page-wrapper{
  .title{
    text-align: left;
    padding: 10px 24px;
    background: white;
    font-size: 16px;
    font-weight: 500;
  }
  .partner-page {
    margin: 0 24px;
    .client-page-menu-wrapper{
      border-radius: 8px;
    }
  }
}
</style>
