<template>
  <div class="delete-modal">
    <a-modal v-model="visible">
      <template slot="title">
        <span style="color: #ff7875; font-weight: bold"> {{
          info.action && (info.action.charAt(0).toUpperCase() + info.action.slice(1))
        }} Modal</span>
      </template>
      <template slot="footer">
        <a-button key="back" @click="cancelOrCloseModal">
          Cancel
        </a-button>
        <a-button
          key="submit"
          type="danger"
          :loading="loading"
          @click="deleteSelectedUser"
        >
          {{ info.action && (info.action.charAt(0).toUpperCase() + info.action.slice(1)) }}
        </a-button>
      </template>
      <FormPage
        :form-data="formFields"
        :label-col="{span: 10}"
        :wrapper-col="{span: 14}"
        :validation-errors="errors"
        @input-change="formChange"
      />
      <p>
        Please Confirm the
        <span style="color: #ff7875; font-weight: bold">Soft Deletion</span> of
        ID <span>{{ info.id }}
        </span>
      </p>
    </a-modal>
  </div>
</template>

<script>
import lodash from 'lodash';
import FormPage from '../FormPage/FormPage';

export default {
  components: { FormPage },
  props: {
    deleteVuexAction: {
      type: String,
      default: '',
    },
    info: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      visible: true,
      lodash,
      formFields: {},
      formValue: {},
      errors: {},
    };
  },
  watch: {
    visible(e) {
      if (!e) {
        this.cancelOrCloseModal();
      }
    },
  },
  async mounted() {
    await this.getForm();
  },
  methods: {
    formChange(e) {
      this.formValue = {
        ...this.formValue,
        ...Object.values(e)[0],
      };
    },
    async getForm() {
      try {
        const path = this.$route.path + `/${this.info.id}/soft-delete-form`;
        const res = await axios.get(path);
        this.formFields = res.data && res.data.fields;
        return res.data;
      } catch (e) {
        return e;
      }
    },
    async deleteSelectedUser() {
      const params = {
        path: this.$route.path,
        id: this.info.id,
        form: this.formValue,
      };
      await this.$store.dispatch(`${this.deleteVuexAction}`, params).then((res) => {
        if (res.errors) {
          this.errors = res.errors;
        } else {
          this.$emit('update-table-info');
          this.cancelOrCloseModal();
        }
      });
    },
    async cancelOrCloseModal() {
      await this.$store.dispatch('modals/toggleDeleteFormModal');
    },
  },
};
</script>

<style scoped lang="scss">
</style>
