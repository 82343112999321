<template>
  <div class="client-comment-view-page">
    <h1>Client Comment View Page</h1>
    <div class="game-item-wrapper">
      <div v-for="( col, index ) in columns"
           :key="index"
      >
        <GameViewItem
          v-if="col.type !=='link'"
          :label="col.label"
          :type="col.type"
          :content-value="rows[col.field]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import GameViewItem from '../components/partial/GameViewItem';

export default {
  components: { GameViewItem },
  filters: {
    dateFilter(date) {
      return moment(date * 1000).format('LLLL');
    },
  },
  computed: {
    ...mapState({
      moduleViewById(state) {
        return state.player.clientCommentView[this.$route.params.id];
      },
      columns(state) {
        // eslint-disable-next-line max-len
        return state.player.clientCommentView[this.$route.params.id] && state.player.clientCommentView[this.$route.params.id].columns;
      },
      rows(state) {
        // eslint-disable-next-line max-len
        return state.player.clientCommentView[this.$route.params.id] && state.player.clientCommentView[this.$route.params.id].result;
      },
    }),
  },
  // eslint-disable-next-line vue/order-in-components
  data() {
    return {
      isLoading: false,
    };
  },
  async mounted() {
    await this.getClientCommentsById();
  },
  methods: {
    async getClientCommentsById() {
      this.isLoading = true;
      const params = {
        path: this.$route.path,
        moduleName: this.$route.params.moduleName,
        id: this.$route.params.id,
      };
      await this.$store.dispatch('player/getClientCommentsById', params);
      this.isLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.client-comment-view-page{
  .game-item-wrapper{
    display: flex;
    width: 50%;
    flex-wrap: wrap;
    margin: auto;
  }
}
</style>
