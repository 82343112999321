<template>
  <li class="nav-item has-treeview"
      :class="{'menu-open': isMenuOpened}"
  >
    <div class="fieldName" @click="toggleMenu">
      <router-link
        class="nav-link"
        :class="{'nav-sub-group-active': isActive}"
        :to="url?`/${url}`:''"
      >
        <!--        <i class="far fa-circle nav-icon" />-->
        <p class="title">
          {{ title }}
          <i v-if="subLinks[0]"
             :class="{'active': isMenuOpened}"
             class="fas fa-angle-left icon-arrow"
          />
        </p>
      </router-link>
    </div>
    <ul v-if="subLinks[0]" class="nav nav-treeview" :class="{'menu-open': isMenuOpened}">
      <li class="nav-item">
        <router-link v-for="( link, index) in subLinks"
                     :key="index"
                     :class="{activeSubItem: $route.path === `/${link.url}`}"
                     class="nav-link sub-item"
                     :to="`/${link.url}`"
        >
          <!--          <i class="far fa-dot-circle nav-icon" />-->
          <span class="title">{{ link.title }}</span>
        </router-link>
      </li>
    </ul>
  </li>
</template>

<script>
export default {
  props: {
    subLinks: {
      type: Array,
      default: () => ([]),
    },
    title: {
      type: String,
      default: '',
    },
    groupName: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isMenuOpened: false,
    };
  },
  computed: {
    isActive() {
      return this.$route.path === `/${this.url}`;
    },
  },
  methods: {
    toggleMenu() {
      this.isMenuOpened = !this.isMenuOpened;
    },
  },
};
</script>
<style scoped lang="scss">

.nav-sidebar.nav-child-indent .nav-treeview {
  transition: padding 0.3s ease-in-out;
  padding: 0;
  padding-left: 1rem;
  list-style: none;
  display: none;

  &.menu-open {
    display: block;
  }
}

.nav-pills .nav-link {
  color: #6c757d;
  display: block;
  padding: 0.5rem 1rem;

  .title {
    margin-bottom: 0;
  }
}

.nav-link {
  text-align: left;
}
.nav-sub-group-active, .activeSubItem {
  background: #002140;

  .title {
    color: #1890FF;
  }
}
.fieldName {
  position: relative;
  text-align: left;

  .icon-arrow {
    position: absolute;
    top: 30%;
    right: 15%;

    &.active {
      margin-left: 30px;
      transform: rotate(-90deg);
    }
  }
}
</style>
