<script>
import { Bar } from 'vue-chartjs';

export default {
  extends: Bar,
  // eslint-disable-next-line vue/require-prop-types
  data: () => ({
    chartData: {
      datasets: [
        {
          data: [
            { x: '221-01-09', y: 10 },
            { x: '221-01-10', y: 20 },
          ],
        },
      ],
    },
    options: {
      scales: {
        x: {},
        y: {
          beginAtZero: true,
        },
      },
    },
  }),

  mounted() {
    this.$nextTick(() => {
      // this.renderChart(this.chartData, this.options);
      this.renderChart(this.chartData, this.options);
    });
  },
  methods: {
  },
};
</script>
