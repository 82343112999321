<template>
  <div class="form-module-wrapper">
    <FormPage1
      :form-data="moduleData && moduleData.fields"
      :old-form-values="moduleData && moduleData.result"
      :is-form-data-loading="isLoading"
      :submit-path="`${$route.path}/update`"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import FormPage1 from '../components/partial/FormPage/FormPage1';

export default {
  components: {
    FormPage1,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      moduleData(state) {
        return state.gameModule.formModules[this.$route.meta.moduleName];
      },
    }),
  },
  watch: {
    async $route() {
      if (!this.moduleData) {
        await this.getModuleData();
      }
    },
  },
  async mounted() {
    if (!this.moduleData) {
      await this.getModuleData();
    }
  },
  methods: {
    async getModuleData() {
      if (!this.isLoading) {
        this.isLoading = true;
        const params = {
          path: this.$route.path,
          moduleName: this.$route.meta.moduleName,
        };
        await this.$store.dispatch('gameModule/getFormModuleData', params);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.form-module-wrapper{
  background: #fff;
  margin: 24px 24px 0 24px;
  border-radius: 2px;
}
</style>
