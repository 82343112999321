var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-wrapper card-body"},[_c('a-form',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[(_vm.isFormTranslatable)?_c('a-form-item',{attrs:{"label":"Language"}},[_c('FormLanguageSwitcher',{on:{"language-change":function (lang) { return _vm.formSelectedLanguage = lang; }}})],1):_vm._e(),_vm._l((_vm.formData),function(val,key){
var _obj, _obj$1, _obj$2, _obj$3;
return _c('div',{key:String(key)},[(val.type === 'checkbox'
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',[_c('Checkbox',{attrs:{"label":val.label || key,"field-name":key,"disabled":val.disabled,"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"input-change":_vm.inputChange}})],1):(val.type === 'text'
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',[_c('Input',{attrs:{"label":val.label || key,"field-name":key,"disabled":val.disabled,"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key),"translatable":val.translatable,"translation":_vm.oldFormValues && _vm.oldFormValues.translation,"form-locale-lang":_vm.formSelectedLanguage || _vm.localeLang},on:{"translation-input-change":_vm.translationInputChange,"input-change":_vm.inputChange}})],1):(val.type === 'ant-date'
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',[_c('AntDateInput',{attrs:{"label":val.label || key,"field":key,"disabled":val.disabled,"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"input-change":_vm.inputChange}})],1):(val.type === 'date'
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',[_c('Input',{attrs:{"type":"date","label":val.label || key,"field-name":key,"disabled":val.disabled,"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key),"form-locale-lang":_vm.formSelectedLanguage || _vm.localeLang,"translatable":val.translatable,"translation":_vm.oldFormValues && _vm.oldFormValues.translation},on:{"translation-input-change":_vm.translationInputChange,"input-change":_vm.inputChange}})],1):(val.type === 'number'
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',[_c('Input',{attrs:{"type":"number","label":val.label || key,"min":_vm.$route.params.moduleName === 'cms-pages' && 0,"field-name":key,"disabled":val.disabled,"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key),"form-locale-lang":_vm.formSelectedLanguage || _vm.localeLang,"translatable":val.translatable,"translation":_vm.oldFormValues && _vm.oldFormValues.translation},on:{"translation-input-change":_vm.translationInputChange,"input-change":_vm.inputChange}})],1):(val.type === 'color_picker'
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',[_c('Input',{attrs:{"type":"color","label":val.label || key,"field-name":key,"disabled":val.disabled,"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key),"form-locale-lang":_vm.formSelectedLanguage || _vm.localeLang,"translatable":val.translatable,"translation":_vm.oldFormValues && _vm.oldFormValues.translation},on:{"translation-input-change":_vm.translationInputChange,"input-change":_vm.inputChange}})],1):(val.type === 'unsigned'
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',[_c('Input',{attrs:{"type":"number","min":0,"label":val.label || key,"field-name":key,"disabled":val.disabled,"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key),"form-locale-lang":_vm.formSelectedLanguage || _vm.localeLang,"translatable":val.translatable,"translation":_vm.oldFormValues && _vm.oldFormValues.translation},on:{"translation-input-change":_vm.translationInputChange,"input-change":_vm.inputChange}})],1):(val.type === 'decimal'
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',[_c('Input',{attrs:{"type":"number","step":0.01,"label":val.label || key,"field-name":key,"disabled":val.disabled,"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key),"form-locale-lang":_vm.formSelectedLanguage || _vm.localeLang,"translatable":val.translatable,"translation":_vm.oldFormValues && _vm.oldFormValues.translation},on:{"translation-input-change":_vm.translationInputChange,"input-change":_vm.inputChange}})],1):(val.type === 'textarea'
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',[_c('Input',{attrs:{"type":"textarea","label":val.label || key,"field-name":key,"disabled":val.disabled,"old-value":_vm.oldFormValues[key],"translatable":val.translatable,"translation":_vm.oldFormValues && _vm.oldFormValues.translation,"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key),"form-locale-lang":_vm.formSelectedLanguage || _vm.localeLang},on:{"translation-input-change":_vm.translationInputChange,"input-change":_vm.inputChange}})],1):(val.type === 'file'
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',[_c('FileInput',{attrs:{"type":"file","label":val.label || key,"disabled":val.disabled,"translatable":val.translatable,"field-name":key,"old-value":_vm.oldFormValues[key],"translation":_vm.oldFormValues && _vm.oldFormValues.translation,"validation-error":_vm.validationErrors[key] || _vm.validationErrors[("old_" + key + "_en")]
            || _vm.validationErrors[("old_" + key + "_am")] || _vm.validationErrors[("old_" + key + "_ru")],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key),"form-locale-lang":_vm.formSelectedLanguage || _vm.localeLang},on:{"translation-input-change":_vm.translationInputChange,"input-change":_vm.inputChange}})],1):((val.type === 'decimal_unsigned' || val.type === 'unsigned_decimal')
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',[_c('Input',{attrs:{"type":"number","min":0,"step":"0.01","label":val.label || key,"disabled":val.disabled,"field-name":key,"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key),"form-locale-lang":_vm.formSelectedLanguage || _vm.localeLang,"translatable":val.translatable,"translation":_vm.oldFormValues && _vm.oldFormValues.translation},on:{"translation-input-change":_vm.translationInputChange,"input-change":_vm.inputChange}})],1):(val.type === 'radio'
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',[_c('Radio',{attrs:{"label":val.label || key,"field-name":key,"disabled":val.disabled,"values":val.values,"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"input-change":_vm.inputChange}})],1):(val.type === 'select'
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',[_c('Select',{attrs:{"type":val.type,"field-name":key,"label":val.label || key,"relation-id":val.relationId,"disabled":val.disabled || _vm.disabledChecker(val.disabledDependsOn),"values":val.values,"relation":val.relation,"server-side-dependence":(val.serverSideDependence
            && ( _obj = {}, _obj[val.serverSideDependence] = (_vm.formValue[val.serverSideDependence]
              || _vm.parentFormValue[val.serverSideDependence]), _obj )) || {},"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"input-change":_vm.inputChange}})],1):(val.type === 'select_multiple'
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',[_c('MultipleSelect',{attrs:{"type":val.type,"field-name":key,"label":val.label || key,"relation-id":val.relationId,"disabled":val.disabled || _vm.disabledChecker(val.disabledDependsOn),"values":val.values,"relation":val.relation,"server-side-dependence":(val.serverSideDependence
            && ( _obj$1 = {}, _obj$1[val.serverSideDependence] = (_vm.formValue[val.serverSideDependence]
              || _vm.parentFormValue[val.serverSideDependence]), _obj$1 )) || {},"old-value":typeof _vm.oldFormValues[key] === 'string'
            ? JSON.parse(_vm.oldFormValues[key]) : _vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"input-change":_vm.inputChange}})],1):(val.type === 'select_multiple_paginated'
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',[_c('PaginatedMultipleSelect',{attrs:{"type":val.type,"field-name":key,"label":val.label || key,"disabled":val.disabled || _vm.disabledChecker(val.disabledDependsOn),"relation":val.relation,"server-side-dependence":(val.serverSideDependence
            && ( _obj$2 = {}, _obj$2[val.serverSideDependence] = (_vm.formValue[val.serverSideDependence]
              || _vm.parentFormValue[val.serverSideDependence]), _obj$2 )) || {},"values":val.values,"old-value":typeof _vm.oldFormValues[key] === 'string'
            ? JSON.parse(_vm.oldFormValues[key]) : _vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"input-change":_vm.inputChange}})],1):(val.type === 'select_paginated'
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',[_c('PaginatedSelect',{attrs:{"type":val.type,"field-name":key,"label":val.label || key,"disabled":val.disabled || _vm.disabledChecker(val.disabledDependsOn),"relation":val.relation,"server-side-dependence":(val.serverSideDependence
            && ( _obj$3 = {}, _obj$3[val.serverSideDependence] = (_vm.formValue[val.serverSideDependence]
              || _vm.parentFormValue[val.serverSideDependence]), _obj$3 )) || {},"values":val.values,"old-value":typeof _vm.oldFormValues[key] === 'string'
            ? JSON.parse(_vm.oldFormValues[key]) : _vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"input-change":_vm.inputChange}})],1):(val.type === 'datetime'
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',[_c('DatePickerInput',{attrs:{"field-name":key,"label":val.label || key,"old-value":_vm.oldFormValues[key],"disabled":val.disabled,"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"input-change":_vm.inputChange}})],1):(val.type === 'sender'
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',[_c('SmsSender',{attrs:{"field-name":key,"label":val.label || key,"disabled":val.disabled,"path":val.path,"button-text":val.buttonText,"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key),"form-value":_vm.formValue}})],1):(val.type === 'array'
        && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',[_c('Array',{attrs:{"form-data":val,"parent-form-value":_vm.formValue,"disabled":val.disabled,"field-name":key,"label":val.label || key,"validation-error":_vm.validationErrors[key],"old-form-values":_vm.oldFormValues[key]},on:{"input-change":_vm.inputChange}})],1):(val.type === 'editor'
        && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',[_c('TinyEditor',{attrs:{"type":"editor","label":val.label || key,"field-name":key,"disabled":val.disabled,"old-value":_vm.oldFormValues[key],"translatable":val.translatable,"translation":_vm.oldFormValues.translation,"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key),"form-locale-lang":_vm.formSelectedLanguage || _vm.localeLang},on:{"translation-input-change":_vm.translationInputChange,"input-change":_vm.inputChange}})],1):(val.type === 'tag'
        && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',[_c('Tag',{attrs:{"type":"tag","label":val.label || key,"field-name":key,"disabled":val.disabled,"old-value":_vm.oldFormValues[key],"translatable":val.translatable,"translation":_vm.oldFormValues && _vm.oldFormValues.translation,"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key),"form-locale-lang":_vm.formSelectedLanguage || _vm.localeLang},on:{"translation-input-change":_vm.translationInputChange,"input-change":_vm.inputChange}})],1):(val.type === 'cron_expression'
        && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',[_c('CronsTrue',{attrs:{"label":val.label || key,"field-name":key,"disabled":val.disabled,"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key),"form-locale-lang":_vm.formSelectedLanguage || _vm.localeLang},on:{"translation-input-change":_vm.translationInputChange,"input-change":_vm.inputChange}})],1):(val.type === 'hidden')?_c('div',[_c('HiddenInput',{attrs:{"field-name":key,"old-value":_vm.oldFormValues[key]},on:{"input-change":_vm.inputChange}})],1):_vm._e()])}),_c('div',{staticClass:"submit-btn-wrapper"},[(Object.keys(_vm.formData)[0])?_c('a-button',{staticClass:"btn btn-success",attrs:{"type":"primary","loading":_vm.isLoading || _vm.isFormDataLoading},on:{"click":function($event){$event.preventDefault();return _vm.formDataSubmit($event)}}},[_vm._v(" Submit ")]):_vm._e(),(!_vm.hideCancel)?_c('a-button',{style:({ marginRight: '8px' }),on:{"click":function($event){return _vm.$emit('toggle-modal')}}},[_vm._v(" Cancel ")]):_vm._e()],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }