<template>
  <div class="module-by-id">
    <!--    <div v-if="!hideLanguageSwitcher" class="lang-switcher-wrapper">-->
    <!--      <LanguageSwitcher />-->
    <!--    </div>-->
    <a-spin :spinning="isLoading">
      <div class="comp-wrapper">
        <div v-if="filter">
          <FilterForm
            v-if="filter"
            :key="JSON.stringify(filter)"
            :filter-list="filter"
            :own-submit="true"
            :is-loading="isLoading"
            :old-form-values="oldFilterValues"
            :has-reset-button="true"
            @reset-search-fields="resetSearchFields"
            @filter-form-search="onFilterFormSearch"
          />
        </div>
        <div v-if="type === 'table_view'">
          <AntdTable
            :columns="columns"
            :rows="results"
            @update-table="getModuleById"
          />
        </div>
        <div v-else-if="type === 'paginated_table_view'">
          <AntdTable
            :columns="columns"
            :rows="results && results.data"
            :table-title="results && results.tableTitle"
            :loading="isLoading"
            :expanded-row-query-data="expandedRowQueryData"
            :order-params="ordering"
            :page-count="Math.ceil(results
              && results.total / results.per_page)"
            :total="results && results.total"
            :page-value="results && results.current_page"
            :per-page="results && results.per_page"
            :pagination="results && results.per_page
              && results.data.length >= results.per_page
              || (results && results.last_page_url)"
            :table-footer="tableFooterArray"
            @on-page-change="getModuleById"
            @per-page-select-change="getModuleById"
            @search-by-order="searchByOrder"
            @update-table="getModuleById"
          >
            <div v-if="currencyOptions">
              <a-select
                v-model="selectedCurrency"
                style="width: 120px; margin-right: 14px"
                placeholder="Currency"
                @change="getModuleById"
              >
                <a-select-option
                  v-for="option in currencyOptions"
                  :key="option.code"
                  :disabled="option.disabled"
                >
                  {{ option.label }}
                </a-select-option>
              </a-select>
            </div>
            <div v-if="actions && actions[0]" class="button-wrapper">
              <span v-for="(action, index) in actions" :key="index">
                <a-button v-if="action.action === 'create'"
                          type="primary"
                          @click="toggleFormModal('create')"
                >
                  + {{ action.label }}
                </a-button>
                <a-button v-else-if="action.action === 'sendWins'"
                          type="primary"
                          @click="handleAction(action.action)"
                >
                  {{ action.label }}
                </a-button>
              </span>
            </div>
            <div style="margin: 0 10px">
              <DownLoadFile v-if="download_token"
                            :total-count="results && results.total"
                            :down-load-url="downLoadUrl"
                            :search-params="downLoadFileSearchParams"
              />
            </div>
          </AntdTable>
        </div>
        <div v-else>
          <a-list bordered :data-source="columns">
            <a-list-item slot="renderItem" slot-scope="item">
              <ViewItem
                :translatable="item.translatable"
                :label="item.label"
                :type="item.type"
                :field-name="item.field"
                :translation="rows.translation"
                :content-value="rows[item.field]"
              />
            <!--            <a-row class="list-row">-->
            <!--              <a-col :span="6">-->
            <!--                {{ item.label }}-->
            <!--              </a-col>-->
            <!--              <a-col :span="18">-->
            <!--                {{ rows[item.field] }}-->
            <!--              </a-col>-->
            <!--            </a-row>-->
            </a-list-item>
            <div v-if="!hideLanguageSwitcher" slot="header">
              <LanguageSwitcher />
            </div>
          </a-list>
        </div>
      </div>
    </a-spin>
    <CreateFormPage
      v-if="Boolean(isFormModalOpen)"
      :visible="Boolean(isFormModalOpen)"
      :action="isFormModalOpen || ''"
      @toggle-modal="toggleFormModal"
    />
    <DeleteFormModal
      v-if="deleteFormModalInfo"
      delete-vuex-action="gameModule/softDeleteById"
      :info="deleteFormModalInfo"
      @update-table-info="getModuleById"
    />
    <DeleteModal
      v-if="deleteModalInfo"
      delete-vuex-action="gameModule/deleteSelectedUser"
      :info="deleteModalInfo"
      @update-table-info="getModuleById"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import socketError from '@/utils/socketError';
import DownLoadFile from '@/components/partial/DownLoadFile';
import ViewItem from './partial/ViewItem';
import LanguageSwitcher from './partial/LanguageSwitcher';
import FilterForm from './partial/CustomFilterList/FilterForm';
import AntdTable from './Table/AntdTable';
import CreateFormPage from '../views/CreateFormPage';
import DeleteFormModal from './partial/Modals/DeleteFormModal';
import DeleteModal from './partial/Modals/DeleteModal';

export default {
  components: {
    DeleteModal,
    DeleteFormModal,
    CreateFormPage,
    DownLoadFile,
    LanguageSwitcher,
    AntdTable,
    ViewItem,
    FilterForm,
  },
  filters: {
    dateFilter(date) {
      return moment(date * 1000).format('LLLL');
    },
  },
  computed: {
    downLoadUrl() {
      return `${process.env.VUE_APP_API_URL}${this.$route.path}/download-link`;
    },
    ...mapState({
      moduleViewById1(state) {
        // eslint-disable-next-line max-len
        return state.gameModule.moduleViewById[this.$route.params.id + this.$route.params.moduleName];
      },
      downLoadFileSearchParams() {
        const params = {
          orderBy: (this.ordering && this.ordering.orderBy) || 'ASC',
          column: (this.ordering && this.ordering.column) || '',
          reportName: this.$route.path,
          perPage: 10000000,
          token: this.download_token,
          ...this.filterValue,
        };
        if (this.currencyOptions) {
          params.currency = this.selectedCurrency;
        }
        return params;
      },
      deleteModalInfo(state) {
        return state.modals.isDeleteModalOpen[this.$route.params.moduleName];
      },
      deleteFormModalInfo(state) {
        return state.modals.isDeleteFormModalOpen[this.$route.params.moduleName];
      },
      isFormModalOpen(state) {
        return state.gameModule.isFormModalOpen;
      },
      moduleViewById(state) {
        // eslint-disable-next-line max-len
        return state.gameModule.moduleViewById[this.$route.params.id + this.$route.params.moduleName];
      },
      hideLanguageSwitcher(state) {
        // eslint-disable-next-line max-len
        return state.gameModule.moduleViewById[this.$route.params.id + this.$route.params.moduleName] && state.gameModule.moduleViewById[this.$route.params.id + this.$route.params.moduleName].hideLanguageSwitcher;
      },
      filter(state) {
        // eslint-disable-next-line max-len
        return state.gameModule.moduleViewById[this.$route.params.id + this.$route.params.moduleName] && state.gameModule.moduleViewById[this.$route.params.id + this.$route.params.moduleName].filter;
      },
      expandedRowQueryData(state) {
        // eslint-disable-next-line max-len
        return state.gameModule.moduleViewById[this.$route.params.id + this.$route.params.moduleName] && state.gameModule.moduleViewById[this.$route.params.id + this.$route.params.moduleName].expandedRowQueryData;
      },
      type(state) {
        // eslint-disable-next-line max-len
        return state.gameModule.moduleViewById[this.$route.params.id + this.$route.params.moduleName] && state.gameModule.moduleViewById[this.$route.params.id + this.$route.params.moduleName].type;
      },
      currencyOptions(state) {
        // eslint-disable-next-line max-len
        return state.gameModule.moduleViewById[this.$route.params.id + this.$route.params.moduleName] && state.gameModule.moduleViewById[this.$route.params.id + this.$route.params.moduleName].currencyOptions;
      },
      tableFooterArray(state) {
        // eslint-disable-next-line max-len
        return state.gameModule.moduleViewById[this.$route.params.id + this.$route.params.moduleName] && state.gameModule.moduleViewById[this.$route.params.id + this.$route.params.moduleName].tableFooterArray;
      },
      columns(state) {
        // eslint-disable-next-line max-len
        return state.gameModule.moduleViewById[this.$route.params.id + this.$route.params.moduleName] && state.gameModule.moduleViewById[this.$route.params.id + this.$route.params.moduleName].columns;
      },
      actions(state) {
        // eslint-disable-next-line max-len
        return state.gameModule.moduleViewById[this.$route.params.id + this.$route.params.moduleName] && state.gameModule.moduleViewById[this.$route.params.id + this.$route.params.moduleName].actions;
      },
      download_token(state) {
        // eslint-disable-next-line max-len
        return state.gameModule.moduleViewById[this.$route.params.id + this.$route.params.moduleName] && state.gameModule.moduleViewById[this.$route.params.id + this.$route.params.moduleName].download_token;
      },
      ordering(state) {
        // eslint-disable-next-line max-len
        return state.gameModule.moduleViewById[this.$route.params.id + this.$route.params.moduleName] && state.gameModule.moduleViewById[this.$route.params.id + this.$route.params.moduleName].ordering;
      },
      oldFilterValues(state) {
        // eslint-disable-next-line max-len
        return state.gameModule.moduleViewById[this.$route.params.id + this.$route.params.moduleName] && state.gameModule.moduleViewById[this.$route.params.id + this.$route.params.moduleName].filterFormData;
      },
      oldSelectedCurrency(state) {
        // eslint-disable-next-line max-len
        return state.gameModule.moduleViewById[this.$route.params.id + this.$route.params.moduleName] && state.gameModule.moduleViewById[this.$route.params.id + this.$route.params.moduleName].oldSelectedCurrency;
      },
      rows(state) {
        // eslint-disable-next-line max-len
        return state.gameModule.moduleViewById[this.$route.params.id + this.$route.params.moduleName] && state.gameModule.moduleViewById[this.$route.params.id + this.$route.params.moduleName].result;
      },
      results(state) {
        // eslint-disable-next-line max-len
        return state.gameModule.moduleViewById[this.$route.params.id + this.$route.params.moduleName] && state.gameModule.moduleViewById[this.$route.params.id + this.$route.params.moduleName].results;
      },
    }),
  },
  // eslint-disable-next-line vue/order-in-components
  data() {
    return {
      isLoading: false,
      orderParams: {},
      filterValue: {},
      selectedCurrency: undefined,
    };
  },
  watch: {
    async $route() {
      this.setDefaultValues();
      if (!this.moduleViewById) {
        await this.getModuleById();
      }
    },
  },
  async mounted() {
    await this.getModuleById();
    this.setDefaultValues();
  },
  methods: {
    async toggleFormModal(action) {
      await this.$store.dispatch('gameModule/toggleFormModal', action);
      if (!action) {
        await this.getModuleById();
      }
    },
    resetSearchFields() {
      this.filterValue = {};
      this.getModuleById();
    },
    async onFilterFormSearch(filterFormData) {
      if (!this.$route.query.page || this.$route.query.page !== '1') {
        await this.$router.push({ query: { ...this.$route.query, page: '1' } });
      }
      this.filterValue = { ...filterFormData };
      await this.getModuleById();
    },
    setDefaultValues() {
      this.filterValue = this.oldFilterValues || {};
      this.orderParams = this.ordering || {};
      this.selectedCurrency = this.oldSelectedCurrency || undefined;
    },
    async searchByOrder(data) {
      this.orderParams = data;
      await this.getModuleById();
    },
    async handleAction(action) {
      this.isLoading = true;
      try {
        await axios.post(`${this.$route.path}/${action}`);
      } catch (e) {
        socketError(e);
      }
      this.isLoading = false;
    },
    async getModuleById() {
      this.isLoading = true;
      const params = {
        pageType: this.type,
        orderParams: this.orderParams,
        path: this.$route.fullPath,
        moduleName: this.$route.params.moduleName,
        page: this.$route.query.page || 1,
        perPage: this.$route.query.perPage || 10,
        id: this.$route.params.id,
        filterFormData: this.filterValue,
        currency: this.selectedCurrency,
      };
      await this.$store.dispatch('gameModule/getModuleById', params);
      this.isLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.module-by-id{
  padding: 20px 24px;
  .lang-switcher-wrapper{
    margin-left: 5%;
    margin-bottom: 30px;
  }
  .game-item-wrapper{
    display: flex;
    flex-wrap: wrap;
    margin: 0 5%;
  }
  .list-row{
    text-align: left;
  }
  .comp-wrapper{
    background: white;
  }
  .ant-list-item{
    /*display: grid;*/
  }
}
</style>
