<template>
  <div class="wheel-level-form-wrapper">
    <div class="currency-select-wrapper">
      <a-select
        v-model="selectedCurrency"
        placeholder="Currency select"
        size="small"
        style="width: 200px"
      >
        <a-select-option
          v-for="option in currencyOptions"
          :key="option.code"
        >
          {{ option.label }}
        </a-select-option>
      </a-select>
    </div>
    <div v-if="selectedCurrency" class="tags-wrapper">
      <div v-if="currentLevelTags[0]" style="margin-right: 40px">
        <a-tag
          v-for="tag in sortedCurrentLevelTags"
          :key="tag"
          :closable="true"
          :color="activeLevelTag === tag && '#1890FF' || ''"
          @click="() => onLevelTagChange(tag)"
          @close="() => handleClose(tag)"
        >
          Level {{ tag }}
        </a-tag>
      </div>
      <a-select
        v-model="selectedLevel"
        style="width: 120px"
        size="small"
        placeholder="New Level"
        @change="addTag"
      >
        <a-select-option
          v-for="optionCode in allowedLevels"
          :key="optionCode"
          :disabled="currentLevelTags.includes(optionCode)"
        >
          Level {{ optionCode }}
        </a-select-option>
      </a-select>
    </div>
    <div v-if="selectedCurrency">
      <div v-for="currencyOption in wheelPartialForms.currencyOptions.values"
           :key="currencyOption.code"
      >
        <WheelLevelForm
          v-for="levelTag in wheelPartialForms.wheelLevel[currencyOption.code].levelTags"
          v-show="levelTag === activeLevelTag && currencyOption.code === selectedCurrency"
          :key="levelTag"
          :tag-level-code="levelTag"
          :currency-code="currencyOption.code"
          :tag-level-label="`Level ${levelTag}`"
          :currency-label="currencyOption.label"
          :form-data="wheelPartialForms.wheelLevel[currencyOption.code].fields"
          :old-form-values="wheelPartialForms.wheelLevel[currencyOption.code][levelTag]
            && wheelPartialForms.wheelLevel[currencyOption.code][levelTag].result"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import WheelLevelForm from './WheelLevelForm';

export default {
  components: { WheelLevelForm },
  data() {
    return {
      selectedCurrency: undefined,
      inputVisible: false,
      inputValue: '',
      activeLevelTag: 1,
      selectedLevel: undefined,
      payload: {
        success: {
          id: 1,
          createdAt: 35456465,
        },
        currencyOptions: {
          values: [
            {
              code: 1,
              label: 'AMD',
            },
            {
              code: 2,
              label: 'USD',
            },
          ],
        },
        wheelLevel: {
          1: {
            levelTags: [
              {
                code: 'level1',
                label: 'Label 1',
              },
              {
                code: 'level2',
                label: 'Label 2',
              },
              {
                code: '3level',
                label: 'Label 3',
              },
              {
                code: '4level',
                label: 'Label 4',
              },
            ],
            level1: {
              result: {
                name: 'asdsad11111111111',
              },
            },
            level2: {
              result: {
                name: 'lkfg2222222222222',
              },
            },
            fields: {
              name: {
                type: 'text',
                label: 'Name',
              },
              name1: {
                type: 'text',
                label: 'Name1',
              },
              name2: {
                type: 'text',
                label: 'Name2',
              },
            },
          },
          2: {
            levelTags: [
              {
                code: 'level1',
                label: 'level1',
              },
              {
                code: 'level2',
                label: 'level2',
              },
            ],
            level1: {
              result: {
                name: 'asd',
              },
            },
            level2: {
              result: {
                name: 'lkfg',
              },
            },
            fields: {
              name: {
                type: 'text',
                label: 'Name',
              },
              title: {
                type: 'text',
                label: 'Title',
              },
            },
            result: {
              55: {
                name: 'text',
              },
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapState({
      wheelPartialForms(state) {
        return state.wheel.wheelPartialForms[this.$route.query.wheelId];
      },
    }),
    currencyOptions() {
      return this.wheelPartialForms && this.wheelPartialForms.currencyOptions.values;
    },
    sortedCurrentLevelTags() {
      const arr = [...this.currentLevelTags];
      return arr.sort((a, b) => a - b);
    },
    activeForm() {
      return this.selectedCurrency && this.payload.wheelLevel[this.selectedCurrency].fields;
    },
    activeFormResult() {
      // console.log(this.selectedCurrency && this.activeLevelTag
      //   && this.payload.wheelLevel[this.selectedCurrency][this.activeLevelTag]
      //   && this.payload.wheelLevel[this.selectedCurrency][this.activeLevelTag].result);
      return this.selectedCurrency && this.activeLevelTag
        && this.payload.wheelLevel[this.selectedCurrency][this.activeLevelTag]
        && this.payload.wheelLevel[this.selectedCurrency][this.activeLevelTag].result;
    },
    allowedLevels() {
      // eslint-disable-next-line max-len
      return this.selectedCurrency && this.wheelPartialForms.wheelLevel[this.selectedCurrency].levels;
    },
    currentLevelTags() {
      // eslint-disable-next-line max-len
      return (this.selectedCurrency && this.wheelPartialForms.wheelLevel[this.selectedCurrency].levelTags) || [];
    },
  },
  watch: {
    selectedCurrency(e) {
      if (e && this.currentLevelTags && this.currentLevelTags[0]) {
        // eslint-disable-next-line prefer-destructuring
        this.activeLevelTag = this.currentLevelTags[0];
      }
    },
    currencyOptions(e) {
      if (e) {
        this.selectedCurrency = e[0].code;
      }
    },
  },
  mounted() {
    if (this.currencyOptions) {
      this.selectedCurrency = this.currencyOptions[0].code;
    }
    if (this.currentLevelTags && this.currentLevelTags[0]) {
      // eslint-disable-next-line prefer-destructuring
      this.activeLevelTag = this.currentLevelTags[0];
    }
    // console.log('mounted this.wheelPartialForms', this.wheelPartialForms);
  },
  methods: {
    async handleClose(removedTag) {
      const targetTagForm = this.wheelPartialForms.wheelLevel[this.selectedCurrency][removedTag]
        && this.wheelPartialForms.wheelLevel[this.selectedCurrency][removedTag].result;
      await this.$store.dispatch('wheel/removeTag', {
        removedTag,
        currencyCode: this.selectedCurrency,
        wheelId: this.$route.query.wheelId,
        dbLevelFormId: targetTagForm && targetTagForm.wl_currency_id,
      });
      if (this.currentLevelTags && this.currentLevelTags[0]) {
        // eslint-disable-next-line prefer-destructuring
        this.activeLevelTag = this.currentLevelTags[0];
      }
    },
    onLevelTagChange(e) {
      this.activeLevelTag = e;
    },
    async addTag(newTag) {
      // const newTag = (this.currentLevelTags && this.currentLevelTags.length
      //   && (this.currentLevelTags[this.currentLevelTags.length - 1] + 1)) || 1;
      this.selectedLevel = undefined;
      const tagData = {
        currencyCode: this.selectedCurrency,
        code: newTag,
        wheelId: this.$route.query.wheelId,
      };
      await this.$store.dispatch('wheel/addTag', tagData);
      this.activeLevelTag = newTag;
    },
    async handleInputConfirm() {
      const { inputValue } = this;
      if (inputValue) {
        const tags = this.wheelPartialForms.wheelLevel[this.selectedCurrency].levelTags;
        let tagExistance = false;
        tags.forEach((tag) => {
          if (tag.label === inputValue || tag.code === inputValue) {
            tagExistance = true;
          }
        });
        if (tagExistance) {
          this.tagInputValidation.status = 'error';
          this.tagInputValidation.help = 'There is a level created width this name';
          return;
        }
        const tagData = {
          currencyCode: this.selectedCurrency,
          label: inputValue,
          code: inputValue,
        };
        await this.$store.dispatch('wheel/addTag', tagData);
        this.activeLevelTag = inputValue;
        this.inputVisible = false;
        this.inputValue = '';
      }
    },
  },
};
</script>

<style lang="scss">
.wheel-level-form-wrapper{
  background: white;
  padding: 24px;
  text-align: left;
  border-radius: 8px;
  margin-top: 24px;
  .currency-select-wrapper{
    margin-bottom: 30px;
  }
  .tags-wrapper{
    display: flex;
    margin-bottom: 30px;
    padding-left: 2px;
    align-items: center;
  }
}
</style>
