<template>
  <a-breadcrumb>
    <a-breadcrumb-item>
      <router-link to="/dashboard">
        <a-icon type="home" style="vertical-align: 1px" /> Home
      </router-link>
    </a-breadcrumb-item>
    <a-breadcrumb-item v-for="item in breadcrumb" :key="item.key">
      <router-link
        v-if="item.type === 'link'"
        :to="pathBuilder(item.path)"
      >
        {{ getTitle(item) }}
      </router-link>
      <span v-else-if="item.type === 'text'">
        {{ getTitle(item) }}
      </span>
    </a-breadcrumb-item>
  </a-breadcrumb>
</template>

<script>

export default {
  computed: {
    breadcrumb() {
      return this.$route.meta.breadcrumb;
    },
  },
  methods: {
    getTitle(item) {
      let title = item.key ? this.$route.params[item.key] : item.text;
      title = item.prefix ? item.prefix + title.replace(title.charAt(0), title[0].toUpperCase())
        : title.replace(title.charAt(0), title[0].toUpperCase());
      return title;
    },
    pathBuilder(pathArray) {
      let path = '';
      pathArray.forEach((elem) => {
        if (elem.type === 'text') {
          path += '/' + elem.key;
        } else if (elem.type === 'link') {
          const prefix = elem.pathPrefix || '';
          path += '/' + prefix + this.$route.params[elem.key];
        }
      });
      return path;
    },
  },
};
</script>
<style lang="scss">
.ant-breadcrumb{
  text-align: left;
  background: #FFFFFF;
  padding: 10px 0 10px 25px!important;
}
</style>
