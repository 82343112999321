<template>
  <div>
    <a-tag :closable="true" @close="removeTag">
      {{ tagLabel }}
    </a-tag>
    <a-input
      v-model="value"
      type="number"
      min="0"
      :max="max"
      placeholder="%"
    />
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['inputValue', 'tagLabel', 'tagCode', 'max'],
  data() {
    return {
      value: '',
    };
  },
  watch: {
    inputValue(e) {
      if (e !== this.value) {
        this.value = this.inputValue;
      }
    },
    value(newVal, prevVal) {
      if (String(newVal) && (newVal < 0 || (this.max && newVal > this.max))) {
        this.value = prevVal;
      }
      this.$emit('input-change', {
        value: this.value, tagCode: this.tagCode,
      });
    },
  },
  mounted() {
    this.value = this.inputValue;
  },
  methods: {
    removeTag() {
      this.$emit('remove-tag', this.tagCode);
    },
  },
};
</script>
