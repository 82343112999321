<template>
  <div class="side-menu-search-bar">
    <a-input
      id="sidebar-search"
      v-model="inputValue"
      class="search-bar-input"
      placeholder="Search"
    >
      <div slot="addonAfter">
        <a-icon type="search" @click="search" />

        <a-icon
          v-if="inputValue"
          type="close-circle"
          class="reset-icon"
          @click="reset"
        />
      </div>
    </a-input>
  </div>
</template>

<script>

export default {
  data() {
    return {
      inputValue: '',
    };
  },

  watch: {
    inputValue() {
      this.search();
    },
  },

  methods: {
    reset() {
      this.inputValue = '';
    },

    search() {
      this.$emit('search', this.inputValue);
    },
  },
};
</script>

<style lang="scss">
  .side-menu-search-bar {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 15px 0;

    .search-bar-input {
      width: 90%;

      .ant-input, .ant-input-group-addon {
        background-color: #000929;
        border-color: #6c757da3;
        color: #6c757d;

        &:focus {
          box-shadow: none;
        }
      }
    }

    .reset-icon {
      margin-left: 4px;
    }
  }
</style>
