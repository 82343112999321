<template>
  <div class="one-row-table">
    <table>
      <tr>
        <th
          v-for="(cell, ind) in columns"
          :key="ind"
          @click.stop="handleOrder(cell)"
        >
          <span class="orderable">
            {{ columnsInfo[cell] && columnsInfo[cell].label || cell }}
            <OrderingButton
              v-if="orderState.field === cell"
              :order-by="orderState.orderBy"
            />
          </span>
        </th>
      </tr>
      <tr>
        <th
          v-for="(cell, ind) in columns"
          :key="ind"
        >
          {{ tableObject[cell] }}
        </th>
      </tr>
    </table>
  </div>
</template>

<script>
import OrderingButton from '../partial/CustomFilterList/OrderingButton';

export default {
  components: { OrderingButton },
  props: {
    orderState: {
      type: Object,
      default() {
        return {};
      },
    },
    tableObject: {
      type: Object,
      default() {
        return {};
      },
    },
    columnsInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      columns: [],
    };
  },
  mounted() {
    this.setColumns();
  },
  methods: {
    handleOrder(field) {
      this.$emit('handle-order', field);
    },
    setColumns() {
      const arr = [];
      _.forIn(this.tableObject, (value, key) => {
        if (typeof value !== 'object') {
          arr.push(key);
        }
      });
      this.columns = arr;
    },
  },
};
</script>

<style scoped lang="scss">
.one-row-table{
  text-align: center;
  table{
    margin-left: 5%;
    tr:first-child{
      color: #075473;
    }
    tr:last-child{
      color: #057b04;
    }
    tr{
      line-height: 1.3;
    }
    th{
      padding: 0 10px;
      white-space: nowrap;
    }
    .orderable{
      position: relative;
      >div{
        top: 0;
        right: -15px;
      }
    }
  }
}
</style>
