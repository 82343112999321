<template>
  <div class="client-global-search">
    <a-dropdown
      overlay-class-name="overlay-class"
    >
      <a-form>
        <a-input v-show="false" type="submit" @click="search" />
        <a-input
          v-model="inputValue"
          type="text"
          style="width: 180px"
          :class="{emptyData: emptyClass}"
          placeholder="Client Search"
        >
          <div slot="addonAfter">
            <a-icon
              v-if="isLoading"
              type="sync"
              spin
            />
            <a-icon
              v-if="inputValue && inputValue.length >= 3 && !isLoading"
              type="search"
              @click.stop="search"
            />
            <a-icon
              v-if="inputValue"
              type="close-circle"
              style="margin-left: 4px"
              @click="reset"
            />
          </div>
        </a-input>
      </a-form>
      <template #overlay>
        <a-menu v-if="clientList.length" style="width: 500px">
          <a-menu-item>
            <a-row>
              <a-col :span="8" class="menu-row menu-column">
                Id
              </a-col>
              <a-col :span="8" class="menu-row menu-column">
                Username
              </a-col>
              <a-col :span="8" class="menu-row menu-column">
                Where
              </a-col>
            </a-row>
          </a-menu-item>
          <a-menu-item
            v-for="item in clientList"
            :key="item.id"
          >
            <router-link
              :to="`/player/client/${item.id}`"
            >
              <a-row>
                <a-col :span="8" class="menu-row">
                  {{ item.id }}
                </a-col>
                <a-col :span="8" class="menu-row">
                  {{ item.userName }}
                </a-col>
                <a-col :span="8" class="menu-row">
                  {{ item.where }}
                </a-col>
              </a-row>
            </router-link>
          </a-menu-item>
        </a-menu>
        <div v-else style="padding-left: 10px" class="emptyData">
          No Data
        </div>
      </template>
    </a-dropdown>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      inputValue: undefined,
      clientList: [],
      emptyClass: false,
    };
  },
  watch: {
    inputValue() {
      if (this.emptyClass) {
        this.emptyClass = false;
      }
    },
  },
  methods: {
    reset() {
      if (!this.isLoading) {
        this.clientList = [];
        this.inputValue = undefined;
      }
    },
    // eslint-disable-next-line consistent-return
    async search() {
      if (this.inputValue.length >= 3) {
        try {
          this.isLoading = true;
          const res = await axios.get('player/search', {
            params: {
              key: this.inputValue,
            },
          });
          this.clientList = (res && res.data && res.data.clientList) || [];
          if (!this.clientList.length) {
            this.emptyClass = true;
          }
          return res && res.data && res.data.clientList;
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-row{
  padding: 0 8px;
  &.menu-column{
    color: #000000b5;
  }
  color: #1890ff;
  /*text-align: center;*/
}
.overlay-class{
  >ul{
    overflow-y: auto;
    max-height: 370px;
  }
}
.emptyData{
  /*border-bottom: 1px solid #91725a;*/
  /*border-left: 1px solid #91725a;*/
  /*border-right: 1px solid #91725a;*/
  border-radius: 4px;
  box-shadow: 1px 1px 2px 1px #ff7c7c;
}
.client-global-search{
  display: flex;
  padding-top: 2px;
}
</style>
