<template>
  <div>
    <a-switch v-model="isChecked" @change="onChange" />
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['oldValue'],
  data() {
    return {
      isChecked: false,
    };
  },
  watch: {
    oldValue(e) {
      this.isChecked = Boolean(e);
    },
  },
  mounted() {
    this.isChecked = Boolean(this.oldValue);
  },
  methods: {
    onChange(checked) {
      this.$emit('switcher-change', checked);
    },
  },
};
</script>
