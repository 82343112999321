import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'localeLang',
    ]),
  },
  methods: {
    getTranslation(translations, fieldName, localeLang = this.localeLang) {
      let res;
      if (translations[localeLang]) {
        translations[localeLang].forEach((obj) => {
          if (obj.column_name === fieldName) {
            res = obj.value;
          }
        });
      }
      return res;
    },
  },
};
