import axios from 'axios';
import socketError from '../utils/socketError';

export default {
  namespaced: true,

  state: {
    info: {},
    userInfo: {},
    printData: {},
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo || {};
    },
    setInfo(state, info) {
      state.info = info;
    },
    clearInfo(state) {
      state.info = {};
    },
    setPrintData(state, data) {
      state.printData = data;
    },
  },
  actions: {
    setPrintData({ commit }, data) {
      commit('setPrintData', data);
    },
    clearUserInfo({ commit }) {
      commit('setUserInfo');
    },
    async fetchUserInfo({ dispatch, commit }) {
      try {
        const params = {};
        const selectedSiteId = localStorage.getItem('selectedPartner');
        if (selectedSiteId) {
          params.selectedSiteId = selectedSiteId;
        }
        const res = await axios.get('/user', { params });
        const resData = res.data && res.data.data;

        const currentVersion = res.headers['backend-version'];
        const registeredVersion = localStorage.getItem('controlBackVersion');
        if (!registeredVersion || currentVersion !== registeredVersion) {
          localStorage.setItem('controlBackVersion', currentVersion);
        }
        commit('setUserInfo', resData);
        return resData;
      } catch (e) {
        socketError({ dispatch, commit }, e);
        return false;
      }
    },
  },
  getters: {
    info: (s) => s.info,
    userInfo: (s) => s.userInfo,
  },
};
