<!-- eslint-disable -->
<template>
  <div class="game-view-item">
    <span v-if="translatable" class="badge badge-warning lang-icon">
      "{{ localeLang }}"
    </span>
    <a-col :span="8" v-if="type === 'date'">
      <a-card :title="label">
        <p>{{ moment(resValue).format('LL') }}</p>
      </a-card>
    </a-col>
    <a-col :span="8" v-if="type === 'editor'">
      <a-card :title="label">
        <p class="value" v-html="resValue" />
      </a-card>
    </a-col>
    <a-col :span="8" v-if="type === 'text' || type === ''">
      <a-card :title="label">
        <p>{{ resValue }}</p>
      </a-card>
    </a-col>
    <a-col :span="8" v-if="type === 'image'">
      <a-card :title="label">
        <p>
          <img
            slot="cover"
            :alt="label"
            :src="resValue"
          />
        </p>
      </a-card>
    </a-col>
    <a-col :span="8" v-if="type === 'badge'" class="badge">
      <a-card :title="label">
        <p>
          <i v-if="!resValue || resValue==='No'" class="fa fa-times" aria-hidden="true" />
          <i v-if="resValue == 1 || resValue==='Yes'"
             class="fas fa-check-square"
             aria-hidden="true"
          />
        </p>
      </a-card>
    </a-col>
    <a-col :span="8" v-if="type === 'toggle'" class="custom-control custom-switch">
      <a-card :title="label">
        <p class="value">
          <input id="customSwitch1" type="checkbox" class="custom-control-input">
          {{ resValue }}
        </p>
      </a-card>
    </a-col>
    <a-col :span="8" v-if="type === 'array'" class="array-type">
      <a-card :title="label">
        <p v-if="resValue[0]">
        <span
          v-for="(item, index) in resValue"
          :key="index"
        >{{ item.name }}</span>
        </p>
      </a-card>
    </a-col>
    <a-col :span="8" v-if="type === 'object'">
      <a-card :title="label">
        <p class="value">
          <span v-if="label === 'Currency'">{{ resValue && resValue.code }}</span>
          <span v-else-if="resValue && resValue.username">{{ resValue.username }}</span>
          <span v-else-if="resValue && resValue.name">{{ resValue.name }}</span>
          <span v-else-if="resValue && resValue.title">{{ resValue.title }}</span>
          <span v-else>{{ resValue && resValue.id }}</span>
        </p>
      </a-card>
    </a-col>
    <a-col :span="8" v-if="type === 'input_number'">
      <a-card :title="label">
        <p v-if="resValue[0]">
          <input type="number" :value="resValue || 0">
        </p>
      </a-card>
    </a-col>
  </div>
</template>

<script>

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['label', 'contentValue', 'type', 'translatable', 'translation', 'fieldName'],
  data() {
    return {
      isLoading: false,
      moment,
    };
  },
  computed: {
    resValue() {
      return this.translatable && this.localeLang !== 'default'
        ? (this.translation && this.translation[this.localeLang]
          && this.getTranslation(this.translation, this.fieldName, this.localeLang)) || ''
        : this.contentValue;
    },
  },
};
</script>

<style scoped lang="scss">
.game-view-item{
  position: relative;
  margin: 10px;
  min-width: 150px;
  .lang-icon{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
  }
  .badge{
    .fa-times{
      font-size: 20px;
      color: red;
    }
    .fa-check-square{
      font-size: 20px;
      color: green;
    }
  }
  .array-type{
    p:last-child{
      >span{
        display: block;
      }
    }
  }
  >div{
    width: 100%;
    background: #e8e7e7;
    border-radius: 10px;
    padding: 0 10px;
    p{
      font-size: 16px;
      font-weight: 600;
      img{
        max-width: 50px;
        max-height: 50px;
      }
    }
  }
}
</style>
