<template>
  <div class="delete-modal">
    <a-modal v-model="visible">
      <template slot="title">
        <span style="color: #ff7875; font-weight: bold">Delete Modal</span>
      </template>
      <template slot="footer">
        <a-button key="back" @click="cancelOrCloseModal">
          Cancel
        </a-button>
        <a-button
          key="submit"
          type="danger"
          :loading="loading"
          @click="deleteSelectedUser"
        >
          Delete
        </a-button>
      </template>
      <p>
        Please Confirm the
        <span style="color: #ff7875; font-weight: bold">Delation</span> of
        {{ $route.params.moduleName && lodash.capitalize($route.params.moduleName) }}
        with ID <span style="display: block">{{ info.id }}
        </span>
      </p>
    </a-modal>
  </div>
</template>

<script>
import lodash from 'lodash';

export default {
  props: {
    deleteVuexAction: {
      type: String,
      default: '',
    },
    info: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      visible: true,
      lodash,
    };
  },
  watch: {
    visible(e) {
      if (!e) {
        this.cancelOrCloseModal();
      }
    },
  },
  methods: {
    async deleteSelectedUser() {
      const params = {
        path: this.$route.path,
        id: this.info.id,
      };
      await this.$store.dispatch(`${this.deleteVuexAction}`, params);
      this.$emit('update-table-info');
      this.cancelOrCloseModal();
    },
    async cancelOrCloseModal() {
      await this.$store.dispatch('modals/toggleDeleteModal');
    },
  },
};
</script>

<style scoped lang="scss">
</style>
