<template>
  <div ref="cron-editor" class="cron-editor">
    <a-form-item
      :label="label"
      :validate-status="validatingStatus"
      :help="validateHelp"
    >
      <VueCronEditorBuefy
        v-model="value"
      />
    </a-form-item>
  </div>
</template>

<script>
import VueCronEditorBuefy from 'vue-cron-editor-buefy';

export default {
  components: { VueCronEditorBuefy },
  // eslint-disable-next-line vue/require-prop-types
  props: ['label', 'fieldName', 'values', 'oldValue', 'validationError', 'isValidationFirstError'],
  data() {
    return {
      value: '',
      validatingStatus: 'validating',
      validateHelp: '',
    };
  },
  watch: {
    value(e) {
      this.$emit('input-change', { [this.fieldName]: e });
    },
    isValidationFirstError(e) {
      if (e) {
        this.$refs['cron-editor'].scrollIntoView();
      }
    },
    validationError(e) {
      if (e) {
        this.validatingStatus = 'error';
        // eslint-disable-next-line prefer-destructuring
        this.validateHelp = e[0];
      } else {
        this.deleteValidationErrors();
      }
    },
  },
  mounted() {
    if (this.oldValue) {
      this.value = this.oldValue;
      this.$emit('input-change', { [this.fieldName]: this.oldValue });
    }
  },
};
</script>

<style scoped lang="scss">
.cron-editor{
  text-align: left;
}
</style>
