<template>
  <div id="bell-notification">
    <a-dropdown
      overlay-class-name="my-dropdown"
    >
      <a-badge :count="computedCount" title="Custom hover text">
        <a-icon type="bell" class="bell" />
      </a-badge>
      <div slot="overlay" class="my-overlay">
        <a-spin :spinning="loading">
          <div class="header">
            <span class="title">Withdraw Requests</span>
            <span v-if="false">
              <a-icon
                type="sync"
                :spin="loading"
                @click="getData"
              />
            </span>
          </div>
          <div v-if="count" class="custom-table-wrapper">
            <table>
              <tr v-for="(row, ind) in rows" :key="ind">
                <td>
                  <router-link
                    :to="`/player/client/${row.id}/transaction-payment`"
                    target="_blank"
                  >
                    <span style="cursor: pointer">
                      {{ row.userName }}
                    </span>
                  </router-link>
                </td>
                <td>
                  <img v-img
                       :src="row.image"
                       alt="img"
                       :style="{ 'max-width': '50px', 'max-height': '50px' }"
                  >
                </td>
                <td>{{ row.amount }}</td>
              </tr>
            </table>
          </div>
          <div v-else style="text-align: center">
            No Result
          </div>
          <div v-if="count > 10" style="text-align: right; padding: 10px">
            <router-link
              to="/admin/withdraw-requests"
            >
              Show More...
            </router-link>
          </div>
        </a-spin>
      </div>
    </a-dropdown>
  </div>
</template>

<script>
import socketError from '../../utils/socketError';

export default {
  data() {
    return {
      count: 0,
      data: [
        {
          key: '1',
          name: 'John Brown',
          age: 32,
          address: 'New York No. 1 Lake Park',
        },
        {
          key: '2',
          name: 'Jim Green',
          age: 42,
          address: 'London No. 1 Lake Park',
        },
        {
          key: '3',
          name: 'Joe Black',
          age: 32,
          address: 'Sidney No. 1 Lake Park',
        },
        {
          key: '3',
          name: 'Joe Black',
          age: 32,
          address: 'Sidney No. 1 Lake Park',
        },
        {
          key: '3',
          name: 'Joe Black',
          age: 32,
          address: 'Sidney No. 1 Lake Park',
        },
        {
          key: '3',
          name: 'Joe Black',
          age: 32,
          address: 'Sidney No. 1 Lake Park',
        },
        {
          key: '3',
          name: 'Joe Black',
          age: 32,
          address: 'Sidney No. 1 Lake Park',
        },
        {
          key: '3',
          name: 'Joe Black',
          age: 32,
          address: 'Sidney No. 1 Lake Park',
        },
        {
          key: '3',
          name: 'Joe Black',
          age: 32,
          address: 'Sidney No. 1 Lake Park',
        },
        {
          key: '3',
          name: 'Joe Black',
          age: 32,
          address: 'Sidney No. 1 Lake Park',
        },
        {
          key: '3',
          name: 'Joe Black',
          age: 32,
          address: 'Sidney No. 1 Lake Park',
        },
      ],
      rows: [],
      loading: false,
    };
  },
  computed: {
    computedCount() {
      return this.count <= 100 ? this.count : '100+';
    },
  },
  mounted() {
    this.getData();
    // setInterval(() => {
    //   this.getData();
    // }, 30000);
    if (this.$pusher) {
      const selectedSiteId = localStorage.getItem('selectedPartner');
      const channel = this.$pusher.subscribe('get-withdraw-event');
      channel.bind('notify_' + selectedSiteId, (noteData) => {
        this.count = noteData.count;
        this.rows = noteData.rows;
      });
    }
  },
  methods: {
    async getData() {
      this.loading = true;
      try {
        const selectedSiteId = localStorage.getItem('selectedPartner');
        const res = await axios.get(`/admin/withdraw-requests-bell-bar?selectedSiteId=${selectedSiteId}`);
        if (res.data && res.data.count) {
          this.count = res.data.count;
          this.rows = res.data.rows;
        }
        this.loading = false;
        return res.data;
      } catch (e) {
        this.loading = false;
        socketError(e);
        return e;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.my-overlay{
  padding-top: 10px;
  background: white;
  max-height: 600px;
  min-width: 200px;
  overflow: auto;
  border: 1px solid #8080801a;
  border-radius: 4px;
  .custom-table-wrapper{
    /*border: 1px solid;*/
    table {
      tr {
        box-shadow: 0 0 8px #efefef;
        td{
          padding: 8px 8px;
        }
      }
    }
  }
  .header{
    display: flex;
    justify-content: space-between;
    padding: 0 10px 10px 10px;
    .title {
      font-size: 14px;
      font-weight: 600;
    }
  }
}
#bell-notification{
  cursor: pointer;
  padding-top: 10px;
  .bell {
    font-size: 22px;
  }
}
</style>
