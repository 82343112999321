import axios from 'axios';
import Vue from 'vue';
// import moment from 'moment';
import socketError from '../utils/socketError';

export default {
  namespaced: true,

  state: {
    loading: false,
    exactPartnerInfo: {},
    isEditFormModalOpen: false,
    editJsonById: {},
  },
  mutations: {
    deleteEditFormJson(state) {
      Vue.set(state, 'editJsonById', {});
    },
    setEditJsonById(state, { key, value }) {
      Vue.set(state.editJsonById, key, value);
    },
    setUpdatedFormValue(state, { key, formValue, menuItem }) {
      Vue.set(state.exactPartnerInfo[key][menuItem].formData, 'result', formValue);
    },
    setExactPartnerInfo(state, { key, data, menuItem }) {
      const value = { ...state.exactPartnerInfo[key] };
      if (!state.exactPartnerInfo[key] || !state.exactPartnerInfo[key].menu) {
        value.menu = data.menu;
      }
      // console.log('data', data);
      value[menuItem] = data.data;
      Vue.set(state.exactPartnerInfo, key, value);
    },
  },
  actions: {
    deleteEditFormJson({ commit }) {
      commit('deleteEditFormJson');
    },
    async deleteById({ dispatch, commit },
      {
        path,
        id,
      }) {
      try {
        const res = await axios.post(`${path}/${id}/destroy`);
        return res.data;
      } catch (e) {
        socketError({
          dispatch,
          commit,
        }, e);
        return false;
      }
    },
    async updatePlayerModuleById({ dispatch, commit }, {
      path, id, action, formData,
    }) {
      try {
        const res = await axios.post(`${path}/${id}/${action}`, formData);
        return res.data;
      } catch (e) {
        socketError({
          dispatch,
          commit,
        }, e);
        return false;
      }
    },
    async editPlayerModuleById({ dispatch, commit }, {
      path, id, action,
    }) {
      try {
        const res = await axios.get(`${path}/${id}/${action}`);
        const data = _.clone(res.data);
        // console.log('get-edit-json', data);
        commit('setEditJsonById', { key: id, value: data });
        return data;
      } catch (e) {
        socketError({
          dispatch,
          commit,
        }, e);
        return false;
      }
    },
    async getExactPartnerInfo({ dispatch, commit },
      {
        path, id, page, filterFormData, orderParams, menuItem, perPage,
      }) {
      // console.log(`${path}/${menuItem}`);
      try {
        const params = {
          ...filterFormData,
        };
        if (page) {
          params.page = page;
        }
        if (perPage) {
          params.perPage = perPage;
        }
        const res = await axios.get(`${path}`, {
          params,
        });
        const data = _.clone(res.data);
        data.filterFormData = filterFormData;
        data.orderParams = orderParams || {};
        // console.log('setExactPartnerInfo', data);
        commit('setExactPartnerInfo', { key: id, data, menuItem });
        return data;
      } catch (e) {
        // commit('setPlayerStates', { playerName: key, isLoading: false, error: true });
        socketError({
          dispatch,
          commit,
        }, e);
        return false;
      }
    },
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    isEditFormModalOpen(state) {
      return state.isEditFormModalOpen;
    },
    exactPartnerInfo(state) {
      return state.exactPartnerInfo;
    },
    exactUserWantedDetail(state) {
      return state.exactUserWantedDetail;
    },
  },
};
