<template>
  <div>
    <a-modal
      :visible="visible"
      style="top: 20px;"
      ok-text="Accept"
      :confirm-loading="isLoading"
      @ok="handleOk"
      @cancel="$emit('close-modal')"
    >
      <p>
        Are You Sure You Want To Change Risk Status
        <span style="color: orangered">Client With ID: {{ clientId }}</span> To
      </p>
      <a-select
        v-model="selectedValue"
        style="min-width: 200px"
      >
        <a-select-option v-for="(option, index) in options" :key="index" :value="option.code">
          {{ option.label }}
        </a-select-option>
      </a-select>
    </a-modal>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['visible', 'isLoading', 'clientId', 'defaultValue'],
  data() {
    return {
      selectedValue: 'pending',
      options: [
        { code: 'positive', label: 'Positive' },
        { code: 'negative', label: 'Negative' },
        { code: 'pending', label: 'Pending' },
      ],
    };
  },
  watch: {
    defaultValue(e) {
      if (e) {
        this.selectedValue = e;
      }
    },
  },
  mounted() {
    if (this.defaultValue) {
      this.selectedValue = this.defaultValue;
    }
  },
  methods: {
    handleOk() {
      if (!this.isLoading) {
        this.$emit('handle-accept', this.selectedValue);
      }
    },
  },
};
</script>
