<template>
  <a-result status="404" title="404" sub-title="Sorry, the page you visited does not exist.">
    <template #extra>
      <a-button type="primary" @click="back">
        Back
      </a-button>
    </template>
  </a-result>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    back() {
      this.$router.go(-2);
      this.$store.dispatch('tabsVuex/removeTab', { key: this.$route.path });
    },
  },
};
</script>
