<template>
  <div>
    <a-input-number
      v-model="value"
      :step="step"
      @pressEnter="onChange"
      @blur="onChange"
    />
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['step', 'oldValue'],
  data() {
    return {
      value: null,
    };
  },
  watch: {
    oldValue(e) {
      this.value = e;
    },

  },
  mounted() {
    if (this.oldValue) {
      this.value = this.oldValue;
    }
  },
  methods: {
    onChange() {
      this.$emit('number-input-changed', this.value);
    },
  },
};
</script>
