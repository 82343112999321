<template>
  <div class="date-picker">
    <a-form-item
      ref="date-input-picker"
      :validate-status="validatingStatus"
      :help="validateHelp"
      class="form-item-holder"
    >
      <template slot="label">
        <span class="form-item-label-text">{{ label }}</span>
      </template>
      <DatePicker
        v-model="selectedDate"
        type="datetime"
        :disabled="disabled"
        :value-type="'timestamp'"
        :default-value="selectedDate"
        @input="change"
      />
    </a-form-item>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: 'DatePickerComponent',
  components: { DatePicker },
  // eslint-disable-next-line vue/require-prop-types
  props: ['oldValue', 'fieldName', 'label', 'disabled', 'validationError'],
  data() {
    return {
      selectedDate: null,
      validatingStatus: 'validating',
      validateHelp: '',
    };
  },
  // props: {
  //   fieldName: {
  //     type: String,
  //     default: '',
  //   },
  //   label: {
  //     type: String,
  //     default: '',
  //   },
  // },
  watch: {
    isValidationFirstError(e) {
      if (e) {
        this.$refs['date-input-picker'].scrollIntoView();
      }
    },
    validationError(e) {
      if (e) {
        this.validatingStatus = 'error';
        // eslint-disable-next-line prefer-destructuring
        this.validateHelp = e[0];
      } else {
        this.deleteValidationErrors();
      }
    },
    oldValue() {
      this.setOldValue();
    },
  },
  mounted() {
    if (this.oldValue) {
      this.setOldValue();
    } else {
      this.selectedDate = moment().valueOf();
      this.$emit('input-change', { [this.fieldName]: this.selectedDate && moment(this.selectedDate).unix() });
    }
  },
  methods: {
    deleteValidationErrors() {
      this.validatingStatus = 'validating';
      this.validateHelp = '';
    },
    setOldValue() {
      this.selectedDate = this.oldValue * 1000;
      this.$emit('input-change', { [this.fieldName]: this.selectedDate && moment(this.selectedDate).unix() });
    },
    change(e) {
      this.$emit('input-change', { [this.fieldName]: moment(e).unix() });
      this.deleteValidationErrors();
    },
  },
};
</script>

<style scoped lang="scss">
.date-picker{
  text-align: left;
  .ant-form-item-label{
    label{
      font-size: 14px!important;
      font-weight: 400!important;
      color: rgba(0, 0, 0, 0.85);
    }
  }
}
</style>
