<template>
  <div :key="$route.path" class="report">
    <div v-if="Boolean(antFilter)">
      <FilterForm
        :filter-list="antFilter"
        :own-submit="true"
        :is-loading="isLoading"
        :filter-ordering="['date-range-picker-with-presets',
                           'ant-date-range', 'ant-date-range-with-timezone',
                           'utc-date-range-picker']"
        :old-form-values="oldFilterFormData || {}"
        @filter-form-search="onFilterFormSearch"
      />
    </div>
    <AntdTable
      :loading="isLoading"
      :columns="columns"
      :order-params="orderParams"
      :rows="rows"
      :total="reportTableInfo && reportTableInfo.total"
      :page-count="Math.ceil(reportTableInfo && reportTableInfo.total / reportTableInfo.per_page)"
      table-size="small"
      :table-footer="tableFooterArray"
      :page-value="reportTableInfo && reportTableInfo.current_page"
      :per-page="reportTableInfo && reportTableInfo.per_page"
      :pagination="reportTableInfo
        && reportTableInfo.per_page && rows.length >= reportTableInfo.per_page
        || (reportTableInfo && reportTableInfo.last_page_url)"
      @search-by-order="searchByOrder"
      @per-page-select-change="getReportInfo"
      @on-page-change="pageChange"
      @update-table="getReportInfo"
    >
      <div v-if="currencyOptions">
        <a-select
          v-model="selectedCurrency"
          style="width: 120px; margin-right: 14px"
          placeholder="Currency"
          @change="currencyOptionChange"
        >
          <a-select-option
            v-for="option in currencyOptions"
            :key="option.code"
            :disabled="option.disabled"
          >
            {{ option.label }}
          </a-select-option>
        </a-select>
      </div>
      <DownLoadFile v-if="reportInfo && reportInfo.download_token"
                    :total-count="reportTableInfo && reportTableInfo.total"
                    :search-params="{
                      orderBy: orderParams.orderBy || 'ASC',
                      column: orderParams.column || '',
                      page: 1,
                      perPage: 10000000,
                      reportName: $route.path,
                      token: reportInfo && reportInfo.download_token,
                      ...filterFormDataForDownLoad,
                      currency: selectedCurrency || '',
                    }"
      />
    </AntdTable>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DownLoadFile from './partial/DownLoadFile';
import AntdTable from './Table/AntdTable';
import FilterForm from './partial/CustomFilterList/FilterForm';

export default {
  components: {
    AntdTable, DownLoadFile, FilterForm,
  },
  data() {
    return {
      isLoading: false,
      filterFormData: {},
      orderParams: {},
      filterFormDataForDownLoad: {},
      selectedCurrency: undefined,
      moment,
    };
  },
  computed: {
    ...mapState({
      errorInLoad(state) {
        return state.reports.widgetStates[this.title].error;
      },
      reportInfo(state) {
        return state.reports.reportInfo[this.$route.params.report
        || this.$route.params.reportGroup];
      },
      reportTableInfo() {
        return (this.reportInfo && this.reportInfo.reports) || {};
      },
      rows() {
        return (this.reportTableInfo && this.reportTableInfo.data) || [];
      },
      tableFooterArray() {
        return (this.reportInfo && this.reportInfo.tableFooterArray) || [];
      },
      columns() {
        return (this.reportInfo && this.reportInfo.columns) || [];
      },
      currencyOptions() {
        return this.reportInfo && this.reportInfo.currencyOptions;
      },
      antFilter(state) {
        const initialFilter = state.reports.reportInfo[this.$route.params.report
        || this.$route.params.reportGroup] && state.reports.reportInfo[this.$route.params.report
        || this.$route.params.reportGroup].antFilter;
        if (initialFilter && initialFilter.date_range) {
          initialFilter.date_range = { ...initialFilter.date_range, disAllowClear: true };
        }
        return initialFilter;
      },
      oldFilterFormData(state) {
        return state.reports.reportInfo[this.$route.params.report
        || this.$route.params.reportGroup] && state.reports.reportInfo[this.$route.params.report
        || this.$route.params.reportGroup].filterFormData;
      },
    }),
  },
  watch: {
    async $route(to, from) {
      if (to.path !== from.path) {
        if (!this.reportInfo) {
          await this.removeAndSetDefaultParams();
          await this.getReportInfo();
        }
        this.setSettings();
      }
    },
  },
  async mounted() {
    if (!this.reportInfo) {
      await this.getTodayTimeAndSetDate();
      await this.getReportInfo();
    }
    this.setSettings();
  },
  methods: {
    async currencyOptionChange() {
      await this.getReportInfo();
    },
    async onFilterFormSearch(filterFormData) {
      if (!this.$route.query.page || this.$route.query.page !== '1') {
        await this.$router.push({ query: { ...this.$route.query, page: '1' } });
      }
      this.filterFormData = filterFormData;
      await this.getReportInfo(filterFormData);
      this.setSettings();
    },
    async getTodayTimeAndSetDate() {
      try {
        const presets = await this.$store.dispatch('dashboard/getPresetList');
        if (!this.filterFormData.date_range) {
          this.filterFormData.date_range = {
            startDate: presets.today.start_date,
            endDate: presets.today.end_date,
          };
        }
        return presets.today;
      } catch {
        return false;
      }
    },
    async removeAndSetDefaultParams() {
      this.selectedCurrency = undefined;
      this.filterFormData = {};
      await this.getTodayTimeAndSetDate();
      this.orderParams = {};
    },
    async searchByOrder(data) {
      this.orderParams = data;
      await this.getReportInfo();
      this.setSettings();
    },
    async pageChange() {
      await this.getReportInfo();
      this.setSettings();
    },
    setFilterFormDataValues() {
      this.filterFormData = { ...this.oldFilterFormData } || {};
    },
    filterBuilderForDownload(filterFormData) {
      const filter = { ...filterFormData };
      if (filter.date_range) {
        filter.endDate = filter.date_range.endDate;
        filter.startDate = filter.date_range.startDate;
        delete filter.date_range;
      }
      return filter;
    },
    async getReportInfo() {
      if (!this.isLoading) {
        this.filterFormDataForDownLoad = this.filterBuilderForDownload(this.filterFormData) || {};
        this.isLoading = true;
        const params = {
          page: this.$route.query.page || 1,
          path: this.$route.path,
          name: this.$route.params.report || this.$route.params.reportGroup,
          perPage: localStorage.getItem('tablePerPage') || this.$route.query.perPage || 10,
          orderParams: this.orderParams,
          filterFormData: this.filterFormData,
          currency: this.selectedCurrency,
        };
        await this.$store.dispatch('reports/getReportInfo', params);
        this.isLoading = false;
      }
    },
    setSettings() {
      this.setFilterFormDataValues();
      this.setOrderingParams();
      this.setOldSelectedCurrency();
    },
    setOldSelectedCurrency() {
      this.selectedCurrency = this.reportInfo && this.reportInfo.currency;
    },
    setOrderingParams() {
      this.orderParams = this.reportInfo && this.reportInfo.ordering;
    },
  },
};
</script>

<style scoped lang="scss">
.report{
  width: 100%;
}
</style>
