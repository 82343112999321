var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wheel-form-page-wrapper"},[_c('div',{staticClass:"form-title"},[_c('span',[_vm._v(_vm._s(_vm.tagLevelLabel))]),_c('span',[_vm._v("("+_vm._s(_vm.currencyLabel)+")")])]),_c('div',{staticClass:"wheel-form-wrapper"},[_c('a-form',{staticClass:"wheel-level-form",attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"layout":"vertical"}},[_vm._l((_vm.orderedFormData),function(row,rowKey){return _c('a-row',{key:rowKey,class:rowKey},_vm._l((row),function(val,key){
var _obj, _obj$1, _obj$2, _obj$3;
return _c('a-col',{key:String(key),staticClass:"component-wrapper",attrs:{"xl":_vm.columnCountInRow(val.type) || 4,"xxl":_vm.columnCountInRow(val.type, 'xxl') || 4,"lg":_vm.columnCountInRow(val.type) || 6,"md":_vm.columnCountInRow(val.type) || 12,"sm":24,"xs":24}},[(val.type === 'checkbox' && (!val.depends_on || _vm.dependOnChecker(val.depends_on)))?_c('div',[_c('Checkbox',{attrs:{"label":val.label || key,"field-name":key,"disabled":val.disabled,"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"input-change":_vm.inputChange}})],1):(val.type === 'text'
              && (!val.depends_on || _vm.dependOnChecker(val.depends_on)))?_c('div',[_c('Input',{attrs:{"label":val.label || key,"field-name":key,"disabled":val.disabled,"old-value":_vm.oldFormValues[key],"translatable":val.translatable,"translation":_vm.oldFormValues && _vm.oldFormValues.translation,"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"translation-input-change":_vm.translationInputChange,"input-change":_vm.inputChange}})],1):(val.type === 'ant-date-range'
              && (!val.depends_on || _vm.dependOnChecker(val.depends_on)))?_c('div',[_c('AntDateRangePicker',{attrs:{"label":val.label || key,"field":key,"disabled":val.disabled,"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"input-change":_vm.inputChange}})],1):(val.type === 'ant-date'
              && (!val.depends_on || _vm.dependOnChecker(val.depends_on)))?_c('div',[_c('AntDateInput',{attrs:{"label":val.label || key,"field":key,"disabled":val.disabled,"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"input-change":_vm.inputChange}})],1):(val.type === 'date'
              && (!val.depends_on || _vm.dependOnChecker(val.depends_on)))?_c('div',[_c('Input',{attrs:{"type":"date","label":val.label || key,"field-name":key,"disabled":val.disabled,"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"input-change":_vm.inputChange}})],1):(val.type === 'number'
              && (!val.depends_on || _vm.dependOnChecker(val.depends_on)))?_c('div',[_c('Input',{attrs:{"type":"number","label":val.label || key,"min":_vm.$route.params.moduleName === 'cms-pages' && 0,"field-name":key,"disabled":val.disabled,"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"input-change":_vm.inputChange}})],1):(val.type === 'color_picker'
              && (!val.depends_on || _vm.dependOnChecker(val.depends_on)))?_c('div',[_c('Input',{attrs:{"type":"color","label":val.label || key,"field-name":key,"disabled":val.disabled,"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"input-change":_vm.inputChange}})],1):(val.type === 'unsigned'
              && (!val.depends_on || _vm.dependOnChecker(val.depends_on)))?_c('div',[_c('Input',{attrs:{"type":"number","min":0,"label":val.label || key,"field-name":key,"disabled":val.disabled,"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"input-change":_vm.inputChange}})],1):(val.type === 'decimal'
              && (!val.depends_on || _vm.dependOnChecker(val.depends_on)))?_c('div',[_c('Input',{attrs:{"type":"number","step":0.01,"label":val.label || key,"field-name":key,"disabled":val.disabled,"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"input-change":_vm.inputChange}})],1):(val.type === 'textarea'
              && (!val.depends_on || _vm.dependOnChecker(val.depends_on)))?_c('div',[_c('Input',{attrs:{"type":"textarea","label":val.label || key,"field-name":key,"disabled":val.disabled,"old-value":_vm.oldFormValues[key],"translatable":val.translatable,"translation":_vm.oldFormValues && _vm.oldFormValues.translation,"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"translation-input-change":_vm.translationInputChange,"input-change":_vm.inputChange}})],1):(val.type === 'file'
              && (!val.depends_on || _vm.dependOnChecker(val.depends_on)))?_c('div',[_c('FileInput',{attrs:{"type":"file","label":val.label || key,"disabled":val.disabled,"translatable":val.translatable,"field-name":key,"old-value":_vm.oldFormValues[key],"translation":_vm.oldFormValues && _vm.oldFormValues.translation,"validation-error":_vm.validationErrors[key] || _vm.validationErrors[("old_" + key + "_en")]
                || _vm.validationErrors[("old_" + key + "_am")] || _vm.validationErrors[("old_" + key + "_ru")],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"translation-input-change":_vm.translationInputChange,"input-change":_vm.inputChange}})],1):((val.type === 'decimal_unsigned' || val.type === 'unsigned_decimal')
              && (!val.depends_on || _vm.dependOnChecker(val.depends_on)))?_c('div',[_c('Input',{attrs:{"type":"number","min":0,"step":"0.01","label":val.label || key,"disabled":val.disabled,"field-name":key,"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"input-change":_vm.inputChange}})],1):(val.type === 'radio'
              && (!val.depends_on || _vm.dependOnChecker(val.depends_on)))?_c('div',[_c('Radio',{attrs:{"label":val.label || key,"field-name":key,"disabled":val.disabled,"values":val.values,"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"input-change":_vm.inputChange}})],1):(val.type === 'selectProbability'
              && (!val.depends_on || _vm.dependOnChecker(val.depends_on)))?_c('div',[_c('SelectWithPercent',{attrs:{"field-name":key,"label":val.label || key,"disabled":val.disabled,"max":val.max,"with-image":val.withImage,"values":val.values,"old-value":_vm.oldFormValues[key] || {},"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"input-change":_vm.inputChange}})],1):(val.type === 'select'
              && (!val.depends_on || _vm.dependOnChecker(val.depends_on)))?_c('div',[_c('Select',{attrs:{"type":val.type,"field-name":key,"label":val.label || key,"relation-id":val.relationId,"disabled":val.disabled,"values":val.values,"relation":val.relation,"server-side-dependence":val.depends_on
                && Object.values(val.depends_on)[0] === 'serverSideDependence' ?
                  ( _obj = {}, _obj[Object.keys(val.depends_on)[0]] = (_vm.formValue[Object.keys(val.depends_on)[0]]
                    || _vm.parentFormValue[Object.keys(val.depends_on)[0]]), _obj )
                : {},"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"input-change":_vm.inputChange}})],1):((val.type === 'select_multiple' || val.type === 'multiSelect')
              && (!val.depends_on || _vm.dependOnChecker(val.depends_on)))?_c('div',[_c('MultipleSelect',{attrs:{"type":val.type,"field-name":key,"label":val.label || key,"relation-id":val.relationId,"disabled":val.disabled,"values":val.values,"relation":val.relation,"server-side-dependence":val.depends_on
                && Object.values(val.depends_on)[0] === 'serverSideDependence' ?
                  ( _obj$1 = {}, _obj$1[Object.keys(val.depends_on)[0]] = (_vm.formValue[Object.keys(val.depends_on)[0]]
                    || _vm.parentFormValue[Object.keys(val.depends_on)[0]]), _obj$1 )
                : {},"old-value":typeof _vm.oldFormValues[key] === 'string'
                ? JSON.parse(_vm.oldFormValues[key]) : _vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"input-change":_vm.inputChange}})],1):(val.type === 'select_multiple_paginated'
              && (!val.depends_on || _vm.dependOnChecker(val.depends_on)))?_c('div',[_c('PaginatedMultipleSelect',{attrs:{"type":val.type,"field-name":key,"label":val.label || key,"disabled":val.disabled,"relation":val.relation,"server-side-dependence":val.depends_on
                && Object.values(val.depends_on)[0] === 'serverSideDependence' ?
                  ( _obj$2 = {}, _obj$2[Object.keys(val.depends_on)[0]] = (_vm.formValue[Object.keys(val.depends_on)[0]]
                    || _vm.parentFormValue[Object.keys(val.depends_on)[0]]), _obj$2 )
                : {},"values":val.values,"old-value":typeof _vm.oldFormValues[key] === 'string'
                ? JSON.parse(_vm.oldFormValues[key]) : _vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"input-change":_vm.inputChange}})],1):(val.type === 'select_paginated'
              && (!val.depends_on || _vm.dependOnChecker(val.depends_on)))?_c('div',[_c('PaginatedSelect',{attrs:{"type":val.type,"field-name":key,"label":val.label || key,"disabled":val.disabled,"relation":val.relation,"server-side-dependence":val.depends_on
                && Object.values(val.depends_on)[0] === 'serverSideDependence' &&
                ( _obj$3 = {}, _obj$3[Object.keys(val.depends_on)[0]] = (_vm.formValue[Object.keys(val.depends_on)[0]]
                  || _vm.parentFormValue[Object.keys(val.depends_on)[0]]), _obj$3 ),"values":val.values,"old-value":typeof _vm.oldFormValues[key] === 'string'
                ? JSON.parse(_vm.oldFormValues[key]) : _vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"input-change":_vm.inputChange}})],1):(val.type === 'datetime'
              && (!val.depends_on || _vm.dependOnChecker(val.depends_on)))?_c('div',[_c('DatePickerInput',{attrs:{"field-name":key,"label":val.label || key,"old-value":_vm.oldFormValues[key],"disabled":val.disabled,"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"input-change":_vm.inputChange}})],1):(val.type === 'array'
            && (!val.depends_on || _vm.dependOnChecker(val.depends_on)))?_c('div',[_c('Array',{attrs:{"form-data":val,"parent-form-value":_vm.formValue,"disabled":val.disabled,"field-name":key,"label":val.label || key,"validation-error":_vm.validationErrors[key],"old-form-values":_vm.oldFormValues[key]},on:{"input-change":_vm.inputChange}})],1):(val.type === 'editor')?_c('div',[_c('TinyEditor',{attrs:{"type":"editor","label":val.label || key,"field-name":key,"disabled":val.disabled,"old-value":_vm.oldFormValues[key],"translatable":val.translatable,"translation":_vm.oldFormValues.translation,"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"translation-input-change":_vm.translationInputChange,"input-change":_vm.inputChange}})],1):(val.type === 'tag')?_c('div',[_c('Tag',{attrs:{"type":"tag","label":val.label || key,"field-name":key,"disabled":val.disabled,"old-value":_vm.oldFormValues[key],"translatable":val.translatable,"translation":_vm.oldFormValues && _vm.oldFormValues.translation,"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"translation-input-change":_vm.translationInputChange,"input-change":_vm.inputChange}})],1):(val.type === 'cron_expression')?_c('div',[_c('CronsTrue',{attrs:{"label":val.label || key,"field-name":key,"disabled":val.disabled,"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"translation-input-change":_vm.translationInputChange,"input-change":_vm.inputChange}})],1):_vm._e()])}),1)}),_c('div',{staticClass:"btn-wrapper"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.formSubmit}},[_vm._v(" Save Changes ")])],1)],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }