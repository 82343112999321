<template>
  <a-drawer
    title="Iframe"
    placement="right"
    :closable="true"
    :visible="Boolean(visible)"
    :body-style="{'padding': '20px', height: 'calc(100vh - 80px)'}"
    width="90%"
    :keyboard="true"
    :z-index="1036"
    @close="() => $emit('toggle-modal')"
  >
    <a-spin v-if="isLoading" size="large" style="position: absolute; top: 150px; left: 48%" />
    <iframe :src="iframeUrl"
            width="100%"
            height="100%"
            title="description"
    />
  </a-drawer>
</template>

<script>

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['visible', 'reqUrl'],
  data() {
    return {
      isLoading: false,
      iframeUrl: '',
    };
  },
  watch: {
    visible(e) {
      if (e) {
        this.getIframeUrl();
      }
    },
  },
  mounted() {
    if (this.visible) {
      this.getIframeUrl();
    }
  },
  methods: {
    async getIframeUrl() {
      this.isLoading = true;
      try {
        this.iframeUrl = (await axios.get(this.reqUrl)).data.url;
        this.isLoading = false;
        return true;
      } catch (e) {
        this.isLoading = false;
        return false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>
