<template>
  <SportCollapsePage
    :sport-report-columns="sportReportColumns"
    :sport-report-reports="sportReportReports"
  />
</template>

<script>
export default {
  components: {
    SportCollapsePage: () => import('./SportCollapsePage'),
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['sportReportReports', 'sportReportColumns'],
};
</script>

<style scoped lang="scss">
.sport-report-page{
  text-align: left;
  .ant-collapse-item-active{
    .header-wrapper{
      >span{
        font-weight: bold;
      }
    }
  }
  .header-wrapper{
    display: flex;
    align-items: center;
    >span{
      min-width: 150px;
    }
  }
}
</style>
