<template>
  <div>
    <a-modal
      title="Please fill the form fields"
      :visible="true"
      :confirm-loading="confirmLoading"
      ok-text="Save"
      @ok="handleSubmit"
      @cancel="handleCancel"
    >
      <a-form
        :form="form"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 18 }"
        @submit="handleSubmit"
      >
        <a-form-item
          label="Frequency"
        >
          <a-input
            v-decorator="['frequency', {
              rules: [{ required: false, message: 'The field is required!'
              }] }]"
            type="number"
            :min="0"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import socketError from '../../../utils/socketError';

export default {
  data() {
    return {
      form: this.$form.createForm(this, { name: 'coordinated' }),
      confirmLoading: false,
    };
  },
  mounted() {
    this.getDefaultValue();
  },
  methods: {
    async getDefaultValue() {
      try {
        const res = await axios.get(`${this.$route.path}/frequency`);
        if (res.data && res.data.frequency) {
          this.$nextTick(() => {
            this.form.setFieldsValue({ frequency: res.data.frequency });
          });
        }
        return res.data;
      } catch (e) {
        socketError({}, e);
        return false;
      }
    },
    handleSubmit(e) {
      e.preventDefault();
      this.confirmLoading = true;
      this.form.validateFields(async (err, values) => {
        if (!err) {
          await this.storeData(values).then((res) => {
            if (res.success) {
              this.$emit('close-frequency-modal', false);
            }
            if (res.errors) {
              _.forIn(res.errors, (value, key) => {
                this.form.setFields({
                  frequency: {
                    value: this.form.getFieldValue(key), // Keep the current value
                    errors: [{ name: key, message: value[0] }], // Set the error message
                  },
                });
              });
            }
          });
        }
      });
      this.confirmLoading = false;
    },
    async storeData(form) {
      try {
        const res = await axios.post(`${this.$route.path}/frequency-store`, {
          ...form,
        });
        return res.data;
      } catch (e) {
        socketError({}, e);
        return false;
      }
    },
    handleCancel() {
      this.$emit('close-frequency-modal', false);
    },
  },
};
</script>
