<template>
  <div class="internal-messages-control-panel-wrapper">
    <div class="internal-messages-control-panel">
      <a-tabs
        :active-key="$route.params.box"
        :tab-bar-style="{
          background: 'white',
          'border-top-left-radius': '8px',
          'border-top-right-radius': '8px',
        }"
        style="background: #ffffff52"
        @change="(tab) => $router.push({params: {box: tab}})"
      >
        <a-tab-pane
          v-for="tab in ['inbox', 'outbox']"
          :key="tab"
          style="padding: 5px 20px 20px 20px"
        >
          <span slot="tab">
            <a-icon v-if="tab==='inbox'" type="download" />
            <a-icon v-else type="upload" />
            <span class="tab-title">
              {{ upperCaseFirstLetter(tab) }}
              <a-badge v-if="tab==='inbox'" class="count" :count="inboxCount" />
              <a-badge v-else class="count" :count="outboxCount" />
            </span>
          </span>
          <div>
            <div v-show="showFilter">
              <FilterForm
                v-if="boxFilter"
                :key="filterFormData"
                :filter-list="boxFilter"
                :own-submit="true"
                :is-loading="isLoading"
                :old-form-values="filterFormData"
                :has-reset-button="true"
                @reset-search-fields="resetSearchFields"
                @filter-form-search="onFilterFormSearch"
              />
            </div>
            <AntdTable
              :columns="columns"
              :rows="rows"
              :order-params="ordering"
              :table-title="`Internal Messages - ${upperCaseFirstLetter(tab)}`"
              :loading="isLoading"
              :page-count="Math.ceil(boxResults && boxResults.total / boxResults.per_page)"
              :total="boxResults && boxResults.total"
              :page-value="boxResults && boxResults.current_page"
              :per-page="boxResults && boxResults.per_page"
              :pagination="boxResults && boxResults.per_page && rows.length >= boxResults.per_page
                || (boxResults && boxResults.last_page_url)"
              @on-page-change="pageChange"
              @per-page-select-change="perPageSelectChange"
              @search-by-order="searchByOrder"
              @update-table="getInternalMessages"
            >
              <div v-if="$route.params.box === 'outbox'" class="button-wrapper">
                <span>
                  <a-button type="primary"
                            @click="toggleFormModal('create')"
                  >
                    + Send New
                  </a-button>
                </span>
              </div>
            </AntdTable>
          </div>
        </a-tab-pane>
        <a-button
          slot="tabBarExtraContent"
          style="margin-right: 20px"
          @click="() => showFilter = !showFilter"
        >
          Filter
          <a-icon v-if="showFilter" style="font-size: 12px" type="up" />
          <a-icon v-else style="font-size: 12px" type="down" />
        </a-button>
      </a-tabs>
    </div>
    <DeleteModal
      v-if="deleteModalInfo"
      delete-vuex-action="gameModule/deleteSelectedUser"
      :info="deleteModalInfo"
      @update-table-info="updateAfterAction"
    />
    <CreateFormPage
      v-if="Boolean(isFormModalOpen)"
      :visible="Boolean(isFormModalOpen)"
      :action="isFormModalOpen || ''"
      @toggle-modal="toggleFormModal"
    />
    <EditFormPage
      v-if="Boolean(isEditFormModalOpen)"
      :visible="isEditFormModalOpen"
      type="edit"
      update-dispatch-action="gameModule/updateModuleById"
      unmount-action="gameModule/deleteEditFormJson"
      get-edit-form-dispatch-action="gameModule/editModuleById"
      state-name="gameModule"
      request-path="/admin"
      :id-of-edit-modal="isEditFormModalOpen"
      @toggle-modal="toggleEditFormModal"
    />
    <InternalMessageModal
      v-if="Boolean(isIntMessControlModalOpen)"
      :id="isIntMessControlModalOpen"
      @update-table="getInternalMessages"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DeleteModal from '../components/partial/Modals/DeleteModal';
import CreateFormPage from './CreateFormPage';
import EditFormPage from './EditFormPage';
import socketError from '../utils/socketError';
import FilterForm from '../components/partial/CustomFilterList/FilterForm';
import AntdTable from '../components/Table/AntdTable';
import InternalMessageModal from '../components/partial/Modals/InternalMessageModal';

export default {
  components: {
    DeleteModal,
    CreateFormPage,
    EditFormPage,
    FilterForm,
    AntdTable,
    InternalMessageModal,
  },
  data() {
    return {
      isLoading: false,
      orderParams: {},
      rangeFiltersValues: {},
      filterFormData: {},
      activeTab: 'inbox',
      showFilter: true,
    };
  },
  computed: {
    ...mapState({
      inboxCount(state) {
        return state.gameModule.internalMessages.inboxCount || '';
      },
      outboxCount(state) {
        return state.gameModule.internalMessages.outboxCount || '';
      },
      isIframeModalOpen(state) {
        return state.modals.isIframeModalOpen;
      },
      isIntMessControlModalOpen(state) {
        return state.modals.isIntMessControlModalOpen;
      },
      isRejectModalOpen(state) {
        return state.modals.isRejectModalOpen;
      },
      isEditFormModalOpen(state) {
        return state.modals.isEditFormModalOpen;
      },
      deleteModalInfo(state) {
        return state.modals.isDeleteModalOpen.internalMessages;
      },
      isFormModalOpen(state) {
        return state.gameModule.isFormModalOpen;
      },
      boxData(state) {
        return state.gameModule.internalMessages?.[this.$route.params.box];
      },
      boxFilter(state) {
        return state.gameModule.internalMessages?.[this.$route.params.box]?.filter;
      },
      boxResults(state) {
        return state.gameModule.internalMessages?.[this.$route.params.box]?.results || {};
      },
      rows(state) {
        return state.gameModule.internalMessages?.[this.$route.params.box]?.results?.data || [];
      },
      columns(state) {
        return state.gameModule.internalMessages?.[this.$route.params.box]?.columns || [];
      },
      ordering(state) {
        // eslint-disable-next-line max-len
        return state.gameModule.internalMessages?.[this.$route.params.box]?.ordering;
      },
    }),
  },
  watch: {
    async $route() {
      if (!this.boxData) {
        await this.getInternalMessages();
      }
      this.setSettings();
    },
  },
  async mounted() {
    await this.addTab();
    if (!this.boxData) {
      await this.getInternalMessages();
    }
    this.setSettings();
  },
  methods: {
    async addTab() {
      let existence = false;
      const panes = this.$store.getters['tabsVuex/panes'];
      panes.forEach((tab) => {
        if (tab.key === this.$route.fullPath) {
          existence = true;
        }
      });
      if (!existence) {
        await this.$store.dispatch('tabsVuex/addTab', { key: this.$route.path, title: 'Internal Messages' });
      }
    },
    upperCaseFirstLetter(word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    },
    async perPageSelectChange() {
      await this.getInternalMessages();
      this.setSettings();
    },
    async searchByFilters() {
      await this.getInternalMessages();
      this.setSettings();
    },
    onRangeFilterInputChange(data) {
      this.rangeFiltersValues = {
        ...this.rangeFiltersValues,
        ...data,
      };
    },
    async resetSearchFields() {
      await this.onFilterFormSearch({});
    },
    async onFilterFormSearch(filterFormData) {
      if (!this.$route.query.page || this.$route.query.page !== '1') {
        await this.$router.push({ query: { ...this.$route.query, page: '1' } });
      }
      this.filterFormData = { ...filterFormData };
      await this.getInternalMessages();
      this.setSettings();
    },
    async updateAfterAction() {
      await this.getInternalMessages();
      this.setSettings();
    },
    async tableActionHandler(action) {
      this.isLoading = true;
      try {
        const res = await axios.get(`${this.$route.path}/${action}`);
        await this.getInternalMessages();
        this.isLoading = false;
        return res.data;
      } catch (e) {
        this.isLoading = false;
        socketError({
        }, e);
        return false;
      }
    },
    async searchByOrder(data) {
      this.orderParams = data;
      await this.getInternalMessages();
      this.setSettings();
    },
    async toggleFormModal(action) {
      await this.$store.dispatch('gameModule/toggleFormModal', action);
      await this.getInternalMessages();
      this.setSettings();
    },
    async pageChange() {
      await this.getInternalMessages();
      this.setSettings();
    },
    async toggleEditFormModal() {
      await this.$store.dispatch('modals/toggleEditFormModal');
      const query = _.clone(this.$route.query);
      delete query.modalId;
      await this.$router.replace({ query });
      await this.getInternalMessages();
      this.setSettings();
    },
    setFilterFormDataValues() {
      this.filterFormData = (this.boxData && this.boxData.filterFormData);
    },
    setSettings() {
      this.setFilterFormDataValues();
    },
    async getInternalMessages(deleteOutBoxFromIntMess) {
      if (!this.isLoading) {
        this.isLoading = true;
        const params = {
          orderParams: this.orderParams,
          path: this.$route.path,
          moduleName: 'internalMessages',
          page: this.$route.query.page || 1,
          targetBox: this.$route.params.box,
          perPage: localStorage.getItem('tablePerPage') || this.$route.query.perPage || 10,
          filterFormData: this.filterFormData,
          deleteOutBoxFromIntMess,
        };
        await this.$store.dispatch('gameModule/getInternalMessages', params);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.internal-messages-control-panel-wrapper{
  text-align: left;
  .internal-messages-control-panel {
    padding: 12px;
    .button-wrapper {
      span {
        margin: 0 4px;
        font-size: 14px!important;
      }
    }
    .tab-title{
      position: relative;
      .count{
        position: absolute;
        top: -14px;
        right: -35px;
      }
    }
  }
}
</style>
