<template>
  <li class="nav-item has-treeview"
      :class="{'nav-link-active': isMenuOpened || isActive}"
  >
    <div class="fieldName" :class="{'activeLink': isActive}" @click="toggleMenu">
      <router-link
        :to="navLinkGroup.length === 0?`/${url}`:''"
        class="nav-link"
        :class="{'activeLink': isActive}"
      >
        <span class="nav-link-icon-wrapper">
          <a-icon :type="icon" />
          <!--          <i :class="`fas ${icon}`" />-->
        </span>
        <p class="title">
          {{ title }}
          <i v-if="navLinkGroup[0]"
             :class="{'active': isMenuOpened}"
             class="fas fa-angle-right icon-arrow"
          />
        </p>
      </router-link>
    </div>
    <div v-if="navLinkGroup.length">
      <ul class="nav-side-menu">
        <SideBarNavLinkItem
          v-for="(item, index) in navLinkGroup"
          :key="index"
          :title="item.title"
          :group-name="title"
          :sub-links="item.children[0] && item.children"
          :url="item.url?item.url:''"
        />
      </ul>
    </div>
  </li>
</template>

<script>
import SideBarNavLinkItem from './SideBarNavLinkItem';

export default {
  components: { SideBarNavLinkItem },
  props: {
    navLinkGroup: {
      type: Array,
      default: () => ([]),
    },
    title: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    isMenuOpened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    isActive() {
      let active = false;
      if (this.$route.path === '/' && this.url === 'dashboard') {
        active = true;
      }
      if (this.$route.path !== '/') {
        active = this.$route.path === `/${this.url}`;
      }
      return active;
    },
  },
  mounted() {
    // console.log(window.location);
    // eslint-disable-next-line guard-for-in
    // for (const item in Object.values(this.navMenu)[0]) {
    //   console.log(item);
    // }
  },
  methods: {
    toggleMenu() {
      if (this.navLinkGroup.length !== 0) {
        if (this.isMenuOpened) {
          this.$emit('toggle-menu', false);
        } else {
          this.$emit('toggle-menu', this.title);
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
ul {
  list-style-type: none;
  padding-left: 0;
}

.nav-pills {
  .nav-link {
    border-radius: 0.25rem;
    color: #6c757d;
    display: block;
    padding: 0.5rem 1rem;

    &:not(.active):hover {
      color: #007bff;
    }

    p {
      display: inline-block;
      -webkit-animation-name: fadeIn;
      animation-name: fadeIn;
      -webkit-animation-duration: 0.3s;
      animation-duration: 0.3s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      margin: 0;
    }
  }

  .nav-link.active,
  .show > .nav-link {
    color: #ffffff;
    background-color: #007bff;
  }

  .nav-item.dropdown.show .nav-link:hover {
    color: #ffffff;
  }
}

.nav-link-active {
  .nav-link-icon-wrapper {
    border-right: 5px solid #1890ff;

    i {
      color: #1890ff;
    }
  }

  .nav-side-menu {
    position: absolute;
    z-index: 55;
    display: block!important;
    background: #001529;
    box-shadow: 2px 0px 6px rgba(0, 21, 41, 0.35);
    top: 0;
    left: 45px;
    width: 205px;
    height: 100%;
  }

  .fieldName {
    color: #1890FF;

    &.activeLink {
      background: #002140;

      .title {
        color: #1890FF;
      }
    }
  }
}
.nav-link-icon-wrapper {
  display: inline-block;
  margin-right: 10px;
  width: 30px;
}

.nav-side-menu {
  display: none;
}

.fieldName {
  position: relative;
  text-align: left;

  i {
    margin-right: 5px;
  }

  .icon-arrow {
    position: absolute;
    top: 30%;
    right: 5%;

    &.active {
      margin-left: 30px;
      transform: rotate(-90deg);
    }
  }
}
</style>
