<!-- eslint-disable -->
<template>
  <div class="table-wrapper" :class="{scrollable: expandedRowQueryData}">
    <a-table
      :columns="antdColumns"
      :data-source="antdRows"
      :row-selection="bulkDelete && { selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      :pagination="false"
      :row-key="record => record.id || record.rowIndex"
      :loading="loading"
      :scroll="{ x: true }"
      :size="tableSize || 'default'"
      :sortDirections="['descend', 'ascend']"
      :ellipsis="true"
      @change="handleTableChange"
    >
      <ExpandedAsyncAntTable
        :slot="expandedRowQueryData ? 'expandedRowRender' : null"
        slot-scope="text"
        :query-data="expandedRowQueryData"
        :row-data="text"
      />
      <template v-if="!withoutTableHeader" slot="title">
        <div>
          <div class="table-title">{{ tableTitle ? tableTitle : tabTitle}}</div>
          <div class="title-action-wrapper">
            <slot />
            <a-button
              v-if="bulkDelete"
              style="margin-left: 5px"
              type="danger"
              @click="showDeleteConfirm"
            >
              Bulk Delete
            </a-button>
            <a-icon v-if="!noRefresh" type="sync" :spin="loading" @click="$emit('update-table')" />
            <!--            <a-icon type="column-height" @click="tableSizeChange" />-->
          </div>
        </div>
      </template>
      <template v-if="footerInfo" #footer>
        {{ footerInfo }}
      </template>
      <template v-if="tableFooter" #footer>
        <span v-for="(item, ind) in tableFooter" :key="ind" style="margin-right: 50px">
          <span>{{ item.label }}: </span>
          <span> {{ item.value }}</span>
        </span>
      </template>
      <template slot="textarea" slot-scope="text">
        <a-textarea style="width: 300px" :value="text"/>
      </template>
      <template slot="jsonViewer" slot-scope="text">
        <a-popover trigger="click" :overlayStyle="{ 'max-width': '60%',  'min-width': '30%'}">
          <div slot="content">
            <JsonViewer
              :json="text"
            />
          </div>
          <a-button>
            Show Json
          </a-button>
        </a-popover>
      </template>
      <template slot="popover" slot-scope="text">
        <a-popover trigger="click" :overlayStyle="{ 'max-width': '50%' }">
          <template slot="content" >
            <p>{{ text }}</p>
          </template>
          <div style="cursor: pointer">{{ text.substring(0, 60) }}</div>
          <span  style="color: #1890ff; cursor: pointer">More...</span>
        </a-popover>
      </template>
      <template slot="html" slot-scope="text">
        <div v-html="text"></div>
      </template>
      <template slot="image" slot-scope="text">
        <img v-if="text"
             v-img
             :src="text"
             alt="img"
             :style="{ 'max-width': '40px', 'max-height': '40px' }"
        >
      </template>
      <template slot="textarea" slot-scope="text">
        <a-textarea style="width: 300px" :value="text"/>
      </template>
      <template slot="image_blank" slot-scope="text">
        <img v-if="text"
             :src="text"
             alt="img"
             :style="{ 'max-width': '40px', 'max-height': '40px', cursor: 'pointer' }"
             @click="onImageBlankClick(text)"
        >
      </template>
      <template slot="image_blank-with-url" slot-scope="text">
        <img v-if="text"
             :src="text.smallImgSrc"
             alt="img"
             :style="{ 'max-width': '40px', 'max-height': '40px', cursor: 'pointer' }"
             @click="onImageBlankClick(text.targetImgUrl)"
        >
      </template>
      <template slot="number" slot-scope="text">
        <span>
          {{ Number(text).toLocaleString() }}
        </span>
      </template>
      <template slot="badge" slot-scope="text">
        <div class="badge-icon-wrapper">
          <a-icon v-if="text == 1 || text==='Yes'"
                  type="check-circle"
                  two-tone-color="#00DC30"
                  theme="twoTone"
                  style="color: #00DC30"
          />
          <a-icon v-else-if="!text || text==='No'"
                  theme="twoTone"
                  style="color: #FF1818"
                  type="close-circle"
                  two-tone-color="#FF1818"
          />
        </div>
        <!--        <div class="badge">-->
        <!--          <i v-if="!text || text==='No'" class="fa fa-times" aria-hidden="true" />-->
        <!--          <i v-if="text == 1 || text==='Yes'"-->
        <!--             class="fas fa-check-square"-->
        <!--             aria-hidden="true"-->
        <!--          />-->
        <!--        </div>-->
      </template>
      <template slot="date" slot-scope="text">
        <span>
          {{ text && isNaN(text) ? moment(text).format('LL') :
            (text && text.length > 10 && moment(JSON.parse(text)).format('LL')
              || (text && moment(JSON.parse(text) * 1000).format('LL')))
            || null
          }}
      </span>
      </template>
      <template slot="hourFormat" slot-scope="text">
        <span>
        {{ text && isNaN(text) ? moment(text).format('YYYY-MM-DD HH:mm:ss') :
          (text && text.length > 10 && moment(JSON.parse(text)).format('YYYY-MM-DD h:mm:ss a')
            || (text && moment(JSON.parse(text) * 1000).format('YYYY-MM-DD h:mm:ss a')))
          || null
        }}
      </span>
      </template>
      <template slot="cron" slot-scope="text">
        <span>
          {{ text && moment(parser.parseExpression(text).next().toString()).format('DD/MM/YY, HH:mm:ss')}}
        </span>
      </template>
      <template slot="dateFormat" slot-scope="text">
        <span>
        {{ text && isNaN(text) ? moment(text).format('YYYY-MM-DD') :
          (text && text.length > 10 && moment(JSON.parse(text)).format('YYYY-MM-DD')
            || (text && moment(JSON.parse(text) * 1000).format('YYYY-MM-DD')))
          || null
        }}
      </span>
      </template>
      <template slot="24hourFormat" slot-scope="text">
        <span>
          {{ text && isNaN(text) ? moment(text).format('DD/MM/YY, HH:mm:ss') :
            (text && text.length > 10 && moment(JSON.parse(text)).format('DD/MM/YY, HH:mm:ss')
              || (text && moment(JSON.parse(text) * 1000).format('DD/MM/YY, HH:mm:ss')))
            || null
          }}
        </span>
      </template>
      <template slot="amountWithCurrency" slot-scope="text">
        <span style="margin-right: 6px">
          {{ Number(text.amount).toLocaleString() }}
        </span>
        <span>
          {{ text.currency }}
        </span>
      </template>
      <template slot="double" slot-scope="text, record">
        <ColsAndRows
          :old-value="text"
          @cols-and-rows-change="(selectedType) => colsAndRowsChange(selectedType, record.id)"
        />
      </template>
      <template slot="dynamicServerLink" slot-scope="text">
        <div v-if="text && text.label">
          <router-link
            :to="text.link"
            :target="text.target"
          >
            <span style="cursor: pointer">
              {{text.label}}
            </span>
          </router-link>
        </div>
      </template>
      <template slot="userNameLink" slot-scope="text">
        <div :style="{display: 'flex', justifyContent: 'space-between',}">
          <router-link
            :to="`/player/client/${text.id}`"
            target="_blank"
          >
            <span v-if="text && text.userName" style="cursor: pointer">
              {{text.userName}}
            </span>
          </router-link>
          <span v-if="text.tags">
                <a-popover v-for="(tag) in text.tags" :key="tag.id">
<!--                  <template slot="content">-->
                  <!--                    <p>{{tag.name}}</p>-->
                  <!--                  </template>-->
                  <template slot="title">
                    <router-link
                      :to="`/admin/client-tags/${tag.id}`"
                    >
                      {{tag.name}}
                  </router-link>
                  </template>
                  <a-button shape="circle" size="small" :style="{margin: '0 6px', backgroundColor: tag.color}"/>
                </a-popover>
              </span>
        </div>
      </template>
      <template slot="pass-change" slot-scope="text, record">
        <div>
          <a-icon
            :style="{ fontSize: '20px', color: '#08c', cursor: 'pointer' }"
            type="sync"
            @click="() => openPassChangeModal(record.id)"
          />
        </div>
      </template>
      <template slot="toggle" slot-scope="text, record">
        <Switcher
          :old-value="text"
          @switcher-change="(isChecked) => onSwitcherChange(isChecked, record.id)"
        />
      </template>
      <template slot="soft-delete" slot-scope="text, record">
        <Switcher
          :old-value="!text"
          @switcher-change="(isChecked) => onSoftDelete(isChecked, record.id)"
        />
      </template>
      <template slot="radioActions" slot-scope="text, record">
        <RadioActions
          :action-desc="text"
          :row-data="record"
        />
      </template>
      <template slot="array_translatable" slot-scope="text">
      <span v-if="text">
        <span
          v-for="(item, index) in text.slice(0, 3)"
          :key="index"
        >
            <span v-if="item && getTranslation(item.translation, 'username')">{{ getTranslation(item.translation, 'username') }}</span>
            <span v-else-if="item && getTranslation(item.translation, 'name')">{{ getTranslation(item.translation, 'name') }}</span>
            <span v-else-if="item && getTranslation(item.translation, 'title')">{{ getTranslation(item.translation, 'title') }}</span>
            <span v-else>{{ item && item.id }}</span>
            <span v-if="index < 2 && text.length > index + 1">,</span>
            <span v-if="index === 2 && text.length > 3">...</span>
        </span>
      </span>
      </template>
      <template slot="array" slot-scope="text">
      <span v-if="text && text[0]">
          <span
            v-for="(item, index) in text.slice(0, 3)"
            :key="index"
          >
            <span v-if="item && item.username">{{ item.username }}</span>
            <span v-else-if="item && item.name">{{ item.name }}</span>
            <span v-else-if="item && item.title">{{ item.title }}</span>
            <span v-else-if="item && item.context">{{ item.context }}</span>
            <span v-else>{{ item && item.id }}</span>
            <span v-if="index < 2 && text.length > index + 1">,</span>
            <span v-if="index === 2 && text.length > 3">...</span>
          </span>
        </span>
      </template>
      <template slot="object" slot-scope="text">
        <span v-if="text && text.code">{{ text.code }}</span>
        <span v-else-if="text && text.username">{{ text.username }}</span>
        <span v-else-if="text && text.name">{{ text.name }}</span>
        <span v-else-if="text && text.title">{{ text.title }}</span>
        <span v-else>{{ text && text.id }}</span>
      </template>
      <template slot="input_number" slot-scope="text, record">
        <InputNumber
          :step="1"
          :old-value="text"
          @number-input-changed="(number) => numberInputChanged(number, record.id)"
        />
      </template>
    </a-table>
    <div class="pagination-wrapper">
      <a-pagination
        v-if="pagination && total > 10"
        style="margin: 25px 0"
        v-model="currentPage"
        :disabled="loading"
        :pageSizeOptions="pageSizeOptions"
        :total="total"
        :show-total="total => `Total ${total} items`"
        :page-size="Number(perPage)"
        show-size-changer
        show-quick-jumper
        showTitle
        @change="onPageChange"
        @showSizeChange="handleSizeChange"
      />
    </div>
  </div>
</template>
<script>
import ColsAndRows from '../partial/components/ColsAndRows';
import Switcher from '../partial/components/Switcher';
import InputNumber from '../partial/components/InputNumber';
import JsonViewer from '../partial/JsonViewer';
import ExpandedAsyncAntTable from './ExpandedAsyncAntTable';
import RadioActions from './partial/RadioActions';
import socketError from '../../utils/socketError';

const parser = require('cron-parser');
/* eslint-disable */
export default {
  components: {
    AntdTableActions: () => import('./partial/AntdTableActions'),
    ColsAndRows,
    Switcher,
    InputNumber,
    JsonViewer,
    ExpandedAsyncAntTable,
    RadioActions,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['columns', 'rows', 'orderParams', 'loading', 'pagination', 'pageValue', 'perPage', 'total', 'translatable', 'tableTitle', 'noRefresh', 'bulkDelete', 'searchParams', 'footerInfo', 'tableFooter', 'expandedRowQueryData', 'withoutTableHeader', 'tableSize'],
  data() {
    return {
      data: [],
      currentPage: 1,
      pageSizeOptions: ['10', '20', '50', '100', '200'],
      selectedRowKeys: [],
      moment,
      parser,
    };
  },
  computed: {
    tabTitle() {
      let title = this.$route.params.menuItem || this.$route.params.module
        || this.$route.params.moduleName || this.$route.params.report
        || this.$route.params.reportGroup || this.$route.path;
      title = title.replace(title.charAt(0), title[0].toUpperCase());
      return title;
    },
    antdRows() {
      if (this.translatable && this.localeLang !== 'default') {
        return this.rows && this.rows.map((elem, index) => {
          const customRow = {};
          this.columns && this.columns.forEach((col) => {
            if (col.translatable && col.type !== 'array_translatable') {
              customRow[col.field] = this.getTranslation(elem.translation, col.field);
            } else {
              customRow[col.field] = elem[col.field]
            }
          });
          if(elem.customActions) {
            customRow.customActions = elem.customActions;
            customRow.translation = elem.translation;
          }
          return { ...customRow, rowIndex: index };
        });
      }
      return this.rows && this.rows.map((elem, index) => ({ ...elem, rowIndex: index }));
    },
    sortedInfo() {
      let { orderParams } = this;
      orderParams = orderParams || {};
      const order = orderParams.orderBy === "DESC" && 'descend'
        || orderParams.orderBy === "ASC" && 'ascend' || false;
      const field = orderParams.column;
      return { order, field };
    },
    antdColumns() {
      let { sortedInfo } = this;
      sortedInfo = sortedInfo || {};
      return this.columns && this.columns.map((elem, ind) => {
        const column = {};
        column.title = elem.label;
        column.dataIndex = elem.field;
        column.key = ind;
        if (elem.type && elem.type !== 'text' || elem.translatable) {
          column.scopedSlots = { customRender: elem.type };
        }
        if (elem.actions) {
          column.customRender = (text, row) => (<AntdTableActions
          actions={row.customActions || elem.actions}
          downloadToken={row.download_token}
          rowData={row}
          updateTable={this.updateTable}
          rowId={row.id}
          dataRowCell={text}
          customCaseIsDeleted={row.is_deleted}
          searchParams={this.searchParams}
          type={row.type}
          />);
          // column.colSpan = 2;
        }
        if (Number(elem.order)){
          column.sorter = true;
          if (sortedInfo.field === elem.field) {
            column.sortOrder = sortedInfo.order;
          }
        }
        if (elem.children) {
          column.children = this.setChildrenColumns(elem.children)
        }
        return column;
      });
    },
  },
  watch: {
    pageValue(e) {
      this.currentPage = e;
    },
  },
  mounted() {
    this.currentPage = this.pageValue || 1;
    document.onkeydown = this.checkKey;
  },
  methods: {
    checkKey(e) {
      const tableScroll = document.querySelector('.ant-table-body');
      if (e.keyCode == '37') {
        // left arrow
        tableScroll.scrollLeft = tableScroll.scrollLeft = tableScroll.scrollLeft - 100;
      }
      else if (e.keyCode == '39') {
        // right arrow
        tableScroll.scrollLeft = tableScroll.scrollLeft + 100;
      }

    },
    async openPassChangeModal(id) {
      const params = {
        id,
      };
      await this.$store.dispatch('modals/toggleChangePassModal', params);
    },
    async showDeleteConfirm() {
      this.$confirm({
        title: 'Are you sure delete selected rows?',
        content: `Id List - ${this.selectedRowKeys.join()}`,
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk: async () => {
          await axios.post(`${this.$route.path}/delete`, {
            idList: JSON.stringify(this.selectedRowKeys),
          });
          await this.updateTable();
        },
      });
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    tableSizeChange() {
      this.tableSize = this.tableSize === 'default'
        ? this.tableSize = 'middle' : this.tableSize === 'middle'
          ? this.tableSize = 'small' : 'default';
    },
    async updateTable() {
      await this.$emit('update-table');
    },
    async handleSizeChange(current, size) {
      if (size) {
        localStorage.setItem('tablePerPage', size);
        if (!this.$route.query.page || this.$route.query.page !== '1') {
          this.onPageChange(1);
        }
        await this.$router.push({ query: { ...this.$route.query, perPage: size } });
        this.$emit('per-page-select-change', size);
      }
    },
    onImageBlankClick(src) {
      if (src) {
        window.open(src, '_blank');
      }
    },
    onPageChange(e) {
      this.currentPage = e;
      this.$nextTick(async() => {
        await this.$router.push({ query: { ...this.$route.query, page: `${e}` } });
        this.$emit('on-page-change', e);
      });
    },
    handleTableChange(pagination, filters, sorter) {
      if (sorter.field !== this.sortedInfo.field || sorter.order !== this.sortedInfo.order) {
        const orderBy = sorter.order === 'descend' && "DESC"
          || sorter.order === 'ascend' && "ASC" || '';
        const column = sorter.field;
        this.$emit('search-by-order', { column, orderBy });
      }
    },
    async numberInputChanged(number, rowId) {
      try {
        const res = await axios.get(`${this.$route.path}/reorder/${rowId}`, {
          params: {
            order: number,
          },
        });
        return res.data;
      } catch (e) {
        socketError({
        }, e);
        return false;
      }
    },
    async onSoftDelete(isChecked, rowId) {
      try {
        const res = await axios.post(`${this.$route.path}/${rowId}/soft-delete`, {
          enabled: Number(isChecked)
        });
        this.$emit('update-table');
        return res.data;
      } catch (e) {
        socketError({
        }, e);
        return false;
      }
    },
    async onSwitcherChange(isChecked, rowId) {
      try {
        const res = await axios.get(`${this.$route.path}/${rowId}/enable`, {
          params: {
            enabled: Number(isChecked),
          },
        });
        this.$emit('update-table');
        return res.data;
      } catch (e) {
        socketError({
        }, e);
        return false;
      }
    },
    async colsAndRowsChange(selectedType, rowId) {
      try {
        const res = await axios.get(`${this.$route.path}/set-dimension/${rowId}`, {
          params: {
            ...selectedType,
          },
        });
        return res.data;
      } catch (e) {
        socketError({
        }, e);
        return false;
      }
    },
    setChildrenColumns(columns) {
      let { sortedInfo } = this;
      sortedInfo = sortedInfo || {};
      return columns.map((elem, index) => {
        const column = {};
        column.title = elem.label;
        column.dataIndex = elem.field;
        column.key = elem.field + index;
        if (elem.type && elem.type !== 'text' || elem.translatable) {
          column.scopedSlots = { customRender: elem.type };
        }
        if (elem.actions) {
          column.customRender = (text, row) => (<AntdTableActions
            actions={row.customActions || elem.actions}
            rowData={row}
            updateTable={this.updateTable}
            rowId={row.id}
            dataRowCell={text}
            customCaseIsDeleted={row.is_deleted}
            searchParams={this.searchParams}
            type={row.type}
          />);
          // column.colSpan = 2;
        }
        if (Number(elem.order)){
          column.sorter = true;
          if (sortedInfo.field === elem.field) {
            column.sortOrder = sortedInfo.order;
          }
        }
        if (elem.children) {
          column.children = this.setChildrenColumns(elem.children)
        }
        return column;
      })
    },
    // handleTableChange(pagination, filters, sorter) {
    //   console.log(pagination);
    //   const pager = { ...this.pagination };
    //   pager.current = pagination.current;
    //   this.pagination = pager;
    // this.fetch({
    //   results: pagination.pageSize,
    //   page: pagination.current,
    //   sortField: sorter.field,
    //   sortOrder: sorter.order,
    //   ...filters,
    // });
    // },
    // fetch(params = {}) {
    //   console.log('params:', params);
    //   this.loading = true;
    //   reqwest({
    //     url: 'https://randomuser.me/api',
    //     method: 'get',
    //     data: {
    //       results: 10,
    //       ...params,
    //     },
    //     type: 'json',
    //   }).then(data => {
    //     const pagination = { ...this.pagination };
    //     // Read total count from server
    //     // pagination.total = data.totalCount;
    //     pagination.total = 200;
    //     this.loading = false;
    //     this.data = data.results;
    //     this.pagination = pagination;
    //   });
    // },
  },
};
</script>

<style lang="scss">
.table-wrapper {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 16px 24px 16px;
  &.scrollable {
    .ant-table-body {
      overflow-x: auto!important;
      overflow-y: auto;
      max-height: 900px;
    }
  }
  .ant-table-wrapper {
    .ant-table-title > div {
      display: flex;
      justify-content: space-between;

      .table-title {
        color: rgba(0, 0, 0, 0.85);
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
      }

      .title-action-wrapper {
        display: flex;
        align-items: center;

        i {
          color: rgba(0, 0, 0, 0.85);
          margin: 0 4px 0 12px;
        }
      }
    }

    thead[class*="ant-table-thead"] {
      tr {
        position: sticky;
        top: 0;
        z-index: 1;
      }

      th {
        background: #FAFAFA;
        box-shadow: -18px 0px 0px -17px rgba(0, 0, 0, 0.06);
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        font-family: Roboto, sans-serif;
      }
    }
    .ant-table .ant-table-tbody td > div {
      white-space: nowrap
    }
    .badge-icon-wrapper {
      font-size: 18px;
    }
  }
  .pagination-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .ant-table-footer {
    font-size: 16px;
    text-align: left;
    color: rgba(0, 0, 0, 0.75);
    font-style: italic;
  }
}
</style>
