<!-- eslint-disable -->
<template>
  <a-row class="view-item">
    <a-list-item-meta :span="6" :description="label">
      {{ label }}
    </a-list-item-meta>
    <a-col :span="18" v-if="type === 'hourFormat'">
      <span>
        {{ resValue && isNaN(resValue) ? moment(resValue).format('YYYY-MM-DD, HH:mm:ss') :
          (resValue && resValue.length > 10 && moment(JSON.parse(resValue)).format('YYYY-MM-DD, HH:mm:ss')
            || (resValue && moment(JSON.parse(resValue) * 1000).format('YYYY-MM-DD, HH:mm:ss')))
          || null
        }}
      </span>
    </a-col>
    <a-col :span="18" v-if="type === 'date'">
      <span>
      {{ resValue && isNaN(resValue) ? moment(resValue).format('LL') :
        (resValue && resValue.length > 10 && moment(JSON.parse(resValue)).format('LL')
          || (resValue && moment(JSON.parse(resValue) * 1000).format('LL')))
        || null
      }}
    </span>
    </a-col>
    <a-col :span="18" v-if="type === 'dateFormat'">
      <span>
        {{ resValue && isNaN(resValue) ? moment(resValue).format('YYYY-MM-DD') :
          (resValue && resValue.length > 10 && moment(JSON.parse(resValue)).format('YYYY-MM-DD')
            || (resValue && moment(JSON.parse(resValue) * 1000).format('YYYY-MM-DD')))
          || null
        }}
      </span>
    </a-col>
    <a-col :span="18" v-if="type === 'editor'">
      <span class="value" v-html="resValue" />
    </a-col>
    <a-col :span="18" v-if="type === 'text' || type === ''">
      <span>{{ resValue }}</span>
    </a-col>
    <a-col :span="18" v-if="type === 'image'">
      <img
        v-if="resValue"
        v-img
        :alt="label"
        :src="resValue"
        width="50px"
      />
    </a-col>
    <a-col :span="18" v-if="type === 'badge'" class="badge">
      <span>
          <i v-if="!resValue || resValue==='No'" class="fa fa-times" aria-hidden="true" />
          <i v-if="resValue == 1 || resValue==='Yes'"
             class="fas fa-check-square"
             aria-hidden="true"
          />
        </span>
    </a-col>
    <a-col :span="18" v-if="type === 'toggle'" class="custom-control custom-switch">
      <span class="value">
          <input id="customSwitch1" type="checkbox" class="custom-control-input">
          {{ resValue }}
      </span>
    </a-col>
    <a-col :span="18" v-if="type === 'link'">
      <span class="value">
        <a :href="`/${resValue}` || ''">{{ resValue }}</a>
      </span>
    </a-col>
    <a-col :span="18" v-if="type === 'array'" class="array-type">
      <span v-if="resValue[0]">
          <span
            v-for="(item, index) in resValue"
            :key="index"
          >
            <span v-if="item && item.name">{{ item.name }}</span>
            <span v-else-if="item && item.username">{{ item.username }}</span>
            <span v-else-if="item && item.title">{{ item.title }}</span>
            <span v-else>{{ item && item.id }}</span>
            <span v-if="index < 2 && resValue.length > index + 1">, </span>
          </span>
        </span>
    </a-col>
    <a-col :span="18" v-if="type === 'object'">
      <span class="value">
          <span v-if="label === 'Currency'">{{ resValue && resValue.code }}</span>
          <span v-else-if="resValue && resValue.username">{{ resValue.username }}</span>
          <span v-else-if="resValue && resValue.name">{{ resValue.name }}</span>
          <span v-else-if="resValue && resValue.title">{{ resValue.title }}</span>
          <span v-else>{{ resValue && resValue.id }}</span>
        </span>
    </a-col>
    <a-col :span="18" v-if="type === 'input_number'">
      <span v-if="resValue[0]">
          <input type="number" :value="resValue || 0">
      </span>
    </a-col>
    <a-col :span="18" v-if="type === 'jsonViewer'">
      <a-popover :title="label" trigger="click" :overlayStyle="{ 'max-width': '60%',  'min-width': '30%'}">
        <div slot="content">
          <JsonViewer
            :json="resValue"
          />
        </div>
        <a-button>
          Show Json
        </a-button>
      </a-popover>
    </a-col>
  </a-row>
</template>

<script>
import JsonViewer from './JsonViewer';

export default {
  components: {
    JsonViewer,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['label', 'contentValue', 'type', 'translatable', 'translation', 'fieldName'],
  data() {
    return {
      isLoading: false,
      moment,
    };
  },
  computed: {
    resValue() {
      return this.translatable && this.localeLang !== 'default'
        ? (this.translation && this.translation[this.localeLang]
        && this.getTranslation(this.translation, this.fieldName, this.localeLang)) || ''
        : this.contentValue;
    },
  },
};
</script>

<style scoped lang="scss">
.view-item{
  display: flex;
  width: 100%;
  text-align: left;
  .badge{
    text-align: left;
    .fa-times{
      font-size: 20px;
      color: red;
    }
    .fa-check-square{
      font-size: 20px;
      color: green;
    }
  }
}
</style>
