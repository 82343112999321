<template>
  <div class="downLoad">
    <a-dropdown>
      <a-menu slot="overlay" @click="handleMenuClick">
        <a-menu-item key="xlsx">
          <a-icon type="file" />
          Xlsx
        </a-menu-item>
        <a-menu-item key="csv">
          <a-icon type="file" />
          Csv
        </a-menu-item>
      </a-menu>
      <a-button
        type="primary"
        :loading="isLoading"
        icon="download"
      >
        {{ isLoading ? 'Loading' : 'Download' }}
      </a-button>
    </a-dropdown>
    <!--    <a ref="myDownLoadFile"-->
    <!--       download-->
    <!--       :href="filePath"-->
    <!--    />-->
  </div>
</template>

<script>
// import socketError from '../../utils/socketError';
import path from 'path';
// import querystring from 'querystring';
const FileDownload = require('js-file-download');

export default {
  props: {
    downLoadUrl: {
      type: String,
      default: '',
    },
    token: {
      type: String,
      default: '',
    },
    searchParams: {
      type: Object,
      default() {
        return {};
      },
    },
    totalCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      path,
      isLoading: false,
      filePath: '',
      axios,
    };
  },
  methods: {
    getFilePath(downloadFormat) {
      this.isLoading = true;
      const selectedSiteId = localStorage.getItem('selectedPartner');
      const searchParams = new window.URLSearchParams(this.searchParams).toString();
      this.filePath = `${process.env.VUE_APP_API_URL}${this.$route.path}-download-link?download_token=${this.token}&notFormPage=true&jwtToken=${localStorage.getItem('token')}&${searchParams}&downloadFormat=${downloadFormat}&selectedSiteId=${selectedSiteId}`;
      const url = `${process.env.VUE_APP_API_URL}${this.$route.path}-download-link`;
      axios({
        url: this.downLoadUrl || url,
        method: 'GET',
        params: {
          download_token: this.token,
          notFormPage: true,
          jwtToken: localStorage.getItem('token'),
          downloadFormat,
          selectedSiteId,
          ...this.searchParams,
        },
      }).then((res) => {
        try {
          const response = JSON.parse(res.request.response);
          if (response.success) {
            this.openNotificationWithIcon('success', response.message);
          }
        } catch {
          FileDownload(res.request.response, res.headers.filename);
        }
        // const response = JSON.parse(res.request.response);
        // console.log('response', response);
        // if (response.success) {
        //   this.openNotificationWithIcon('success', response.message);
        // }
      });
      // if (this.totalCount > 5000) {
      //   const url = this.filePath;
      //   axios({
      //     url,
      //     method: 'GET',
      //   }).then((res) => {
      //     const response = JSON.parse(res.request.response);
      //
      //     if (response.success) {
      //       this.openNotificationWithIcon('success', response.message);
      //     }
      //   });
      // } else {
      //   this.$nextTick(() => {
      //     const { myDownLoadFile } = this.$refs;
      //     myDownLoadFile.click();
      //   });
      // }
      // this.asd(this.filePath);
      this.isLoading = false;
    },
    openNotificationWithIcon(type, message, duration = 4) {
      this.$notification[type]({
        message: type,
        top: '20%',
        duration,
        description: message,
      });
    },
    handleMenuClick(menuItem) {
      this.getFilePath(menuItem.key);
    },
    // asd(url) {
    //   axios({
    //     url,
    //     method: 'GET',
    //     responseType: 'blob', // Important
    //   }).then((res) => {
    //     console.log(res);
    //     // const req = res.request;
    //     console.log(res.json());
    //   });
    // },
    // getFilePath() {
    //   this.isLoading = true;
    //   try {
    // const res = await axios.get(`${this.$route.path}-download-link`, {
    //   params: this.searchParams,
    // });
    // const relationPath = path.join(this.$route.path, 'relation', this.relation);
    // axios({
    //   url: `${this.$route.path}-download-link`,
    //   method: 'GET',
    //   responseType: 'blob',
    // }).then((response) => {
    //   console.log('response', response);
    //   const fileURL = window.URL.createObjectURL(new Blob([response.data]));
    //   const fileLink = document.createElement('a');
    //
    //   fileLink.href = fileURL;
    //
    //   fileLink.setAttribute('download', 'file.csv');
    //
    //   document.body.appendChild(fileLink);
    //   fileLink.click();
    // });
    //
    //
    // this.filePath = res.data.download_url;
    // this.$nextTick(() => {
    //   const { myDownLoadFile } = this.$refs;
    //   myDownLoadFile.click();
    // });
    //

    // this.filePath = res.data.download_url;
    // this.$nextTick(() => {
    //   const { myDownLoadFile } = this.$refs;
    //   myDownLoadFile.click();
    // });
    // this.isLoading = false;
    // return true;
    //   } catch (e) {
    //     this.isLoading = false;
    //     // socketError(e);
    //     return false;
    //   }
    // },
  },
};
</script>

<style lang="scss">
.downLoad{
  text-align: right;
  .ant-btn-primary{
    i{
      color: white!important;
      margin: 0!important;
    }
  }
}
</style>
