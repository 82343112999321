<template>
  <div class="dashboard-wrapper">
    <a-spin :spinning="loading">
      <div class="dashboard">
        <div class="page-title">
          Dashboard
        </div>
        <div v-if="loading"
             style="background: white;margin-top: 20px;
             border-radius: 8px; height: 100vh;padding-top: 50px"
        >
          <h1>
            Loading...
          </h1>
        </div>
        <div v-else>
          <div v-if="!widgetList || !widgetList[0]" class="permission-note">
            You don’t have appropriate permissions to see the dashboard!!!
          </div>
          <a-row
            v-else
            :gutter="24"
            class="widget-wrapper"
          >
            <NewWidget
              v-for="(widgetName,key) in widgetList"
              :key="key + $route.path"
              :title="widgetName"
              :ind="key+1"
              :has-chart="chartList.includes(widgetName)"
              :preset-list="presetList"
            />
          </a-row>
        </div>
      </div>
      <div
        v-if="!loading && widgetList && widgetList[0] && dashboardMessage"
        class="notification"
      >
        <p>{{ dashboardMessage }}</p>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import NewWidget from '../components/NewWidget';

export default {
  components: {
    NewWidget,
  },
  data() {
    return {
      presetList: null,
    };
  },
  computed: {
    ...mapGetters({
      loading: 'dashboard/loading',
      // widgetList: 'dashboard/widgetList',
      dashboardMessage: 'dashboard/dashboardMessage',
      chartList: 'dashboard/chartList',
    }),
    ...mapState({
      widgetList(state) {
        return state.dashboard.widgetList[this.$route.path.substring(1) || 'dashboard'];
      },
      dashboardMessage(state) {
        return state.dashboard.dashboardMessage[this.$route.path.substring(1) || 'dashboard'];
      },
    }),
  },
  watch: {
    async $route() {
      this.$store.commit('dashboard/setLoading', true);
      if (!this.widgetList) {
        await this.getWidgetList();
      }
      this.getPresetList();
    },
  },
  async mounted() {
    this.$store.commit('dashboard/setLoading', true);
    if (!this.widgetList) {
      await this.getWidgetList();
    }
    this.getPresetList();
  },
  methods: {
    ...mapActions({
      getWidgetList: 'dashboard/getWidgetList',
    }),
    async getPresetList() {
      const presets = await this.$store.dispatch('dashboard/getPresetList');
      this.presetList = _.mapValues(presets, (o) => ([
        o.start_date.valueOf(),
        o.end_date.valueOf(),
      ]));
      this.$store.commit('dashboard/setLoading', false);
    },
  },
};
</script>
<style lang="scss">

.dashboard-wrapper {
  .page-title {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    text-align: left;
  }

  .dashboard {
    padding: 24px 24px;
  }

  .permission-note {
    text-align: left;
    padding-left: 8px;
    padding-top: 12px;
    margin-top: 15px;
    background: white;
    border: 1px solid #e3e3e3;
    height: 20vh;
    border-radius: 8px;
    color: #95231b;
  }

  .notification {
    border: 1px solid #e3e3e3;
    border-radius: 6px;
    text-align: left;
    padding: 8px 12px;
    margin: 0 24px;
    background: white;
  }
}
</style>
