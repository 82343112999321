<template>
  <div class="sport-report-page">
    <SportCollapsePage
      :sport-report-columns="sportReportColumns"
      :sport-report-reports="sportReportReports"
      :sport-report-name="sportReportName"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SportCollapsePage from './SportCollapsePage';

export default {
  components: { SportCollapsePage },
  data() {
    return {
      orderState: {
        orderBy: 'ASC',
        filed: '',
      },
    };
  },
  computed: {
    ...mapState({
      sportReportColumns(state) {
        return state.reports.sportReportInfo.columns;
      },
      sportReportReports(state) {
        return state.reports.sportReportInfo.reports;
      },
      sportReportName(state) {
        return state.reports.sportReportInfo.reports_name;
      },
    }),
  },
  mounted() {
    this.getSportReportInfo();
  },
  methods: {
    async getSportReportInfo() {
      const params = {
        path: this.$route.path,
      };
      await this.$store.dispatch('reports/getSportReportInfo', params);
    },
  },
};
</script>

<style scoped lang="scss">
.sport-report-page{
  text-align: left;
  .ant-collapse-item-active{
    .header-wrapper{
      >span{
        font-weight: bold;
      }
    }
  }
  .header-wrapper{
    display: flex;
    align-items: center;
    >span{
      min-width: 150px;
    }
  }
}
</style>
