<template>
  <span>{{ ranges }}</span>
</template>

<script>
export default {
  name: 'DateRangePickerInput',
  props: {
    ranges: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
