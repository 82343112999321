import axios from 'axios';
import Vue from 'vue';
import socketError from '../utils/socketError';

export default {
  namespaced: true,

  state: {
    loading: false,
    moduleInfo: {},
    formModules: {},
    moduleViewById: {},
    editJsonById: {},
    formCreateJson: {},
    isFormModalOpen: false,
    isEditFormModalOpen: false,
    betShops: {},
    clientInfo: {},
    dragableOrderModule: {},
    internalMessages: {},
    deleteModalInfo: {
      isOpen: false,
      info: {},
    },
  },
  mutations: {
    setInternalMessages(state, { data, deleteOutBoxFromIntMess }) {
      const newValue = { ...state.internalMessages, ...data };
      if (deleteOutBoxFromIntMess && newValue.outbox) {
        delete newValue.outbox;
      }
      Vue.set(state, 'internalMessages', newValue);
    },
    setBetShopsInfo(state, { key, value }) {
      const initialData = state.betShops[key] || {};
      const data = { ...initialData, [value.type]: value.data };
      Vue.set(state.betShops, key, data);
    },
    setClientVerificationInfo(state, { key, value }) {
      Vue.set(state.clientInfo, key, value);
    },
    toggleFormModal(state, action) {
      if (action) {
        state.isFormModalOpen = action;
      } else {
        state.isFormModalOpen = !state.isFormModalOpen;
      }
    },
    setFormCreateJson(state, { data }) {
      Vue.set(state.formCreateJson, 'formData', data);
    },
    setEditJsonById(state, { key, value }) {
      Vue.set(state.editJsonById, key, value);
    },
    deleteEditFormJson(state) {
      Vue.set(state, 'editJsonById', {});
    },
    deleteCreateFormJson(state) {
      Vue.set(state, 'formCreateJson', {});
    },
    setDeleteModalInfo(state, { params }) {
      state.deleteModalInfo = params;
    },
    setModuleById(state, { key, value }) {
      Vue.set(state.moduleViewById, key, value);
    },
    setDragableOrderModule(state, { data, type, moduleName }) {
      if (!state.dragableOrderModule[moduleName]) {
        const info = {
          tabs: data.tabs,
        };
        Vue.set(state.dragableOrderModule, moduleName, info);
      }
      Vue.set(state.dragableOrderModule[moduleName], type, { results: data.results });
    },
    setModuleTotalBlock(state, { key, value }) {
      const data = { ...value };
      data.results = [data.results];
      Vue.set(state.moduleInfo, key, data);
    },
    setSecondWithdrawRequest(state, { value }) {
      const newData = state.moduleInfo['withdraw-requests'].results.data.map((row) => {
        const addedData = value[row.client.id];
        return { ...row, ...addedData };
      });
      Vue.set(state.moduleInfo['withdraw-requests'].results, 'data', newData);
    },
    setSecondCashLogsRequest(state, { value }) {
      const oldData = _.clone(state.moduleInfo['cash-logs'].results.data);
      const newData = [...oldData, ...value];
      Vue.set(state.moduleInfo['cash-logs'].results, 'data', newData);
    },
    setGameModule(state, { key, value }) {
      const data = _.clone(value);
      if (!value.type) {
        data.results.data = value.results.data.map((elem) => {
          const row = _.clone(elem);
          if (row.last_updated_date) {
            row.last_updated_date = moment(row.last_updated_date * 1000).format('LLLL');
          }
          return row;
        });
      }
      Vue.set(state.moduleInfo, key, data);
    },
    setFormModuleData(state, { key, value }) {
      Vue.set(state.formModules, key, value);
    },
  },
  actions: {
    deleteEditFormJson({ commit }) {
      commit('deleteEditFormJson');
    },
    deleteCreateFormJson({ commit }) {
      commit('deleteCreateFormJson');
    },
    toggleFormModal({ commit }, action) {
      commit('toggleFormModal', action);
    },
    toggleDeleteModal({ commit }, params) {
      commit('setDeleteModalInfo', params);
    },

    async getDragableOrderModule({ dispatch, commit }, { type, moduleName, path }) {
      try {
        const res = await axios.get(path, {
          params: {
            // vendorSegment: type,
            segment: type,
          },
        });
        commit('setDragableOrderModule', { data: res.data, type, moduleName });
        return res.data;
      } catch (e) {
        socketError({ dispatch }, e);
        return false;
      }
    },
    async softDeleteById({ dispatch }, {
      path,
      id,
      form,
    }) {
      try {
        const res = await axios.post(`${path}/${id}/soft-delete`, {
          ...form,
        });
        return res.data;
      } catch (e) {
        socketError({
          dispatch,
        }, e);
        return false;
      }
    },
    async deleteSelectedUser({ dispatch }, { path, id }) {
      try {
        const res = await axios.get(`${path}/${id}/destroy`);
        return res.data;
      } catch (e) {
        socketError({
          dispatch,
        }, e);
        return false;
      }
    },
    async submitReject({ dispatch }, {
      path, id, formData, action,
    }) {
      try {
        const res = await axios.get(`${path}/${id}/${action}`, {
          params: {
            ...formData,
          },
        });
        return res.data;
      } catch (e) {
        socketError({
          dispatch,
        }, e);
        return false;
      }
    },
    async sendDragableOrderModuleIdes({ dispatch }, { sortedIdes, type, path }) {
      try {
        // 'admin/reorder-categories'
        const res = await axios.post(`${path}/reorder`, {
          orders: JSON.stringify(sortedIdes),
          // vendorSegment: type,
          segment: type,
        });
        const data = _.clone(res.data);
        return data;
      } catch (e) {
        socketError({
          dispatch,
        }, e);
        return false;
      }
    },
    async createFormPage({ dispatch, commit }, {
      path, params = {},
    }) {
      try {
        const res = await axios.get(`${path}`, { params });
        const data = _.clone(res.data);
        commit('setFormCreateJson', { data });
        return data;
      } catch (e) {
        socketError({
          dispatch,
          commit,
        }, e);
        return false;
      }
    },
    async updateModuleById({ dispatch, commit }, {
      path, id, moduleName, action, formData,
    }) {
      try {
        const res = await axios.post(`${path}${moduleName}/${id}/${action}`, formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        return res.data;
      } catch (e) {
        socketError({
          dispatch,
          commit,
        }, e);
        return false;
      }
    },
    async getBetShopsInfo({ dispatch, commit }, {
      path, id, type, orderParams,
    }) {
      try {
        const res = await axios.get(`${path}`, {
          params: {
            type,
            orderBy: orderParams.orderBy || 'ASC',
            column: orderParams.column || '',
          },
        });
        const data = _.clone(res.data);
        data.ordering = orderParams || {};
        commit('setBetShopsInfo', { key: id, value: { data, type } });
        return res.data;
      } catch (e) {
        socketError({
          dispatch,
          commit,
        }, e);
        return false;
      }
    },
    async getClientVerificationInfo({ dispatch, commit }, {
      path, id, type,
    }) {
      try {
        const res = await axios.get(`${path}`, {
          params: {
            type,
          },
        });
        commit('setClientVerificationInfo', { key: id, value: { [type]: res.data } });
        return res.data;
      } catch (e) {
        socketError({
          dispatch,
          commit,
        }, e);
        return false;
      }
    },
    async editModuleById({ dispatch, commit }, {
      path, id, moduleName, action,
    }) {
      try {
        const res = await axios.get(`${path}${moduleName}/${id}/${action}`);
        const data = _.clone(res.data);
        commit('setEditJsonById', { key: id, value: data });
        return data;
      } catch (e) {
        socketError({
          dispatch,
          commit,
        }, e);
        return false;
      }
    },
    async secondCashLogsRequest({ commit }, params) {
      const cashLogs = new Promise((resolve) => {
        resolve(
          axios.get('/admin/cash-logs/cash-backs', { params }).then((res) => {
            if (res.data && res.data.results) {
              commit('setSecondCashLogsRequest', { value: res.data.results.data });
            }
          }),
        );
      });
      const corrections = new Promise((resolve) => {
        resolve(
          axios.get('/admin/cash-logs/corrections', { params }).then((res) => {
            if (res.data && res.data.results) {
              commit('setSecondCashLogsRequest', { value: res.data.results.data });
            }
          }),
        );
      });
      const myPromises = [cashLogs, corrections];
      await Promise.all(myPromises);
    },
    async secondWithdrawRequest({ dispatch, commit }, clientIdes) {
      try {
        const res = await axios.get('/admin/withdraw-requests/get-balances', {
          params: {
            data: JSON.stringify(clientIdes),
          },
        });
        if (res.data.message === 'success') {
          commit('setSecondWithdrawRequest', { value: res.data.data });
        }
        return res.data;
        // eslint-disable-next-line no-unreachable
      } catch (e) {
        socketError({
          dispatch,
          commit,
        }, e);
        return false;
      }
    },
    async getModuleTotalBlock({ dispatch, commit }, { moduleName }) {
      try {
        const res = await axios.get(`admin/${moduleName}/totals`);
        commit('setModuleTotalBlock', { key: `${moduleName}-total`, value: res.data.total });
        return res.data;
      } catch (e) {
        socketError({
          dispatch,
          commit,
        }, e);
        return false;
      }
    },
    async getFormModuleData({ dispatch, commit },
      {
        path, moduleName,
      }) {
      try {
        const res = await axios.get(path);
        const data = _.clone(res.data);
        commit('setFormModuleData', { key: moduleName, value: data });
        return data;
      } catch (e) {
        socketError({
          dispatch,
          commit,
        }, e);
        return false;
      }
    },
    async getModuleById({ dispatch, commit }, {
      path, id, orderParams, filterFormData, page, perPage, pageType, moduleName, currency,
    }) {
      try {
        let params = {};
        if (pageType === 'paginated_table_view') {
          params = {
            page,
            perPage,
            orderBy: orderParams.orderBy || 'ASC',
            column: orderParams.column || '',
            ...filterFormData,
          };
          if (currency) {
            params.currency = currency;
          }
        }
        const res = await axios.get(`${path}`, {
          params,
        });
        const data = _.clone(res.data);
        if (pageType === 'paginated_table_view') {
          data.ordering = orderParams || {};
          data.filterFormData = filterFormData || {};
          if (currency) {
            data.oldSelectedCurrency = currency;
          }
        }
        commit('setModuleById', { key: id + moduleName, value: data });
        return data;
      } catch (e) {
        socketError({
          dispatch,
          commit,
        }, e);
        return false;
      }
    },
    async getInternalMessages({ dispatch, commit }, {
      path, page, orderParams, filterFormData, perPage, targetBox, deleteOutBoxFromIntMess,
    }) {
      try {
        const params = {
          page,
          perPage,
          orderBy: orderParams.orderBy || 'ASC',
          column: orderParams.column || '',
          ...filterFormData,
        };
        const res = await axios.get(`${path}`, {
          params,
        });
        const data = _.clone(res.data);
        data[targetBox].ordering = orderParams || {};
        data[targetBox].filterFormData = filterFormData || {};
        commit('setInternalMessages', { data, deleteOutBoxFromIntMess });
        return data;
      } catch (e) {
        socketError({
          dispatch,
          commit,
        }, e);
        return false;
      }
    },
    async getGameModule({ dispatch, commit },
      {
        path, moduleName, page, key, s, orderParams, rangeFiltersValues, filterFormData, perPage,
        currency,
      }) {
      try {
        const params = {
          page,
          key,
          s,
          perPage,
          orderBy: orderParams.orderBy || 'ASC',
          column: orderParams.column || '',
          ...rangeFiltersValues,
          ...filterFormData,
        };
        if (currency) {
          params.currency = currency;
        }
        const res = await axios.get(`${path}`, {
          params,
        });
        const data = _.clone(res.data);
        data.oldSelectedCurrency = currency;
        data.searchBar = { key, searchValue: s };
        data.ordering = orderParams || {};
        data.filterFormData = filterFormData || {};
        data.rangeFiltersValues = rangeFiltersValues || {};
        commit('setGameModule', { key: moduleName, value: data });
        if (path === '/admin/withdraw-requests' && res.data.results) {
          const clientIdes = res.data.results.data.map((row) => row.client.id);
          await this.dispatch('gameModule/secondWithdrawRequest', clientIdes);
        }
        if (path === '/admin/cash-logs' && res.data.results) {
          await this.dispatch('gameModule/secondCashLogsRequest', params);
        }
        return data;
      } catch (e) {
        socketError({
          dispatch,
          commit,
        }, e);
        return false;
      }
    },
  },
  getters: {
    betShops(state) {
      return state.betShops;
    },
    getClientVerificationInfo(state) {
      return state.clientInfo;
    },
    isEditFormModalOpen(state) {
      return state.isEditFormModalOpen;
    },
    isFormModalOpen(state) {
      return state.isFormModalOpen;
    },
    formCreateJson(state) {
      return state.formCreateJson;
    },
    editJsonById(state) {
      return state.editJsonById;
    },
    moduleViewById(state) {
      return state.moduleViewById;
    },
    deleteModalInfo(state) {
      return state.deleteModalInfo;
    },
    loading(state) {
      return state.loading;
    },
    moduleInfo(state) {
      return state.playerInfo;
    },
  },
};
