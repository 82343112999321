import axios from 'axios';
import Vue from 'vue';
import socketError from '../utils/socketError';

export default {
  namespaced: true,

  state: {
    loading: false,
    playerInfo: {},
    exactClientInfo: {},
    exactUserWantedDetail: {},
    clientCommentView: {},
    isEditFormModalOpen: false,
    editJsonById: {},
    topWins: {},
    smsTextArea: '',
  },
  mutations: {
    destroyClientListFromVuex(state, { key }) {
      // console.log('destroyClientListFromVuex');
      Vue.delete(state.playerInfo, key);
      // console.log(state, key);
    },
    deleteEditFormJson(state) {
      Vue.set(state, 'editJsonById', {});
    },
    setEditJsonById(state, { key, value }) {
      Vue.set(state.editJsonById, key, value);
    },
    setSmsTextArea(state, { value }) {
      state.smsTextArea = value;
    },
    addToSmsTextArea(state, { value }) {
      if (value === 'resetSmsTextArea') {
        state.smsTextArea = '';
        return;
      }
      if (state.smsTextArea.indexOf(value) !== -1) {
        return;
      }
      if (state.smsTextArea.length) {
        state.smsTextArea = state.smsTextArea + ',' + value;
      } else {
        state.smsTextArea += value;
      }
    },
    setExactUserWantedDetails(state, { key, value }) {
      Vue.set(state.exactClientInfo, key, value);
    },
    setClientCommentsById(state, { key, value }) {
      Vue.set(state.clientCommentView, key, value);
    },
    setExactClientInfo(state, { key, data, menuItem }) {
      const value = { ...state.exactClientInfo[key] };
      if (!state.exactClientInfo[key] || !state.exactClientInfo[key].menu) {
        value.menu = data.menu;
      }
      value[menuItem] = data;
      Vue.set(state.exactClientInfo, key, value);
    },
    setTopWins(state, { key, value }) {
      Vue.set(state.topWins, key, value);
    },
    setCollapseTableSectionInfo(state, {
      key, value, menuItem, moduleName,
    }) {
      const data = { ...state.exactClientInfo[key] };
      data[menuItem] = { ...data[menuItem], [moduleName]: value };
      Vue.set(state.exactClientInfo, key, data);
    },
    setPlayerInfo(state, { key, value }) {
      const newVal = { ...value };
      // console.log(newVal);
      function setRows() {
        newVal.player_module.data = value.player_module.data.map((elem) => {
          const oneRow = {};
          _.forIn(elem, (val, subKey) => {
            if (subKey === 'created_at' || subKey === 'updated_at') {
              oneRow[subKey] = moment(val * 1000).format('LLLL');
            } else {
              oneRow[subKey] = val;
            }
          });
          return oneRow;
        });
      }
      function setColumns() {
        const columns = [...value.columns];
        newVal.columns = columns.sort((a, b) => {
          if (a.label.toLowerCase().indexOf('id') !== -1 || a.label.toLowerCase().indexOf('username') !== -1) {
            return -1;
          }
          if (b.label.toLowerCase().indexOf('id') !== -1 || b.label.toLowerCase().indexOf('username') !== -1) {
            return 1;
          }
          return 0;
        });
      }
      setColumns();
      setRows();
      // console.log('newVal', newVal);
      Vue.set(state.playerInfo, key, newVal);
    },
  },
  actions: {
    // eslint-disable-next-line no-dupe-args
    addToSmsTextArea({ commit }, text) {
      commit('addToSmsTextArea', { value: text });
    },
    deleteEditFormJson({ commit }) {
      commit('deleteEditFormJson');
    },
    smsTextAreaHandler({ commit }, text) {
      commit('setSmsTextArea', { value: text });
    },
    destroyClientListFromVuex({ commit }, { key }) {
      // console.log(key);
      commit('destroyClientListFromVuex', { key });
    },
    destroyExactClientFromVuex({ commit }, { key }) {
      commit('destroyExactClientFromVuex', { key });
    },
    async softDeleteById({
      dispatch,
      commit,
    },
    {
      path,
      id,
      form,
    }) {
      try {
        const res = await axios.post(`${path}/${id}/soft-delete`, {
          ...form,
        });
        return res.data;
      } catch (e) {
        socketError({
          dispatch,
          commit,
        }, e);
        return false;
      }
    },
    async getExactUserWantedDetails({
      dispatch,
      commit,
    },
    {
      path,
      // detail,
    }) {
      try {
        const res = await axios.get(`${path}`);
        const data = _.clone(res.data);
        // console.log(data);
        // commit('setExactUserWantedDetails', { key: detail, value: data });
        return data;
      } catch (e) {
        // commit('setPlayerStates', { playerName: key, isLoading: false, error: true });
        socketError({
          dispatch,
          commit,
        }, e);
        return false;
      }
    },
    async deleteById({
      dispatch,
      commit,
    },
    {
      path,
      id,
    }) {
      try {
        const res = await axios.post(`${path}/${id}/destroy`);
        return res.data;
      } catch (e) {
        socketError({
          dispatch,
          commit,
        }, e);
        return false;
      }
    },
    async updatePlayerModuleById({
      dispatch,
      commit,
    }, {
      path,
      id,
      action,
      formData,
    }) {
      try {
        const res = await axios.post(`${path}/${id}/${action}`, formData);
        return res.data;
      } catch (e) {
        socketError({
          dispatch,
          commit,
        }, e);
        return false;
      }
    },
    async editPlayerModuleById({
      dispatch,
      commit,
    }, {
      path,
      id,
      action,
    }) {
      try {
        const res = await axios.get(`${path}/${id}/${action}`);
        const data = _.clone(res.data);
        // console.log('get-edit-json', data);
        commit('setEditJsonById', {
          key: id,
          value: data,
        });
        return data;
      } catch (e) {
        socketError({
          dispatch,
          commit,
        }, e);
        return false;
      }
    },
    async getClientCommentsById({
      dispatch,
      commit,
    }, {
      path,
      id,
    }) {
      try {
        const res = await axios.get(`${path}`);
        const data = _.clone(res.data);
        // console.log('by-id-game-module', data);
        commit('setClientCommentsById', {
          key: id,
          value: data,
        });
        return data;
      } catch (e) {
        socketError({
          dispatch,
          commit,
        }, e);
        return false;
      }
    },
    async getExactClientInfo({
      dispatch,
      commit,
    },
    {
      path,
      id,
      page,
      filterFormData,
      orderParams,
      menuItem,
      perPage,
    }) {
      try {
        const params = {
          ...filterFormData,
          orderBy: orderParams.orderBy || 'ASC',
          column: orderParams.column || '',
        };
        if (page) {
          params.page = page;
        }
        if (perPage) {
          params.perPage = perPage;
        }
        const res = await axios.get(`${path}`, {
          params,
        });
        // console.log('player-exact-res-data', res.data);
        const data = _.clone(res.data);
        data.filterFormData = filterFormData;
        data.orderParams = orderParams || {};
        // console.log('setExactClientInfo', data);
        commit('setExactClientInfo', {
          key: id,
          data,
          menuItem,
        });
        return data;
      } catch (e) {
        // commit('setPlayerStates', { playerName: key, isLoading: false, error: true });
        socketError({
          dispatch,
          commit,
        }, e);
        return false;
      }
    },
    async getCollapseTableSectionInfo({ dispatch, commit }, {
      path, id, filterFormData, menuItem, moduleName,
    }) {
      try {
        const res = await axios.get(`${path}/${moduleName}`, {
          params: {
            ...filterFormData,
          },
        });
        const data = _.clone(res.data);
        data.filterFormData = filterFormData;
        commit('setCollapseTableSectionInfo', {
          key: id, value: data, menuItem, moduleName,
        });
        return res.data;
      } catch (e) {
        socketError({
          dispatch,
          commit,
        }, e);
        return false;
      }
    },
    async getTopWins({ dispatch, commit }, {
      path, id, dateRange,
    }) {
      try {
        const res = await axios.get(`${path}-top-wins`, {
          params: {
            dateRange,
          },
        });
        const data = _.clone(res.data);
        data.dateRange = dateRange;
        commit('setTopWins', { key: id, value: data });
        return res.data;
      } catch (e) {
        socketError({
          dispatch,
          commit,
        }, e);
        return false;
      }
    },
    async getPlayerInfo({ dispatch, commit },
      {
        path, page, name, perPage, searchParams, orderParams, rangeFiltersValues,
      }) {
      try {
        const res = await axios.get(`${path}`, {
          params: {
            perPage,
            column: orderParams.column || '',
            orderBy: orderParams.orderBy || 'ASC',
            page: page || 1,
            ...searchParams,
            ...rangeFiltersValues,
          },
        });
        const data = _.clone(res.data);
        data.searchParams = searchParams;
        data.rangeFiltersValues = rangeFiltersValues;
        // console.log(data.searchParams, searchParams);
        // console.log('playerInfo', data);
        commit('setPlayerInfo', { key: name, value: data });
        return data;
      } catch (e) {
        // commit('setPlayerStates', { playerName: key, isLoading: false, error: true });
        socketError({
          dispatch,
          commit,
        }, e);
        return false;
      }
    },
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    isEditFormModalOpen(state) {
      return state.isEditFormModalOpen;
    },
    clientCommentView(state) {
      return state.clientCommentView;
    },
    playerInfo(state) {
      return state.playerInfo;
    },
    topWins(state) {
      return state.topWins;
    },
    exactClientInfo(state) {
      return state.exactClientInfo;
    },
    exactUserWantedDetail(state) {
      return state.exactUserWantedDetail;
    },
    smsTextArea(state) {
      return state.smsTextArea;
    },
  },
};
