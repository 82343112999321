<template>
  <div ref="input-component" class="input-component">
    <a-form-item>
      <a-input
        ref="input"
        v-model="value"
        type="hidden"
        :value="value"
        class="form-control"
        @change="handleInputChange"
      />
    </a-form-item>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['oldValue', 'type', 'fieldName'],
  data() {
    return {
      value: '',
    };
  },
  watch: {},
  created() {},
  mounted() {
    if (this.oldValue) {
      this.$emit('input-change', { [this.fieldName]: this.oldValue });
    }
  },
  methods: {
    handleInputChange() {
      this.$emit('input-change', { [this.fieldName]: this.value });
    },
  },
};
</script>
