<template>
  <a-layout id="components-layout-demo-responsive" class="with-header-layout">
    <a-layout>
      <a-layout-header
        class="header-wrapper"
        style="background: #fff; padding: 0; text-align: left"
      >
        <div class="right-side">
          <div class="user-tools">
            <a-avatar icon="user" />
            <span class="user-name">{{ userInfo.name }}</span>
            <a-icon type="logout" @click="logout" />
          </div>
        </div>
      </a-layout-header>
      <a-layout-content
        :style="{ 'margin-bottom': '20px', minHeight: 'auto' }"
      >
        <EditWithNewPage />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import EditWithNewPage from '../views/EditWithNewPage';

export default {
  components: {
    EditWithNewPage,
  },
  data() {
    return {
      collapsed: false,
      globalCurrency: 'AMD',
    };
  },
  computed: {
    hideGlobalCurrency() {
      return process.env.VUE_APP_hideGlobalCurrency;
    },
    hideClientSearch() {
      return process.env.VUE_APP_hideClientSearch;
    },
    collapsedWidth() {
      return window.innerWidth < 1000 ? 0 : 45;
    },
    windowWidth() {
      return window.innerWidth;
    },
    ...mapGetters({
      isSidebarActive: 'sidebar/isActive',
      userInfo: 'info/userInfo',
    }),
  },
  methods: {
    ...mapActions({
      closeSidebar: 'sidebar/close',
    }),
    async logout() {
      const success = await this.$store.dispatch('auth/logout');
      if (success) {
        await this.$store.dispatch('info/clearUserInfo');
        await this.$router.go('/login');
        // await this.$router.push('/login');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.with-header-layout{
  .header-wrapper{
    display: flex;
    justify-content: flex-end;
  }
  .right-side{
    padding-right: 8px;
    .user-name{
      margin: 0 8px;
    }
  }
}
</style>
