<template>
  <div class="view-image-modal">
    <a-modal
      title="View Image"
      :visible="Boolean(info)"
      :ok-button-props="{ props: { disabled: true } }"
      :cancel-button-props="{ props: { disabled: true } }"
      @cancel="handleCancel"
    >
      <div class="image-wrapper">
        <img :src="info" alt="image">
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['info'],
  data() {
    return {
    };
  },
  methods: {
    async handleCancel() {
      await this.$store.dispatch('modals/toggleViewImageModal');
    },
  },
};
</script>
<style scoped lang="scss">
.image-wrapper{
  max-width: 200px;
  margin: auto;
  img{
    max-width: 100%;
    height: auto;
    display: block;
  }
}
</style>
