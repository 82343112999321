<template>
  <div>
    <a-modal
      title="Rejection"
      :visible="Boolean(modalFormContent)"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <FormPage
        :form-data="modalFormContent && modalFormContent.data && modalFormContent.data.fields"
        :label-col="{span: 10}"
        :wrapper-col="{span: 12}"
        @input-change="formChange"
      />
    </a-modal>
  </div>
</template>
<script>
import FormPage from '../FormPage/FormPage';

export default {
  components: { FormPage },
  // eslint-disable-next-line vue/require-prop-types
  props: ['modalFormContent'],
  data() {
    return {
      formValue: {},
      confirmLoading: false,
    };
  },
  methods: {
    formChange(e) {
      this.formValue = {
        ...this.formValue,
        ...Object.values(e)[0],
      };
    },
    async handleOk() {
      const params = {
        path: this.$route.path,
        formData: this.formValue,
        id: this.modalFormContent.id,
        action: this.modalFormContent.action,
      };
      this.confirmLoading = true;
      await this.$store.dispatch('gameModule/submitReject', params).then(async () => {
        this.$emit('update-table-info');
        await this.$store.dispatch('modals/toggleRejectModal');
      }).catch(() => {
        this.openNotificationWithIcon('error');
      });
    },
    async handleCancel() {
      await this.$store.dispatch('modals/toggleRejectModal');
    },
  },
};
</script>
