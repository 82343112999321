<template>
  <div ref="input-radio" class="input-radio">
    <a-form-item
      :validate-status="validatingStatus"
      :help="validateHelp"
      class="form-item-holder"
    >
      <template slot="label" class="radio-label">
        <span class="form-item-label-text">{{ label }}</span>
      </template>
      <a-radio-group
        v-model="picked"
        button-style="solid"
        :disabled="disabled === 'disabled'"
      >
        <a-radio-button
          v-for="(value,index) in values"
          :key="value + String(index)"
          :value="value.code"
          :disabled="(disabled && disabled.includes(value.code))
            || (optionDisableServerSide && optionDisableServerSide.includes(value.code))"
          class="radio-wrapper"
          @click="() => changeHandler(value.code)"
        >
          {{ value.label }}
        </a-radio-button>
      </a-radio-group>
    </a-form-item>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['label', 'fieldName', 'values', 'oldValue', 'validationError', 'isValidationFirstError', 'disabled', 'optionDisableServerSide'],
  data() {
    return {
      picked: '',
      validatingStatus: 'validating',
      validateHelp: '',
    };
  },
  watch: {
    optionDisableServerSide(newVal) {
      if (newVal && newVal.length && newVal.includes(this.picked)) {
        this.picked = '';
      }
    },
    isValidationFirstError(e) {
      if (e) {
        this.$refs['input-radio'].scrollIntoView();
      }
    },
    validationError(e) {
      if (e) {
        this.validatingStatus = 'error';
        // eslint-disable-next-line prefer-destructuring
        this.validateHelp = e[0];
      } else {
        this.deleteValidationErrors();
      }
    },
  },
  mounted() {
    if (this.oldValue) {
      this.picked = this.oldValue;
      this.$emit('input-change', { [this.fieldName]: this.oldValue });
    }
  },
  methods: {
    deleteValidationErrors() {
      this.validatingStatus = 'validating';
      this.validateHelp = '';
    },
    changeHandler(clickedCode) {
      if (clickedCode === this.picked) {
        this.picked = '';
        this.$emit('input-change', { [this.fieldName]: '' });
      } else {
        this.$emit('input-change', { [this.fieldName]: clickedCode });
      }
    },
  },
};
</script>

<style lang="scss">

.input-radio {
  text-align: left;

  .radio-wrapper {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
  }
}
</style>
