<template>
  <div class="form-wrapper card-body border">
    <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item v-if="isFormTranslatable" label="Language">
        <LanguageSwitcher />
      </a-form-item>
      <div v-for="(val, key) in formData"
           :key="String(key)"
           class="row"
      >
        <div
          v-if="val.type === 'checkbox' && (!val.depends_on || dependOnChecker(val.depends_on))"
          class="col-8"
        >
          <Checkbox
            :label="val.label || key"
            :field-name="key"
            :old-value="oldFormValues[key] && oldFormValues[key].value"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @input-change="inputChange"
          />
        </div>
        <div
          v-if="val.type === 'text' && (!val.depends_on || dependOnChecker(val.depends_on))"
          class="col-8"
        >
          <Input
            :label="val.label || key"
            :field-name="key"
            :old-value="oldFormValues[key] && oldFormValues[key].value"
            :translatable="val.translatable"
            :translation="oldFormValues && oldFormValues.translation"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @translation-input-change="translationInputChange"
            @input-change="inputChange"
          />
        </div>
        <div
          v-if="val.type === 'date' && (!val.depends_on || dependOnChecker(val.depends_on))"
          class="col-8"
        >
          <Input
            type="date"
            :label="val.label || key"
            :field-name="key"
            :old-value="oldFormValues[key] && oldFormValues[key].value"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @input-change="inputChange"
          />
        </div>
        <div
          v-if="val.type === 'number' && (!val.depends_on || dependOnChecker(val.depends_on))"
          class="col-8"
        >
          <Input
            type="number"
            :label="val.label || key"
            :min="$route.params.moduleName === 'cms-pages' && 0"
            :field-name="key"
            :old-value="oldFormValues[key] && oldFormValues[key].value"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @input-change="inputChange"
          />
        </div>
        <div
          v-if="val.type === 'color_picker' && (!val.depends_on || dependOnChecker(val.depends_on))"
          class="col-8"
        >
          <Input
            type="color"
            :label="val.label || key"
            :field-name="key"
            :old-value="oldFormValues[key] && oldFormValues[key].value"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @input-change="inputChange"
          />
        </div>
        <div
          v-if="val.type === 'unsigned' && (!val.depends_on || dependOnChecker(val.depends_on))"
          class="col-8"
        >
          <Input
            type="number"
            :min="0"
            :label="val.label || key"
            :field-name="key"
            :old-value="oldFormValues[key] && oldFormValues[key].value"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @input-change="inputChange"
          />
        </div>
        <div
          v-if="val.type === 'decimal' && (!val.depends_on || dependOnChecker(val.depends_on))"
          class="col-8"
        >
          <Input
            type="number"
            :step="0.01"
            :label="val.label || key"
            :field-name="key"
            :old-value="oldFormValues[key] && oldFormValues[key].value"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @input-change="inputChange"
          />
        </div>
        <div
          v-if="val.type === 'textarea' && (!val.depends_on || dependOnChecker(val.depends_on))"
          class="col-8"
        >
          <Input
            type="textarea"
            :label="val.label || key"
            :field-name="key"
            :old-value="oldFormValues[key] && oldFormValues[key].value"
            :translatable="val.translatable"
            :translation="oldFormValues && oldFormValues.translation"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @translation-input-change="translationInputChange"
            @input-change="inputChange"
          />
        </div>
        <div
          v-if="val.type === 'file' && (!val.depends_on || dependOnChecker(val.depends_on))"
          class="col-8"
        >
          <FileInput
            type="file"
            :label="val.label || key"
            :translatable="val.translatable"
            :field-name="key"
            :old-value="oldFormValues[key] && oldFormValues[key].value"
            :translation="oldFormValues && oldFormValues.translation"
            :validation-error="validationErrors[key] || validationErrors[`old_${key}_en`]
              || validationErrors[`old_${key}_am`] || validationErrors[`old_${key}_ru`]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @translation-input-change="translationInputChange"
            @input-change="inputChange"
          />
        </div>
        <div
          v-if="val.type === 'decimal_unsigned'
            && (!val.depends_on || dependOnChecker(val.depends_on))"
          class="col-8"
        >
          <Input
            type="number"
            :step="0.01"
            :label="val.label || key"
            :field-name="key"
            :old-value="oldFormValues[key] && oldFormValues[key].value"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @input-change="inputChange"
          />
        </div>
        <div
          v-if="val.type === 'unsigned_decimal'
            && (!val.depends_on || dependOnChecker(val.depends_on))"
          class="col-8"
        >
          <Input
            type="number"
            :min="0"
            step="0.01"
            :label="val.label || key"
            :field-name="key"
            :old-value="oldFormValues[key] && oldFormValues[key].value"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @input-change="inputChange"
          />
        </div>
        <div
          v-if="val.type === 'radio' && (!val.depends_on || dependOnChecker(val.depends_on))"
          class="col-8"
        >
          <Radio
            :label="val.label || key"
            :field-name="key"
            :values="val.values"
            :old-value="oldFormValues[key] && oldFormValues[key].value"
            @input-change="inputChange"
          />
        </div>
        <div
          v-if="val.type === 'select' && (!val.depends_on || dependOnChecker(val.depends_on))"
          class="col-8"
        >
          <Select
            :field-name="key"
            :label="val.label || key"
            :values="val.values"
            :relation="val.relation"
            :old-value="oldFormValues[key] && oldFormValues[key].value"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @input-change="inputChange"
          />
        </div>
        <div
          v-if="val.type === 'select_multiple'
            && (!val.depends_on || dependOnChecker(val.depends_on))"
          class="col-8"
        >
          <MultipleSelect
            :field-name="key"
            :label="val.label || key"
            :values="val.values"
            :relation="val.relation"
            :old-value="typeof (oldFormValues[key] && oldFormValues[key].value == 'string')
              ? JSON.parse(oldFormValues[key] && oldFormValues[key].value)
              :oldFormValues[key] && oldFormValues[key].value"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @input-change="inputChange"
          />
        </div>
        <div
          v-if="val.type === 'select_multiple_paginated'
            && (!val.depends_on || dependOnChecker(val.depends_on))"
          class="col-8"
        >
          <PaginatedMultipleSelect
            :field-name="key"
            :label="val.label || key"
            :relation="val.relation"
            :values="val.values"
            :old-value="typeof (oldFormValues[key] && oldFormValues[key].value) === 'string'
              ? JSON.parse(oldFormValues[key] && oldFormValues[key].value)
              :oldFormValues[key] && oldFormValues[key].value"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @input-change="inputChange"
          />
        </div>
        <div
          v-if="val.type === 'select_paginated'
            && (!val.depends_on || dependOnChecker(val.depends_on))"
          class="col-8"
        >
          <PaginatedSelect
            :field-name="key"
            :label="val.label || key"
            :relation="val.relation"
            :values="val.values"
            :old-value="typeof (oldFormValues[key] && oldFormValues[key].value) === 'string'
              ? JSON.parse(oldFormValues[key] && oldFormValues[key].value)
              :oldFormValues[key] && oldFormValues[key].value"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @input-change="inputChange"
          />
        </div>
        <div
          v-if="val.type === 'datetime' && (!val.depends_on || dependOnChecker(val.depends_on))"
          class="col-8"
        >
          <DatePickerInput
            :field-name="key"
            :label="val.label || key"
            :old-value="oldFormValues[key] && oldFormValues[key].value"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @input-change="inputChange"
          />
        </div>
        <div v-if="val.type === 'array'" class="col-8">
          <Array
            :form-data="val"
            :parent-form-value="formValue"
            :field-name="key"
            :label="val.label || key"
            :old-form-values="oldFormValues[key] && oldFormValues[key].value"
            @input-change="inputChange"
          />
        </div>
        <div v-if="val.type === 'editor'" class="col-8">
          <TinyEditor
            type="editor"
            :label="val.label || key"
            :field-name="key"
            :old-value="oldFormValues[key] && oldFormValues[key].value"
            :translatable="val.translatable"
            :translation="oldFormValues.translation"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @translation-input-change="translationInputChange"
            @input-change="inputChange"
          />
        </div>
        <div v-if="val.type === 'tag'" class="col-8">
          <Tag
            type="tag"
            :label="val.label || key"
            :field-name="key"
            :old-value="oldFormValues[key] && oldFormValues[key].value"
            :translatable="val.translatable"
            :translation="oldFormValues && oldFormValues.translation"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @translation-input-change="translationInputChange"
            @input-change="inputChange"
          />
        </div>
        <div v-if="val.type === 'phone'" class="col-8">
          <Input
            type="tel"
            :label="val.label || key"
            :field-name="key"
            :old-value="oldFormValues[key] && oldFormValues[key].value"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @input-change="inputChange"
          />
        </div>
        <div class="col-4">
          <Checkbox
            :field-name="`${key}_status`"
            :old-value="oldFormValues[key] && oldFormValues[key].status"
            @input-change="inputChange"
          />
        </div>
      </div>
      <button
        v-if="ownSubmit"
        class="btn btn-success"
        @click.prevent="$emit('form-data-submit', formValue)"
      >
        {{ submitButtonText || 'Submit' }}
      </button>
    </a-form>
  </div>
</template>

<script>
import Tag from '@/components/FormElements/Tag';
import Checkbox from '../../FormElements/Checkbox';
import Input from '../../FormElements/Input';
import Radio from '../../FormElements/Radio';
import Select from '../../FormElements/Select';
import Array from '../../FormElements/Array';
import DatePickerInput from '../../FormElements/DatePickerInput';
import MultipleSelect from '../../FormElements/MultipleSelect';
import TinyEditor from '../../FormElements/TinyEditor';
import LanguageSwitcher from '../LanguageSwitcher';
import PaginatedMultipleSelect from '../../FormElements/PaginatedMultipleSelect';
import PaginatedSelect from '../../FormElements/PaginatedSelect';
import FileInput from '../../FormElements/FileInput';

export default {
  name: 'FormPage',
  components: {
    Tag,
    Checkbox,
    Input,
    Radio,
    Select,
    Array,
    DatePickerInput,
    MultipleSelect,
    TinyEditor,
    LanguageSwitcher,
    PaginatedMultipleSelect,
    PaginatedSelect,
    FileInput,
  },
  props: {
    submitButtonText: {
      type: String,
      default: '',
    },
    validationErrors: {
      type: Object,
      default() {
        return {};
      },
    },
    oldFormValues: {
      type: Object,
      default() {
        return {};
      },
    },
    parentFormValue: {
      type: Object,
      default() {
        return {};
      },
    },
    ownSubmit: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default() {
        return {};
      },
    },
    fieldName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      },
      selectedDateRange: {
        startDate: null,
        endDate: null,
      },
      isFormTranslatable: false,
      formValue: {},
    };
  },
  watch: {
    formData() {
      this.detectIsFormTranslatable();
      if (this.isFormTranslatable === true) {
        this.formValue = {
          ...this.formValue,
          translation: {
            am: [],
            ru: [],
            en: [],
          },
        };
      }
    },
  },
  mounted() {
    this.detectIsFormTranslatable();
    if (this.isFormTranslatable === true) {
      this.formValue = {
        ...this.formValue,
        translation: {
          am: [],
          ru: [],
          en: [],
        },
      };
    }
  },
  methods: {
    isValidationFirstError(errors, key) {
      return Object.keys(errors)[0] === key;
    },
    detectIsFormTranslatable() {
      _.forIn(this.formData, (value) => {
        if (value.translatable) {
          this.isFormTranslatable = true;
        }
      });
    },
    dependOnChecker(data) {
      const key = Object.keys(data)[0];
      const value = data[key];
      return this.formValue[key] === value || this.parentFormValue[key] === value;
    },
    translationInputChange(obj) {
      let existence = false;
      let myArr = [];
      myArr = this.formValue.translation[obj.lang].map((elem) => {
        let elemTranslation;
        if (elem.column_name === obj.info.column_name) {
          elemTranslation = { ...obj.info };
          existence = true;
        }
        return elemTranslation || elem;
      });
      if (!existence) {
        myArr.push(obj.info);
      }
      this.formValue.translation[obj.lang] = myArr;
      // this.$emit('translation-input-change', obj);
    },
    inputChange(e) {
      this.formValue = {
        ...this.formValue,
        ...e,
      };
      this.$emit('input-change', { [this.fieldName]: this.formValue });
    },
  },
};
</script>

<style scoped lang="scss">
.form-wrapper{
  padding: 20px;
  button:last-child{
    height: 30px;
    line-height: 1;
    margin: 0 10px;
  }
  .components-wrapper{
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
