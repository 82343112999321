<template>
  <div ref="input-component" class="input-component">
    <a-form-item
      v-if="translatable"
      :validate-status="validatingStatus"
      :help="validateHelp"
    >
      <template slot="label">
        <span class="label-template">
          <span class="badge badge-warning">
            "{{ formLocaleLang }}"
          </span>
          <label class="label-input" :for="`${label}/input`">
            {{ label }}
          </label>
        </span>
      </template>
      <template v-for="(tag, index) in tags">
        <a-tooltip v-if="tag.length > 20" :key="tag" :title="tag">
          <a-tag :key="tag" :closable="index !== 0" @close="() => handleClose(tag)">
            {{ `${tag.slice(0, 20)}...` }}
          </a-tag>
        </a-tooltip>
        <a-tag v-else
               :key="tag"
               :closable="index >= 0"
               color="blue"
               @close="() => handleClose(tag)"
        >
          {{ tag }}
        </a-tag>
      </template>
      <a-input
        v-if="inputVisible"
        ref="input"
        type="text"
        size="small"
        :style="{ width: '100px' }"
        :value="inputValue"
        @change="handleInputChange"
        @blur="handleInputConfirm"
        @keyup.enter="handleInputConfirm"
      />
      <a-tag v-else style="background: #87d068; borderStyle: dashed;" @click="showInput">
        <a-icon type="plus" /> Add Client
      </a-tag>
    </a-form-item>
    <a-form-item
      v-else
      :validate-status="validatingStatus"
      :help="validateHelp"
    >
      <template slot="label">
        <span class="label-template">
          <label class="label-input" :for="`${label}/input`">
            {{ label }}
          </label>
        </span>
      </template>
      <template v-for="(tag, index) in tags">
        <a-tooltip v-if="tag.length > 20" :key="tag" :title="tag">
          <a-tag :key="tag" :closable="index !== 0" @close="() => handleClose(tag)">
            {{ `${tag.slice(0, 20)}...` }}
          </a-tag>
        </a-tooltip>
        <a-tag v-else
               :key="tag"
               :closable="index >= 0"
               color="blue"
               @close="() => handleClose(tag)"
        >
          {{ tag }}
        </a-tag>
      </template>
      <a-input
        v-if="inputVisible"
        ref="input"
        type="text"
        size="small"
        :style="{ width: '100px' }"
        :value="inputValue"
        @change="handleInputChange"
        @blur="handleInputConfirm"
        @keyup.enter="handleInputConfirm"
      />
      <a-tag v-else style="background: #87d068; borderStyle: dashed;" @click="showInput">
        <a-icon type="plus" /> Add Client
      </a-tag>
    </a-form-item>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['oldValue', 'label', 'type', 'min', 'step', 'fieldName', 'translatable', 'translation', 'validationError', 'isValidationFirstError', 'formLocaleLang'],
  data() {
    return {
      value: '',
      translatableValue: {},
      validatingStatus: 'validating',
      validateHelp: '',
      tags: [],
      inputVisible: false,
      inputValue: '',
    };
  },
  computed: {
    ...mapState({
      langs(state) {
        return state.languages;
      },
    }),
  },
  watch: {
    isValidationFirstError(e) {
      if (e) {
        this.$refs['input-component'].scrollIntoView();
      }
    },
    validationError(e) {
      if (e) {
        this.validatingStatus = 'error';
        // eslint-disable-next-line prefer-destructuring
        this.validateHelp = e[0];
      } else {
        this.deleteValidationErrors();
      }
    },
  },
  created() {
    if (this.translatable) {
      this.setTranslatableValues();
    }
  },
  mounted() {
    if (this.type === 'date' && this.oldValue) {
      this.value = moment(this.oldValue).format('YYYY-MM-DD');
      this.$emit('input-change', { [this.fieldName]: this.oldValue });
    } else if (this.oldValue && this.type !== 'file' && !this.translatable) {
      this.value = this.oldValue;
      this.$emit('input-change', { [this.fieldName]: this.oldValue });
    }
    if (this.translatable) {
      this.langs.forEach((lang) => {
        this.onTranslatableValueChange(lang);
      });
    }
  },
  methods: {
    handleClose(removedTag) {
      const tags = this.tags.filter((tag) => tag !== removedTag);
      this.tags = tags;
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    handleInputChange(e) {
      this.inputValue = e.target.value;
    },
    handleInputConfirm() {
      const { inputValue } = this;
      let { tags } = this;
      if (inputValue && tags.indexOf(inputValue) === -1) {
        tags = [...tags, inputValue];
      }
      Object.assign(this, {
        tags,
        inputVisible: false,
        inputValue: '',
      });
    },
    deleteValidationErrors() {
      this.validatingStatus = 'validating';
      this.validateHelp = '';
    },
    onTranslatableValueChange(language) {
      let lang;
      if (typeof language === 'string') {
        lang = language;
      } else {
        lang = this.formLocaleLang;
      }
      if (lang === 'default') {
        this.$emit('input-change', { [this.fieldName]: this.translatableValue[lang] || '' });
      } else {
        const obj = {
          lang,
          info: {
            column_name: this.fieldName,
            value: this.translatableValue[lang],
          },
        };
        this.$nextTick(() => {
          this.$emit('translation-input-change', obj);
        });
      }
    },
    setTranslatableValues() {
      const translatableValue = {};
      // translatableValue[formLocaleLang][fieldName]
      this.langs.forEach((lang) => {
        if (lang === 'default') {
          translatableValue[lang] = this.oldValue || '';
        } else {
          translatableValue[lang] = (this.translation
            && this.getTranslation(this.translation, this.fieldName, lang)) || '';
        }
      });
      this.translatableValue = translatableValue;
    },
  },
};
</script>

<style scoped lang="scss">
.input-component{
  text-align: left;
  .label-input{
    margin-left: 5px;
    cursor: pointer;
  }
  .label-input:hover{
    color: dodgerblue;
  }
  .img-file{
    img{
      margin-top: 10px;
      max-width: 100px;
      max-height: 100px;
    }
  }
}
</style>
