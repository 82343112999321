<template>
  <div class="client-balance-card">
    <a-card
      :bordered="false"
      :head-style="{'border-top': '1px solid #E9E9E9'}"
    >
      <template slot="title">
        <div class="title-wrapper">
          <div>
            <span
              style="font-size: 20px;"
            >
              Main Balance : {{ data.mainBalance }} {{ data.currency }}
            </span>
            <span class="balance">
              Pending Withdraw: {{ data.pendingWithdraw }} {{ data.currency }}
            </span>
          </div>
          <div>
            <div>
              <span class="balance" style="font-size: 20px">
                Bonus Balance : {{ data.bonusBalance }} {{ data.currency }}
              </span>
              <!--              <span class="option-name">-->
              <!--                Currency : <span>{{ data.currency }}</span>-->
              <!--              </span>-->
            </div>
            <div>
              <span class="option-name">
                Net Balance : {{ data.netBalance }} {{ data.currency }}
              </span>
            </div>
          </div>
        </div>
      </template>
      <div class="body-wrapper">
        <div
          v-for="(info, index) in data.gameDetailBalance"
          :key="index"
          class="option"
        >
          <span class="option-name">{{ info.label }} :</span>
          <span class="option-amount">{{ info.value }} {{ data.currency }}</span>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>

export default {
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.client-balance-card{
  flex: 1 1 630px;

  .title-wrapper{
    display: flex;
    justify-content: space-between;
    div:first-child{
      text-align: left;
    }
    div:last-child{
      text-align: right;
      span:last-child{
        font-size: 14px;
        span:last-child{
          color: #1890FF;
        }
      }
    }
    >div{
      display: flex;
      flex-direction: column;
      .option-name{
        font-size: 12px;
        line-height: 22px;
        color: #595959;
        font-weight: normal;
      }
    }
    .balance{
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
  }
  .body-wrapper{
    display: grid;
    grid-template-columns: repeat(3, 3fr);
    grid-template-rows: repeat(auto-fit, minmax(40px, 1fr));
    .option{
      display: flex;
      flex-direction: column;
      text-align: left;
      margin-bottom: 8px;

      &:nth-child(3n + 3) {
        text-align: right;
      }

      .option-name{
        color: #595959;
        font-size: 12px;
        line-height: 22px;
        font-weight: 400;
      }
      .option-amount{
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
      }
    }
  }
}
</style>
