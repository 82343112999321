<template>
  <div class="form-wrapper card-body">
    <a-form :label-col="labelCol" :wrapper-col="wrapperCol" :layout="layoutType">
      <a-form-item v-if="isFormTranslatable" label="Language">
        <FormLanguageSwitcher @language-change="(lang) => formSelectedLanguage = lang" />
      </a-form-item>
      <a-form-item
        v-if="oldFormValues && oldFormValues.notes"
        label="Notes"
        style="border-bottom: 2px solid"
      >
        <a-collapse style="margin-bottom: 15px">
          <a-collapse-panel>
            <div v-for="(note, index) in oldFormValues.notes" :key="index">
              <strong>{{ index + 1 }}:</strong> {{ note }}
            </div>
          </a-collapse-panel>
        </a-collapse>
      </a-form-item>
      <div v-for="(val, key) in formData"
           :key="String(key)"
      >
        <div
          v-if="val.type === 'checkbox'
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <Checkbox
            :label="val.label || key"
            :field-name="key"
            :disabled="val.disabled"
            :old-value="oldFormValues[key]"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @input-change="inputChange"
          />
        </div>
        <div
          v-else-if="val.type === 'text'
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <Input
            :label="val.label || key"
            :field-name="key"
            :disabled="val.disabled"
            :old-value="oldFormValues[key]"
            :translatable="val.translatable"
            :translation="oldFormValues && oldFormValues.translation"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            :form-locale-lang="formSelectedLanguage || localeLang"
            @translation-input-change="translationInputChange"
            @input-change="inputChange"
          />
        </div>
        <div
          v-else-if="(val.type === 'ant-date' || val.type === 'datetime')
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <AntDateInput
            :label="val.label || key"
            :field="key"
            :disabled="val.disabled"
            :old-value="oldFormValues[key]"
            :hide-time="val.hideTime"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @input-change="inputChange"
          />
        </div>
        <div
          v-else-if="val.type === 'ant-date-range'
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <AntDateRangePicker
            :label="val.label || key"
            :default-value="val.defaultValue"
            :show-time="val.showTime"
            :dis-allow-clear="val.disAllowClear"
            :field="key"
            :old-value="oldFormValues[key]"
            @input-change="inputChange"
          />
        </div>
        <div
          v-else-if="val.type === 'date-range-picker-with-presets'
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <DateRangePickerWithPresets
            :label="val.label || key"
            :default-value="val.defaultValue"
            :show-time="val.showTime"
            :as-form-element="true"
            :dis-allow-clear="val.disAllowClear"
            :field="key"
            :old-value="oldFormValues[key]"
            @input-change="inputChange"
          />
        </div>
        <div
          v-else-if="(val.type === 'month-picker')
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <MonthPicker
            :label="val.label || key"
            :field="key"
            :disabled="val.disabled"
            :old-value="oldFormValues[key]"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @input-change="inputChange"
          />
        </div>
        <div
          v-else-if="val.type === 'date'
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <Input
            type="date"
            :label="val.label || key"
            :disabled="val.disabled"
            :field-name="key"
            :old-value="oldFormValues[key]"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            :translatable="val.translatable"
            :translation="oldFormValues && oldFormValues.translation"
            :form-locale-lang="formSelectedLanguage || localeLang"
            @translation-input-change="translationInputChange"
            @input-change="inputChange"
          />
        </div>
        <div
          v-else-if="val.type === 'number'
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <Input
            type="number"
            :label="val.label || key"
            :disabled="val.disabled"
            :min="$route.params.moduleName === 'cms-pages' && 0"
            :field-name="key"
            :old-value="oldFormValues[key]"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            :translatable="val.translatable"
            :translation="oldFormValues && oldFormValues.translation"
            :form-locale-lang="formSelectedLanguage || localeLang"
            @translation-input-change="translationInputChange"
            @input-change="inputChange"
          />
        </div>
        <div
          v-else-if="val.type === 'color_picker'
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <Input
            type="color"
            :label="val.label || key"
            :disabled="val.disabled"
            :field-name="key"
            :old-value="oldFormValues[key]"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            :translatable="val.translatable"
            :translation="oldFormValues && oldFormValues.translation"
            :form-locale-lang="formSelectedLanguage || localeLang"
            @translation-input-change="translationInputChange"
            @input-change="inputChange"
          />
        </div>
        <div
          v-else-if="val.type === 'unsigned'
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <Input
            type="number"
            :min="0"
            :label="val.label || key"
            :field-name="key"
            :disabled="val.disabled"
            :old-value="oldFormValues[key]"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            :translatable="val.translatable"
            :translation="oldFormValues && oldFormValues.translation"
            :form-locale-lang="formSelectedLanguage || localeLang"
            @translation-input-change="translationInputChange"
            @input-change="inputChange"
          />
        </div>
        <div
          v-else-if="val.type === 'decimal'
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <Input
            type="number"
            :step="0.01"
            :label="val.label || key"
            :field-name="key"
            :disabled="val.disabled"
            :old-value="oldFormValues[key]"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            :translatable="val.translatable"
            :translation="oldFormValues && oldFormValues.translation"
            :form-locale-lang="formSelectedLanguage || localeLang"
            @translation-input-change="translationInputChange"
            @input-change="inputChange"
          />
        </div>
        <div
          v-else-if="val.type === 'textarea'
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <Input
            type="textarea"
            :label="val.label || key"
            :field-name="key"
            :disabled="val.disabled"
            :old-value="oldFormValues[key]"
            :translatable="val.translatable"
            :translation="oldFormValues && oldFormValues.translation"
            :form-locale-lang="formSelectedLanguage || localeLang"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @translation-input-change="translationInputChange"
            @input-change="inputChange"
          />
        </div>
        <div
          v-else-if="val.type === 'file'
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <FileInput
            type="file"
            :label="val.label || key"
            :translatable="val.translatable"
            :disabled="val.disabled"
            :field-name="key"
            :old-value="oldFormValues[key]"
            :translation="oldFormValues && oldFormValues.translation"
            :validation-error="validationErrors[key] || validationErrors[`old_${key}_en`]
              || validationErrors[`old_${key}_am`] || validationErrors[`old_${key}_ru`]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            :form-locale-lang="formSelectedLanguage || localeLang"
            @translation-input-change="translationInputChange"
            @input-change="inputChange"
          />
        </div>
        <div
          v-else-if="(val.type === 'decimal_unsigned' || val.type === 'unsigned_decimal')
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <Input
            type="number"
            :min="0"
            step="0.01"
            :label="val.label || key"
            :field-name="key"
            :disabled="val.disabled"
            :old-value="oldFormValues[key]"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            :translatable="val.translatable"
            :translation="oldFormValues && oldFormValues.translation"
            :form-locale-lang="formSelectedLanguage || localeLang"
            @translation-input-change="translationInputChange"
            @input-change="inputChange"
          />
        </div>
        <div
          v-else-if="val.type === 'radio'
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <Radio
            :label="val.label || key"
            :field-name="key"
            :disabled="val.disabled"
            :option-disable-server-side="val.disabledOptionDependsOn &&
              disableOptionChecker(val.disabledOptionDependsOn, key)"
            :values="val.values"
            :old-value="oldFormValues[key]"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @input-change="inputChange"
          />
        </div>
        <div
          v-else-if="val.type === 'select'
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <Select
            :type="val.type"
            :field-name="key"
            :label="val.label || key"
            :disabled="val.disabled
              || (val.disabledDependsOn && disabledChecker(val.disabledDependsOn))"
            :values="val.values"
            :relation-id="val.relationId"
            :relation="val.relation"
            :request-method="val.requestMethod"
            :server-side-dependence="(val.serverSideDependence
              && {[val.serverSideDependence]: (formValue[val.serverSideDependence]
                || parentFormValue[val.serverSideDependence])}) || {}"
            :old-value="oldFormValues[key]"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @input-change="inputChange"
          />
        </div>
        <div
          v-else-if="val.type === 'select_multiple'
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <MultipleSelect
            :type="val.type"
            :field-name="key"
            :label="val.label || key"
            :disabled="val.disabled
              || (val.disabledDependsOn && disabledChecker(val.disabledDependsOn))"
            :values="val.values"
            :relation-id="val.relationId"
            :relation="val.relation"
            :request-method="val.requestMethod"
            :server-side-dependence="(val.serverSideDependence
              && {[val.serverSideDependence]: (formValue[val.serverSideDependence]
                || parentFormValue[val.serverSideDependence])}) || {}"
            :old-value="typeof oldFormValues[key] === 'string'
              ? JSON.parse(oldFormValues[key]) : oldFormValues[key]"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @input-change="inputChange"
          />
        </div>
        <div
          v-else-if="val.type === 'select_multiple_paginated'
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <PaginatedMultipleSelect
            :type="val.type"
            :field-name="key"
            :label="val.label || key"
            :disabled="val.disabled
              || (val.disabledDependsOn && disabledChecker(val.disabledDependsOn))"
            :relation="val.relation"
            :request-method="val.requestMethod"
            :server-side-dependence="(val.serverSideDependence
              && {[val.serverSideDependence]: (formValue[val.serverSideDependence]
                || parentFormValue[val.serverSideDependence])}) || {}"
            :values="val.values"
            :old-value="typeof oldFormValues[key] === 'string'
              ? JSON.parse(oldFormValues[key]) : oldFormValues[key]"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @input-change="inputChange"
          />
        </div>
        <div
          v-else-if="val.type === 'select_paginated'
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <PaginatedSelect
            :type="val.type"
            :field-name="key"
            :label="val.label || key"
            :disabled="val.disabled
              || (val.disabledDependsOn && disabledChecker(val.disabledDependsOn))"
            :relation="val.relation"
            :request-method="val.requestMethod"
            :server-side-dependence="(val.serverSideDependence
              && {[val.serverSideDependence]: (formValue[val.serverSideDependence]
                || parentFormValue[val.serverSideDependence])}) || {}"
            :values="val.values"
            :old-value="typeof oldFormValues[key] === 'string' && isJSON(oldFormValues[key])
              ? JSON.parse(oldFormValues[key]) : oldFormValues[key]"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            @input-change="inputChange"
          />
        </div>
        <!--        <div-->
        <!--          v-else-if="val.type === 'datetime'-->
        <!--            && (!val.depends_on || dependOnChecker(val.depends_on, key))"-->
        <!--          v-show="currentStep.includes(key)"-->
        <!--        >-->
        <!--          <DatePickerInput-->
        <!--            :field-name="key"-->
        <!--            :label="val.label || key"-->
        <!--            :disabled="val.disabled"-->
        <!--            :old-value="oldFormValues[key]"-->
        <!--            :validation-error="validationErrors[key]"-->
        <!--            :is-validation-first-error=
        "isValidationFirstError(validationErrors, key)"-->
        <!--            @input-change="inputChange"-->
        <!--          />-->
        <!--        </div>-->
        <div
          v-else-if="val.type === 'sender'
            && (!val.depends_on || dependOnChecker(val.depends_on, key))"
          v-show="currentStep.includes(key)"
        >
          <SmsSender
            :field-name="key"
            :label="val.label || key"
            :disabled="val.disabled"
            :path="val.path"
            :button-text="val.buttonText"
            :old-value="oldFormValues[key]"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            :form-value="formValue"
          />
        </div>
        <div v-else-if="val.type === 'array'
               && (!val.depends_on || dependOnChecker(val.depends_on, key))"
             v-show="currentStep.includes(key)"
        >
          <Array
            :form-data="val"
            :parent-form-value="formValue"
            :field-name="key"
            :label="val.label || key"
            :validation-error="validationErrors[key]"
            :old-form-values="oldFormValues[key]"
            @input-change="inputChange"
          />
        </div>
        <div v-else-if="val.type === 'editor'
               && (!val.depends_on || dependOnChecker(val.depends_on, key))"
             v-show="currentStep.includes(key)"
        >
          <TinyEditor
            type="editor"
            :label="val.label || key"
            :field-name="key"
            :disabled="val.disabled"
            :old-value="oldFormValues[key]"
            :translatable="val.translatable"
            :translation="oldFormValues.translation"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            :form-locale-lang="formSelectedLanguage || localeLang"
            @translation-input-change="translationInputChange"
            @input-change="inputChange"
          />
        </div>
        <div v-else-if="val.type === 'tag'
               && (!val.depends_on || dependOnChecker(val.depends_on, key))"
             v-show="currentStep.includes(key)"
        >
          <Tag
            type="tag"
            :label="val.label || key"
            :field-name="key"
            :disabled="val.disabled"
            :old-value="oldFormValues[key]"
            :translatable="val.translatable"
            :translation="oldFormValues && oldFormValues.translation"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            :form-locale-lang="formSelectedLanguage || localeLang"
            @translation-input-change="translationInputChange"
            @input-change="inputChange"
          />
        </div>
        <div v-else-if="val.type === 'cron_expression'
               && (!val.depends_on || dependOnChecker(val.depends_on, key))"
             v-show="currentStep.includes(key)"
        >
          <CronsTrue
            :label="val.label || key"
            :field-name="key"
            :disabled="val.disabled"
            :old-value="oldFormValues[key]"
            :validation-error="validationErrors[key]"
            :is-validation-first-error="isValidationFirstError(validationErrors, key)"
            :form-locale-lang="formSelectedLanguage || localeLang"
            @translation-input-change="translationInputChange"
            @input-change="inputChange"
          />
        </div>
        <div v-else-if="val.type === 'hidden'
          && (!val.depends_on || dependOnChecker(val.depends_on, key))"
        >
          <HiddenInput
            :field-name="key"
            :old-value="oldFormValues[key]"
            @input-change="inputChange"
          />
        </div>
      </div>
      <div class="submit-btn-wrapper" style="text-align: end;">
        <a-button
          v-if="ownSubmit && Object.keys(formData)[0]"
          type="primary"
          :loading="isLoading"
          @click.prevent="$emit('form-data-submit', formValue)"
        >
          Submit
        </a-button>
        <a-button :style="{ marginRight: '8px' }" @click="$emit('toggle-modal')">
          Cancel
        </a-button>
      </div>
    </a-form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Tag from '@/components/FormElements/Tag';
import HiddenInput from '@/components/FormElements/HiddenInput';
import Checkbox from '../../FormElements/Checkbox';
import Input from '../../FormElements/Input';
import Radio from '../../FormElements/Radio';
import Select from '../../FormElements/Select';
import Array from '../../FormElements/Array';
// import DatePickerInput from '../../FormElements/DatePickerInput';
import MultipleSelect from '../../FormElements/MultipleSelect';
import TinyEditor from '../../FormElements/TinyEditor';
import FormLanguageSwitcher from '../../FormElements/FormLanguageSwitcher';
import PaginatedMultipleSelect from '../../FormElements/PaginatedMultipleSelect';
import PaginatedSelect from '../../FormElements/PaginatedSelect';
import FileInput from '../../FormElements/FileInput';
import CronsTrue from '../../FormElements/CronsTrue';
import AntDateInput from '../../FormElements/AntDateInput';
import SmsSender from '../../FormElements/SmsSender';
import MonthPicker from '../../FormElements/MonthPicker';
import { isJSON } from '../../../utils/helpers';
import AntDateRangePicker from '../../FormElements/AntDateRangePicker';
import DateRangePickerWithPresets from '../../FormElements/DateRangePickerWithPresets';

export default {
  name: 'FormPage',
  components: {
    DateRangePickerWithPresets,
    AntDateRangePicker,
    SmsSender,
    HiddenInput,
    Tag,
    Checkbox,
    Input,
    Radio,
    Select,
    Array,
    MultipleSelect,
    TinyEditor,
    FormLanguageSwitcher,
    PaginatedMultipleSelect,
    PaginatedSelect,
    FileInput,
    CronsTrue,
    AntDateInput,
    MonthPicker,
  },
  props: {
    validationErrors: {
      type: Object,
      default() {
        return {};
      },
    },
    oldFormValues: {
      type: Object,
      default() {
        return {};
      },
    },
    // eslint-disable-next-line vue/require-prop-types
    currentStep: {
      default: 'Not specified',
    },
    parentFormValue: {
      type: Object,
      default() {
        return {};
      },
    },
    ownSubmit: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default() {
        return {};
      },
    },
    layoutType: {
      type: String,
      default: 'horizontal',
    },
    fieldName: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formSelectedLanguage: '',
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      selectedDateRange: {
        startDate: null,
        endDate: null,
      },
      isFormTranslatable: false,
      formValue: {},
      isJSON,
    };
  },
  computed: {
    ...mapState({
      langs(state) {
        return state.languages;
      },
    }),
  },
  watch: {
    formData() {
      this.detectIsFormTranslatable();
    },
  },
  mounted() {
    this.detectIsFormTranslatable();
  },
  methods: {
    disableOptionChecker(serverDepOn, fieldName) {
      const disabledOptions = [];
      const optionCodeList = [];
      _.forIn(serverDepOn, (data, optionCode) => {
        optionCodeList.push(optionCode);
        _.forIn(data, (value, key) => {
          if (typeof value === 'object') {
            value.forEach((val) => {
              if (this.formValue[key] === val) {
                disabledOptions.push(optionCode);
              }
              if (typeof this.formValue[key] === 'object' && this.formValue[key].includes(val)) {
                disabledOptions.push(optionCode);
              }
              if (val === 'notEmptyValue' && this.formValue[key]) {
                disabledOptions.push(optionCode);
              }
              if (!this.formValue[key] && (val === 'defaultDisabled' || val === 'emptyValue')) {
                disabledOptions.push(optionCode);
              }
            });
          } else if (this.formValue[key] === value || this.parentFormValue[key] === value) {
            disabledOptions.push(optionCode);
          }
        });
      });
      if (disabledOptions.length && optionCodeList.includes(this.formValue[fieldName])) {
        delete this.formValue[fieldName];
      }
      return disabledOptions;
    },
    disabledChecker(data) {
      if (!data) return undefined;
      let presence;
      Object.keys(data).forEach((key) => {
        const value = data[key];
        if (this.formValue[key] === value || this.parentFormValue[key] === value) {
          presence = 'disabled';
        }
      });
      return presence;
    },
    isValidationFirstError(errors, key) {
      return Object.keys(errors)[0] === key;
    },
    detectIsFormTranslatable() {
      _.forIn(this.formData, (value) => {
        if (value.translatable) {
          this.isFormTranslatable = true;
        }
      });
    },
    dependsFromMultiSelect(val, key) {
      const depChecker = {
        presence: false,
        areAllTruthy: false,
      };
      const presence = val.every((option) => {
        if (this.formValue[key] && this.formValue[key].indexOf(option) > -1) {
          return true;
        }
        return false;
      });
      if (presence) {
        depChecker.presence = true;
        depChecker.areAllTruthy = true;
      }
      return depChecker;
    },
    dependOnChecker(data, fieldName) {
      let presence = false;
      // eslint-disable-next-line consistent-return
      _.forIn(data, (value, key) => {
        let areAllTruthy = false;
        if (typeof value === 'object') {
          value.forEach((val) => {
            if (typeof val === 'object') {
              if (this.formData[key] && (this.formData[key].type === 'select_multiple'
                || this.formData[key].type === 'select_multiple_paginated')) {
                const checker = this.dependsFromMultiSelect(val, key);
                presence = checker.presence;
                areAllTruthy = checker.areAllTruthy;
              }
            }
            if (this.formValue[key] === val || this.parentFormValue[key] === val) {
              presence = true;
              areAllTruthy = true;
            }
            if (val === 'notEmptyValue' && this.formValue[key]) {
              presence = true;
              areAllTruthy = true;
            }
            if (!this.formValue[key] && !this.parentFormValue[key] && (val === 'defaultShow' || val === 'emptyValue')) {
              presence = true;
              areAllTruthy = true;
            }
          });
          if (data.mustBeSameTimeTruthy && !areAllTruthy) {
            presence = false;
            return false;
          }
        } else if (this.formValue[key] === value || this.parentFormValue[key] === value) {
          presence = true;
        }
      });
      // Object.keys(data).forEach((key) => {
      //   const value = data[key];
      //   if (typeof value === 'object') {
      //     value.forEach((val) => {
      //       if (this.formValue[key] === val || this.parentFormValue[key] === val) {
      //         presence = true;
      //       }
      //       if (val === 'notEmptyValue' && this.formValue[key]) {
      //         presence = true;
      //       }
      // eslint-disable-next-line max-len
      //       if (!this.formValue[key] && !this.parentFormValue[key] && (val === 'defaultShow' || val === 'emptyValue')) {
      //         presence = true;
      //       }
      //     });
      //   } else if (this.formValue[key] === value || this.parentFormValue[key] === value) {
      //     presence = true;
      //   }
      // });
      if (!presence) {
        delete this.formValue[fieldName];
      }
      return presence;
    },
    setDefaultTranslatableForm() {
      const translation = this.langs.reduce((acc, lang) => {
        acc[lang] = [];
        return acc;
      }, {});
      this.formValue = {
        ...this.formValue,
        translation,
      };
    },
    translationInputChange(obj) {
      if (!this.formValue.translation) {
        this.setDefaultTranslatableForm();
      }
      let existence = false;
      let myArr = [];
      myArr = this.formValue.translation[obj.lang].map((elem) => {
        let elemTranslation;
        if (elem.column_name === obj.info.column_name) {
          elemTranslation = { ...obj.info };
          existence = true;
        }
        return elemTranslation || elem;
      });
      if (!existence) {
        myArr.push(obj.info);
      }
      // console.log(this.formValue);
      this.formValue.translation[obj.lang] = myArr;
      this.$emit('translation-input-change', obj);
    },
    inputChange(e) {
      this.formValue = {
        ...this.formValue,
        ...e,
      };
      this.$emit('input-change', { [this.fieldName]: this.formValue });
    },
  },
};
</script>

<style scoped lang="scss">
.form-wrapper{
  padding: 20px;
  button:last-child{
    height: 30px;
    line-height: 1;
    margin: 0 10px;
    position: relative;
  }
  .submit-btn-wrapper{
    .loading-layer{
      position: absolute;
      top: -5px;
      right: -3px;
      color: #fdf70a;
      .spinner-border{
        width: 1rem;
        height: 1rem;
      }
    }
  }
  .components-wrapper{
    display: flex;
    flex-wrap: wrap;
  }
  .notes{
    width: 90%;
    margin: auto;
  }
}
</style>
