<template>
  <a-modal v-if="Boolean(visible)"
           :visible="Boolean(visible)"
           width="1000px"
           :footer="null"
           @cancel="() => $emit('toggle-modal')"
  >
    <template slot="title">
      <a-steps
        v-model="currentStep"
        style="margin-top: 35px"
      >
        <a-step
          v-for="(stepNumber, stepIndex) in stepDivision.length"
          :key="stepNumber"
          :status="stepValidator(stepIndex, stepValidation)"
          :title="`Step ${stepNumber}`"
        />
      </a-steps>
    </template>
    <div v-if="formInformation">
      <a-button @click="() => isVisibleDrawer = true">
        Info
      </a-button>
      <a-drawer
        v-model="isVisibleDrawer"
        placement="right"
        width="800"
        :visible="isVisibleDrawer"
        @close="() => isVisibleDrawer = false"
      >
        <template slot="title">
          <span style="font-size: 16px; font-weight: bold">
            {{ formInformation && formInformation.infoTitle }}
          </span>
        </template>
        <a-table
          v-for="table in formInformation && formInformation.tables"
          :key="table.title"
          :columns="table.columns"
          :data-source="table.rows"
          :pagination="false"
          style="margin-bottom: 20px"
        >
          <template slot="title">
            <span style="font-size: 16px; font-weight: bold">
              {{ table.title }}
            </span>
          </template>
        </a-table>
      </a-drawer>
    </div>
    <div class="steps-content">
      <div class="create-form-wrapper">
        <NewStepForm
          v-if="visible"
          :form-data="editJsonById"
          :current-step="stepDivision[currentStep]"
          :old-form-values="oldFormValues"
          :is-loading="isLoading"
          :own-submit="true"
          :validation-errors="errors"
          @form-data-submit="submitFormData"
          @toggle-modal="$emit('toggle-modal')"
        />
      </div>
    </div>
    <div class="steps-action">
      <a-button v-if="currentStep < stepDivision.length - 1" type="primary" @click="next">
        Next
      </a-button>
      <a-button v-if="currentStep > 0" style="margin-left: 8px" @click="prev">
        Back
      </a-button>
    </div>
  </a-modal>
</template>

<script>
import { mapState } from 'vuex';
import NewStepForm from '../components/partial/FormPage/NewStepForm';

export default {
  components: { NewStepForm },
  // eslint-disable-next-line vue/require-prop-types
  props: ['idOfEditModal', 'updateDispatchAction', 'getEditFormDispatchAction', 'stateName', 'requestPath', 'unmountAction', 'moduleName', 'visible'],
  data() {
    return {
      errors: {},
      isLoading: false,
      currentStep: 0,
      perStepLength: 5,
      stepValidation: [],
      formValidationRules: null,
      isVisibleDrawer: false,
    };
  },
  computed: {
    ...mapState({
      validationRules(state) {
        // eslint-disable-next-line max-len
        return state[this.stateName].editJsonById[this.idOfEditModal] && state[this.stateName].editJsonById[this.idOfEditModal].validationRules;
      },
      isFrontValidActive(state) {
        // eslint-disable-next-line max-len
        return state[this.stateName].editJsonById[this.idOfEditModal] && state[this.stateName].editJsonById[this.idOfEditModal].isFrontValidActive;
      },
      formInformation(state) {
        // eslint-disable-next-line max-len
        return state[this.stateName].editJsonById[this.idOfEditModal] && state[this.stateName].editJsonById[this.idOfEditModal].formInformation;
      },
      editJsonById(state) {
        // eslint-disable-next-line max-len
        return state[this.stateName].editJsonById[this.idOfEditModal] && state[this.stateName].editJsonById[this.idOfEditModal].fields;
      },
      oldFormValues(state) {
        // eslint-disable-next-line max-len
        return state[this.stateName].editJsonById[this.idOfEditModal] && state[this.stateName].editJsonById[this.idOfEditModal].result;
      },
      serverStepDivisionRules(state) {
        // eslint-disable-next-line max-len
        return state[this.stateName].editJsonById[this.idOfEditModal] && state[this.stateName].editJsonById[this.idOfEditModal].serverStepDivisionRules;
      },
    }),
    stepDivision() {
      if (this.serverStepDivisionRules) {
        return this.serverStepDivisionRules;
      }
      return this.editJsonById
        ? _.chunk(Object.keys(this.editJsonById), this.perStepLength) : [];
    },
  },
  watch: {
    async visible(e) {
      if (e) {
        await this.getEditFormJson();
      } else {
        await this.$store.dispatch(this.unmountAction);
      }
    },
  },
  // created() {
  //   this.formValidationRules = {
  //     url: {
  //       required: { message: 'Please Fill The Input' },
  //       max: { count: 50, message: 'The Field must be less then 50' },
  //     },
  //     translation: {
  //       am: {
  //         title: {
  //           required: { message: 'Am Please Fill The Input' },
  //         },
  //       },
  //       ru: {
  //         text: {
  //           required: { message: 'Ru Please Fill The Input' },
  //         },
  //       },
  //     },
  //   };
  // },
  async mounted() {
    if (this.visible) {
      await this.getEditFormJson();
    }
  },
  async destroyed() {
    if (this.unmountAction) {
      await this.$store.dispatch(this.unmountAction);
    }
  },
  methods: {
    stepValidator(stepIndex, stepValidation) {
      return _.intersection(stepValidation, this.stepDivision[stepIndex]).length
        ? 'error' : '';
    },
    showStepValidation(errors) {
      this.stepValidation = Object.keys(errors);
    },
    next() {
      this.currentStep++;
    },
    prev() {
      this.currentStep--;
    },
    async submitFormData(data) {
      this.isLoading = true;
      if (this.isFrontValidActive) {
        this.errors = this.$formCustomValidate(data, this.editJsonById);
        if (!_.isEmpty(this.errors)) {
          this.showStepValidation(this.errors);
          this.isLoading = false;
          return;
        }
      }
      const formData = new FormData();
      _.forIn(data, (value, key) => {
        if (key === 'translation' || (typeof value === 'object'
          && this.editJsonById[key] && this.editJsonById[key].type !== 'file')) {
          const val = JSON.stringify(value);
          formData.append(key, val);
        } else {
          formData.append(key, value);
        }
      });
      const params = {
        path: this.requestPath,
        moduleName: this.$route.params.moduleName ? `/${this.$route.params.moduleName}` : '',
        id: this.idOfEditModal,
        action: 'update',
        formData,
      };
      await this.$store.dispatch(this.updateDispatchAction, params).then((e) => {
        this.isLoading = false;
        if (e.errors) {
          this.errors = e.errors;
          this.showStepValidation(e.errors);
        } else {
          this.errors = {};
          this.$emit('toggle-modal', 'updated');
        }
      }).catch(() => {
        this.isLoading = false;
      });
    },
    async getEditFormJson() {
      const params = {
        path: this.requestPath,
        moduleName: this.$route.params.moduleName ? `/${this.$route.params.moduleName}` : '',
        id: this.idOfEditModal,
        action: 'edit',
      };

      await this.$store.dispatch(this.getEditFormDispatchAction, params);
    },
  },
};
</script>

<style scoped lang="scss">
.editFormPage{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #808080bf;
  overflow-y: scroll;
  z-index: 54;
  .form-page-wrapper{
    width: 95%;
    margin: 15vh auto 0;
    background: white;
    min-height: 60%;
    border-radius: 10px;
    position: relative;
    .close{
      position: absolute;
      top: -10px;
      right: -10px;
      cursor: pointer;
    }
  }
}
</style>
