import axios from 'axios';
import Vue from 'vue';
import _ from 'lodash';
import router from '@/router';
import socketError from '../utils/socketError';

export default {
  namespaced: true,

  state: {
    widgetInfo: {},
    widgetList: {},
    loading: false,
    widgetStates: {},
    dashboardMessage: {},
    chartList: [],
    widgetChartData: {
      // client: {
      //   amount: {
      //     chartData: [
      //       {
      //         data: [
      //           { x: 1356994200500, y: 4 },
      //           { x: 1356995300500, y: 2 },
      //           { x: 1356998400800, y: 7 },
      //           { x: 1356999500800, y: 5 },
      //           { x: 1356999600800, y: 8 },
      //           { x: 1356999700800, y: 9 },
      //           { x: 1356999900800, y: 10 },
      //         ],
      //         name: 'Amount',
      //       },
      //     ],
      //   },
      //   deposit: {
      //     chartData: [
      //       {
      //         data: [
      //           { x: 1356994200500, y: 40 },
      //           { x: 1356995300500, y: 210 },
      //           { x: 1356998400800, y: 74 },
      //           { x: 1356999500800, y: 5 },
      //           { x: 1356999600800, y: 8 },
      //           { x: 1356999700800, y: 95 },
      //           { x: 1356999900800, y: 20 },
      //         ],
      //         name: 'Deposit',
      //       },
      //     ],
      //   },
      // eslint-disable-next-line max-len
      //   chartListByField: [{ label: 'Amount', code: 'amount' }, { label: 'Deposit', code: 'deposit' }],
      // },
    },
  },
  mutations: {
    setDashboardMessage(state, message) {
      const dashboardName = router.history.current.path.substring(1) || 'dashboard';
      Vue.set(state.dashboardMessage, dashboardName, message);
    },
    setWidgetList(state, widgetList) {
      const dashboardName = router.history.current.path.substring(1) || 'dashboard';
      state.widgetStates = { ...state.widgetStates, [dashboardName]: {} };
      state.widgetInfo = { ...state.widgetInfo, [dashboardName]: {} };
      widgetList.forEach((el) => {
        Vue.set(state.widgetStates[dashboardName], el, {
          isLoading: false,
          error: false,
        });
      });
      Vue.set(state.widgetList, dashboardName, widgetList);
      // state.loading = false;
    },
    setChartList(state, chartList) {
      state.chartList = chartList;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setWidgetInfo(state, { key, value, selectedDate }) {
      const dashboardName = router.history.current.path.substring(1) || 'dashboard';
      const data = {
        widgetInfo: value,
        oldSelectedDate: selectedDate,
      };
      Vue.set(state.widgetInfo[dashboardName], key, data);
      // Vue.set(state.widgetInfo, key, value);
    },
    setWidgetChartData(state, { key, value, selectedChartByField }) {
      if (!state.widgetChartData[key]) {
        Vue.set(state.widgetChartData, key, value);
      } else {
        Vue.set(state.widgetChartData[key], selectedChartByField, value[selectedChartByField]);
      }
    },
    setWidgetStates(state, { widgetName, isLoading, error }) {
      const dashboardName = router.history.current.path.substring(1) || 'dashboard';
      state.widgetStates[dashboardName][widgetName] = {
        isLoading,
        error,
      };
    },
  },
  actions: {
    async getWidgetList({ dispatch, commit }) {
      try {
        let path = '/dashboard';
        if (router.history.current.path === '/admin/total-financial-reports') {
          path = '/admin/total-financial-reports';
        }
        if (router.history.current.path === '/admin/total-clients-balance-reports') {
          path = '/admin/total-clients-balance-reports';
        }
        if (router.history.current.path === '/admin/dashboard-sport-widget') {
          path = '/admin/dashboard-sport-widget';
        }
        if (router.history.current.path === '/new-dashboard') {
          path = '/new-dashboard';
        }
        const res = await axios.get(path);
        const widgetList = (res.data && res.data.widgetList) || [];
        const message = (res.data && res.data.message) || '';
        const chartList = (res.data && res.data.chartList) || [];
        if (res.data.redirect) {
          router.push(res.data.redirect).then(() => {
            this.dispatch('tabsVuex/removeTab', { key: '/' });
            this.dispatch('tabsVuex/removeTab', { key: '/dashboard' });
          });
          return false;
        }
        if (res.data && res.data.widgetList) {
          commit('setWidgetList', res.data.widgetList);
          commit('setDashboardMessage', message);
          commit('setChartList', chartList);
          widgetList.forEach((key) => commit('setWidgetInfo', { key, value: {} }));
        } else {
          commit('setWidgetList', res.data);
          res.data.forEach((key) => commit('setWidgetInfo', { key, value: {} }));
        }
        return res.data;
      } catch (e) {
        socketError({
          dispatch,
          commit,
        }, e);
        return false;
      }
    },
    getPresetList() {
      // try {
      //   const res = await axios.get('/presets/periods');
      //   return res.data;
      // } catch (e) {
      //   socketError({
      //     dispatch,
      //   }, e);
      //   return false;
      // }
      return {
        yesterday: {
          start_date: moment().subtract(1, 'days').startOf('day').unix(),
          end_date: moment().subtract(1, 'days').endOf('day').unix(),
        },
        today: {
          start_date: moment().startOf('day').unix(),
          end_date: moment().endOf('day').unix(),
        },
        'This Month': {
          start_date: moment().startOf('month').unix(),
          end_date: moment().endOf('day').unix(),
        },
        'This Week': {
          start_date: moment().startOf('isoWeek').unix(),
          end_date: moment().endOf('day').unix(),
        },
        'Previous Week': {
          start_date: moment().subtract(1, 'week').startOf('isoWeek').unix(),
          end_date: moment().subtract(1, 'week').endOf('isoWeek').unix(),
        },
        'Previous Month': {
          start_date: moment().subtract(1, 'month').startOf('month').unix(),
          end_date: moment().subtract(1, 'month').endOf('month').unix(),
        },
        'Last 30 Days': {
          start_date: moment().subtract(29, 'days').startOf('day').unix(),
          end_date: moment().endOf('day').unix(),
        },
        'Last 7 Days': {
          start_date: moment().subtract(6, 'days').startOf('day').unix(),
          end_date: moment().endOf('day').unix(),
        },
      };
    },
    async getWidgetInfo({ dispatch, commit }, { key, selected }) {
      commit('setWidgetStates', { widgetName: key, isLoading: true, error: false });
      try {
        let path = '/dashboard';
        if (router.history.current.path === '/admin/total-financial-reports') {
          path = '/admin/total-financial-reports';
        }
        if (router.history.current.path === '/admin/total-clients-balance-reports') {
          path = '/admin/total-clients-balance-reports';
        }
        if (router.history.current.path === '/admin/dashboard-sport-widget') {
          path = '/admin/dashboard-sport-widget';
        }
        if (router.history.current.path === '/new-dashboard') {
          path = '/new-dashboard';
        }
        const res = await axios.get(`${path}/data`, {
          params: {
            widgets: key,
            start_date: selected.startDate,
            end_date: selected.endDate,
          },
        });
        const data = _.chain(res.data)
          .mapValues((values) => _.mapKeys(values, (v, k) => k.replace(/_/g, ' ')))
          .value();
        commit('setWidgetStates', { widgetName: key, isLoading: false, error: false });
        commit('setWidgetInfo', { key, value: data[key], selectedDate: selected });
        return data[key];
      } catch (e) {
        commit('setWidgetStates', { widgetName: key, isLoading: false, error: true });
        socketError({
          dispatch,
          commit,
        }, e);
        return false;
      }
    },
    async getWidgetChartData({ dispatch, commit }, { key, selected, selectedChartByField }) {
      commit('setWidgetStates', { widgetName: key, isLoading: true, error: false });
      try {
        const res = await axios.get('/dashboard/chart', {
          params: {
            widgetName: key,
            start_date: selected.startDate,
            end_date: selected.endDate,
            selectedChartByField,
          },
        });
        commit('setWidgetStates', { widgetName: key, isLoading: false, error: false });
        commit('setWidgetChartData', { key, value: res && res.data[key], selectedChartByField });
        return res.data;
      } catch (e) {
        commit('setWidgetStates', { widgetName: key, isLoading: false, error: true });
        socketError({
          dispatch,
          commit,
        }, e);
        return false;
      }
    },
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    widgetInfo(state) {
      return state.widgetInfo;
    },
    widgetList(state) {
      return state.widgetList;
    },
    chartList(state) {
      return state.chartList;
    },
    widgetStates(state) {
      return state.widgetStates;
    },
    dashboardMessage(state) {
      return state.dashboardMessage;
    },
    widgetChartData(state) {
      return state.widgetChartData;
    },
  },
};
