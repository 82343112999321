<template>
  <a-form-item
    ref="date-range-input"
    :validate-status="validatingStatus"
    :help="validateHelp"
    class="form-item-holder"
  >
    <template slot="label">
      <span class="form-item-label-text">{{ label }}</span>
    </template>
    <a-range-picker v-model="dateValue"
                    :default-value="[]"
                    :allow-clear="!disAllowClear"
                    :show-time="showTime && {
                      hideDisabledOptions: true,
                      // eslint-disable-next-line max-len,max-len
                      defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')],
                    }"
                    @change="onChange"
    />
  </a-form-item>
</template>
<script>

import moment from 'moment-timezone';

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['oldValue', 'field', 'label', 'showTime', 'defaultValue', 'disAllowClear', 'validationError'],
  data() {
    return {
      moment,
      dateValue: [],
      validatingStatus: 'validating',
      validateHelp: '',
    };
  },
  watch: {
    isValidationFirstError(e) {
      if (e) {
        this.$refs['date-range-input'].scrollIntoView();
      }
    },
    validationError(e) {
      if (e) {
        this.validatingStatus = 'error';
        // eslint-disable-next-line prefer-destructuring
        this.validateHelp = e[0];
      } else {
        this.deleteValidationErrors();
      }
    },
    oldValue(e) {
      if (e && e.startDate && e.endDate) {
        const isSame = e.startDate === moment(this.dateValue[0]).unix()
          && e.endDate === moment(this.dateValue[1]).unix();
        if (!isSame) {
          this.dateValue = [moment(e.startDate * 1000), moment(e.endDate * 1000)];
        }
      }
    },
    dateValue(e) {
      if (e && e[0]) {
        let value = null;
        if (this.showTime) {
          value = {
            [this.field]: {
              startDate: moment(e[0]).unix(),
              endDate: moment(e[1]).unix(),
            },
          };
        } else {
          value = {
            [this.field]: {
              startDate: moment(e[0]).startOf('day').unix(),
              endDate: moment(e[1]).endOf('day').unix(),
            },
          };
        }
        this.$emit('input-change', value);
      } else {
        this.$emit('input-change', {
          [this.field]: null,
        });
      }
      this.deleteValidationErrors();
    },
  },
  mounted() {
    const value = this.oldValue || this.defaultValue;
    this.dateValue = value
      ? [moment(value.startDate * 1000), moment(value.endDate * 1000)] : [];
    // if (this.oldValue || this.defaultValue) {
    //   this.dateValue = this.oldValue ? moment(this.oldValue * 1000)
    //     : moment(this.defaultValue * 1000);
    // }
  },
  methods: {
    onChange() {
    },
    deleteValidationErrors() {
      this.validatingStatus = 'validating';
      this.validateHelp = '';
    },
  },
};
</script>

<style scoped lang="scss">
</style>
