<template>
  <div class="json-viewer">
    <JsonViewer
      :value="JSON.parse(json)"
    />
  </div>
</template>

<script>
import JsonViewer from 'vue-json-viewer';

export default {
  components: {
    JsonViewer,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['json'],
  data() {
    return {};
  },
};
</script>
