<!-- eslint-disable -->
<template>
  <div class="select-with-percent">
    <a-form-item
      :label="label"
      :validate-status="validatingStatus"
      :help="validateHelp"
    >
      <a-select
        v-model="selectedCode"
        placeholder="Please Select"
        style="width: 432px"
      >
        <a-select-option
          v-for="option in values"
          :key="option.code"
          :disabled="Object.keys(selectedSections).includes(String(option.code))"
          @click="() => onSelect(option)"
        >
          {{ option.label }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <div class="tag-input-wrapper">
      <div v-for="(tag, n, ind) in selectedSections"
           :key="tag.code"
           class="tag-input"
           :style="withImage? {'margin-right': '30px'} : {}"
      >
        <TagWidthInput
          :input-value="selectedSections[tag.code].value"
          :tag-label="tag.label || findTagLabel(tag.code)"
          :tag-code="tag.code"
          :max="max"
          @remove-tag="removeTag"
          @input-change="(params) => onInputChange(params, ind)"
        />
        <FileInput
          v-if="withImage"
          type="file"
          :field-name="tag.code"
          :old-value="selectedSections[tag.code].image"
          @input-change="fileInputChange"
        />
        <!--        <a-tag :closable="true" @close="() => removeTag(tag)">-->
        <!--          {{ tag.label || findTagLabel(tag.code) }}-->
        <!--        </a-tag>-->
        <!--        <a-input-->
        <!--          v-model="selectedSections[tag.code].value"-->
        <!--          type="number"-->
        <!--          min="0"-->
        <!--          max="100"-->
        <!--          placeholder="%"-->
        <!--          @change="(event) => onInputChange(event, tag.code)"-->
        <!--        />-->
      </div>
    </div>
  </div>
</template>
<script>
import TagWidthInput from './TagWidthInput';
import FileInput from './FileInput';

export default {
  components: { TagWidthInput, FileInput },
  props: {
    label: {
      type: String,
      default: '',
    },
    fieldName: {
      type: String,
      default: '',
    },
    withImage: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: null,
    },
    values: {
      type: Array,
      default() {
        return [];
      },
    },
    oldValue: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      selectedSections: {},
      selectedCode: undefined,
      validatingStatus: 'validating',
      validateHelp: '',
    };
  },
  mounted() {
    if (this.oldValue) {
      this.selectedSections = this.oldValue;
    }
  },
  methods: {
    findTagLabel(targetCode) {
      let label = '';
      this.values.forEach((option) => {
        if (option.code === targetCode) {
          label = option.label;
        }
      });
      return label;
    },
    convertBase64(file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
          resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    },
    async fileInputChange(fileValue) {
      const tagCode = Object.keys(fileValue)[0];
      if (tagCode.slice(0, 3) === 'old') {
        const targetCode = tagCode.slice(4);
        const file = Object.values(fileValue)[0];
        // eslint-disable-next-line max-len
        this.selectedSections[targetCode] = { ...this.selectedSections[targetCode], oldImage: file };
        this.$emit('input-change', { [this.fieldName]: { ...this.selectedSections } });
      } else {
        const file = fileValue[tagCode] ? await this.convertBase64(fileValue[tagCode]) : '';
        // eslint-disable-next-line max-len
        this.selectedSections[tagCode] = { ...this.selectedSections[tagCode], image: file };
        this.$emit('input-change', { [this.fieldName]: { ...this.selectedSections } });
      }
    },
    onSelect({ label, code }) {
      this.selectedSections[code] = { label, code };
      this.$nextTick(() => {
        this.selectedCode = undefined;
        this.$emit('input-change', { [this.fieldName]: { ...this.selectedSections } });
      });
    },
    onInputChange({ value, tagCode }, ind) {
      this.selectedSections[tagCode].value = value;
      this.selectedSections[tagCode].index = ind;
      this.$emit('input-change', { [this.fieldName]: { ...this.selectedSections } });
    },
    removeTag(tagCode) {
      const sections = { ...this.selectedSections };
      delete sections[tagCode];
      this.selectedSections = { ...sections };
      this.$emit('input-change', { [this.fieldName]: { ...sections } });
    },
  },
};
</script>

<style lang="scss">
.select-with-percent{
  overflow-x: scroll;
  width: auto;
  text-align: left;
  margin-bottom: 24px;
  .tag-input-wrapper{
    display: flex;
    margin-bottom: 30px;
    .tag-input{
      /*display: flex;*/
      /*flex-direction: column;*/
      .ant-tag{
        /*width: 100%;*/
        margin-bottom: 30px;
        margin-right: 12px;
        font-size: 16px;
        height: 32px;
        display: flex;
        align-items: center;
      }
      input{
        width: 76px;
      }
    }
  }
}
</style>
