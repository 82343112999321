<template>
  <div class="dragable-orders">
    <a-spin :spinning="isLoading">
      <a-tabs
        v-model="activeKey"
        :tab-bar-style="{background: 'white', borderRadius: '6px'}"
        @change="handleTabChange"
      >
        <a-tab-pane v-for="{ label, code} in dragableOrderModuleTabs" :key="code" :tab="label">
          <DragAbleCategoriesOrders
            :dragable-order-data="dragableOrderModule"
            :module-name="moduleName"
          />
        </a-tab-pane>
      </a-tabs>
    </a-spin>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DragAbleCategoriesOrders from '../components/DragAbleOrders/DragAbleCategoriesOrders';

export default {
  components: {
    DragAbleCategoriesOrders,
  },
  data() {
    return {
      isLoading: false,
      activeKey: '',
    };
  },
  computed: {
    moduleName() {
      return this.$route.meta.moduleName || '';
    },
    ...mapState({
      dragableOrderModule(state) {
        return state.gameModule.dragableOrderModule[this.moduleName]
          && state.gameModule.dragableOrderModule[this.moduleName][this.$route.query.type]
          && state.gameModule.dragableOrderModule[this.moduleName][this.$route.query.type].results;
      },
      dragableOrderModuleTabs(state) {
        return state.gameModule.dragableOrderModule[this.moduleName]
          && state.gameModule.dragableOrderModule[this.moduleName].tabs;
      },
      // categoriesOrder(state) {
      //   return state.gameModule.categoriesOrder[this.$route.query.type]
      //     && state.gameModule.categoriesOrder[this.$route.query.type].results;
      // },
      // categoriesOrderTabs(state) {
      //   return state.gameModule.categoriesOrder && state.gameModule.categoriesOrder.tabs;
      // },
    }),
  },
  watch: {
    async $route() {
      if (!this.dragableOrderModule) {
        await this.getDragableOrderModule();
      }
    },
  },
  async mounted() {
    if (!this.$route.query.type) {
      await this.$router.push({ query: { type: this.$route.meta.defaultType } });
    }
    if (!this.dragableOrderModule) {
      await this.getDragableOrderModule();
    }
    this.activeKey = this.$route.query.type;
  },
  methods: {
    async handleTabChange(e) {
      await this.$router.push({ query: { type: e } });
    },
    async getDragableOrderModule() {
      if (!this.isLoading) {
        this.isLoading = true;
        const params = {
          path: this.$route.path,
          type: this.$route.query.type,
          moduleName: this.moduleName,
        };
        await this.$store.dispatch('gameModule/getDragableOrderModule', params);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.dragable-orders {
  padding: 24px 20px;
}
</style>
