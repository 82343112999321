var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-wrapper card-body"},[_c('a-form',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"layout":_vm.layoutType}},[(_vm.isFormTranslatable)?_c('a-form-item',{attrs:{"label":"Language"}},[_c('FormLanguageSwitcher',{on:{"language-change":function (lang) { return _vm.formSelectedLanguage = lang; }}})],1):_vm._e(),(_vm.oldFormValues && _vm.oldFormValues.notes)?_c('a-form-item',{staticStyle:{"border-bottom":"2px solid"},attrs:{"label":"Notes"}},[_c('a-collapse',{staticStyle:{"margin-bottom":"15px"}},[_c('a-collapse-panel',_vm._l((_vm.oldFormValues.notes),function(note,index){return _c('div',{key:index},[_c('strong',[_vm._v(_vm._s(index + 1)+":")]),_vm._v(" "+_vm._s(note)+" ")])}),0)],1)],1):_vm._e(),_vm._l((_vm.formData),function(val,key){
var _obj, _obj$1, _obj$2, _obj$3;
return _c('div',{key:String(key)},[(val.type === 'checkbox'
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentStep.includes(key)),expression:"currentStep.includes(key)"}]},[_c('Checkbox',{attrs:{"label":val.label || key,"field-name":key,"disabled":val.disabled,"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"input-change":_vm.inputChange}})],1):(val.type === 'text'
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentStep.includes(key)),expression:"currentStep.includes(key)"}]},[_c('Input',{attrs:{"label":val.label || key,"field-name":key,"disabled":val.disabled,"old-value":_vm.oldFormValues[key],"translatable":val.translatable,"translation":_vm.oldFormValues && _vm.oldFormValues.translation,"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key),"form-locale-lang":_vm.formSelectedLanguage || _vm.localeLang},on:{"translation-input-change":_vm.translationInputChange,"input-change":_vm.inputChange}})],1):((val.type === 'ant-date' || val.type === 'datetime')
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentStep.includes(key)),expression:"currentStep.includes(key)"}]},[_c('AntDateInput',{attrs:{"label":val.label || key,"field":key,"disabled":val.disabled,"old-value":_vm.oldFormValues[key],"hide-time":val.hideTime,"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"input-change":_vm.inputChange}})],1):(val.type === 'ant-date-range'
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentStep.includes(key)),expression:"currentStep.includes(key)"}]},[_c('AntDateRangePicker',{attrs:{"label":val.label || key,"default-value":val.defaultValue,"show-time":val.showTime,"dis-allow-clear":val.disAllowClear,"field":key,"old-value":_vm.oldFormValues[key]},on:{"input-change":_vm.inputChange}})],1):(val.type === 'date-range-picker-with-presets'
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentStep.includes(key)),expression:"currentStep.includes(key)"}]},[_c('DateRangePickerWithPresets',{attrs:{"label":val.label || key,"default-value":val.defaultValue,"show-time":val.showTime,"as-form-element":true,"dis-allow-clear":val.disAllowClear,"field":key,"old-value":_vm.oldFormValues[key]},on:{"input-change":_vm.inputChange}})],1):((val.type === 'month-picker')
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentStep.includes(key)),expression:"currentStep.includes(key)"}]},[_c('MonthPicker',{attrs:{"label":val.label || key,"field":key,"disabled":val.disabled,"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"input-change":_vm.inputChange}})],1):(val.type === 'date'
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentStep.includes(key)),expression:"currentStep.includes(key)"}]},[_c('Input',{attrs:{"type":"date","label":val.label || key,"disabled":val.disabled,"field-name":key,"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key),"translatable":val.translatable,"translation":_vm.oldFormValues && _vm.oldFormValues.translation,"form-locale-lang":_vm.formSelectedLanguage || _vm.localeLang},on:{"translation-input-change":_vm.translationInputChange,"input-change":_vm.inputChange}})],1):(val.type === 'number'
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentStep.includes(key)),expression:"currentStep.includes(key)"}]},[_c('Input',{attrs:{"type":"number","label":val.label || key,"disabled":val.disabled,"min":_vm.$route.params.moduleName === 'cms-pages' && 0,"field-name":key,"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key),"translatable":val.translatable,"translation":_vm.oldFormValues && _vm.oldFormValues.translation,"form-locale-lang":_vm.formSelectedLanguage || _vm.localeLang},on:{"translation-input-change":_vm.translationInputChange,"input-change":_vm.inputChange}})],1):(val.type === 'color_picker'
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentStep.includes(key)),expression:"currentStep.includes(key)"}]},[_c('Input',{attrs:{"type":"color","label":val.label || key,"disabled":val.disabled,"field-name":key,"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key),"translatable":val.translatable,"translation":_vm.oldFormValues && _vm.oldFormValues.translation,"form-locale-lang":_vm.formSelectedLanguage || _vm.localeLang},on:{"translation-input-change":_vm.translationInputChange,"input-change":_vm.inputChange}})],1):(val.type === 'unsigned'
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentStep.includes(key)),expression:"currentStep.includes(key)"}]},[_c('Input',{attrs:{"type":"number","min":0,"label":val.label || key,"field-name":key,"disabled":val.disabled,"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key),"translatable":val.translatable,"translation":_vm.oldFormValues && _vm.oldFormValues.translation,"form-locale-lang":_vm.formSelectedLanguage || _vm.localeLang},on:{"translation-input-change":_vm.translationInputChange,"input-change":_vm.inputChange}})],1):(val.type === 'decimal'
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentStep.includes(key)),expression:"currentStep.includes(key)"}]},[_c('Input',{attrs:{"type":"number","step":0.01,"label":val.label || key,"field-name":key,"disabled":val.disabled,"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key),"translatable":val.translatable,"translation":_vm.oldFormValues && _vm.oldFormValues.translation,"form-locale-lang":_vm.formSelectedLanguage || _vm.localeLang},on:{"translation-input-change":_vm.translationInputChange,"input-change":_vm.inputChange}})],1):(val.type === 'textarea'
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentStep.includes(key)),expression:"currentStep.includes(key)"}]},[_c('Input',{attrs:{"type":"textarea","label":val.label || key,"field-name":key,"disabled":val.disabled,"old-value":_vm.oldFormValues[key],"translatable":val.translatable,"translation":_vm.oldFormValues && _vm.oldFormValues.translation,"form-locale-lang":_vm.formSelectedLanguage || _vm.localeLang,"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"translation-input-change":_vm.translationInputChange,"input-change":_vm.inputChange}})],1):(val.type === 'file'
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentStep.includes(key)),expression:"currentStep.includes(key)"}]},[_c('FileInput',{attrs:{"type":"file","label":val.label || key,"translatable":val.translatable,"disabled":val.disabled,"field-name":key,"old-value":_vm.oldFormValues[key],"translation":_vm.oldFormValues && _vm.oldFormValues.translation,"validation-error":_vm.validationErrors[key] || _vm.validationErrors[("old_" + key + "_en")]
            || _vm.validationErrors[("old_" + key + "_am")] || _vm.validationErrors[("old_" + key + "_ru")],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key),"form-locale-lang":_vm.formSelectedLanguage || _vm.localeLang},on:{"translation-input-change":_vm.translationInputChange,"input-change":_vm.inputChange}})],1):((val.type === 'decimal_unsigned' || val.type === 'unsigned_decimal')
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentStep.includes(key)),expression:"currentStep.includes(key)"}]},[_c('Input',{attrs:{"type":"number","min":0,"step":"0.01","label":val.label || key,"field-name":key,"disabled":val.disabled,"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key),"translatable":val.translatable,"translation":_vm.oldFormValues && _vm.oldFormValues.translation,"form-locale-lang":_vm.formSelectedLanguage || _vm.localeLang},on:{"translation-input-change":_vm.translationInputChange,"input-change":_vm.inputChange}})],1):(val.type === 'radio'
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentStep.includes(key)),expression:"currentStep.includes(key)"}]},[_c('Radio',{attrs:{"label":val.label || key,"field-name":key,"disabled":val.disabled,"option-disable-server-side":val.disabledOptionDependsOn &&
            _vm.disableOptionChecker(val.disabledOptionDependsOn, key),"values":val.values,"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"input-change":_vm.inputChange}})],1):(val.type === 'select'
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentStep.includes(key)),expression:"currentStep.includes(key)"}]},[_c('Select',{attrs:{"type":val.type,"field-name":key,"label":val.label || key,"disabled":val.disabled
            || (val.disabledDependsOn && _vm.disabledChecker(val.disabledDependsOn)),"values":val.values,"relation-id":val.relationId,"relation":val.relation,"request-method":val.requestMethod,"server-side-dependence":(val.serverSideDependence
            && ( _obj = {}, _obj[val.serverSideDependence] = (_vm.formValue[val.serverSideDependence]
              || _vm.parentFormValue[val.serverSideDependence]), _obj )) || {},"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"input-change":_vm.inputChange}})],1):(val.type === 'select_multiple'
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentStep.includes(key)),expression:"currentStep.includes(key)"}]},[_c('MultipleSelect',{attrs:{"type":val.type,"field-name":key,"label":val.label || key,"disabled":val.disabled
            || (val.disabledDependsOn && _vm.disabledChecker(val.disabledDependsOn)),"values":val.values,"relation-id":val.relationId,"relation":val.relation,"request-method":val.requestMethod,"server-side-dependence":(val.serverSideDependence
            && ( _obj$1 = {}, _obj$1[val.serverSideDependence] = (_vm.formValue[val.serverSideDependence]
              || _vm.parentFormValue[val.serverSideDependence]), _obj$1 )) || {},"old-value":typeof _vm.oldFormValues[key] === 'string'
            ? JSON.parse(_vm.oldFormValues[key]) : _vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"input-change":_vm.inputChange}})],1):(val.type === 'select_multiple_paginated'
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentStep.includes(key)),expression:"currentStep.includes(key)"}]},[_c('PaginatedMultipleSelect',{attrs:{"type":val.type,"field-name":key,"label":val.label || key,"disabled":val.disabled
            || (val.disabledDependsOn && _vm.disabledChecker(val.disabledDependsOn)),"relation":val.relation,"request-method":val.requestMethod,"server-side-dependence":(val.serverSideDependence
            && ( _obj$2 = {}, _obj$2[val.serverSideDependence] = (_vm.formValue[val.serverSideDependence]
              || _vm.parentFormValue[val.serverSideDependence]), _obj$2 )) || {},"values":val.values,"old-value":typeof _vm.oldFormValues[key] === 'string'
            ? JSON.parse(_vm.oldFormValues[key]) : _vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"input-change":_vm.inputChange}})],1):(val.type === 'select_paginated'
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentStep.includes(key)),expression:"currentStep.includes(key)"}]},[_c('PaginatedSelect',{attrs:{"type":val.type,"field-name":key,"label":val.label || key,"disabled":val.disabled
            || (val.disabledDependsOn && _vm.disabledChecker(val.disabledDependsOn)),"relation":val.relation,"request-method":val.requestMethod,"server-side-dependence":(val.serverSideDependence
            && ( _obj$3 = {}, _obj$3[val.serverSideDependence] = (_vm.formValue[val.serverSideDependence]
              || _vm.parentFormValue[val.serverSideDependence]), _obj$3 )) || {},"values":val.values,"old-value":typeof _vm.oldFormValues[key] === 'string' && _vm.isJSON(_vm.oldFormValues[key])
            ? JSON.parse(_vm.oldFormValues[key]) : _vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key)},on:{"input-change":_vm.inputChange}})],1):(val.type === 'sender'
          && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentStep.includes(key)),expression:"currentStep.includes(key)"}]},[_c('SmsSender',{attrs:{"field-name":key,"label":val.label || key,"disabled":val.disabled,"path":val.path,"button-text":val.buttonText,"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key),"form-value":_vm.formValue}})],1):(val.type === 'array'
             && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentStep.includes(key)),expression:"currentStep.includes(key)"}]},[_c('Array',{attrs:{"form-data":val,"parent-form-value":_vm.formValue,"field-name":key,"label":val.label || key,"validation-error":_vm.validationErrors[key],"old-form-values":_vm.oldFormValues[key]},on:{"input-change":_vm.inputChange}})],1):(val.type === 'editor'
             && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentStep.includes(key)),expression:"currentStep.includes(key)"}]},[_c('TinyEditor',{attrs:{"type":"editor","label":val.label || key,"field-name":key,"disabled":val.disabled,"old-value":_vm.oldFormValues[key],"translatable":val.translatable,"translation":_vm.oldFormValues.translation,"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key),"form-locale-lang":_vm.formSelectedLanguage || _vm.localeLang},on:{"translation-input-change":_vm.translationInputChange,"input-change":_vm.inputChange}})],1):(val.type === 'tag'
             && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentStep.includes(key)),expression:"currentStep.includes(key)"}]},[_c('Tag',{attrs:{"type":"tag","label":val.label || key,"field-name":key,"disabled":val.disabled,"old-value":_vm.oldFormValues[key],"translatable":val.translatable,"translation":_vm.oldFormValues && _vm.oldFormValues.translation,"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key),"form-locale-lang":_vm.formSelectedLanguage || _vm.localeLang},on:{"translation-input-change":_vm.translationInputChange,"input-change":_vm.inputChange}})],1):(val.type === 'cron_expression'
             && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentStep.includes(key)),expression:"currentStep.includes(key)"}]},[_c('CronsTrue',{attrs:{"label":val.label || key,"field-name":key,"disabled":val.disabled,"old-value":_vm.oldFormValues[key],"validation-error":_vm.validationErrors[key],"is-validation-first-error":_vm.isValidationFirstError(_vm.validationErrors, key),"form-locale-lang":_vm.formSelectedLanguage || _vm.localeLang},on:{"translation-input-change":_vm.translationInputChange,"input-change":_vm.inputChange}})],1):(val.type === 'hidden'
        && (!val.depends_on || _vm.dependOnChecker(val.depends_on, key)))?_c('div',[_c('HiddenInput',{attrs:{"field-name":key,"old-value":_vm.oldFormValues[key]},on:{"input-change":_vm.inputChange}})],1):_vm._e()])}),_c('div',{staticClass:"submit-btn-wrapper",staticStyle:{"text-align":"end"}},[(_vm.ownSubmit && Object.keys(_vm.formData)[0])?_c('a-button',{attrs:{"type":"primary","loading":_vm.isLoading},on:{"click":function($event){$event.preventDefault();return _vm.$emit('form-data-submit', _vm.formValue)}}},[_vm._v(" Submit ")]):_vm._e(),_c('a-button',{style:({ marginRight: '8px' }),on:{"click":function($event){return _vm.$emit('toggle-modal')}}},[_vm._v(" Cancel ")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }