import { render, staticRenderFns } from "./ViewImageModal.vue?vue&type=template&id=6fef7770&scoped=true&"
import script from "./ViewImageModal.vue?vue&type=script&lang=js&"
export * from "./ViewImageModal.vue?vue&type=script&lang=js&"
import style0 from "./ViewImageModal.vue?vue&type=style&index=0&id=6fef7770&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fef7770",
  null
  
)

export default component.exports