<template>
  <div class="range-filter">
    <div v-if="type === 'date'">
      <FromToDatePicker
        :to-val="to"
        :from-val="from"
        :field="field"
        :label="label"
        @on-change="(value) => $emit('on-range-filter-input-change', value)"
      />
    </div>
    <div v-else class="to-from-num-range">
      <label class="from">
        <span class="filed-name">{{ label }}</span>
        <span class="range">From</span>
        <span class="input-wrapper">
          <a-input
            v-model="from"
            type="number"
            @change="onChange"
          />
        </span>
      </label>
      <label class="to">
        <span class="range">To</span>
        <span class="input-wrapper">
          <a-input
            v-model="to"
            type="number"
            @change="onChange"
          />
        </span>
      </label>
    </div>
  </div>
</template>

<script>
import FromToDatePicker from '../../FormElements/FromToDatePicker';

export default {
  components: { FromToDatePicker },
  // eslint-disable-next-line vue/require-prop-types
  props: ['toVal', 'fromVal', 'field', 'label', 'type'],
  data() {
    return {
      from: '',
      to: '',
    };
  },
  watch: {
    toVal(e) {
      this.to = e;
    },
    fromVal(e) {
      this.from = e;
    },
  },
  mounted() {
    this.setInputValues();
  },
  methods: {
    setInputValues() {
      this.to = this.toVal;
      this.from = this.fromVal;
    },
    onChange() {
      const value = {
        [this.field + '_to']: this.to,
        [this.field + '_from']: this.from,
      };
      this.$emit('on-range-filter-input-change', value);
    },
  },
};
</script>

<style scoped lang="scss">
.range-filter{
  text-align: left;
  display: flex;
  .to-from-num-range{
    display: flex;
  }
  label{
    display: flex;
    .input-wrapper{
      width: 200px;
    }
    .range{
      color: #696262;
      font-style: oblique;
      font-size: 12px;
    }
    .filed-name{
      display: inline-block;
      width: 150px;
    }
    span{
      margin: 0 15px;
    }
  }
}
</style>
