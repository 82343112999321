// import axios from 'axios';
import Vue from 'vue';
import socketError from '../utils/socketError';

export default {
  namespaced: true,

  state: {
    isEditFormModalOpen: false,
    isClientEditFormModalOpen: false,
    isCreateFormModalOpen: false,
    isRejectModalOpen: false,
    isDeleteModalOpen: false,
    isImageModalOpen: false,
    isEditBalanceFormModalOpen: false,
    isIframeModalOpen: false,
    isApproveModalOpen: false,
    isDeleteFormModalOpen: false,
    isChangePassModalOpen: false,
    isFrequencyModalOpen: false,
    dynamicFormModalInfo: null,
    isIntMessControlModalOpen: null,
  },
  mutations: {
    toggleChangePassModal(state, id) {
      if (id) {
        Vue.set(state, 'isChangePassModalOpen', id);
      } else {
        Vue.set(state, 'isChangePassModalOpen', false);
      }
    },
    toggleApproveModal(state, id) {
      if (id) {
        Vue.set(state, 'isApproveModalOpen', id);
      } else {
        Vue.set(state, 'isApproveModalOpen', false);
      }
    },
    toggleFrequencyModal(state, isOpen) {
      state.isFrequencyModalOpen = isOpen;
    },
    toggleIframeModal(state, reqUrl) {
      if (reqUrl) {
        Vue.set(state, 'isIframeModalOpen', reqUrl);
      } else {
        Vue.set(state, 'isIframeModalOpen', false);
      }
    },
    toggleCreateFormModal(state) {
      state.isCreateFormModalOpen = !state.isCreateFormModalOpen;
    },
    toggleViewImageModal(state, params) {
      if (params && params.url) {
        Vue.set(state, 'isImageModalOpen', params.url);
      } else {
        Vue.set(state, 'isImageModalOpen', false);
      }
    },
    toggleEditBalanceFormModal(state, params) {
      if (params && params.id) {
        Vue.set(state, 'isEditBalanceFormModalOpen', params.id);
      } else {
        Vue.set(state, 'isEditBalanceFormModalOpen', false);
      }
    },
    toggleClientEditFormModal(state, params) {
      if (params && params.id) {
        Vue.set(state, 'isClientEditFormModalOpen', params.id);
      } else {
        Vue.set(state, 'isClientEditFormModalOpen', false);
      }
    },
    toggleEditFormModal(state, params) {
      if (params && params.id) {
        Vue.set(state, 'isEditFormModalOpen', params.id);
      } else {
        Vue.set(state, 'isEditFormModalOpen', false);
      }
    },
    toggleDeleteFormModal(state, {
      moduleName,
      id,
      path,
      action,
    }) {
      Vue.set(state, 'isDeleteFormModalOpen', false);
      if (id) {
        Vue.set(state, 'isDeleteFormModalOpen', { [moduleName]: { id, path, action } });
      } else {
        Vue.set(state, 'isDeleteFormModalOpen', false);
      }
    },
    toggleDeleteModal(state, { moduleName, id, path }) {
      Vue.set(state, 'isDeleteModalOpen', false);
      if (id) {
        Vue.set(state, 'isDeleteModalOpen', { [moduleName]: { id, path } });
      } else {
        Vue.set(state, 'isDeleteModalOpen', false);
      }
    },
    toggleRejectModal(state, { data, id, action }) {
      if (data) {
        Vue.set(state, 'isRejectModalOpen', { data, id, action });
      } else {
        Vue.set(state, 'isRejectModalOpen', false);
      }
    },
    toggleDynamicFormModal(state, { id, action, path }) {
      if (id) {
        Vue.set(state, 'dynamicFormModalInfo', { id, action, path });
      } else {
        Vue.set(state, 'dynamicFormModalInfo', null);
      }
    },
    toggleIntMessControlModal(state, id) {
      if (id) {
        Vue.set(state, 'isIntMessControlModalOpen', id);
      } else {
        Vue.set(state, 'isIntMessControlModalOpen', false);
      }
    },
  },
  actions: {
    toggleChangePassModal({ commit }, params) {
      commit('toggleChangePassModal', params && params.id);
    },
    toggleIntMessControlModal({ commit }, params) {
      commit('toggleIntMessControlModal', params && params.id);
    },
    toggleApproveModal({ commit }, params) {
      commit('toggleApproveModal', params && params.id);
    },
    toggleIframeModal({ commit }, params) {
      commit('toggleIframeModal', params && params.reqUrl);
    },
    toggleFrequencyModal({ commit }, isOpen) {
      commit('toggleFrequencyModal', isOpen);
    },
    toggleClientEditFormModal({ commit }, params) {
      commit('toggleClientEditFormModal', params);
    },
    toggleEditBalanceFormModal({ commit }, params) {
      commit('toggleEditBalanceFormModal', params);
    },
    toggleViewImageModal({ commit }, params) {
      commit('toggleViewImageModal', params);
    },
    toggleEditFormModal({ commit }, params) {
      commit('toggleEditFormModal', params);
    },
    toggleCreateFormModal({ commit }) {
      commit('toggleCreateFormModal');
    },
    toggleDeleteModal({ commit }, params) {
      commit('toggleDeleteModal', params || {});
    },
    toggleDeleteFormModal({ commit }, params) {
      commit('toggleDeleteFormModal', params || {});
    },
    async toggleRejectModal({ commit }, params) {
      if (params) {
        try {
          const res = await axios.get(`${params.path}/${params.id}/${params.action}`);
          commit('toggleRejectModal', { data: res.data, id: params.id, action: res.data.action || (params.action === 'cancel-form' ? 'cancel' : params.action) });
          return res.data;
        } catch (e) {
          socketError({
          }, e);
          return false;
        }
      } else {
        return commit('toggleRejectModal', {});
      }
    },
    async toggleDynamicFormModal({ commit }, params) {
      commit('toggleDynamicFormModal', params || {});
    },
  },
  getters: {
    isImageModalOpen(state) {
      return state.isImageModalOpen;
    },
    isDeleteModalOpen(state) {
      return state.isDeleteModalOpen;
    },
    isRejectModalOpen(state) {
      return state.isRejectModalOpen;
    },
    isEditFormModalOpen(state) {
      return state.isEditFormModalOpen;
    },
    isCreateFormModalOpen(state) {
      return state.isCreateFormModalOpen;
    },
    isEditBalanceFormModalOpen(state) {
      return state.isEditBalanceFormModalOpen;
    },
    isIframeModalOpen(state) {
      return state.isIframeModalOpen;
    },
    isApproveModalOpen(state) {
      return state.isApproveModalOpen;
    },
    dynamicFormModalInfo(state) {
      return state.dynamicFormModalInfo;
    },
  },
};
