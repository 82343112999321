<template>
  <a-form-item
    ref="date-range-input"
    :label="label"
    :validate-status="validatingStatus"
    class="date-range-input"
    :help="validateHelp"
  >
    <template slot="label">
      <span class="date-range-picker-label">{{ label }}</span>
    </template>
    <DateRangePicker
      v-model="selectedSearchDate"
      date-util="moment"
      :ranges="presetList"
      :opens="pickerPosition"
      control-container-class="date-range-picker"
      :close-on-esc="true"
      :time-picker24-hour="true"
      :time-picker="true"
      :time-picker-increment="1"
      style="min-width: 180px"
    />
  </a-form-item>
</template>
<script>
import DateRangePicker from 'vue2-daterange-picker';

export default {
  components: { DateRangePicker },
  // eslint-disable-next-line vue/require-prop-types
  props: ['oldValue', 'field', 'label', 'defaultValue'],
  data() {
    return {
      moment,
      selectedSearchDate: { startDate: null, endDate: null },
      presetList: {},
      pickerPosition: 'right',
      validatingStatus: 'validating',
      validateHelp: '',
    };
  },
  watch: {
    selectedSearchDate(e) {
      if (e.startDate && e.endDate) {
        const date = {
          startDate: moment(this.selectedSearchDate.startDate).unix(),
          endDate: moment(this.selectedSearchDate.endDate).unix(),
        };
        this.$emit('input-change', { [this.field]: date });
      }
    },
    isValidationFirstError(e) {
      if (e) {
        this.$refs['date-range-input'].scrollIntoView();
      }
    },
    validationError(e) {
      if (e) {
        this.validatingStatus = 'error';
        // eslint-disable-next-line prefer-destructuring
        this.validateHelp = e[0];
      } else {
        this.deleteValidationErrors();
      }
    },
    oldValue(e) {
      if (e) {
        this.selectedSearchDate.startDate = e.startDate * 1000;
        this.selectedSearchDate.endDate = e.endDate * 1000;
      }
    },
  },
  async mounted() {
    await this.getPresetList().then(() => {
      if (this.defaultValue) {
        // eslint-disable-next-line prefer-destructuring
        this.selectedSearchDate.startDate = this.presetList.yesterday[0];
        // eslint-disable-next-line prefer-destructuring
        this.selectedSearchDate.endDate = this.presetList.yesterday[1];
      }
    });
    if (this.oldValue) {
      this.selectedSearchDate.startDate = this.oldValue.startDate * 1000;
      this.selectedSearchDate.endDate = this.oldValue.endDate * 1000;
    }
    if (this.selectedSearchDate.startDate && this.selectedSearchDate.endDate) {
      const date = {
        startDate: moment(this.selectedSearchDate.startDate).unix(),
        endDate: moment(this.selectedSearchDate.endDate).unix(),
      };
      this.$emit('input-change', { [this.field]: date });
    }
  },
  methods: {
    // update() {
    //   // const date = {
    //   //   startDate: moment(this.selectedSearchDate.startDate).unix(),
    //   //   endDate: moment(this.selectedSearchDate.endDate).unix(),
    //   // };
    //   // this.$emit('input-change', { [this.field]: date });
    // },
    async getPresetList() {
      const presets = await this.$store.dispatch('dashboard/getPresetList');
      this.presetList = _.mapValues(presets, (o) => ([
        moment.unix(o.start_date),
        moment.unix(o.end_date),
      ]));
    },
    deleteValidationErrors() {
      this.validatingStatus = 'validating';
      this.validateHelp = '';
    },
  },
};
</script>

<style lang="scss">
.date-range-input {
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
  }

  .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .no-gutters {
    margin-right: 0;
    margin-left: 0;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .daterangepicker .drp-buttons .btn {
    margin-left: 8px;
    font-size: 12px;
    font-weight: 700;
    padding: 8px;
    border: 0;
    border-radius: 3px;
    cursor: pointer;

    &.btn-success {
      color: #fff;
      background-color: #28a745;
      border-color: #28a745;
    }

    &.btn-default {
      background: transparent;
    }
  }

  .date-range-picker-label {
    font-size: 14px!important;
    font-weight: 400!important;
    color: rgba(0, 0, 0, 0.85);
  }

  .vue-daterange-picker {
    border: 1px solid #8080806e !important;
    padding: 0 5px;
    border-radius: 5px;
    min-width: 180px;
    width: 100%;
    height: 32px;
  }

  .date-range-picker {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;

    span {
      margin: 0 10px;
    }
  }
}
</style>
