<template>
  <div class="client" :class="{midSize}">
    <a-card v-for="(card, index) in data"
            :key="index"
            class="custom-card"
    >
      <div class="client-details-card-body">
        <span v-for="(row, ind) in card" :key="ind" class="client-details-row">
          <span>{{ row.label }}</span>
          <span v-if="!row.type || row.type === 'text'">{{ row.value }}</span>
          <span v-else-if="row.type === 'date'">
            {{ row.value && isNaN(row.value) ? moment(row.value).format('YYYY-MM-DD') :
              (row.value && row.value.length > 10
                && moment(JSON.parse(row.value)).format('YYYY-MM-DD')
                || (row.value && moment(JSON.parse(row.value) * 1000).format('YYYY-MM-DD')))
              || null
            }}
          </span>
          <span v-else-if="row.type === 'icon'">
            <a-icon v-if="row.value === 1"
                    type="check-circle"
                    two-tone-color="#00DC30"
                    theme="twoTone"
                    style="color: #00DC30"
            />
            <a-icon v-else-if="row.value === 0"
                    theme="twoTone"
                    style="color: #FF1818"
                    type="close-circle"
                    two-tone-color="#FF1818"
            />
          </span>
        </span>
      </div>
    </a-card>
  </div>
</template>

<script>
export default {
  props: {
    midSize: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      moment,
      data1: [
        [
          {
            label: 'Idsdfsd',
            value: 324,
            type: 'text',
          },
          {
            label: 'Id',
            value: 32435,
            type: 'text',
          },
          {
            label: 'Id',
            value: 32435,
            type: 'text',
          },
          {
            label: 'Id',
            value: 32435,
            type: 'text',
          },
          {
            label: 'Id',
            value: 32435,
            type: 'text',
          },
          {
            label: 'Id',
            value: 32435,
            type: 'text',
          },
        ],
        [
          {
            label: 'Id',
            value: 32435,
            type: 'text',
          },
          {
            label: 'Id',
            value: 32435,
            type: 'text',
          },
          {
            label: 'Id',
            value: 32435,
            type: 'text',
          },
          {
            label: 'Id',
            value: 32435,
            type: 'text',
          },
          {
            label: 'Id',
            value: 32435,
            type: 'text',
          },
          {
            label: 'Id',
            value: 322222222222435,
            type: 'text',
          },
        ],
        [
          {
            label: 'Active',
            value: 1,
            type: 'icon',
          },
          {
            label: 'Passive',
            value: 0,
            type: 'icon',
          },
          {
            label: 'Active',
            value: 1,
            type: 'icon',
          },
          {
            label: 'Passive',
            value: 0,
            type: 'icon',
          },
          {
            label: 'Active',
            value: 1,
            type: 'icon',
          },
          {
            label: 'Passive',
            value: 0,
            type: 'icon',
          },
        ],
      ],
    };
  },
};
</script>
<style lang="scss">
.client{
  flex-grow: 1;
  display: flex;
  margin-left: 12px;
  justify-content: space-between;
  div:last-child{
    margin-right: 0;
  }
  &.midSize {
    margin-left: 0;
    .custom-card{
      margin: 0 4px;
      &.ant-card {
        .ant-card-body {
          padding: 6px 10px!important;
          .client-details-card-body{
            .client-details-row{
              margin: 0;
              line-height: 18px;
              font-size: 12px;
            }
          }
        }
      }
    }
    div:last-child{
      margin-right: 0;
    }
    div:first-child{
      margin-left: 0;
    }
  }
  .custom-card{
    margin: 0 12px;
   &.ant-card{
     border-radius: 2px!important;
     flex-grow: 1;
     .ant-card-body {
       padding: 16px!important;
       .client-details-card-body{
         display: flex;
         flex-direction: column;
         .client-details-row{
           display: flex;
           justify-content: space-between;
           color: #000000;
           line-height: 22px;
           margin-bottom: 4px;
           span:first-child{
             color: #979797;
             margin-right: 10px;
           }
         }
       }
     }
   }
  }
}
</style>
